/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 30/03/2024
/* ******************************************* */

import React from 'react';
import SidebarLayout from '../../templates/SidebarLayout/SidebarLayout';
import ManageCategoryForms from '../../organisms/ManageCategoryForms/ManageCategoryForms';

function ManageCategoryPage() {
  return (
    <SidebarLayout
      menuTitle={'Gestion des catégories'}
      menuRoutePath={'Gestion des catégories '}
    >
      <ManageCategoryForms />
    </SidebarLayout>
  );
}

export default ManageCategoryPage;
