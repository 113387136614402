/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 23/05/2024
/* ******************************************* */

import React, { useState, useEffect, useRef } from 'react';
import { collection, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import {
  useDisclosure,
  useToast,
  Button,
  Text,
  VStack,
} from '@chakra-ui/react';
import EditBlogForm from '../EditBlogForm/EditBlogForm';
import BlogItem from '../BlogItem/BlogItem';
import AlertPopup from '../../atoms/AlertPopup/AlertPopup';
import { CiEdit } from 'react-icons/ci';
import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const ManageBlogListContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const ManageBlogCard = styled.div`
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
`;

function ManageBlog() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  // Gère la suppresion et la modification de l'avis
  const cancelRef = useRef();
  const [selectedBlog, setSelectedBlog] = useState(null);
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  function openDeleteDialog(blog) {
    setSelectedBlog(blog);
    onDeleteOpen();
  }

  function closeDeleteDialog() {
    setSelectedBlog(null);
    onDeleteClose();
  }

  async function deleteBlog() {
    if (!selectedBlog) return;
    try {
      const blogRef = doc(firestore_db, TABLE_NAME.blogs, selectedBlog.id);
      await deleteDoc(blogRef);
      toast({
        title: 'Article supprimé',
        description: "L'article a été supprimé avec succès.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur de suppresion ',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    onDeleteClose(); // Fermez le modal après la suppression
  }

  // Modification de la blog
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  function openEditDialog(blog) {
    setSelectedBlog(blog);
    onEditOpen();
  }

  // recupère les données de firestore
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, TABLE_NAME.blogs),
      (snapshot) => {
        const blogSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(blogSnapshot);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  if (loading) {
    console.log('Loading...');
  }

  return (
    <VStack pt={2} borderTop="1px" borderColor="gray.200" mt={10} mb={2}>
      <Text fontSize="2xl" as="b" textAlign="left">
        {' '}
        Listes des articles sanbbiz{' '}
      </Text>

      <ManageBlogListContainer>
        {blogs.map((blog) => (
          <ManageBlogCard key={blog.id}>
            {' '}
            <BlogItem currentBlog={blog} />
            <Button
              position="absolute"
              right="0"
              top="0"
              size="xs"
              colorScheme="red"
              zIndex="1"
              onClick={() => openDeleteDialog(blog)}
            >
              &#10005; {/* Croix */}
            </Button>
            <Button
              position="absolute"
              right={2}
              bottom={2}
              size="sm"
              colorScheme="blue"
              p={0}
              onClick={() => openEditDialog(blog)}
            >
              <CiEdit />
            </Button>
          </ManageBlogCard>
        ))}
        {isDeleteOpen && (
          <AlertPopup
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={closeDeleteDialog}
            onNo={closeDeleteDialog}
            onYes={deleteBlog}
            windowTitle="Supprimer l'article"
          >
            Êtes-vous sûr de vouloir supprimer l'article :{selectedBlog?.title}{' '}
            ?
          </AlertPopup>
        )}
        {isEditOpen && (
          <EditBlogForm
            blog={selectedBlog}
            isOpen={isEditOpen}
            onClose={onEditClose}
          />
        )}
      </ManageBlogListContainer>
    </VStack>
  );
}

export default ManageBlog;
