/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import CategoryFilter2GetEvent from '../../molecules/CategoryFilter2GetEvent/CategoryFilter2GetEvent';
import EventsListComponent from '../../molecules/EventsListComponent/EventsListComponent';
import { collection, onSnapshot, where, query } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import { useLocation } from 'react-router-dom';
import { look4NearestEventSlot } from '../../../utils/function';
import { checkIfIsPastDate } from '../../../utils/date';
import styled from 'styled-components';
import { EVENT_REVIEW_STATE } from '../../../utils/constants';

import { Button } from '@chakra-ui/react';
import { TABLE_NAME } from '../../../utils/variables';

const NoResultsMessageContainer = styled.div`
  max-width: 700px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Ce composant est commun à NoResultsMessage et EventList
const SectionTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
`;

const AppliedFilterType = styled.div`
  font-size: 20px;
  padding-bottom: 10px;
  text-align: center;
`;

const NoResultsMessageText = styled.div`
  font-style: italic;
  font-size: 20px;
  padding-top: 10px;
`;

function NoResultsMessage({ searchText, selectedCategories, categories }) {
  const categoryNames = categories
    .filter((cat) => selectedCategories?.includes(cat.id))
    .map((cat) => cat.name)
    .join(', ');

  const hasTextFilter = searchText && searchText.trim() !== '';
  const hasCategoryFilter = selectedCategories?.length > 0;
  // console.log('hasTextFilter', hasTextFilter);
  // console.log('hasCategoryFilter', hasCategoryFilter);

  return (
    <NoResultsMessageContainer>
      <SectionTitle>Oups, pas de résultats pour :</SectionTitle>
      {hasTextFilter && (
        <AppliedFilterType>
          <strong>Texte:</strong> <br /> {searchText}
        </AppliedFilterType>
      )}
      {hasCategoryFilter && (
        <AppliedFilterType>
          <strong>Catégories:</strong> <br /> {categoryNames}
        </AppliedFilterType>
      )}
      <NoResultsMessageText>
        {!hasCategoryFilter && !hasTextFilter
          ? 'Les événements de cette catégorie seront disponibles prochainement.'
          : 'Veuillez ajuster vos critères de recherche ou explorer la liste complète des événements ci-dessous.'}
      </NoResultsMessageText>
    </NoResultsMessageContainer>
  );
}

// Récupérer les paramètres de la chaîne de requête de l'URL
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const EventListNFilterContainer = styled.div`
  width: 100%;
  display: grid;

  @media screen and (min-width: 720px) {
    grid-template-columns: 300px 1fr;
    gap: 20px;
  }
`;

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

function EventList({ selectedCategoriesProp }) {
  // Gère la redirection quand on clique sur une catégorie
  // Récupère les paramètres de l'URL à l'aide du hook useQuery.
  const urlQuery = useQuery();

  // Extrait la valeur du paramètre 'category' de l'URL.
  const initialCategory = urlQuery.get('category');

  // Obtenir les events
  const [events, setEvents] = useState([]);

  // Obtenir les categories
  const [categories, setCategories] = useState([]);
  // Filtrer par categories
  const [selectedCategories, setSelectedCategories] = useState(() => {
    return initialCategory ? [initialCategory] : [];
  });

  // // Synchronise l'état des catégories sélectionnées avec localStorage.
  // useEffect(() => {
  //   // Sauvegarde les catégories sélectionnées dans localStorage pour la persistance à travers les sessions.
  //   localStorage.setItem(
  //     'selectedCategories',
  //     JSON.stringify(selectedCategories)
  //   );
  // }, [selectedCategories]);

  // filtrer par texte
  const [searchText, setSearchText] = useState('');

  const [loading, setLoading] = useState(true);

  // Souscription aux événements acceptés
  useEffect(() => {
    const q = query(
      collection(firestore_db, TABLE_NAME.events),
      where('review.state', '==', EVENT_REVIEW_STATE.ACCEPTED)
    );
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        setEvents(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
        setLoading(false);
      }
    );

    return () => unsubscribe(); // Nettoyage
  }, []);

  // Souscription aux catégories
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, TABLE_NAME.categories),
      (snapshot) => {
        setCategories(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
        setLoading(false);
      }
    );

    return () => unsubscribe(); // Nettoyage
  }, []);

  // useEffect qui réagit aux changements de initialCategory.
  useEffect(() => {
    // Si initialCategory est présent, met à jour les catégories sélectionnées.
    if (initialCategory) {
      setSelectedCategories([initialCategory]);
    }
  }, [initialCategory]);

  const handleCategoryChange = (categoryId, isChecked) => {
    const updatedCategories = isChecked
      ? [...selectedCategories, categoryId]
      : selectedCategories.filter((id) => id !== categoryId);
    setSelectedCategories(updatedCategories);
    // localStorage.setItem(
    //   'selectedCategories',
    //   JSON.stringify(updatedCategories)
    // );
  };

  const handleFilterByText = (text) => setSearchText(text);

  const filteredEvents = events.filter((event) => {
    // Filtrer par catégories
    const isCategoryMatch =
      selectedCategories?.length === 0 ||
      selectedCategories.includes(event.categoryId);

    // Filtrer par texte
    const textLower = searchText.toLowerCase();
    const isTextMatch =
      searchText === '' ||
      event.name.toLowerCase().includes(textLower) ||
      event.description.toLowerCase().includes(textLower);

    // Filtrer par dates (événements futurs)
    const slotIndex = look4NearestEventSlot(event);
    let isDateValid = false;
    if (slotIndex !== undefined) {
      const nearestDate = event.dates[slotIndex].end.toDate();
      isDateValid = !checkIfIsPastDate(1, nearestDate);
    }

    return isCategoryMatch && isTextMatch && isDateValid;
  });

  if (loading) {
    return <Button size={'xl'} isLoading></Button>;
  }

  return (
    <EventListNFilterContainer>
      <CategoryFilter2GetEvent
        categories={categories}
        selectedCategories={selectedCategories}
        onCategoryChange={handleCategoryChange}
        filterByText={handleFilterByText}
      />
      {filteredEvents?.length > 0 ? (
        <ListContainer>
          <EventsListComponent events={filteredEvents} />
        </ListContainer>
      ) : (
        <ListContainer>
          <NoResultsMessage
            searchText={searchText}
            selectedCategories={selectedCategories}
            categories={categories}
          />
          <EventsListComponent events={events} />
        </ListContainer>
      )}
    </EventListNFilterContainer>
  );
}

export default EventList;
