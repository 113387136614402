/* ******************************************** */
/* * Author: Jamal SIDIKOU
/* * Project : SANNBIZ
/* * Create at : 03/06/2024
/* ******************************************* */

import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import PaymentTicketItem from '../../organisms/PaymentTicketItem/PaymentTicketItem';
import {
  addDoc,
  and,
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  addDocument2Collection,
  readCollection,
} from '../../../api/firebase/firestore';
import {
  CINETPAY,
  ROUTES,
  TABLE_NAME,
  USER_STATUS,
  env,
} from '../../../utils/variables';
import { checkIfIsSameDate } from '../../../utils/date';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { Divider, Switch, useToast } from '@chakra-ui/react';
import Popup from '../../atoms/Popup/Popup';
import orangeMoney from '../../../assets/Orange-Money-logo.png';
import moovMoney from '../../../assets/Moov_Money_logo.png';
import { TailSpin, ThreeDots } from 'react-loader-spinner';
import { Spinner } from '@chakra-ui/react';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 3%;
  padding-right: 3%;
  justify-content: center;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-bottom: 25px;
`;

const LeftContainer = styled.div`
  width: 65%;
  /* height: 100%; */
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  align-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
`;

const RightContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  /* margin-right: 10px; */
`;

const ChargeLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: black;
  margin-bottom: 5px;
`;

const PageTitle = styled.h2``;

const InputContainer = styled.div`
  /* position: relative; */
  display: flex;
  /* align-content: center; */
  border-width: 1px;
  border-color: green;
  /* width: 30px; */
  height: 35px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
`;
const PromoInput = styled.input`
  /* background: "white"; */
  border: 0;
  border-radius: 10px;
  border-color: blue;
  color: white;
  /* padding: 20px; */
  height: 90%;
  /* width: 100px; */
  width: 75%;
  /* :focus{
    outline: none;
  } */
`;

const ButtonInput = styled.div`
  display: flex;
  align-items: center;
  background: 'white';
  height: 100%;
  color: lightgreen;
  font-weight: bold;
  padding: 0px;
  height: 100%;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: x-large;
`;

const Subtitle = styled.h2`
  font-weight: bold;
`;

const TotalAmount = styled.h2`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: green;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f1360a;
  position: relative;
  color: white;
  font-size: 15px;
  margin: 1em;
  padding: 0.25em 1em;
  border-radius: 3px;
`;

const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  margin-top: 50px;
`;

const SelectContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const BoxPayment = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 150px;
  margin-right: 5px;
  position: relative;
  border: 1px solid #ccc;
  box-sizing: border-box;
  justify-content: space-around;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const ImgSelect = styled.img`
  width: 75px;
  height: 58px;
  object-fit: contain;
  border: 0;
  /* border-radius: 10px; */
`;

function Checkout() {
  const { userLoggedIn, currentUser } = useAuth();
  const toast = useToast();

  const navigate = useNavigate();

  const [queryParameters] = useSearchParams();
  const eventId = queryParameters.get('eventId');
  const ticket = queryParameters.get('ticket');
  const schedule = queryParameters.get('scheduleSelected');
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [event, setEvent] = useState(null);
  const [paymentUrl, setpaymentUrl] = useState('');
  const [transactionId, settransactionId] = useState(null);
  const [transactionDocumentRef, settransactionDocumentRef] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [ticketAvailable, setTicketAvailable] = useState(6);
  const [validity, setValidity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isCaurisUsed, setisCaurisUsed] = useState(false);

  const fakePrice = 500;

  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const openModal = () => {
    setShowModal(true);
  };

  // Fonction pour fermer le modal et effectuer le checkPaymentResult
  const closeModalAndCheckPayment = () => {
    setShowModal(false);
    checkPayment();
  };

  const updatePriceAndQuantity = (symbol) => {
    let tmpPrice = selectedVersion.price;
    let tmpQuantity = quantity;
    if (ticketAvailable === 0) {
      toast({
        title: 'Limite de tickets atteinte',
        description:
          'Vous avez atteint la limite de tickets que vous pouvez acheter.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    switch (symbol) {
      case '+':
        if (quantity >= ticketAvailable) {
          toast({
            title: 'Total de tickets atteint',
            description:
              'Vous avez atteint la limite de tickets que vous pouvez acheter.',
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
          break;
        }
        tmpQuantity += 1;
        setQuantity(tmpQuantity);
        tmpPrice = tmpPrice * tmpQuantity;
        setPrice(tmpPrice);
        break;
      case '-':
        if (quantity <= 1) {
          toast({
            title: 'Vous ne pouvez aller en dessous',
            description:
              'Vous ne pouvez pas aller en dessous de la quantité actuelle.',
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
          break;
        }
        tmpQuantity -= 1;
        setQuantity(tmpQuantity);
        const newPrice = tmpPrice * tmpQuantity;
        setPrice(newPrice);
        break;
      default:
        break;
    }
    if (quantity === 0) {
      setPrice(0);
    }
  };

  useEffect(() => {
    const getEvent = async () => {
      setLoading(true);
      const eventRef = doc(firestore_db, TABLE_NAME.events, eventId);

      try {
        const doc = await getDoc(eventRef);

        if (!doc.exists()) console.log('No such document!');
        const newEvent = { id: doc.id, ...doc.data() };
        setValidity(newEvent.dates[parseInt(schedule)]);
        setEvent(newEvent);
        setSelectedVersion(newEvent.ticketVersions[ticket]);
        setPrice(newEvent.ticketVersions[ticket].price);
      } catch (error) {
        console.error('Error getting document:', error);
      }
      setLoading(false);
    };

    getEvent();
  }, [eventId]);

  useEffect(() => {
    const getAvailabilityTickets = async () => {
      setLoading(true);
      if (
        currentUser?.authProfile.uid &&
        ticket &&
        eventId &&
        validity !== null &&
        validity !== undefined
      ) {
        try {
          const q = query(
            collection(firestore_db, TABLE_NAME.tickets),
            where('versionId', '==', parseInt(ticket)),
            where('customerId', '==', currentUser.authProfile.uid),
            where('eventId', '==', eventId)
            // where('validity', "==", validity)
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot) console.log('No such document!');

          let stockSold = 0;

          querySnapshot.forEach((doc) => {
            const docValidity = doc.data().validity;
            if (
              (validity.start.seconds === docValidity.start.seconds &&
                validity.end.seconds === docValidity.end.seconds) ||
              doc.data().isStand === true
            )
              stockSold++;
          });

          // Pour éviter les erreurs si availibility est négatif on le set à zéro
          const availability = ticketAvailable - stockSold;
          if (availability <= 0) {
            setTicketAvailable(0);
            setQuantity(0);
            setPrice(0);
          } else setTicketAvailable(availability);
        } catch (error) {
          console.error('Error getting document:', error);
        }
      }
      setLoading(false);
    };
    getAvailabilityTickets();
  }, [validity]);

  // const [select, setSelect] = useState('cinetpay');

  // const handleSelectChange = (e) => {
  //   console.log('hemmp');
  //   const value = e.target.value;
  //   console.log(value);
  //   setSelect(value);
  // };

  async function createTickets(ticket) {
    let status = false;
    let count = 0;
    do {
      const colRef = collection(firestore_db, TABLE_NAME.tickets);
      const result = await addDoc(colRef, ticket);
      if (result) {
        status = true;
      }
      count++;
    } while (status === true && count < quantity);
    setLoadingButton(false);
    return status;
  }

  async function checkPaymentResult(transactionId) {
    let paymentResult;
    const configCheck = {
      method: 'post',
      url: CINETPAY.PAYMENT_RESULT_CHECK_URL,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        apikey: CINETPAY.APIKEY,
        site_id: CINETPAY.SITE_ID,
        transaction_id: transactionId,
      },
    };
    try {
      const response = await axios(configCheck);
      paymentResult = response.data;
      return paymentResult;
    } catch (error) {
      toast({
        title: 'Oups...',
        description:
          'Une erreur est survenue. Veuillez réessayer dans quelques minutes.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  // Test phone number: 74 33 89 33
  async function openCinetPay(transactionDocumentRef) {
    let status;
    // Payment amount
    if (price === 0) {
      status = true;
    } else {
      let url; // CinetPay payment url
      let code; // Payment query error code
      // Id of the new transaction document created
      settransactionDocumentRef(transactionDocumentRef);
      const transaction_Id = transactionDocumentRef.id;
      // (2)
      // Payment configuration
      const paymentData = JSON.stringify({
        apikey: CINETPAY.APIKEY,
        site_id: CINETPAY.SITE_ID,
        transaction_id: transaction_Id,
        amount: price,
        currency: 'XOF',
        description: 'tickets_payment',
        channels: 'MOBILE_MONEY',
      });
      const configPayment = {
        method: 'post',
        url: CINETPAY.PAYMENT_URL,
        headers: {
          'Content-Type': 'application/json',
        },
        data: paymentData,
      };
      // (3)
      // Look for payment url.
      try {
        const callApiCinetPay = await axios(configPayment);
        // console.log('then: reponse', callApiCinetPay);
        let temp = JSON.stringify(callApiCinetPay.data);
        temp = JSON.parse(temp);
        if (temp.code !== undefined && temp.data.payment_url !== undefined) {
          code = parseInt(temp.code);
          url = temp.data.payment_url;
        } else {
          // do something...
          toast({
            title: 'Oups...',
            description:
              'Une erreur est survenue. Veuillez réessayer dans quelques minutes.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          setLoadingButton(false);
          return;
        }
      } catch (error) {
        // TODO: handle axios errors
        toast({
          title: 'Oups...',
          description:
            'Une erreur est survenue. Veuillez réessayer dans quelques minutes.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setLoadingButton(false);
        return;
      }
      if (code === CINETPAY.RESULTS.PAYMENT.URL_CREATED && url !== undefined) {
        // (4)
        // verifier si ca passe et on set le paymentUrl et on ouvre le modal.
        setpaymentUrl(url);
        settransactionId(transaction_Id);
        openModal();
        // (5)
      } else {
        // Show alert error: unable to generate payment link.
        toast({
          title: 'Oups...',
          description:
            'Une erreur est survenue. Veuillez réessayer dans quelques minutes.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setLoadingButton(false);
        return;
      }
    }
    return status;
  }

  async function checkPayment() {
    let code;
    //  on remplace les booleens des tatus par des 0, 1 pour pouvoir gérer plus de status
    let status = 1;
    // Check payment result and update database transaction state.
    let paymentResult = await checkPaymentResult(transactionId);
    if (paymentResult !== undefined) {
      code = parseInt(paymentResult.code);
 
      if (
        code === CINETPAY.RESULTS.PAYMENT_CHECK.OTP_CODE_ERROR ||
        code === CINETPAY.RESULTS.PAYMENT_CHECK.PAYMENT_FAILED ||
        code === CINETPAY.RESULTS.PAYMENT_CHECK.TRANSACTION_CANCEL ||
        code === CINETPAY.RESULTS.PAYMENT_CHECK.WAITING_CUSTOMER_PAYMENT
      ) {
        status = 0;
      }
      // je pense qu'on arrive jamais dans ce cas parce que si le prix est à 0
      // on crée lees tickets sans ouvrir cinetpay
      // if (price === 0) {
      //   status = 1;
      // }
    } else {
      // do something...
    }
    // En dev, on génère le ticket meme si on valide pas le paiement
    if (env === "dev") {
      status = 1;
    }
    if (transactionDocumentRef) {
      const docRef = doc(firestore_db, TABLE_NAME.transactions, transactionId);
      await updateDoc(docRef, { cinetpay: paymentResult });
    }
    if (status === 1) {
      const document = {
        versionId: selectedVersion.id,
        validity: validity,
        customerId: currentUser.authProfile.uid,
        eventId: event.id,
        transactionId: transactionDocumentRef.id,
        scans: [],
        isRefund: false,
      };
      const createTicketsStatus = await createTickets(document);

      if (createTicketsStatus) {
        navigate(ROUTES.orderSucceedPage);
      }
    } else {
      // do something...
      setLoadingButton(false);
      // console.log('error in checkpayment');
    }
  }

  async function checkIfBalanceExist() {
    // (0)
    // Check if a balance document exists for this event.
    let existingBalance;

    const q = query(
      collection(firestore_db, TABLE_NAME.balances),
      where('eventId', '==', event.id)
    );
    try {
      const balances = await getDocs(q);
      balances.docs.forEach((document) => {
        const balance = { id: document.id, data: document.data() };
        const { availableAt, isLocked } = balance.data;
        if (
          isLocked === false &&
          checkIfIsSameDate(1, endTimeStamp.toDate(), availableAt.toDate()) ===
            true
        ) {
          existingBalance = balance;
        } else {
          existingBalance = undefined;
        }
      });
    } catch {
      // console.log('error à quelque part');
    }
    return existingBalance;
  }

  async function pay() {
    setLoadingButton(true);
    if (event.id === undefined) {
      setLoadingButton(false);
      return;
    }
    if (
      // (true) {
      currentUser === USER_STATUS.GUEST ||
      !currentUser
    ) {
      //  -------- Notification connexion requise
      toast({
        title: 'Connexion requise',
        description:
          'Vous pouvez acheter des tickets uniquement si vous êtes connectés.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      // toggleCheckoutModal();
      setLoadingButton(false);
      return;
    }
    if (ticketAvailable === 0) {
      //  -------- Notification connexion requise
      toast({
        title: 'nombre de tickets max atteint',
        description:
          'Vous avez atteint le nombre maximum de tickets que vous pouvez acheter.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      setLoadingButton(false);
      return;
    }
    let status = false;
    // Check stock availability.
    let stock = selectedVersion.stock;
    try {
      const q = query(
        collection(firestore_db, TABLE_NAME.tickets),
        where('eventId', '==', eventId),
        where('versionId', '==', parseInt(ticket))
      );
      const querySnapshot = await getDocs(q);

      let stockSold = [];
      querySnapshot.forEach((document) => {
        const doc = { id: document.id, data: document.data() };
        stockSold.push(doc);
      });

      stockSold = stockSold.filter(
        (ticketInStock) =>
          validity.start.seconds ===
            ticketInStock.data.validity.start.seconds &&
          validity.end.seconds === ticketInStock.data.validity.end.seconds
        // // voir avec William pour les stands
        // ||
        //   event.ticketVersions[ticketInStock.data.versionId].isStand === true
      );
      stock = stock - stockSold.length;
    } catch (error) {
      console.error('Error getting document:', error);
      stock = 0;
    }

    if (!(quantity <= stock)) {
      toast({
        title: 'Oups...',
        description:
          stock > 0
            ? `Il ne reste plus que ${stock} tickets ${selectedVersion.name} disponibles.`
            : `Tu arrives trop tard...\nLes tickets ${selectedVersion.name} ont tous été vendus.`,
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      setLoadingButton(false);
      return;
    }
    // (0)
    // Check if a balance document exists for this event.
    let existingBalance = await checkIfBalanceExist();

    if (existingBalance === undefined) {
      const document = {
        amount: 0,
        availableAt: endTimeStamp,
        eventId: event.id,
        promoterId: event.createdBy,
        commission: event.mngtPlan.commission,
        transactionIds: [],
        isLocked: false,
        isCashedOut: false,
      };
      const finalDocument = {
        ...document,
        createdAt: serverTimestamp(),
      };
      const colRef = collection(firestore_db, TABLE_NAME.balances);
      await addDoc(colRef, finalDocument);
    } else {
      // do something...
    }
    // (1)
    // Save transaction data into the database.
    const transaction = {
      createdAt: serverTimestamp(),
      customerId: currentUser.authProfile.uid,
      state: 0,
    };
    const transactionDocumentRef = await addDoc(
      collection(firestore_db, TABLE_NAME.transactions),
      transaction
    );
    if (!transactionDocumentRef) {
      // Show alert error: unable to create transaction id.
      setLoadingButton(false);
      toast({
        title: 'Oups...',
        description:
          'Une erreur est survenue. Veuillez réessayer dans quelques minutes.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    } else {
      settransactionDocumentRef(transactionDocumentRef);
      // Proceed to payment on CinetPay.
      if (price === 0) {
        const document = {
          versionId: selectedVersion.id,
          validity: validity,
          customerId: currentUser.authProfile.uid,
          eventId: event.id,
          transactionId: transactionDocumentRef.id,
          scans: [],
          isRefund: false,
        };
        status = await createTickets(document);
      } else {
        status = await openCinetPay(transactionDocumentRef);
      }
    }
  }

  // const changeIsCaurisUsed = () => {
  //   console.log('new isCaurisUsed');
  //   console.log(!isCaurisUsed);
  //   setisCaurisUsed(!isCaurisUsed);
  // };

  const endTimeStamp = validity?.end;
  const startTimeStamp = validity?.start;

  const location = `${event?.location.place}, ${event?.location.city}`;
  return (
    <Container>
      <ContentContainer>
        <LeftContainer>
          {loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
              }}
            >
              <Spinner size="xl" color="#f1360a" radius={'8px'} />
            </div>
          ) : (
            <PaymentTicketItem
              title={event?.name}
              startTimeStamp={startTimeStamp}
              endTimeStamp={endTimeStamp}
              location={location}
              price={selectedVersion?.price}
              quantity={quantity}
              img={event?.posterPicture}
              updatePriceAndQuantity={updatePriceAndQuantity}
              availability={ticketAvailable}
            />
          )}
          <Divider borderWidth="1px" borderColor="gray.300" />
          <PaymentContainer>
            <Subtitle> Méthodes de paiement</Subtitle>
            <SelectContainer>
              <BoxPayment>
                {/* <RadioButton
                  type="radio"
                  name="select"
                  value="cinetpay"
                  checked={select === 'cinetpay'}
                  onChange={handleSelectChange}
                /> */}
                <ImgSelect src={orangeMoney} alt="etc" />
                {/* <ImgSelect src={event?.posterPicture?.url}/> */}
              </BoxPayment>
              <BoxPayment>
                {/* <RadioButton
                  type="radio"
                  name="select"
                  value="cinetpay"
                  checked={select === 'cinetpay'}
                  onChange={handleSelectChange}
                /> */}
                <ImgSelect src={moovMoney} alt="etc" />
                {/* <ImgSelect src={event?.posterPicture?.url}/> */}
              </BoxPayment>
            </SelectContainer>
          </PaymentContainer>
        </LeftContainer>

        <RightContainer>
          <Subtitle>Résumé du Paiement</Subtitle>
          {/* <InputContainer>
            <PromoInput placeholder="Code Coupon" type="text" />
            <ButtonInput>Appliquer</ButtonInput>
          </InputContainer> */}

          {/* spacing */}
          <div style={{ marginTop: '20px' }} />

          {/* <Subtitle>Taxes et frais</Subtitle> */}
          <ChargeLine>
            <Subtitle>Prix</Subtitle>
            <Subtitle>{`${price} F CFA`}</Subtitle>
          </ChargeLine>
          {/* <ChargeLine>
                <Subtitle>Taxe</Subtitle>
                <Subtitle>0 F CFA</Subtitle>

                </ChargeLine>
                <ChargeLine>
                <Subtitle>Frais de service</Subtitle>
                <Subtitle>0 F CFA</Subtitle>

                </ChargeLine>
                <ChargeLine>
                <Subtitle>Réduction</Subtitle>
                <Subtitle>0 F CFA</Subtitle>
                </ChargeLine> */}

          {/* spacing */}
          <div style={{ marginTop: '20px' }} />

          <Divider borderWidth="1px" borderColor="gray.300" />

          <TotalAmount>
            <Subtitle style={{ color: 'green' }}>Prix total</Subtitle>
            <Subtitle style={{ color: 'green' }}> {`${price} F CFA`}</Subtitle>
          </TotalAmount>
          {/* <ChargeLine>
          <Switch isChecked={isCaurisUsed} onChange={changeIsCaurisUsed}/>
          utiliser vos cauris
          </ChargeLine> */}
          <Button onClick={pay} disabled={loadingButton}>
            {loadingButton ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spinner color="white" radius={'8px'} />
              </div>
            ) : (
              'Commander'
            )}
          </Button>
        </RightContainer>
      </ContentContainer>
      <Popup
        isOpen={showModal}
        onClose={closeModalAndCheckPayment}
        // showCloseIcon={false}
        isCentered
        styles={{
          modal: {
            width: '100%',
            height: '100%',
            backgroundColor: 'inherit',
            overflowY: 'hidden',
          },
        }}
      >
        <div className="modal-content">
          {/* iframe avec le lien de paiement */}
          <iframe
            src={paymentUrl}
            title="Paiement avec CinetPay"
            width="100%"
            height="400px"
          ></iframe>
        </div>
      </Popup>
    </Container>
  );
}

export default Checkout;
