/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import { useRef, useState } from 'react';
import { Heading, Button, Link, Text, useDisclosure } from '@chakra-ui/react';
import MultipleImageBox from '../../atoms/MultipleImageBox/MultipleImageBox';
import { useAuth } from '../../../contexts/AuthContext';
import FormField from '../../atoms/FormField/FormField';
import {
  deleteAccountWithCredentials,
  doCreateUserWithEmailAndPassword,
  doDeleteCurrentUser,
} from '../../../api/firebase/auth';
import { useToast } from '@chakra-ui/react';
import { USER_SEX, USER_PROFESSION } from '../../../utils/constants';

import styled from 'styled-components';
import RedirectComponent from '../../atoms/RedirectComponent/RedirectComponent';
import AlertPopup from '../../atoms/AlertPopup/AlertPopup';
import { ROUTES } from '../../../utils/variables';
import { Navigate, useNavigate } from 'react-router-dom';

const CreateAccountFormContainer = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 0;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  border-radius: 10px;
  overflow: hidden;
  @media screen and (max-width: 930px) {
    display: inline;
  }
`;

const CreateAccountFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  height: '500px';
  padding: 20px;
`;

const FormFields = styled.div``;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

function CreateAccountForm() {
  const toast = useToast();
  const navigate = useNavigate();

  const { userLoggedIn, currentUser, setUserLoggedIn, setCurrentUser } =
    useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const cancelRef = useRef();

  const handleDeleteAccount = async (e) => {
    e.preventDefault();

    try {
      const result = userLoggedIn
        ? await doDeleteCurrentUser(currentUser.dbProfile.email, password)
        : await deleteAccountWithCredentials(email, password);
      if (result.success) {
        setUserLoggedIn(false);
        setCurrentUser(null);
        toast({
          title: 'Succès',
          description: 'Compte supprimé avec succès',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate(ROUTES.homePage);
        window.scrollTo(0, 0);
      } else {
        toast({
          title: 'Erreur lors de la suppression du compte',
          description: result.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Gérer l'erreur de connexion ici
      toast({
        title: 'Erreur lors de la suppression du compte',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <CreateAccountFormContainer>
        <MultipleImageBox />
        <CreateAccountFormContent>
          <Heading>Supprimer votre compte </Heading>
          {userLoggedIn ? (
            <FormFields>
              <FormFieldsGrid>
                <FormField
                  id="last-name"
                  label="Nom"
                  inputType="text"
                  placeholder={currentUser?.dbProfile.lname}
                  disabled
                />
                <FormField
                  id="first-name"
                  label="Prénom"
                  inputType="text"
                  placeholder={currentUser?.dbProfile.fname}
                  disabled
                />
              </FormFieldsGrid>
              <FormFieldsGrid>
                <FormField
                  id="phone-number"
                  label="Numéro de téléphone"
                  inputType="tel"
                  placeholder={'+226 ' + currentUser?.dbProfile.phone}
                  disabled
                />
                <FormField
                  id="mail"
                  label="Adresse email"
                  inputType="email"
                  placeholder={currentUser?.dbProfile.email}
                  disabled
                />
              </FormFieldsGrid>
            </FormFields>
          ) : (
            <FormFields>
              <FormField
                id="email"
                label="Email"
                inputType="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                isRequired
              />
            </FormFields>
          )}
          <Button
            width="full"
            mt={10}
            type="submit"
            colorScheme="orange"
            onClick={() => onOpen()}
          >
            {' '}
            Supprimer le compte
          </Button>

          {isOpen && (
            <AlertPopup
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
              onNo={onClose}
              onYes={handleDeleteAccount}
              windowTitle="Suppression du compte"
            >
              Êtes-vous sûr de vouloir supprimer votre compte ?
              <FormField
                id="password"
                label="Confirmer le mot de passe"
                inputType="password"
                placeholder="Entrez votre mot de passe "
                onChange={(e) => setPassword(e.target.value)}
                isRequired
              />
            </AlertPopup>
          )}
        </CreateAccountFormContent>
      </CreateAccountFormContainer>
    </>
  );
}

export default CreateAccountForm;
