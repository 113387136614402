/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 30/03/2024
/* ******************************************* */

import React, { useState } from 'react';
import { firestore_db } from '../../../api/firebase/firebase-config';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import FormField from '../../atoms/FormField/FormField';
import EditEventType from '../../molecules/EditEventType/EditEventType';
import ManageCategory from '../../molecules/ManageCategory/ManageCategory';
import EditCategoryForm from '../../molecules/EditCategoryForm/EditCategoryForm';

import { Button, useToast, useDisclosure } from '@chakra-ui/react';
import bgImage from '../../../assets/sanbbizLogos/admin_pages_cover.png';

import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const ManageCatFormContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 255, 0.15);
`;

const ManageCatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 20px;
`;

const LeftHeaderGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledDash = styled.div`
  width: 60px;
  height: 15px;
  background-color: #f1360a;
  border-radius: 20px;
  transform: translateY(50%);
`;

const ManageCatFormTitle = styled.h2`
  font-weight: bold;
  font-size: 36px;
  color: #051145;
`;
const ManageCatFormBanner = styled.div`
  width: 100%;
  position: relative;
  right: 0;
  background-size: cover;
  min-height: 200px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-image: url(${bgImage});
`;
const ManageCatFormContent = styled.div`
  margin-top: 40px;
  border-radius: 10px;
`;

const EventTypeManagerContainer = styled.form`
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  border-radius: 10px;
`;

const EventTypeManagerTitle = styled.h3`
  font-weight: bold;
  font-size: '24px';
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

function ManageCategoryForms() {
  const toast = useToast();
  const [name, setName] = useState('');
  const [isFormFieldVisible, setIsFormFieldVisible] = useState(false);

  const [mngChoice, setMngChoice] = useState('');

  const handleMngChoice = (e) => {
    setMngChoice(e.target.value);
  };

  const toggleFormFieldVisibility = () => {
    setIsFormFieldVisible(!isFormFieldVisible);
  };

  const handleSubmitCreateEventTypeForm = async (event) => {
    event.preventDefault();

    try {
      await addDoc(collection(firestore_db, TABLE_NAME.eventTypes), {
        name: name,
        createdAt: serverTimestamp(),
      });
      toast({
        title: "Type d'événement ajouté",
        description: "Le type d'événement a été ajouté avec succès",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setIsFormFieldVisible(false); // Cachez le formulaire
      setName(''); // Réinitialisez le nom pour un futur usage
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ManageCatFormContainer>
      <ManageCatHeader>
        <LeftHeaderGroup>
          <StyledDash />
          <ManageCatFormTitle>
            Types d'événement et catégories{' '}
          </ManageCatFormTitle>
        </LeftHeaderGroup>
      </ManageCatHeader>

      <ManageCatFormBanner />

      <ManageCatFormContent>
        <FormField
          id="catMngChoice"
          label="Quel opération voulez vous réaliser ?"
          inputType="select"
          placeholder="Faites un choix "
          onChange={handleMngChoice}
        >
          <option key="mngEventType" value="mngEventType">
            Gérer les types d'événement
          </option>
          <option key="mngCategories" value="mngCategories">
            Gérer les catégories
          </option>
        </FormField>
      </ManageCatFormContent>

      {mngChoice === 'mngEventType' && mngChoice && (
        <>
          <EditEventType />
          {isFormFieldVisible && (
            <EventTypeManagerContainer
              onSubmit={handleSubmitCreateEventTypeForm}
            >
              <EventTypeManagerTitle>
                Création d'un nouveau type d'événement{' '}
              </EventTypeManagerTitle>
              <FormField
                id="mngEventType"
                label="Nom du type d'événement"
                inputType="text"
                placeholder="Titre de votre événement"
                value={name}
                onChange={(e) => setName(e.target.value)}
                isRequired
              />
              <ButtonContainer>
                <Button
                  boxShadow="base"
                  colorScheme="blue"
                  w="150px"
                  type="submit"
                  mt={4}
                >
                  Ajouter
                </Button>
                <Button
                  boxShadow="base"
                  colorScheme="red"
                  w="150px"
                  onClick={toggleFormFieldVisibility}
                  mt={4}
                >
                  Annuler
                </Button>
              </ButtonContainer>
            </EventTypeManagerContainer>
          )}
          <Button
            boxShadow="base"
            colorScheme="red"
            type="submit"
            mt={4}
            onClick={toggleFormFieldVisibility}
          >
            {!isFormFieldVisible
              ? `Créer un nouveau type d'événement`
              : `Annuler la création `}
          </Button>
        </>
      )}
      {mngChoice === 'mngCategories' && mngChoice && (
        <>
          <ManageCategory />
          <EditCategoryForm isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
          <Button
            boxShadow="base"
            colorScheme="red"
            type="submit"
            mt={4}
            onClick={onOpen}
          >
            Créer une nouvelle catégorie
          </Button>
        </>
      )}
    </ManageCatFormContainer>
  );
}

export default ManageCategoryForms;
