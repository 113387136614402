/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 05/05/2024
/* ******************************************* */

import React from 'react';
import styled from 'styled-components';
import UserProfile from '../../molecules/UserProfile/UserProfile';
import UserNotifications from '../../molecules/UserNotifications/UserNotifications';

const HeaderContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0px;
`;

// Composant React pour le header
const HeaderSidebar = () => {
  return (
    <HeaderContainer>
      <UserNotifications />
      <UserProfile isCustomerProfile={false} />
    </HeaderContainer>
  );
};

export default HeaderSidebar;
