/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import ResetPasswordForm from '../../molecules/ResetPasswordForm/ResetPasswordForm';
import PageLayout from '../../templates/PageLayout/PageLayout';

function ResetPasswordPage() {
  const title = 'Nouveau mot de passe'; // Le titre de la page

  return (
    <>
      <PageLayout title={title}>
        <ResetPasswordForm />
      </PageLayout>
    </>
  );
}

export default ResetPasswordPage;
