/* ******************************************** */
/* * Author: Jamal Sidikou 
/* * Project : SANNBIZ
/* * Create at : 27/05/2024
/* ******************************************* */

import React from 'react'
import OrderSucceed from '../../organisms/OrderSucceed/OrderSucceed';
import PageLayout from '../../templates/PageLayout/PageLayout';

function OrderSucceedPage() {

  const title = "Achat Validé"; // Le titre de la page

  return (
    <>
      <PageLayout title={title}>
        <OrderSucceed />
      </PageLayout>
    </>
  )
}

export default OrderSucceedPage