import React, { useState, useEffect, useRef } from 'react';
import { FaArrowLeft, FaArrowRight, FaPlay } from 'react-icons/fa';
import { Link, Button, AspectRatio } from '@chakra-ui/react';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import bg_banner from '../../../assets/medias/banner/SpoligthedBackgroundPicture.png';
import styled from 'styled-components';
import sanbbizLogo from '../../../assets/sanbbizLogos/logoWithColor.png';
import {
  enrichMarketingGroupWithEventDetails,
  filterAndFetchEventDetails,
  filterSpotlightVideos,
} from '../../../utils/function';
import { TABLE_NAME } from '../../../utils/variables';

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 80px;
  z-index: 3;
`;

const VideoContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Pour Firefox */
  &::-webkit-scrollbar {
    display: none; /* Pour Chrome, Safari et Opera */
  }
  align-items: center;
  gap: 0.5%;
  justify-content: center;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.isMain ? '480px' : '300px')};
  height: ${(props) => (props.isMain ? '435px' : '270px')};
  // backgroud-color: black;
  border-radius: 20px;
  overflow: hidden;
`;

const ScrollButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  z-index: 10;
  padding: 10px;
  border-radius: 50%;
  &:hover {
    background-color: #f0f0f0;
  }
  &:disabled {
    color: #ccc;
    cursor: default;
  }
`;

export const LeftButton = styled(ScrollButton)`
  left: 20px;
`;

export const RightButton = styled(ScrollButton)`
  right: 20px;
`;

const VideoCarousel = ({ videoMarketingDocs, onMainVideoChange }) => {
  const [currentIndex, setCurrentIndex] = useState(
    Math.floor(Math.random() * videoMarketingDocs.length)
  );
  const videoRefs = useRef(new Array(videoMarketingDocs.length));
  // Déclencher la lecture automatique de la vidéo principale
  useEffect(() => {
    const videoElement = videoRefs.current[currentIndex];
    if (videoElement && videoMarketingDocs[currentIndex].isMain) {
      videoElement.play();
    }
  }, [currentIndex, videoMarketingDocs]);

  const handleVideoEnded = () => {
    scroll('right'); // Défilement automatique vers la droite quand la vidéo se termine
  };

  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    const handleFirstInteraction = () => {
      setUserInteracted(true);
      document.removeEventListener('click', handleFirstInteraction);
    };

    document.addEventListener('click', handleFirstInteraction);
    return () => document.removeEventListener('click', handleFirstInteraction);
  }, []);
  const [currentMainEventId, setCurrentMainEventId] = useState(null);

  useEffect(() => {
    if (videoMarketingDocs.length > 0) {
      setCurrentMainEventId(videoMarketingDocs[currentIndex].eventId);
      onMainVideoChange(videoMarketingDocs[currentIndex].eventId); // Notify parent component
    }
  }, [currentIndex, videoMarketingDocs, onMainVideoChange]);

  const scroll = (direction) => {
    if (videoMarketingDocs.length === 1) {
      return; // Pas de défilement si une seule vidéo
    }

    let newIndex = currentIndex;
    if (videoMarketingDocs.length === 2) {
      // Défilement infini entre deux vidéos
      newIndex = currentIndex === 0 ? 1 : 0;
    } else {
      // Défilement normal pour trois vidéos ou plus
      if (direction === 'left') {
        newIndex =
          currentIndex - 1 < 0
            ? videoMarketingDocs.length - 1
            : currentIndex - 1;
      } else if (direction === 'right') {
        newIndex = (currentIndex + 1) % videoMarketingDocs.length;
      }
    }
    setCurrentIndex(newIndex);
  };

  const calculateItemsToShow = () => {
    let items = [];
    if (videoMarketingDocs.length === 1) {
      // Si une seule vidéo, elle est affichée en grand (isMain)
      return [{ ...videoMarketingDocs[0], isMain: true }];
    } else if (videoMarketingDocs.length === 2) {
      // Pour deux vidéos, la première est isMain si elle est au centre
      return videoMarketingDocs.map((video, idx) => ({
        ...video,
        isMain: currentIndex === idx,
      }));
    } else {
      // Pour trois vidéos ou plus, afficher la vidéo principale au centre et les autres à gauche et à droite
      for (let i = -1; i <= 1; i++) {
        let indexToShow =
          (currentIndex + i + videoMarketingDocs.length) %
          videoMarketingDocs.length;
        items.push({ ...videoMarketingDocs[indexToShow], isMain: i === 0 });
      }
    }
    return items;
  };

  const itemsToShow = calculateItemsToShow();

  return (
    <CarouselContainer>
      <LeftButton
        onClick={() => scroll('left')}
        style={{ display: videoMarketingDocs.length > 1 ? 'block' : 'none' }}
      >
        <FaArrowLeft size="24px" />
      </LeftButton>

      <VideoContainer>
        {itemsToShow.map((videoMarketingDoc, index) => (
          <VideoWrapper
            key={videoMarketingDoc.id}
            isMain={videoMarketingDoc.isMain}
          >
            <AspectRatio maxW="100%" maxH="100%" ratio={1}>
              <video
                ref={(el) => (videoRefs.current[index] = el)}
                onEnded={handleVideoEnded}
                controls
                autoplay={videoMarketingDoc.isMain}
                muted={!videoMarketingDoc.isMain}
                preload="auto"
                src={videoMarketingDoc.video?.url}
                style={{ width: '100%', height: '100%' }}
              >
                Your browser does not support the video tag.
              </video>
            </AspectRatio>
          </VideoWrapper>
        ))}
      </VideoContainer>

      <RightButton
        onClick={() => scroll('right')}
        style={{ display: videoMarketingDocs.length > 1 ? 'block' : 'none' }}
      >
        <FaArrowRight size="24px" />
      </RightButton>
    </CarouselContainer>
  );
};

const SpotlightBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpotlightTitle = styled.h1`
  font-weight: bold;
  font-size: 32px;
  color: #051145;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpotlightBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-43%);
  width: 100%;
  height: 500px;
  background-color: blueviolet;
  background: url(${bg_banner}) no-repeat;
  background-size: cover;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-image: linear-gradient(
      to right,
      rgba(255, 192, 203, 1),
      rgba(0, 0, 255, 1)
    );
    opacity: 0.76;
    z-index: 2;
  }
  margin-bottom: -10%;
`;

const SpotlightText = styled.h1`
  position: relative;
  z-index: 3;
  font-weight: bold;
  font-size: 32px;
  color: white;
  width: 70%;
  margin-top: 10%;
  margin-bottom: 20px;
  display: flex;
  text-align: center;
  align-items: center;
`;

function SpotLightHomePage() {
  const [enrichedVideos, setEnrichedVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentEventId, setCurrentEventId] = useState('');

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, TABLE_NAME.marketingCampaigns),
      async (snapshot) => {
        const videosSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Filter and enrich videos immediately after fetching
        const filteredVideos = filterSpotlightVideos(videosSnapshot);
        const enriched = await Promise.all(
          filteredVideos.map(enrichMarketingGroupWithEventDetails)
        );
        setEnrichedVideos(enriched);
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching documents:', error);
        setLoading(false); // Ensure loading is set to false on error
      }
    );

    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Loading state UI
  }

  if (enrichedVideos.length === 0) {
    return null; // Handling case with no videos
  }

  const handleMainVideoChange = (eventId) => {
    setCurrentEventId(eventId);
  };

  return (
    <SpotlightBlock>
      <SpotlightTitle> A la Une !</SpotlightTitle>
      <VideoCarousel
        videoMarketingDocs={enrichedVideos}
        onMainVideoChange={handleMainVideoChange}
      />
      <SpotlightBackground>
        <SpotlightText>
          {' '}
          Découvrez et Plongez au cœur des événements les plus mémorables de
          votre ville !
        </SpotlightText>
        <Link color="blue.500" href={`/events/${currentEventId}`}>
          <Button
            position="relative"
            zIndex="3"
            mt={10}
            bg="transparent"
            color="white"
            border="2px solid white"
            _hover={{
              bg: 'rgb(70, 36, 224)',
              color: 'white',
              borderColor: 'rgb(70, 36, 224)',
            }}
          >
            Réserver Maintenant
          </Button>
        </Link>
      </SpotlightBackground>
    </SpotlightBlock>
  );
}

export default SpotLightHomePage;
