/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 29/04/2024
/* ******************************************* */

/**
 * Definit le type de profil d'utilisateur
 */
export const USER_SEX = {
  MALE: 0,
  FEMALE: 1,
};

/**
 * Definit le type de profil d'utilisateur
 */
export const USER_PROFESSION = {
  SCHOOLBOY: 0,
  STUDENT: 1,
  EMPLOYEE: 2,
  OTHER: 3,
};

/**
 * Definit le type de profil d'utilisateur
 */
export const USER_STATUS = {
  CUSTOMER: 0,
  PROMOTER: 1,
  ADMIN: 2,
  GUEST: 3,
};

/**
 * Définit les différents status de revue  pour un document dev_marketing_campaigns
 *
 */
export const MARKETING_CAMPAIGN_REVIEW_STATE = {
  WAITING4_REVIEW: 0,
  DENIED: 1,
  ACCEPTED: 2,
};

/**
 * Définit les plan de campaign
 */
export const EVENT_MNGT_PLAN_ADVANTAGES_TYPE = {
  UNLIMITED_SCANNERS: 'unlimited_scanners',
  MARKETING_EMAIL: 'marketing_email',
  MARKETING_PUSH: 'marketing_push',
  MARKETING_VIDEO: 'marketing_video',
  STATS_RAW_TICKETS_SOLD: 'stats_raw_tickets_sold',
  STATS_DETAILED_TICKETS_SOLD: 'stats_detailed_tickets_sold',
  STATS_RAW_FILLING_RATE: 'stats_raw_filling_rate',
  STATS_DETAILED_FILLING_RATE: 'stats_detailed_filling_rate',
  MARKETING_BOOST_FEED: 'marketing_boost_feed',
};

/** */
export const EVENT_REVIEW_STATE = {
  WAITING4_REVIEW: 0,
  DENIED: 1,
  ACCEPTED: 2,
  DRAFT: 3,
};

/** */
export const HOMESCREEN_PREDEFINED_FILTER = {
  TODAY: 0,
  THIS_WEEK: 1,
  NEW: 2,
  MOST_VIEWED: 3,
};

/** */
export const EVENT_DATES_TYPE = {
  NONE_PERIODIC: 0,
  PERIODIC: 1,
};

export const XOF2CORIS = 10;
