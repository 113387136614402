/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState } from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import EventDetail from '../../organisms/EventDetail/EventDetail';
import { formatText } from '../../../utils/function';

function EventDetailsPage() {
  const title = "Détails de l'événement"; // Le titre de la page
  const [currentEvent, setCurrentEvent] = useState(null);

  return (
    <div>
      <PageLayout
        title={title}
        backgroundImage={
          currentEvent ? currentEvent.posterPicture.url : undefined
        }
        routesPath={
          'Accueil / Événements / ' + formatText(currentEvent?.name || '')
        }
      >
        <EventDetail setCurrentEvent={setCurrentEvent} />
      </PageLayout>
    </div>
  );
}

export default EventDetailsPage;
