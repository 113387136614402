/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 09/05/2024
/* ******************************************* */

import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Flex, Heading, Button, Link, Text } from '@chakra-ui/react';
import MultipleImageBox from '../../atoms/MultipleImageBox/MultipleImageBox';
import { useAuth } from '../../../contexts/AuthContext';
import FormField from '../../atoms/FormField/FormField';
import { doCreateUserWithEmailAndPassword } from '../../../api/firebase/auth';
import { useToast } from '@chakra-ui/react';
import { USER_SEX, USER_PROFESSION } from '../../../utils/constants';
import { collection, getDocs } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';

import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const PromoterAccountFormContainer = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 0;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  border-radius: 10px;
  overflow: hidden;
  @media screen and (max-width: 930px) {
    display: inline;
    width: 100%;
  }
`;

const PromoterAccountFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  height: '500px';
  padding: 20px;
`;

const FormFields = styled.div``;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

function Update2PromoterAccount(user) {
  const toast = useToast();

  const [businessName, setBusinessName] = useState(null);
  const [businessPhone, setBusinessPhone] = useState(null);
  const [cashoutPaymentMethods, setCashoutPaymentMethods] = useState([]);

  const [paymentMethodItems, setPaymentMethodItems] = useState([]);

  const { userLoggedIn } = useAuth();

  useEffect(() => {
    // Charger les payment method
    const fetchPaymenMethod = async () => {
      const querySnapshot = await getDocs(
        collection(firestore_db, TABLE_NAME.paymentMethods)
      );
      const paimentMethodItems = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPaymentMethodItems(paimentMethodItems);
    };

    fetchPaymenMethod();
  }, []);

  const handleBecomePromoter = async (e) => {
    e.preventDefault();
  };

  return (
    <>
      <PromoterAccountFormContainer>
        <MultipleImageBox />
        <PromoterAccountFormContent>
          <Heading>Devenir un promoteur d'événements</Heading>
          <FormFields>
            <FormFieldsGrid>
              <FormField
                id="id-document"
                label="Pièce d'identité"
                buttonTextBefore="Importer un document"
                buttonTextAfter="Modifier le document"
                inputType="imageUploader"
                onFilesSelected={() => {}}
                imgWidth="200px"
                imgHeight="200px"
                defaultImageUrl={user?.idFileUrls ? [user.idFileUrls] : []}
                isRequired
              />
              <FormField
                id="picture"
                label="Photo de la catégorie"
                inputType="imageUploader"
                onFilesSelected={() => {}}
                imgWidth="200px"
                imgHeight="200px"
                defaultImageUrl={
                  user?.profilePicture?.url ? [user.profilePicture.url] : []
                }
                isRequired
              />
            </FormFieldsGrid>
            <FormFieldsGrid>
              <FormField
                id="businessName"
                label="Société"
                inputType="text"
                defaultValue={businessName}
                placeholder="Le nom de votre société"
                onChange={(e) => {
                  setBusinessName(e.target.value);
                }}
                isRequired
              />
              <FormField
                id="phone-pro"
                label="Téléphone pro"
                inputType="number"
                placeholder="Le numéro de téléphone professionel"
                defaultValue={businessPhone}
                onChange={(e) => {
                  setBusinessPhone(e.target.value);
                }}
                isRequired
              />
            </FormFieldsGrid>
            <FormField
              id="payment-method"
              label="Moyen de retrait d'argent"
              inputType="div"
              isRequired
              onChange={() => {}}
              justify="left"
              gap="20px"
            >
              {paymentMethodItems.length > 0 ? (
                paymentMethodItems.map((paymentMethodItem) => (
                  <Button
                    size="md"
                    colorScheme={'blue'}
                    key={paymentMethodItem.id}
                    value={paymentMethodItem.id}
                  >
                    {paymentMethodItem.name}
                  </Button>
                ))
              ) : (
                <Text>Aucun moyen de paiement disponible</Text>
              )}
            </FormField>
          </FormFields>
          <Button
            width="full"
            mt={10}
            type="submit"
            colorScheme="orange"
            onClick={handleBecomePromoter}
          >
            {' '}
            Devenir Promoteur
          </Button>
        </PromoterAccountFormContent>
      </PromoterAccountFormContainer>
    </>
  );
}

export default Update2PromoterAccount;
