/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState } from 'react';
import {
  Box,
  Flex,
  VStack,
  Button,
  Heading,
  Text,
  Icon,
  Grid,
  GridItem,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdLocationOn, MdEmail, MdPhone } from 'react-icons/md';
import FormField from '../../atoms/FormField/FormField';

import styled from 'styled-components';
import { useSanbbizConfig } from '../../../contexts/ConfigContext';

const FormFields = styled.div`
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 20px;
`;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

function ContactForm() {
  const { config } = useSanbbizConfig();

  const [lname, setLname] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [msgContent, setMsgContent] = useState(null);
  const [subject, setSubject] = useState(null);

  const handleSubjectChoice = (e) => {
    const selectedSubject = Number(e.target.value);
    setSubject(selectedSubject);
  };

  return (
    <Box p={8} maxWidth="container.xl" mx="auto">
      <Heading mb={10} textAlign="center">
        N'hésitez pas à nous contacter
      </Heading>
      <Flex gap={10} flexDirection={{ base: 'column', lg: 'row' }}>
        <FormFields>
          <FormFieldsGrid>
            <FormField
              id="last-name"
              label="Nom"
              inputType="text"
              placeholder="Nom"
              onChange={(e) => setLname(e.target.value)}
              isRequired
            />
            <FormField
              id="email"
              label="Email"
              inputType="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              isRequired
            />
          </FormFieldsGrid>
          <FormFieldsGrid>
            <FormField
              id="phone-number"
              label="Numéro de Téléphone"
              inputType="number"
              placeholder="Numéro de Téléphone"
              onChange={(e) => setPhone(e.target.value)}
              isRequired
            />
            <FormField
              id="contact-subject"
              label="Sujet"
              inputType="select"
              onChange={handleSubjectChoice}
              isRequired
            >
              <option key="1" value="1">
                Problème de connexion
              </option>
              <option key="2" value="2">
                Problème de remboursement
              </option>
              <option key="3" value="3">
                Avis sur l'application
              </option>
              <option key="4" value="4">
                Autres ...
              </option>
            </FormField>
          </FormFieldsGrid>

          <FormField
            id="contact-message"
            label="Votre Message"
            inputType="textarea"
            placeholder="Votre Message"
            height="128px"
            onChange={(e) => setMsgContent(e.target.value)}
            isRequired
          />
          <Button colorScheme="red" w="full" mt={10}>
            Envoyer le Message
          </Button>
        </FormFields>

        <VStack flex="1" spacing={4}>
          <InfoCard
            icon={MdLocationOn}
            title="Notre Adresse"
            text={config.location.city}
          />
          <InfoCard
            icon={MdEmail}
            title="Envoyer Un Email"
            text={[config.contact.email]}
          />
          <InfoCard
            icon={MdPhone}
            title="Numéro de Téléphone"
            text={[
              'Téléphone: ' + config.contact.phone,
              'Whatsapp: ' + config.contact.whatsapp,
            ]}
          />
        </VStack>
      </Flex>
    </Box>
  );
}

const InfoCard = ({ icon, title, text }) => {
  const IconComponent = icon;
  const cardBg = useColorModeValue('white', 'gray.600');

  return (
    <Grid
      templateColumns="50px 1fr"
      gap={2}
      bg={cardBg}
      p={4}
      boxShadow="lg"
      borderRadius="lg"
      w="300px"
      minH="150px"
    >
      <GridItem display="flex" alignItems="center">
        <Box
          bg="white"
          borderRadius="50%"
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          border="1px"
          borderColor="gray.300"
        >
          <Icon as={IconComponent} boxSize="32px" color="green.500" />
        </Box>
      </GridItem>
      <GridItem display="flex" flexDirection="column" justifyContent="center">
        <Text fontWeight="bold" fontSize="xl" color="#051145" textAlign="left">
          {title}
        </Text>
        {Array.isArray(text) ? (
          text.map((t) => (
            <Text textAlign="left" key={t}>
              {t}
            </Text>
          ))
        ) : (
          <Text textAlign="left">{text}</Text>
        )}
      </GridItem>
    </Grid>
  );
};

export default ContactForm;
