/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState } from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import YourTicketDetail from '../../organisms/YourTicketDetail/YourTicketDetail';
import { formatText } from '../../../utils/function';

function BoughtTicketDetailsPage() {
  const title = 'Details Billet';
  const [currentEvent, setCurrentEvent] = useState(null);
  const routesPath = 'Profil / Mes billets / ';

  return (
    <div>
      <PageLayout
        title={title}
        backgroundImage={
          currentEvent ? currentEvent.posterPicture.url : undefined
        }
        routesPath={routesPath + formatText(currentEvent?.name || '')}
      >
        <YourTicketDetail setCurrentEvent={setCurrentEvent} />
      </PageLayout>
    </div>
  );
}

export default BoughtTicketDetailsPage;
