/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 01/04/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import {
  collection,
  onSnapshot,
  doc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import {
  Button,
  Box,
  Text,
  Stack,
  HStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import FormField from '../../atoms/FormField/FormField';
import AlertPopup from '../../atoms/AlertPopup/AlertPopup';
import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const EvenTypeName = styled.span``;

function EditEventType() {
  const [eventTypes, setEventTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  // Gère la suppresion du type d'événeemnts
  const [selectedEventType, setSelectedEventType] = useState(null);
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const cancelRef = React.useRef();

  function openEditDialog(eventType) {
    setEditingEventType(eventType);
    onEditOpen();
  }

  function closeEditDialog() {
    setEditingEventType(null);
    onEditClose();
  }

  function openDeleteDialog(eventType) {
    setSelectedEventType(eventType);
    onDeleteOpen();
  }

  function closeDeleteDialog() {
    setSelectedEventType(null);
    onDeleteClose();
  }

  // Modification du type
  const [editingEventType, setEditingEventType] = useState(null);

  async function saveEdit() {
    if (!editingEventType) return;

    // Vérifiez si le nom est vide
    if (editingEventType.name.trim() === '') {
      toast({
        title: 'Champ requis manquant',
        description: 'Le champ nom ne peut pas être vide.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return; // Retourne immédiatement pour empêcher la suite de l'exécution
    }

    try {
      const eventTypeRef = doc(
        firestore_db,
        TABLE_NAME.eventTypes,
        editingEventType.id
      );
      await updateDoc(eventTypeRef, { name: editingEventType.name.trim() });
      toast({
        title: "Type d'événement modifié",
        description: "Le type d'événement a été mis à jour avec succès.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      closeEditDialog(); // Fermer le modal après la sauvegarde
    } catch (error) {
      toast({
        title: 'Erreur de mise à jour',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  async function deleteEventType() {
    if (!selectedEventType) return;
    try {
      const eventTypeRef = doc(
        firestore_db,
        TABLE_NAME.eventTypes,
        selectedEventType.id
      );
      await deleteDoc(eventTypeRef);
      toast({
        title: "Type d'événement supprimé",
        description: "Le type d'événement a été supprimé avec succès.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur de suppresion ',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    closeDeleteDialog(); // Fermez le modal après la suppression
  }

  // recupère les données de firestore
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, TABLE_NAME.eventTypes),
      (snapshot) => {
        const eventTypeSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEventTypes(eventTypeSnapshot);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  if (loading) {
    console.log('Loading...');
  }

  return (
    <Box pt={2} borderTop="1px" borderColor="gray.200" mt={10} mb={2}>
      <Text fontSize="2xl" as="b">
        {' '}
        Listes des types d'événements{' '}
      </Text>
      <Stack pt={4}>
        {eventTypes.map((eventType, index) => (
          <Box
            key={`${eventType.name}-${index}`}
            position="relative"
            boxShadow="md"
            borderRadius="md"
            overflow="hidden"
          >
            <HStack
              id={`${eventType.name}`}
              p={2}
              justifyContent="space-between"
              mr="40px"
            >
              <EvenTypeName>{eventType.name}</EvenTypeName>
              <Button
                size="sm"
                colorScheme="blue"
                onClick={() => openEditDialog(eventType)}
              >
                Modifier
              </Button>
              <Button
                position="absolute"
                right="0"
                top={2}
                size="sm"
                colorScheme="red"
                onClick={() => openDeleteDialog(eventType)}
              >
                &#10005; {/* Croix */}
              </Button>
            </HStack>
          </Box>
        ))}
        {isEditOpen && (
          <AlertPopup
            isOpen={isEditOpen}
            leastDestructiveRef={cancelRef}
            onClose={closeEditDialog}
            onNo={closeEditDialog}
            onYes={saveEdit}
            windowTitle="Edition du type d'événements"
          >
            <FormField
              pl={2}
              label="Entrez le nouveau nom"
              inputType="text"
              defaultValue={editingEventType.name}
              onChange={(e) =>
                setEditingEventType({
                  ...editingEventType,
                  name: e.target.value,
                })
              }
            />
          </AlertPopup>
        )}
        {isDeleteOpen && (
          <AlertPopup
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={closeDeleteDialog}
            onNo={closeDeleteDialog}
            onYes={deleteEventType}
            windowTitle="Supprimer le type d'Événement"
          >
            Êtes-vous sûr de vouloir supprimer le type d'événement: "
            {selectedEventType?.name}" ?
          </AlertPopup>
        )}
      </Stack>
    </Box>
  );
}

export default EditEventType;
