import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 20%;
  cursor: pointer;
  padding: 5px 0px;
  margin-left: 5px;
`;
const ImgContainer = styled.div`
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Img = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 50px;
`;

const TextContainer = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 1rem;
`;

function ResearchPromoterItem({ promoter }) {
  return (
    <Container>
      <ImgContainer>
        <Img
          src={promoter.profilePicture?.url}
          alt={`${promoter.businessName} icon`}
          style={{}}
        />
      </ImgContainer>
      <TextContainer>
        <Title>{promoter.businessName}</Title>
      </TextContainer>
    </Container>
  );
}

export default ResearchPromoterItem;
