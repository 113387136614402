/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 05/05/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { MdNotifications } from 'react-icons/md';

const Card = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: black;
  padding: 10px;
  margin: 5px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DetailCard = styled.div``;

const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
`;

const Message = styled.div`
  flex: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  padding: 5px;
  font-size: 0.8rem;
  line-height: 1.2em;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const TimeStamp = styled.div`
  color: #666;
  font-size: 0.7rem; // Smaller font size for the timestamp
`;

const NotificationCard = ({ avatar, message, time }) => {
  return (
    <Card>
      <Avatar src={avatar} alt="User Avatar" />
      <DetailCard>
        <Message>{message}</Message>
        <TimeStamp>{time}</TimeStamp>
      </DetailCard>
    </Card>
  );
};

const NotificationsMenu = styled.div`
  position: absolute;
  top: 50px;
  right: -90px;
  width: 400px;
  height: 500px;
  background-color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  display: ${(props) => (props.show ? 'flex' : 'none')};
`;

const NotificationHeader = styled.div`
  padding: 10px;
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  background-color: #f8f9ff;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotificationContent = styled.div`
  background-color: #f8f9f5;
  flex: 1;
  overflow-y: auto;
  padding: 10px;
`;

const NotificationFooter = styled.div`
  padding: 10px;
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  background-color: #f8f9ff;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MarkAllButton = styled.button`
  background: none;
  border: none;
  color: #0056b3;
  cursor: pointer;
  padding: 5px 10px;
`;

function NotificationsPopup({ show }) {
  const notifications = [
    {
      id: 1,
      message:
        "Gordon Kinley a réservé 1 billet pour l'événement Virtual Live Karaoke le 10 février 2024 à 12h00 avec succès. De EventRight Proordon Kinley a réservé 1 billet pour l'événement Virtual Live Karaoke le 10 février 2024 à 12h00 avec succès. De EventRight Pro",
      time: 'IL Y A 21 MINUTES',
    },
    {
      id: 2,
      message: 'TEstr une autre longueur de texte ',
      time: 'IL Y A 2 HEURES',
    },
    {
      id: 3,
      message:
        "Gordon Kinley a réservé 1 billet pour l'événement Histoire du Cinéma le 22 mars 2023 à 12h00 avec succès. De EventRight Pro",
      time: 'IL Y A 2 HEURES',
    },
    {
      id: 3,
      message:
        "Gordon Kinley a réservé 1 billet pour l'événement Histoire du Cinéma le 22 mars 2023 à 12h00 avec succès. De EventRight Pro",
      time: 'IL Y A 2 HEURES',
    },
    {
      id: 1,
      message:
        "Gordon Kinley a réservé 1 billet pour l'événement Virtual Live Karaoke le 10 février 2024 à 12h00 avec succès. De EventRight Proordon Kinley a réservé 1 billet pour l'événement Virtual Live Karaoke le 10 février 2024 à 12h00 avec succès. De EventRight Pro",
      time: 'IL Y A 21 MINUTES',
    },
    {
      id: 2,
      message: 'TEstr une autre longueur de texte ',
      time: 'IL Y A 2 HEURES',
    },
    {
      id: 1,
      message:
        "Gordon Kinley a réservé 1 billet pour l'événement Virtual Live Karaoke le 10 février 2024 à 12h00 avec succès. De EventRight Proordon Kinley a réservé 1 billet pour l'événement Virtual Live Karaoke le 10 février 2024 à 12h00 avec succès. De EventRight Pro",
      time: 'IL Y A 21 MINUTES',
    },
    {
      id: 2,
      message: 'TEstr une autre longueur de texte ',
      time: 'IL Y A 2 HEURES',
    },
  ];

  return (
    <NotificationsMenu show={show}>
      <NotificationHeader>
        Notifications
        <MarkAllButton
          onClick={() =>
            alert('Toutes les notifications ont été marquées comme lues.')
          }
        >
          Tout marquer comme lu
        </MarkAllButton>
      </NotificationHeader>
      <NotificationContent>
        {notifications.map((notification) => (
          <NotificationCard
            avatar="https://randomuser.me/api/portraits/men/32.jpg" // Example avatar URL
            message={notification.message}
            time={notification.time}
          />
        ))}
      </NotificationContent>
      <NotificationFooter>
        <MarkAllButton>Voir tout</MarkAllButton>
      </NotificationFooter>
    </NotificationsMenu>
  );
}

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(20deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-20deg); }
  100% { transform: rotate(0deg); }
`;

const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

const NotificationIcon = styled.div`
  position: relative;
  font-size: 32px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 15px; // Ajout de padding à gauche et à droite

  .notification-icon {
    animation: ${(props) =>
      props.hasNotifications
        ? css`
            ${rotate} 2s linear infinite
          `
        : 'none'};
  }

  .blink-dot {
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    right: 8px;
    top: 12px;
    animation: ${(props) =>
      props.hasNotifications
        ? css`
            ${blink} 1s linear infinite
          `
        : 'none'};
    display: ${(props) => (props.hasNotifications ? 'block' : 'none')};
  }
`;

const UserNotificationWidget = styled.div`
  position: relative;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

function UserNotifications() {
  const [hasNotifications, setHasNotifications] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHasNotifications(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <NotificationIcon
      onClick={toggleNotifications}
      hasNotifications={hasNotifications}
    >
      <UserNotificationWidget>
        <MdNotifications className="notification-icon" />
        <span className="blink-dot"></span>
      </UserNotificationWidget>

      <NotificationsPopup show={showNotifications} />
    </NotificationIcon>
  );
}

export default UserNotifications;
