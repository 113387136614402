/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 04/05/2024
/* ******************************************* */

export function extractCoordsFromMapUrl(url) {
  // Cette regex essaie de capturer les coordonnées qui peuvent être dans différents formats
  const regex = /@?(\-?\d+(\.\d+)?),(\-?\d+(\.\d+)?)/;
  const match = url.match(regex);
  if (match) {
    // Assurez-vous de retourner les deux premiers groupes capturés qui correspondent à la latitude et la longitude
    return [parseFloat(match[1]), parseFloat(match[3])];
  }
  return null; // Retourne null si aucune coordonnée n'est trouvée
}
