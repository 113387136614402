/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 30/03/2024
/* ******************************************* */
import React, { useState } from 'react';
import { Button, HStack } from '@chakra-ui/react';

function DaysPicker() {
  // Initialise l'état avec tous les jours déselectionnés
  const [selectedDays, setSelectedDays] = useState({
    lu: false,
    ma: false,
    me: false,
    je: false,
    ve: false,
    sa: false,
    di: false,
  });

  // Fonction pour gérer la sélection/déselection des jours
  const toggleDay = (day) => {
    setSelectedDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  // Création d'une liste pour mapper les boutons
  const days = ['lu', 'ma', 'me', 'je', 've', 'sa', 'di'];

  return (
    <HStack justify="space-evenly">
      {days.map((day) => (
        <Button
          key={day}
          onClick={() => toggleDay(day)}
          colorScheme={selectedDays[day] ? 'blue' : 'gray'}
        >
          {day}
        </Button>
      ))}
    </HStack>
  );
}

export default DaysPicker;
