import React from 'react';
import styled from 'styled-components';
import { getDateDetailsFromEventDetailSchedulerData } from '../../../utils/date';

const Container = styled.div`
  min-width: 132px;
  max-width: 132px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: white;
  border: ${(props) => (props.isSelected ? 'solid 3px #051145' : 'none')};
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;

  box-shadow: ${(props) =>
    props.isSelected
      ? '0px 0px 10px rgba(0, 0, 0, 0.5);'
      : '0px 0px 10px rgba(0, 0, 0, 0.1)'};

  transform: ${(props) => (props.isSelected ? 'translateY(-10px)' : '')};
  &:hover {
    box-shadow: 0 6px 10px rgba(5, 17, 69, 0.4);
  }
`;

const DarkText = styled.h2`
  color: #051145;
  font-weight: bold;
  text-align: center;
`;

const LightText = styled.h2`
  // color: lightgray;
  font-weight: bold;
  text-align: center;
  color: #051145;
`;

const CircleContainer = styled.div`
  display: flex; /* Utiliser Flexbox pour centrer le contenu */
  align-items: center; /* Centre verticalement */
  justify-content: center; /* Centre horizontalement */
  background-color: #77afbc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

function EventDetailSchedulerItem({ schedulerData, onClick, isSelected }) {
  const { weekday, day, month, slot } =
    getDateDetailsFromEventDetailSchedulerData(
      schedulerData,
      true,
      true,
      false
    );

  return (
    <Container isSelected={isSelected} onClick={() => onClick(schedulerData)}>
      <DarkText>{weekday}</DarkText>
      <CircleContainer>
        <DarkText>{day}</DarkText>
      </CircleContainer>
      <LightText>{month}</LightText>
      <LightText>{slot}</LightText>
    </Container>
  );
}

export default EventDetailSchedulerItem;
