/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 10/05/2024
/* ******************************************* */

import React, { useState, useEffect, useRef } from 'react';
import { collection, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import {
  useDisclosure,
  useToast,
  Button,
  Menu,
  MenuList,
  MenuItem,
  Checkbox,
  MenuButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';
import EditCategoryForm from '../EditCategoryForm/EditCategoryForm';
import CategoryItem from '../CategoryItem/CategoryItem';
import AlertPopup from '../../atoms/AlertPopup/AlertPopup';
import { CiEdit } from 'react-icons/ci';
import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const ManageCategoryListContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const ManageCategoryCard = styled.div`
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
`;

function ManageCategory() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  // Filtrer les categories en fonction du type d'événements
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);

  const handleEventTypeChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Ajoute l'id du type d'événement au tableau s'il est coché
      setSelectedEventTypes((prev) => [...prev, value]);
    } else {
      // Retire l'id du type d'événement du tableau s'il est décoché
      setSelectedEventTypes((prev) =>
        prev.filter((typeId) => typeId !== value)
      );
    }
  };

  const handleSelectAllChange = (e) => {
    if (selectedEventTypes.length < eventTypes.length) {
      setSelectedEventTypes(eventTypes.map((type) => type.id));
    } else {
      setSelectedEventTypes([]);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, TABLE_NAME.eventTypes),
      (snapshot) => {
        const types = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEventTypes(types);
      },
      (error) => {
        console.error(
          "Erreur lors de la récupération des types d'événements:",
          error
        );
      }
    );

    return () => unsubscribe();
  }, []);

  // Gère la suppresion et la modification de la catégorie
  const cancelRef = useRef();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  function openDeleteDialog(category) {
    setSelectedCategory(category);
    onDeleteOpen();
  }

  function closeDeleteDialog() {
    setSelectedCategory(null);
    onDeleteClose();
  }

  async function deleteCategory() {
    if (!selectedCategory) return;
    try {
      const categoryRef = doc(
        firestore_db,
        TABLE_NAME.categories,
        selectedCategory.id
      );
      await deleteDoc(categoryRef);
      toast({
        title: 'Catégorie supprimée',
        description: 'La catégorie a été supprimée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur de suppresion ',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    onDeleteClose(); // Fermez le modal après la suppression
  }

  // Modification de la category
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  function openEditDialog(category) {
    setSelectedCategory(category); // Définir la catégorie à éditer
    onEditOpen(); // Ouvrir la popup d'édition
  }

  // recupère les données de firestore
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, TABLE_NAME.categories),
      (snapshot) => {
        const categorySnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categorySnapshot);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  if (loading) {
    console.log('Loading...');
  }

  return (
    <VStack pt={2} borderTop="1px" borderColor="gray.200" mt={10} mb={2}>
      <Text fontSize="2xl" as="b" textAlign="left">
        {' '}
        Listes des catégories{' '}
      </Text>

      <Menu closeOnSelect={false}>
        <MenuButton as={Button} rightIcon={<FaChevronDown />}>
          Filtrer par type d'événement
        </MenuButton>
        <MenuList zIndex="popover">
          {' '}
          <MenuItem minH="48px" onClick={handleSelectAllChange}>
            <Checkbox
              isChecked={selectedEventTypes.length === eventTypes.length}
            >
              Sélectionner tout
            </Checkbox>
          </MenuItem>
          {eventTypes.map((type) => (
            <MenuItem key={type.id} minH="48px">
              <Checkbox
                isChecked={selectedEventTypes.includes(type.id)}
                onChange={(e) => handleEventTypeChange(e, type.id)}
                value={type.id}
              >
                {type.name}
              </Checkbox>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <ManageCategoryListContainer>
        {categories
          .filter(
            (category) =>
              selectedEventTypes.length === 0 ||
              selectedEventTypes.includes(category.typeId)
          )
          .map((category) => (
            <ManageCategoryCard key={category.id}>
              {' '}
              <CategoryItem
                cover={category.picture?.url}
                name={category.name}
              />
              <Button
                position="absolute"
                right="0"
                top="0"
                size="xs"
                colorScheme="red"
                zIndex="1"
                onClick={() => openDeleteDialog(category)}
              >
                &#10005; {/* Croix */}
              </Button>
              <Button
                position="absolute"
                right={2}
                bottom={2}
                size="sm"
                colorScheme="blue"
                p={0}
                onClick={() => openEditDialog(category)}
              >
                <CiEdit />
              </Button>
            </ManageCategoryCard>
          ))}
        {isDeleteOpen && (
          <AlertPopup
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={closeDeleteDialog}
            onNo={closeDeleteDialog}
            onYes={deleteCategory}
            windowTitle="Supprimer la catégorie"
          >
            Êtes-vous sûr de vouloir supprimer la catégorie: "
            {selectedCategory?.name}" ?
          </AlertPopup>
        )}
        {isEditOpen && (
          <EditCategoryForm
            category={selectedCategory}
            isOpen={isEditOpen}
            onClose={onEditClose}
          />
        )}
      </ManageCategoryListContainer>
    </VStack>
  );
}

export default ManageCategory;
