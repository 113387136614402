/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 13/04/2024
/* ******************************************* */

import './Popup.css';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

function Popup({
  isOpen,
  onClose,
  popupTitle,
  width,
  height,
  children,
  backgroundColor,
  disableCloseButton,
}) {
  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={width}
      height={height}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        width={width}
        backgroundColor={backgroundColor}
        boxShadow={'none'}
      >
        <ModalHeader>{popupTitle}</ModalHeader>
        {!disableCloseButton && <ModalCloseButton />}
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default Popup;
