import { authToken, firestore_db } from './firebase-config';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updatePassword,
  sendPasswordResetEmail,
  getAuth,
  deleteUser,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from 'firebase/auth';

import { addDocument2Collection } from './firestore';
import { doc, updateDoc } from 'firebase/firestore';
import { uploadFile } from './storage';

import {
  checkPhoneNumberFormat,
  cleanUpUserSubscriptions,
} from '../../utils/function';

import { USER_PROFESSION, USER_SEX, USER_STATUS } from '../../utils/constants';
import { Timestamp } from 'firebase/firestore';

export const doCreateUserWithEmailAndPassword = async (
  fname,
  lname,
  phone,
  email,
  pwd,
  sex,
  profession,
  birthdate,
  confirmedPwd
) => {
  if (
    !fname ||
    !lname ||
    !phone ||
    !email ||
    !pwd ||
    !confirmedPwd ||
    !sex ||
    !birthdate ||
    !profession
  ) {
    return {
      code: 'form/missing-information',
      message: 'Veuillez entrer toutes les informations demandées.',
    };
  }

  if (pwd !== confirmedPwd) {
    return {
      code: 'auth/password-not-confirmed',
      message: 'Les mots de passe ne sont pas identiques.',
    };
  }

  if (!checkPhoneNumberFormat(phone)) {
    return {
      code: 'phone/wrong-format',
      message: 'Le format du numéro de téléphone est incorrect.',
    };
  }

  if (sex === '') {
    return {
      code: 'auth/sex-info-missing',
      message: "Vous n'avez pas choisi de sexe ",
    };
  }
  if (profession === '') {
    return {
      code: 'auth/profession-missing',
      message: 'Choississez un profession',
    };
  }
  if (birthdate == null) {
    return {
      code: 'auth/wrong-birthdate',
      message: 'Choississez votre date de naissance',
    };
  }

  // Convertir la date de naissance au format Timestamp Firestore avant l'envoi
  const birthdateTimestamp = Timestamp.fromDate(new Date(birthdate));

  let document = {
    fname,
    lname,
    phone,
    email,
    status: USER_STATUS.CUSTOMER,
    isFirstLogin: true,
    businessName: null,
    businessPhone: null,
    profilePicture: null,
    sex,
    profession,
    birthdate: birthdateTimestamp,
  };

  try {
    await createUserWithEmailAndPassword(authToken, email, pwd);

    await addDocument2Collection('users', authToken.currentUser.uid, document);
    return { success: true }; // En cas de succès, retounre succes = true
  } catch (error) {
    return { code: error.code, message: error.message };
  }
};

export const doUpdateUserWithInfos = async (updates, isPromoterOrAdmin) => {
  if (!authToken.currentUser.uid) {
    return {
      code: 'user/invalid-id',
      message: 'ID utilisateur non fourni',
    };
  }

  if (updates.birthdate && Date.parse(updates.birthdate)) {
    updates.birthdate = Timestamp.fromDate(new Date(updates.birthdate));
  }

  if (
    isPromoterOrAdmin &&
    updates.businessName &&
    updates.businessName === ''
  ) {
    return {
      code: 'phone/wrong-format',
      message: 'Saissisez un nom de société',
    };
  }

  if (
    isPromoterOrAdmin &&
    updates.businessPhone &&
    !checkPhoneNumberFormat(updates.businessPhone)
  ) {
    return {
      code: 'phone/wrong-format',
      message: 'Le format du numéro de téléphone professionel est incorrect',
    };
  }

  if (updates.sex === '') {
    return {
      code: 'auth/sex-info-missing',
      message: "Vous n'avez pas choisi de sexe ",
    };
  }
  if (updates.profession === '') {
    return {
      code: 'auth/profession-missing',
      message: 'Choississez un profession',
    };
  }
  if (updates.birthdate == null) {
    return {
      code: 'auth/wrong-birthdate',
      message: 'Choississez votre date de naissance',
    };
  }
  const userRef = doc(firestore_db, 'users', authToken.currentUser.uid);

  try {
    await updateDoc(userRef, updates);
    return { success: true };
  } catch (error) {
    return { code: error.code, message: error.message };
  }
};

export const doSignInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      authToken,
      email,
      password
    );
    return { user: userCredential.user };
  } catch (error) {
    return { error: error.message };
  }
};

export const doSignOut = async () => {
  try {
    await authToken.signOut();
  } catch (error) {
    console.error('Erreur de déconnexion', error);
  }
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(authToken, email);
};

export const doPasswordChange = (password) => {
  return updatePassword(authToken.currentUser, password);
};

export const doSendEmailVerification = () => {
  return sendEmailVerification(authToken.currentUser, {
    url: `${window.location.origin}/home`,
  });
};

export const doDeleteCurrentUser = async (email, password) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    return { error: 'Aucun utilisateur connecté.' };
  }

  const credentials = EmailAuthProvider.credential(email, password);

  try {
    await reauthenticateWithCredential(user, credentials);

    // D'abord, on nettoie les abonnements
    const cleanupResult = await cleanUpUserSubscriptions(user.uid);
    if (!cleanupResult.success) {
      throw new Error(cleanupResult.message);
    }

    await deleteUser(user);
    return {
      success: true,
      message: 'Compte utilisateur supprimé avec succès.',
    };
  } catch (error) {
    console.error("Erreur lors de la suppression de l'utilisateur:", error);
    return { error: error.message };
  }
};

export const deleteAccountWithCredentials = async (email, password) => {
  const auth = getAuth();

  try {
    // Connexion de l'utilisateur avec email et mot de passe
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    // D'abord, on nettoie les abonnements
    const cleanupResult = await cleanUpUserSubscriptions(user.uid);
    if (!cleanupResult.success) {
      throw new Error(cleanupResult.message);
    }

    // Suppression du compte utilisateur
    await deleteUser(user);
    return {
      success: true,
      message: 'Compte utilisateur supprimé avec succès.',
    };
  } catch (error) {
    console.error("Erreur lors de la suppression de l'utilisateur:", error);
    return { error: error.message };
  }
};
