import imgForFormType1 from '../medias/forms/imgForFormType1.jpg';
import imgForFormType2 from '../medias/forms/imgForFormType2.jpg';
import imgForFormType3 from '../medias/forms/imgForFormType3.jpg';
import imgForFormType4 from '../medias/forms/imgForFormType4.jpg';
import imgForFormType5 from '../medias/forms/imgForFormType5.jpg';
import imgForFormType6 from '../medias/forms/imgForFormType6.jpg';
import imgForFormType7 from '../medias/forms/imgForFormType7.jpg';
import imgForFormType8 from '../medias/forms/imgForFormType8.jpg';
import imgForFormType9 from '../medias/forms/imgForFormType9.jpg';
import imgForFormType10 from '../medias/forms/imgForFormType10.jpg';
import imgForFormType11 from '../medias/forms/imgForFormType11.jpg';
import imgForFormType12 from '../medias/forms/imgForFormType12.jpg';
import imgForFormType13 from '../medias/forms/imgForFormType13.jpg';

import pageBanner1 from '../medias/banner/PageBanner1.png';
import pageBanner2 from '../medias/banner/PageBanner2.png';
import pageBanner3 from '../medias/banner/PageBanner3.png';

export const PictureListLinksForFormType1 = [
  imgForFormType1,
  imgForFormType2,
  imgForFormType3,
  imgForFormType4,
  imgForFormType5,
  imgForFormType6,
  imgForFormType7,
  imgForFormType8,
  imgForFormType9,
  imgForFormType10,
  imgForFormType11,
  imgForFormType12,
  imgForFormType13,
];

export const BannerPictureListLinks = [pageBanner1, pageBanner2, pageBanner3];
