/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import CategoryItem from '../../molecules/CategoryItem/CategoryItem';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import defaultCoverUrl from '../../../assets/sanbbizLogos/logoWithColor.png';
import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const CategoryListContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const CategoryCard = styled.li`
  box-shadow: 0 5px 15px rgba(0, 0, 255, 0.1);
`;
function CategoryList() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, TABLE_NAME.categories),
      (snapshot) => {
        const categoriesSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesSnapshot);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  if (loading) {
    // console.log('Loading...');
  }

  return (
    <CategoryListContainer>
      {categories.map((category) => (
        <CategoryCard>
          <CategoryItem
            key={category.id}
            id={category.id}
            cover={category.picture?.url || defaultCoverUrl}
            name={category.name}
          />
        </CategoryCard>
      ))}
    </CategoryListContainer>
  );
}

export default CategoryList;
