/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 11/05/2024
/* ******************************************* */

import { extendTheme } from '@chakra-ui/react';

const colors = {
  primary: {
    100: '#F1360A',
    200: '#F1360A',
    300: '#F1360A',
    400: '#F1360A',
    500: '#F1360A',
    600: '#F1360A',
    700: '#F1360A',
    800: '#F1360A',
    900: '#F1360A',
  },
};

const breakpoints = {
  sm: '320px',
  md: '790px',
  lg: '930px',
  xl: '1200px',
};

const customTheme = extendTheme({ colors, breakpoints });

export default customTheme;
