/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 06/04/2024
/* ******************************************* */

import React, { useState } from 'react';
import { Button, Grid } from '@chakra-ui/react';

const MonthDaysPicker = ({ onDaysSelected }) => {
  const [selectedDays, setSelectedDays] = useState([]);

  const toggleDay = (day) => {
    const isSelected = selectedDays.includes(day);
    const updatedSelectedDays = isSelected
      ? selectedDays.filter((d) => d !== day)
      : [...selectedDays, day];
    setSelectedDays(updatedSelectedDays);
    if (onDaysSelected) {
      onDaysSelected(updatedSelectedDays);
    }
  };

  return (
    <Grid templateColumns="repeat(7, 1fr)" gap={2}>
      {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
        <Button
          key={day}
          colorScheme={selectedDays.includes(day) ? 'blue' : 'gray'}
          onClick={() => toggleDay(day)}
        >
          {day}
        </Button>
      ))}
    </Grid>
  );
};

export default MonthDaysPicker;
