/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 13/04/2024
/* ******************************************* */

import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'; // pour les vues semaine et jour
import interactionPlugin from '@fullcalendar/interaction'; // pour selectable
import listPlugin from '@fullcalendar/list'; // Vue en list
import frLocale from '@fullcalendar/core/locales/fr';
import { Button, Box } from '@chakra-ui/react';
import RecurrentDateCreatorForm from '../../molecules/RecurrentDateCreatorForm/RecurrentDateCreatorForm';
import { useDisclosure } from '@chakra-ui/react'; //  useDisclosure pour le contrôle de l'affichage de la Modal
import Popup from '../../atoms/Popup/Popup';
import { useToast } from '@chakra-ui/react';

import './CreateEventScheduler.css';

const MyCalendar = ({ onDateClick }) => {
  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
      }}
      selectable={true}
      dateClick={onDateClick}
      expandRows={true} // Permet au calendrier de s'étendre pour remplir le conteneur
      locale={frLocale}
      slotDuration="0:30:00" // Créneaux de 30 minutes
      slotLabelFormat={{
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Format 24h
      }}
      allDaySlot={false} // Retire la rangée "Toute la journée"
      scrollTime="08:00:00" // Démarre l'affichage à 8h
      fixedWeekCount={false} // Affiche uniquement les semaines du mois en cours
      nowIndicator={true}
      initialDate={new Date()} // Centrer la vue sur le jour courant
    />
  );
};
function CreateEventScheduler() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDate, setSelectedDate] = useState(null); // État pour stocker la date sélectionnée

  const toast = useToast();

  const handleDateClick = (arg) => {
    const clickedDate = arg.dateStr; // FullCalendar passe la date au format ISO par défaut
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (new Date(clickedDate) >= today) {
      setSelectedDate(clickedDate.split('T')[0]); // S'assurer de passer uniquement la date sans l'heure
      onOpen();
    } else {
      toast({
        title: 'Action non autorisée.',
        description:
          "Vous ne pouvez pas ajouter d'événements sur des dates passées.",
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="scheduler-container">
      <Button size="md" onClick={onOpen}>
        Ajouter des dates
      </Button>
      <MyCalendar onDateClick={handleDateClick} />
      {/* Popup pour afficher le formulaire */}
      <Popup
        isOpen={isOpen}
        onClose={onClose}
        popupTitle={'Ajouter un événement récurrent'}
        isCentered
      >
        <RecurrentDateCreatorForm
          selectedDate={selectedDate}
          onClose={onClose}
        />
      </Popup>
    </div>
  );
}

export default CreateEventScheduler;
