/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { extractCoordsFromMapUrl } from '../../../utils/map';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function getPositionFromUrl(url) {
  // URL par défaut pointant sur Ouagadougou, Burkina Faso
  const defaultUrl =
    'https://www.google.fr/maps/place/Mairie+de+Ouagadougou+(Mairie+Centrale)/@12.3683027,-1.5272587,20.04z/data=!4m15!1m8!3m7!1s0xe2e95ecceaa44cd:0x799f67799c743b8b!2sOuagadougou,+Burkina+Faso!3b1!8m2!3d12.3714277!4d-1.5196603!16zL20vMGZqdzk!3m5!1s0xe2e958c5fa36cff:0x4870fc174d4bbcac!8m2!3d12.3680826!4d-1.527066!16s%2Fg%2F1tgpzkcd?entry=ttu';

  // Vérifie si l'URL est valide, sinon utilisez l'URL par défaut
  if (!url) {
    return extractCoordsFromMapUrl(defaultUrl);
  }

  //Extrait les coordonnées de l'URL fournie
  const coords = extractCoordsFromMapUrl(url);
  if (coords) {
    return coords; // Retourne les coordonnées si elles sont trouvées
  } else {
    return extractCoordsFromMapUrl(defaultUrl); // Utilisez l'URL par défaut si l'extraction échoue
  }
}

const MapDesign = ({ url, address }) => {
  const position = getPositionFromUrl(url);
  return (
    <MapContainer
      center={position}
      zoom={16}
      style={{
        height: '400px',
        width: '100%',
        borderRadius: '20px',
        overflow: 'hidden',
        marginBottom: '20px',
      }}
      scrollWheelZoom={false}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position}>
        <Popup>{address}</Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapDesign;
