/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 03/06/2024
/* ******************************************* */

import { doc, getDoc } from 'firebase/firestore';
import { createContext, useContext, useEffect, useState } from 'react';
import { firestore_db } from '../api/firebase/firebase-config';

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConfig = async () => {
      const configDoc = await getDoc(
        doc(firestore_db, 'configs', 'LVQFeHElEerWTCwxmlgd')
      );
      if (configDoc.exists()) {
        setConfig(configDoc.data());
      } else {
        console.log('No config document found!');
      }
      setLoading(false);
    };

    fetchConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ config, loading }}>
      {!loading && children}
    </ConfigContext.Provider>
  );
};

export function useSanbbizConfig() {
  return useContext(ConfigContext);
}
