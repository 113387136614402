/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import CategoryList from '../../organisms/CategoryList/CategoryList';
import category_page_banner from '../../../assets/medias/banner/PageBanner2.png';

function CategoriesPage() {
  const title = "Catégorie d'événement"; // Le titre de la page

  return (
    <>
      <PageLayout title={title} backgroundImage={category_page_banner}>
        <CategoryList />
      </PageLayout>
    </>
  );
}

export default CategoriesPage;
