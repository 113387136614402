/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 26/04/2024
/* ******************************************* */

import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  GridItem,
  Icon,
  Text,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdLocationOn, MdCalendarMonth } from 'react-icons/md';
import styled from 'styled-components';

const UserInfoContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserNameDisplay = styled.h3`
  color: black;
  margin: 0;
  padding: 0;
  text-align: left;
  font-weight: bold;
`;

const CompanyNameDisplay = styled.h4`
  color: gray;
  margin: 0;
  padding: 0;
  text-align: left;
  span {
    color: #f1360a;
  }
`;

const EventInfoContent = ({
  contentType,
  imageUrl,
  text,
  surname,
  company,
  ...props
}) => {
  const cardBg = useColorModeValue('white', 'gray.600');
  const leftContentBg = useColorModeValue('#ecf0f2');
  // Sélection du type de contenu à afficher `leftContent `
  let leftContent;
  // Sélection du type de contenu à afficher `leftContent `
  let rightContent = (
    <>
      <Text>{text}</Text>
    </>
  );

  switch (contentType) {
    case 'location':
      leftContent = (
        <>
          <Box
            bg={leftContentBg} // Couleur de fond du cercle
            borderRadius="50%" // Rend le fond parfaitement rond
            p={6} // Padding pour la taille du cercle
            display="flex"
            justifyContent="center" // Centre l'icône horizontalement
            alignItems="center" // Centre l'icône verticalement
            boxSize="40px"
          >
            <Icon as={MdLocationOn} boxSize="28px" color="#051145" />
          </Box>
        </>
      );
      break;

    case 'date':
      leftContent = (
        <>
          <Box
            bg={leftContentBg} // Couleur de fond du cercle
            borderRadius="50%" // Rend le fond parfaitement rond
            p={6} // Padding pour la taille du cercle
            display="flex"
            justifyContent="center" // Centre l'icône horizontalement
            alignItems="center" // Centre l'icône verticalement
            boxSize="40px"
          >
            <Icon as={MdCalendarMonth} boxSize="28px" color="#051145" />
          </Box>
        </>
      );
      break;

    case 'userInfo':
      leftContent = (
        <>
          <Box
            bg={leftContentBg} // Couleur de fond pour le cercle
            borderRadius="50%" // Rend le fond parfaitement rond
            // p={1} // Padding pour la taille du cercle
            display="flex"
            justifyContent="center" // Centre l'icône horizontalement
            alignItems="center" // Centre l'icône verticalement
            boxSize="50px"
          >
            <Image
              src={imageUrl} // URL de la photo de profil
              borderRadius="full" // Assure que l'image est ronde
              boxSize="50px" // Taille de l'image
              objectFit="cover" // Assure que l'image couvre bien l'espace sans déformation
              boxShadow={'0 6px 10px rgba(236, 240, 242, 0.2)'}
              alt="Profile Picture" // Texte alternatif pour l'image
            />
          </Box>
        </>
      );
      rightContent = (
        <UserInfoContainer>
          <UserNameDisplay>{company}</UserNameDisplay>
        </UserInfoContainer>
      );
      break;

    // Ajout d'autres cas
    default:
      leftContent = <></>;
      rightContent = <></>;
  }

  return (
    <Grid
      templateColumns="60px 1fr"
      gap={4}
      bg={cardBg}
      p={2}
      boxShadow="lg"
      borderRadius="lg"
      w="100%"
      {...props}
    >
      <GridItem>{leftContent}</GridItem>
      <GridItem display="flex" justifyContent="flex-start" alignItems="center">
        {rightContent}
      </GridItem>
    </Grid>
  );
};

EventInfoContent.propTypes = {
  imageUrl: PropTypes.string, // URL pour les images de profil utilisateur
  text: PropTypes.string.isRequired, // Texte principal à afficher
  contentType: PropTypes.oneOf(['location', 'userInfo', 'date']).isRequired, // Types de contenu
};

EventInfoContent.defaultProps = {};

export default EventInfoContent;
