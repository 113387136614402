/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BannerPictureListLinks } from '../../../assets/datas/PictureListLinks';
import logoWithColor from '../../../assets/sanbbizLogos/logoWithColor.png';

const PageContent = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;

  @media screen and (min-width: 930px) {
    transform: translateY(-100px);
  }
`;

const PageBanner = styled.div`
  max-width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 0;
  object-fit: cover;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(236, 240, 242, 0.825);
    z-index: 1;
  }
`;

const PageBannerContent = styled.div`
  position: relative;
  z-index: 2;
  padding-top: 20px;
`;

const PageBannerTitle = styled.h1`
  position: relative;
  color: #051145;
  font-weight: bold;
  top: 100px;
  font-size: 2em;
  margin: 0;
`;

const PageRoutesPath = styled.p`
  position: relative;
  top: 100px;
  font-size: 1em;
  margin-top: 10px;
`;

const PageBodyContent = styled.div`
  width: auto;
  margin: 60px 50px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 930px) {
    margin: 60px 20px;
  }

  @media screen and (max-width: 780px) {
    margin: 60px 10px;
  }
`;

const PageLayout = ({ title, routesPath, children, backgroundImage }) => {
  const currentIndex = Math.floor(
    Math.random() * BannerPictureListLinks.length
  );

  useEffect(() => {
    // Précharger toutes les images
    BannerPictureListLinks.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  const backgroundStyle = {
    backgroundImage: backgroundImage
      ? `url(${backgroundImage}), url(${logoWithColor})`
      : `url(${BannerPictureListLinks[currentIndex]}), url(${logoWithColor})`,
  };

  return (
    <PageContent>
      <PageBanner style={backgroundStyle}>
        <PageBannerContent>
          <PageBannerTitle>{title}</PageBannerTitle>
          <PageRoutesPath>{routesPath}</PageRoutesPath>
        </PageBannerContent>
      </PageBanner>
      <PageBodyContent>{children}</PageBodyContent>
    </PageContent>
  );
};

export default PageLayout;
