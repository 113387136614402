/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 21/05/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@chakra-ui/react';

import { timestampToDateOnlyString } from '../../../utils/date';
import { useAuth } from '../../../contexts/AuthContext';
import FormField from '../../atoms/FormField/FormField';
import { USER_SEX, USER_PROFESSION } from '../../../utils/constants';
import EditUserProfileForm from '../../molecules/EditUserProfileForm/EditUserProfileForm';
import { useDisclosure } from '@chakra-ui/react';
import defaultUserProfile from '../../../assets/medias/defaultUserProfilPicture.jpg';
import ReloadCauris from '../../molecules/ReloadCauris/ReloadCauris';
import Array from '../../atoms/Array/Array';
import EventsListComponent from '../../molecules/EventsListComponent/EventsListComponent';
import {
  filterEventsLikedByCurrentUser,
  getTotalAvailableTickets,
  getTotalTicketsSoldForEvent,
  useFindCurrentUserEvents,
} from '../../../utils/event';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import {
  DateCell,
  EventDetails,
  EventPoster,
  IconNText,
} from '../../atoms/DashboardEventSections/DashboardEventSections';
import { FaCalendarAlt, FaUserFriends } from 'react-icons/fa';
import { getEventDateRange } from '../../../utils/function';
import { IoTicketSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { TABLE_NAME } from '../../../utils/variables';

const ProfileContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 20px;
`;

const LeftHeaderGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
`;

const StyledDash = styled.div`
  width: 60px;
  height: 15px;
  background-color: #f1360a;
  border-radius: 20px;
  transform: translateY(50%);
`;

const ProfileTitle = styled.h2`
  font-weight: bold;
  font-size: 36px;
  color: #051145;
`;

const UserProfileDetails = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 10px 5px;
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  aling-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 255, 0.05);
`;

const UserProfileBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const ProfilPictureContainer = styled.div`
  align-self: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 10px;
  background-color: #ecf0f2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfilImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const UserInfos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const UserName = styled.h2`
  font-size: 26px;
  font-weight: bold;
`;
const NbSubscriber = styled.span`
  font-size: 20px;
  color: gray;
  span {
    color: #f1360a;
    font-weight: bold;
  }
`;

const UserProfileInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 255, 0.05);
`;

const FormFields = styled.div``;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 255, 0.05);
`;
const NoLikedEventMessageText = styled.div`
  font-style: italic;
  font-size: 20px;
  padding-top: 10px;
`;

function TicketSalesCell({ event }) {
  const [ticketsSold, setTicketsSold] = useState(0);

  useEffect(() => {
    getTotalTicketsSoldForEvent(event).then(setTicketsSold);
  }, [event]);

  return (
    <IconNText
      icon={<IoTicketSharp size="24px" color="#f1360a" />}
      number={ticketsSold}
      label="Places vendues"
    />
  );
}

function ManageScanner() {
  const { currentUser, reloadUser } = useAuth();
  const navigate = useNavigate();

  const handleEventClick = (event) => {
    navigate(`/events/${event.id}`);
    window.scrollTo(0, 0);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Obtenir les events
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeEvents = onSnapshot(
      collection(firestore_db, TABLE_NAME.events),
      (snapshot) => {
        setEvents(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
        setLoading(false);
      }
    );

    return () => {
      unsubscribeEvents();
    };
  }, []);

  if (loading) {
    console.log('Loading...');
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Profil picture',
        accessor: '',
        width: 130,
        Cell: ({ row }) => (
          <EventPoster src={row.original.posterPicture?.url} />
        ),
      },
      {
        Header: 'Nom',
        accessor: '',
        width: 130,
        Cell: ({ row }) => (
          <EventPoster src={row.original.posterPicture?.url} />
        ),
      },
      {
        Header: 'Statut',
        accessor: '',
        width: 300,
        Cell: ({ row }) => (
          <EventDetails
            title={row.original.name}
            location={
              row.original.location.place + ', ' + row.original.location.city
            }
            description={row.original.description}
          />
        ),
      },
      {
        Header: 'Nombre de scans',
        accessor: '',
        width: 100,
        Cell: ({ row }) => (
          <IconNText
            icon={<FaUserFriends size="24px" color="#f1360a" />}
            number={getTotalAvailableTickets(row.original)}
            label="Places"
          />
        ),
      },
      {
        Header: 'Actions',
        accessor: '',
        width: 100,
        Cell: ({ row }) => <TicketSalesCell event={row.original} />,
      },
      {
        Header: 'Evénements',
        accessor: '',
        width: 100,
        Cell: ({ row }) => (
          <DateCell
            icon={<FaCalendarAlt size="24px" color="#f1360a" />}
            date={getEventDateRange(row.original)}
          />
        ),
      },
    ],
    []
  );

  const currentUserEvents = useFindCurrentUserEvents(events);

  return (
    <ProfileContainer>
      <ProfileHeader>
        <LeftHeaderGroup>
          <StyledDash />
          <ProfileTitle> Liste de vos scanneurs </ProfileTitle>
        </LeftHeaderGroup>
        <Button
          size="md"
          color="white"
          bgColor="#f1360a"
          position="relative"
          onClick={() => {}}
        >
          {' '}
          Ajouter un scanneur
        </Button>
      </ProfileHeader>

      <UserProfileDetails>
        <Array
          columns={columns}
          data={currentUserEvents.slice(0, 5)}
          onRowClick={handleEventClick}
          rowHeight="120px"
          showHeader={true}
        />
      </UserProfileDetails>
    </ProfileContainer>
  );
}

export default ManageScanner;
