/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 01/06/2024
/* ******************************************* */

import React from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import { CGVDoc } from '../../atoms/CGVAtoms/CGVAtoms';
import styled from 'styled-components';

const CGVContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 40px;
  overflow: hidden;

  @media screen and (min-width: 1100px) {
    width: 70%;
  }
`;

function CGVPage() {
  const title = 'Conditions Générales de vente '; // Le titre de la page

  return (
    <>
      <PageLayout title={title}>
        <CGVContainer>
          <CGVDoc />
        </CGVContainer>
      </PageLayout>
    </>
  );
}

export default CGVPage;
