/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 30/06/2024
/* ******************************************* */

import React from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import CustomerFeedbackList from '../../organisms/CustomerFeedbackList/CustomerFeedbackList';

function FeedbacksPage() {
  const title = 'Retour client'; // Le titre de la page

  return (
    <>
      <PageLayout title={title}>
        <CustomerFeedbackList />
      </PageLayout>
    </>
  );
}

export default FeedbacksPage;
