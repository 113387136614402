/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 29/04/2024
/* ******************************************* */
import { IoLocation } from 'react-icons/io5';
import { IoIosMail } from 'react-icons/io';
import { FaPhoneAlt } from 'react-icons/fa';
import { ROUTES } from '../../utils/variables';
import { useSanbbizConfig } from '../../contexts/ConfigContext';

export const FooterLinksGroup = [
  {
    group: 'Liens des services',
    name: 'Accueil',
    href: ROUTES.homePage,
  },
  {
    group: 'Liens des services',
    name: 'Événements',
    href: ROUTES.eventListPage,
  },
  {
    group: 'Liens des services',
    name: 'Catégories',
    href: ROUTES.categoryListPage,
  },
  {
    group: 'Liens des services',
    name: 'A propos de nous',
    href: ROUTES.aboutPage,
  },
  {
    group: 'Liens des services',
    name: 'Témoignages',
    href: ROUTES.feedbackListPage,
  },
  // {
  //   group: 'Liens des services',
  //   name: 'Notre équipe',
  //   href: '/ourTeams',
  // },
  {
    group: 'Liens importants',
    name: 'CGV',
    href: ROUTES.CGVPage,
  },
  {
    group: 'Liens importants',
    name: 'Contact',
    href: ROUTES.contactPage,
  },
  {
    group: 'Liens importants',
    name: 'Rendez-vous',
    href: ROUTES.bookingPage,
  },
  {
    group: 'Liens importants',
    name: 'Termes & conditions',
    href: ROUTES.CGUPage,
  },
  {
    group: 'Liens importants',
    name: 'FAQ',
    href: '/FAQ',
  },
  {
    group: 'Liens rapides',
    name: 'Politique de confidentialité',
    href: ROUTES.privacyPolicyPage,
  },
  {
    group: 'Liens rapides',
    name: 'Créer un événement',
    href: ROUTES.createEventPage,
  },
  {
    group: 'Liens rapides',
    name: 'Mes billets',
    href: ROUTES.ordersPage,
  },
  // {
  //   group: 'Liens rapides',
  //   name: 'Tarification',
  //   href: '/pricing',
  // },
  // {
  //   group: 'Liens rapides',
  //   name: 'Notre succès',
  //   href: '/ourSuccess',
  // },
  { group: 'Liens rapides', name: 'Blog', href: ROUTES.blogListPage },
  {
    group: 'Liens rapides',
    name: 'Se connecter',
    href: ROUTES.loginPage,
  },
];

export const OfficialInfoItems = () => {
  const { config } = useSanbbizConfig();

  return [
    {
      icon: IoLocation,
      title: 'Localisation',
      desc: config.location.city,
    },
    {
      icon: IoIosMail,
      title: 'Email',
      desc: config.contact.email,
    },
    {
      icon: FaPhoneAlt,
      title: 'Téléphone',
      desc: config.contact.phone,
    },
  ];
};
