/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 30/05/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import FeedbackItem from '../../molecules/FeedbackItem/FeedbackItem';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';

import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const FeedbackListContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 0px 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const FeedbackCard = styled.li`
  box-shadow: 0 5px 15px rgba(0, 0, 255, 0.1);
`;

function CustomerFeedbackList() {
  // Obtenir les feedback
  const [feedbacks, setFeedbacks] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeFeedbacks = onSnapshot(
      collection(firestore_db, TABLE_NAME.customerFeedbacks),
      (snapshot) => {
        setFeedbacks(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
        setLoading(false);
      }
    );

    return () => {
      unsubscribeFeedbacks();
    };
  }, []);

  if (loading) {
    console.log('Loading...');
  }

  return (
    <FeedbackListContainer>
      {feedbacks.map((feedback) => {
        return (
          <FeedbackCard key={feedback.id}>
            <FeedbackItem currentFeedback={feedback} />
          </FeedbackCard>
        );
      })}
    </FeedbackListContainer>
  );
}

export default CustomerFeedbackList;
