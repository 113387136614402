/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 19/05/2024
/* ******************************************* */

import React, { useState, useEffect, useRef } from 'react';
import {
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';

import { firestore_db } from '../../../api/firebase/firebase-config';
import { uploadFile } from '../../../api/firebase/storage';
import AlertPopup from '../../atoms/AlertPopup/AlertPopup';

import FormField from '../../atoms/FormField/FormField';
import { Button, Text, useToast, Box } from '@chakra-ui/react';
import styled from 'styled-components';

const EditFormulaFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  height: '500px';
  padding: 20px;
`;

const FormFields = styled.div``;
const AdvListContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FormFieldsGrid = styled.div`
  padding: 0;
  margin: 0;
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

function EditBusinessFormulaForm({ formula, isOpen, onOpen, onClose }) {
  const toast = useToast();
  const [name, setName] = useState(formula ? formula.name : null);
  const [commission, setCommission] = useState(
    formula ? formula.commission : null
  );
  const [formulaAdvs, setFormulaAdvs] = useState(
    formula ? formula?.advantages : null
  );

  console.log(' début formula :', formula);
  console.log(' début formula.advantages :', formula?.advantages);
  console.log(' début formulaAdvs :', formulaAdvs);

  const [selectedAdvantages, setSelectedAdvantages] = useState({});

  const [advantages, setAdvantages] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, 'dev_mngt_plan_advantages'),
      (snapshot) => {
        const advantagesSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAdvantages(advantagesSnapshot);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  if (loading) {
    console.log('Loading...');
  }

  useEffect(() => {
    if (formulaAdvs && advantages) {
      const initAdvantages = {};
      formulaAdvs.forEach((formulaAdv) => {
        const fullAdvantage = advantages.find(
          (advantageItem) => advantageItem.id === formulaAdv.id
        );
        if (fullAdvantage) {
          initAdvantages[formulaAdv.id] = {
            formulaAdv: { ...formulaAdv },
            ...fullAdvantage,
            checked: true,
            isOccurrence: fullAdvantage.occurrence || null,
            isDuration: formulaAdv.duration || null,
          };
          console.log('fullAdvantage :', fullAdvantage);
        }
      });
      setSelectedAdvantages(initAdvantages);
    }
  }, [formulaAdvs, advantages]);

  console.log(' recu formulaAdvs :', formulaAdvs);
  console.log('selectedAdvantages :', selectedAdvantages);

  const handleAdvantageChange = (id, checked) => {
    setSelectedAdvantages((prevAdvantages) => ({
      ...prevAdvantages,
      [id]: {
        ...prevAdvantages[id],
        checked,
      },
    }));
  };

  // Gère la creation
  const cancelRef = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formulaData = {};

      if (formula) {
        // Mise à jour de la catégorie existante
        const docRef = doc(firestore_db, 'dev_mngt_plans', formula.id);
        await updateDoc(docRef, formulaData);
        toast({
          title: 'Formule modifiée',
          description: 'La formule a été mise à jour avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Création d'une nouvelle catégorie
        formulaData.createdAt = serverTimestamp(); // Ajout du champ createdAt uniquement ici
        const docRef = await addDoc(
          collection(firestore_db, 'dev__mngt_plans'),
          formulaData
        );
        toast({
          title: 'Formule ajoutée',
          description: 'La Formule a été ajoutée avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        console.log('New formula created with ID:', docRef.id);
      }
      onClose(); // Fermer le formulaire après l'opération
    } catch (error) {
      toast({
        title: 'Erreur de mise à jour',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Initialisation directe de l'état avec un formulaire de billet
  const [advForms, setAdvForms] = useState();

  const addAdv = (type) => {};

  const removeAdv = (id) => {};

  return (
    <AlertPopup
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      onNo={onClose}
      onYes={handleSubmit}
      windowTitle={
        formula ? 'Modifier la formule' : 'Créer une nouvelle formule'
      }
    >
      <EditFormulaFormContent>
        <FormFields>
          <FormField
            id="name"
            label="Nom de la formule"
            inputType="text"
            defaultValue={name}
            placeholder="Entrez le nouveau nom de la formule"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isRequired
          />
          <FormField
            id="commission"
            label="Commission (%) "
            inputType="number"
            defaultValue={commission}
            placeholder="Entrez le nouveau nom de la catégorie"
            value={commission}
            onChange={(e) => setCommission(e.target.value)}
            isRequired
          />
          <Box
            pt={2}
            borderTop="1px"
            borderColor="gray.200"
            m={4}
            textAlign="center"
          >
            <Text fontSize="md" as="b" textAlign="center">
              {' '}
              Liste des avantages{' '}
            </Text>
            <AdvListContainer>
              {advantages &&
                advantages.map((advantage) => (
                  <React.Fragment key={advantage.id}>
                    <FormField
                      id={`advantage-${advantage.id}`}
                      inputType="checkbox"
                      name={advantage.description}
                      label={advantage.description}
                      value={advantage.id}
                      checked={!!selectedAdvantages[advantage.id]?.checked}
                      onChange={(e) =>
                        handleAdvantageChange(advantage.id, e.target.checked)
                      }
                      isRequired
                    />
                    {selectedAdvantages[advantage.id]?.checked && (
                      <FormFieldsGrid>
                        <FormField
                          id={`occurrence-${advantage.id}`}
                          label="Occurrence"
                          inputType="text"
                          defaultValue={
                            selectedAdvantages[advantage.id]?.occurrence
                          }
                          placeholder="Occurrence"
                          onChange={(e) => {
                            const newOccurrence = e.target.value;
                            setSelectedAdvantages((prevAdvantages) => ({
                              ...prevAdvantages,
                              [advantage.id]: {
                                ...prevAdvantages[advantage.id],
                                occurrence: newOccurrence,
                              },
                            }));
                          }}
                          isRequired
                          isDisabled={!advantage.occurrence}
                        />

                        <FormField
                          id={`duration-${advantage.id}`}
                          label="Duration"
                          inputType="text"
                          defaultValue={
                            selectedAdvantages[advantage.id]?.duration
                          }
                          placeholder="Duration"
                          onChange={(e) => {
                            const newDuration = e.target.value;
                            setSelectedAdvantages((prevAdvantages) => ({
                              ...prevAdvantages,
                              [advantage.id]: {
                                ...prevAdvantages[advantage.id],
                                duration: newDuration,
                              },
                            }));
                          }}
                          isRequired
                          isDisabled={!advantage.duration}
                        />
                      </FormFieldsGrid>
                    )}
                  </React.Fragment>
                ))}
            </AdvListContainer>
          </Box>
          {/* <Button size="md" onClick={() => addAdv('ticket')}>
            Ajouter un billet
          </Button> */}
        </FormFields>
      </EditFormulaFormContent>
    </AlertPopup>
  );
}

export default EditBusinessFormulaForm;
