/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/05/2024
/* ******************************************* */

import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

function RedirectComponent({ to }) {
  useEffect(() => {
    // Cette fonction défile vers le haut immédiatement après la mise à jour du DOM
    window.scrollTo(0, 0);
  }, []); // Les crochets vides garantissent que l'effet ne s'exécute qu'une fois après le premier rendu

  return <Navigate to={to} replace={true} />;
}

export default RedirectComponent;
