/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 10/04/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import logo from '../../../assets/sanbbizLogos/logo.png';

const PlansContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
`;

const Plan = styled.div`
  border: ${({ isSelected }) =>
    isSelected ? '2px solid blue' : '1px solid #e1e1e1'};
  border-radius: 16px;
  padding: 24px;
  width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: ${({ isSelected }) => (isSelected ? 'blue' : 'white')};
  color: ${({ isSelected }) => (isSelected ? 'white' : 'black')};
  text-align: center;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-5px);
  }
`;

const PlanTitle = styled.h2`
  margin: 0;
  font-size: 24px;
`;

// const TopText = styled.p`
//   font-size: 16px;
//   font-weight: bold;
// `;

const PlanPrice = styled.p`
  margin: 16px 0 2px 0;
  font-size: 32px;
  font-weight: bold;
`;
const BottomText = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const PlanFeatures = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0;

  li {
    margin-bottom: 16px;
    text-align: left;
    position: relative;
    padding-left: 24px;
  }

  li::before {
    content: '';
    background-image: url(${logo});
    background-size: cover;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const PricingPlan = ({
  advantages,
  commission,
  name,
  isSelected,
  onSelect,
}) => (
  <Plan isSelected={isSelected} onClick={onSelect}>
    <PlanTitle>{name}</PlanTitle>
    {/* <TopText>Je rentre du texte pour tester</TopText> */}
    <PlanPrice>
      {commission}
      {'%'}
    </PlanPrice>
    <BottomText>des ventes de billets</BottomText>
    <PlanFeatures>
      {advantages.map((adv, index) => (
        <li key={index}>{adv}</li>
      ))}
    </PlanFeatures>
  </Plan>
);

function CreateEventPricing() {
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [mngtPlans, setMngtPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  // Gérer la sélection d'un plan
  const handlePlanSelect = (planId) => {
    setSelectedPlanId(planId);
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, 'dev_mngt_plans'),
      (snapshot) => {
        const mngtPlansSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Trier les plans en fonction de la commission avant de mettre à jour l'état
        mngtPlansSnapshot.sort(
          (a, b) => parseFloat(a.commission) - parseFloat(b.commission)
        );
        setMngtPlans(mngtPlansSnapshot);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  if (loading) {
    console.log('Loading...');
  }

  return (
    <PlansContainer>
      {mngtPlans.map((plan) => (
        <PricingPlan
          key={plan.id}
          {...plan}
          isSelected={plan.id === selectedPlanId}
          onSelect={() => handlePlanSelect(plan.id)}
        />
      ))}
    </PlansContainer>
  );
}

export default CreateEventPricing;
