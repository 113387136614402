/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import BookingForm from '../../molecules/BookingForm/BookingForm';
import PageLayout from '../../templates/PageLayout/PageLayout';

function LoginPage() {
  const title = 'Prendre rendez-vous'; // Le titre de la page

  return (
    <>
      <PageLayout title={title}>
        <BookingForm />
      </PageLayout>
    </>
  );
}

export default LoginPage;
