/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 01/04/2024
/* ******************************************* */

import React, { useState } from 'react';
import { Box, RadioGroup, Radio, HStack, Text } from '@chakra-ui/react';
import FormField from '../../atoms/FormField/FormField';

function EndDateSelector({ onEndDateChange, onEventCountChange }) {
  const [endOption, setEndOption] = useState('date');

  const handleEndOptionChange = (value) => {
    setEndOption(value);
    // Réinitialiser les valeurs lors du changement de type
    onEndDateChange('');
    onEventCountChange('');
  };

  return (
    <Box pt={2} borderTop="1px" borderColor="gray.200" mt={4}>
      <Text fontSize="2xl" as="b">
        {' '}
        Fin d'événements{' '}
      </Text>
      <RadioGroup onChange={setEndOption} value={endOption}>
        <HStack spacing={4} justify="space-between">
          <Radio value="date" />
          <FormField
            id="eventDayEnd"
            label="Date de fin"
            inputType="date"
            onChange={(e) => onEndDateChange(e.target.value)}
            isDisabled={endOption !== 'date'}
          />
          <Radio value="count" />
          <FormField
            id="eventNbEventEnd"
            label="Nombre d'événements"
            inputType="number"
            placeholder="Nombre d'événements au total"
            onChange={(e) => onEventCountChange(e.target.value)}
            isDisabled={endOption !== 'count'}
          />
        </HStack>
      </RadioGroup>
    </Box>
  );
}

export default EndDateSelector;
