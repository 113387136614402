/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 23/05/2024
/* ******************************************* */

import React from 'react';
import ProfilePromoter from '../../organisms/ProfilePromoter/ProfilePromoter';
import SidebarLayout from '../../templates/SidebarLayout/SidebarLayout';
import { useAuth } from '../../../contexts/AuthContext';
import RedirectComponent from '../../atoms/RedirectComponent/RedirectComponent';
import { ROUTES } from '../../../utils/variables';

function MainProfilePage() {
  const { userLoggedIn, currentUser } = useAuth();

  // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
  if (!userLoggedIn) {
    return <RedirectComponent to={ROUTES.loginPage} replace={true} />;
  }

  if (!currentUser || !currentUser.dbProfile) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <SidebarLayout menuTitle={'Profil'} menuRoutePath={' Profil'}>
        <ProfilePromoter />
      </SidebarLayout>
    </>
  );
}

export default MainProfilePage;
