/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 20/04/2024
/* ******************************************* */

import { MdSpaceDashboard, MdEvent, MdContactPhone } from 'react-icons/md';
import { BsBank } from 'react-icons/bs';
import { HiUserGroup } from 'react-icons/hi2';
import { TiMessages } from 'react-icons/ti';
import { IoTicketOutline } from 'react-icons/io5';
import {
  MdQrCodeScanner,
  MdNotificationImportant,
  MdAdminPanelSettings,
} from 'react-icons/md';
import { ImStatsDots } from 'react-icons/im';
import { BiCategoryAlt } from 'react-icons/bi';
import { LuNewspaper } from 'react-icons/lu';
import { MdOutlineFeedback } from 'react-icons/md';
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import { IoSettingsSharp } from 'react-icons/io5';
import { FaUserTie } from 'react-icons/fa'; // promoter icon
import { IoTicketSharp } from 'react-icons/io5';
import { FaRegUser } from 'react-icons/fa';
import { CgProfile } from 'react-icons/cg';
import { USER_STATUS } from '../../utils/constants';
import { ROUTES } from '../../utils/variables';

export const UserMenuList = [
  {
    role: USER_STATUS.ADMIN,
    menus: [
      {
        name: 'Tableau de bord',
        path: ROUTES.userDashboardPage,
        icon: MdSpaceDashboard,
      },
      {
        name: 'Utilisateurs',
        path: '/manageUserAccounts',
        icon: HiUserGroup,
      },
      {
        name: 'Catégories',
        path: ROUTES.manageCategoryPage,
        icon: BiCategoryAlt,
      },
      {
        name: 'Événements',
        path: ROUTES.manageEventPage,
        icon: MdEvent,
      },
      {
        name: 'Créer notification',
        path: '/createNotification',
        icon: MdNotificationImportant,
      },

      {
        name: 'Finance',
        path: '/mngtPlanAdvantage',
        icon: BsBank,
      },

      {
        name: 'Blog',
        path: ROUTES.manageBlogPage,
        icon: LuNewspaper,
      },
      {
        name: 'Retour client',
        path: ROUTES.manageFeedbacksPage,
        icon: MdOutlineFeedback,
      },
      {
        name: 'Tarification',
        path: ROUTES.manageBusinessPlanPage,
        icon: FaMoneyBillTransfer,
      },

      {
        name: 'Rendez vous',
        path: '/appointmentList',
        icon: TiMessages,
      },
      {
        name: 'Prise de contact',
        path: '/contactList',
        icon: MdContactPhone,
      },
      {
        name: 'Paramètres ',
        path: '/parameters',
        icon: IoSettingsSharp,
      },
    ],
  },

  {
    role: USER_STATUS.PROMOTER,
    menus: [
      {
        name: 'Tableau de bord',
        path: ROUTES.userDashboardPage,
        icon: MdSpaceDashboard,
      },

      {
        name: 'Événements',
        path: ROUTES.manageEventPage,
        icon: MdEvent,
      },
      {
        name: 'Rapport',
        path: '/report',
        icon: ImStatsDots,
      },
      {
        name: 'Commandes',
        path: ROUTES.ordersPage,
        icon: IoTicketOutline,
      },
      {
        name: 'Scanneur',
        path: ROUTES.manageScannerPage,
        icon: MdQrCodeScanner,
      },
      {
        name: 'Finance',
        path: '/finance',
        icon: BsBank,
      },
    ],
  },
];

export const getUserProfileMenuItems = (userStatus, isCustomerProfile) => [
  ...(isCustomerProfile
    ? [
        {
          id: 1,
          title: 'Profil',
          profiles: [
            USER_STATUS.ADMIN,
            USER_STATUS.PROMOTER,
            USER_STATUS.CUSTOMER,
          ],
          windowPath: ROUTES.customerProfilePage,
          group: 'Compte',
          icon: CgProfile,
        },
      ]
    : !isCustomerProfile
    ? [
        {
          id: 1,
          title: 'Profil',
          profiles: [
            USER_STATUS.ADMIN,
            USER_STATUS.PROMOTER,
            USER_STATUS.CUSTOMER,
          ],
          windowPath: ROUTES.mainProfilePage,
          group: 'Compte',
          icon: CgProfile,
        },
      ]
    : []),

  ...(userStatus === USER_STATUS.ADMIN && isCustomerProfile
    ? [
        {
          id: 2,
          title: 'Devenir admin',
          profiles: [USER_STATUS.ADMIN],
          windowPath: ROUTES.userDashboardPage,
          group: 'Changer de profil',
          icon: MdAdminPanelSettings,
        },
      ]
    : userStatus === USER_STATUS.ADMIN && !isCustomerProfile
    ? [
        {
          id: 2,
          title: 'Devenir participant',
          profiles: [USER_STATUS.ADMIN],
          windowPath: ROUTES.homePage,
          group: 'Changer de profil',
          icon: FaRegUser,
        },
      ]
    : []),
  ...(userStatus === USER_STATUS.PROMOTER && isCustomerProfile
    ? [
        {
          id: 3,
          title: 'Devenir promoteur',
          profiles: [USER_STATUS.PROMOTER],
          windowPath: ROUTES.userDashboardPage,
          group: 'Changer de profil',
          icon: FaUserTie,
        },
      ]
    : userStatus === USER_STATUS.PROMOTER && !isCustomerProfile
    ? [
        {
          id: 3,
          title: 'Devenir participant',
          profiles: [USER_STATUS.PROMOTER],
          windowPath: ROUTES.homePage,
          group: 'Changer de profil',
          icon: FaRegUser,
        },
      ]
    : []),
  {
    id: 4,
    title: 'Mes billets',
    profiles: [USER_STATUS.ADMIN, USER_STATUS.PROMOTER, USER_STATUS.CUSTOMER],
    windowPath: ROUTES.ordersPage,
    group: 'Compte',
    icon: IoTicketSharp,
  },
];
