/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 01/04/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import { Input } from '@chakra-ui/react';

function TimePicker({
  id,
  placeholder,
  isRequired,
  value,
  updateTime,
  ...props
}) {
  const [time, setTime] = useState(value);

  // Écouter les changements de la prop 'value'
  useEffect(() => {
    setTime(value);
  }, [value]);

  // Mettre à jour l'état local et propager les changements au parent
  const handleTimeChange = (event) => {
    const newTime = event.target.value;
    setTime(newTime);
    updateTime(newTime); // Fonction du parent pour mettre à jour l'état
  };

  return (
    <Input
      id={id}
      type="time"
      placeholder={placeholder}
      required={isRequired}
      value={time}
      onChange={handleTimeChange}
      {...props}
    />
  );
}

export default TimePicker;
