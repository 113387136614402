/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 01/04/2024
/* ******************************************* */

import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';

import { storage_firebase } from './firebase-config';
import { v4 as uuidv4 } from 'uuid';

export async function uploadFile(directoryPath, file) {
  const uniqueFileName = uuidv4(); // Crée un nom de fichier unique
  const fileExtension = file.name.substring(file.name.lastIndexOf('.')); // Extrayez l'extension du fichier original
  const path = `${directoryPath}/${uniqueFileName}${fileExtension}`; // Chemin complet avec le nom de fichier unique
  const storageRef = ref(storage_firebase, path); // Crée une référence au fichier dans Firebase Storage
  let url = undefined;

  try {
    await uploadBytes(storageRef, file); // Télécharge le fichier
    url = await getDownloadURL(storageRef); // Obtient l'URL de téléchargement du fichier
    console.log('File uploaded and URL fetched:', url);
  } catch (error) {
    console.error('Le chargement du fichier a échoué: ', error);
    throw error; // Propage l'erreur pour une gestion plus haut dans l'application
  }

  return url;
}

export async function deleteFile(fileUrl) {
  const fileRef = ref(storage_firebase, fileUrl);

  try {
    await deleteObject(fileRef);
    return true;
  } catch (error) {
    console.error('La suppression du fichier a échoué: ', error);
    return false;
  }
}
