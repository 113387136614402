/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import ContactForm from '../../molecules/ContactForm/ContactForm';

function ContactPage() {
  const title = 'Nous contacter'; // Le titre de la page

  return (
    <>
      <PageLayout title={title}>
        <ContactForm />
      </PageLayout>
    </>
  );
}

export default ContactPage;
