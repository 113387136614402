/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import { useState } from 'react';
import './CategoryFilter2GetEvent.css';
import FormField from '../../atoms/FormField/FormField';

function CategoryFilter2GetEvent({
  categories,
  onCategoryChange,
  selectedCategories,
  filterByText,
}) {
  const [inputText, setInputText] = useState('');

  const handleInputChange = (event) => {
    setInputText(event.target.value);
    filterByText(event.target.value);
  };

  return (
    <div className="event-filter-container">
      <div className="event-filter-form-container">
        <FormField
          inputType="search"
          placeholder="Rechercher ..."
          value={inputText}
          onChange={handleInputChange}
        />
      </div>
      <div className="cat-list-container">
        <span className="section-name">Categories</span>
        {categories.map((category) => (
          <label key={category.id}>
            <input
              type="checkbox"
              name={category.name}
              value={category.id}
              checked={selectedCategories.includes(category.id)} // Vérifie si la catégorie est sélectionnée
              onChange={(e) =>
                onCategoryChange(e.target.value, e.target.checked)
              }
            />{' '}
            {category.name}
          </label>
        ))}
      </div>
    </div>
  );
}

export default CategoryFilter2GetEvent;
