/* ******************************************** */
/* * Author: Jamal SIDIKOU
/* * Project : SANNBIZ
/* * Create at : 09/03/2024
/* * Updated at : 27/03/2024
/* ******************************************* */

import React, { useState } from 'react';

import {
  Box,
  VStack,
  Stack,
  Link,
  Divider,
  HStack,
  Icon,
  Text,
  Button,
} from '@chakra-ui/react';

import {
  FooterLinksGroup,
  OfficialInfoItems,
} from '../../../assets/datas/FooterLinksGroup';

import Logo from '../../../assets/sanbbizLogos/logo.png';
import GooglePlayIcon from '../../atoms/Icon/GooglePlayIcon';
import AppStoreIcon from '../../atoms/Icon/AppStoreIcon';
import { IoIosArrowDown } from 'react-icons/io';
import { useMediaQuery } from '@chakra-ui/react';

import styled from 'styled-components';
import { useSanbbizConfig } from '../../../contexts/ConfigContext';
import { DiAndroid } from 'react-icons/di';
import { FaApple } from 'react-icons/fa';

const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ImageLogo = styled.img`
  width: 70%;
  object-fit: cover;
`;

// Utilitaire pour obtenir les liens par groupe
export const getLinksByGroup = (links) => {
  return links.reduce((acc, link) => {
    // Si le groupe n'existe pas, créez-le et initialisez-le avec un tableau vide
    if (!acc[link.group]) {
      acc[link.group] = [];
    }
    // Ajoutez le lien au groupe correspondant
    acc[link.group].push(link);
    return acc;
  }, {});
};

// Composant LinkGroup
const LinkGroup = ({ groupName, groupItems }) => {
  const [isLargerThan1290] = useMediaQuery('(min-width: 1290px)');
  const [showLinks, setShowLinks] = useState(isLargerThan1290);
  // Toggle pour l'affichage des liens
  const handleToggleLinks = () => {
    if (!isLargerThan1290) {
      setShowLinks(!showLinks);
    }
  };
  return (
    <VStack align="start" spacing={4} w="full">
      <HStack
        w="full"
        justifyContent="space-between"
        onClick={handleToggleLinks}
        cursor="pointer"
      >
        <Text fontWeight="semibold" fontSize="xl" color="white">
          {groupName}
        </Text>
        {isLargerThan1290 ? null : (
          <Icon
            as={IoIosArrowDown}
            boxSize={5}
            color="white"
            transform={showLinks ? 'rotate(180deg)' : 'rotate(0)'}
          />
        )}
      </HStack>
      {(showLinks || isLargerThan1290) &&
        groupItems.map((item) => (
          <Link
            key={item.name}
            href={item.href}
            textDecoration="underline"
            _hover={{ textDecoration: 'none' }}
            color="gray.200"
          >
            {item.name}
          </Link>
        ))}
    </VStack>
  );
};

const OfficialInfoGroup = () => {
  const [isLargerThan1290] = useMediaQuery('(min-width: 1290px)');
  const [showLinks, setShowLinks] = useState(isLargerThan1290);
  // Toggle pour l'affichage des liens
  const handleToggleLinks = () => {
    if (!isLargerThan1290) {
      setShowLinks(!showLinks);
    }
  };

  const officialInfo = OfficialInfoItems();

  return (
    <VStack alignItems="start" spacing={4} w="full">
      <HStack
        w="full"
        justifyContent="space-between"
        onClick={handleToggleLinks}
        cursor="pointer"
      >
        <Text fontWeight="semibold" fontSize="xl" color="white">
          Informations
        </Text>
        {isLargerThan1290 ? null : (
          <Icon
            as={IoIosArrowDown}
            boxSize={5}
            color="white"
            transform={showLinks ? 'rotate(180deg)' : 'rotate(0)'}
          />
        )}
      </HStack>
      {(showLinks || isLargerThan1290) &&
        officialInfo.map((item, index) => (
          <HStack key={index}>
            <Icon as={item.icon} boxSize={6} color="white" />
            <VStack align="start" gap="2px">
              <Text fontWeight="semibold">{item.title}</Text>
              <Text>{item.desc}</Text>
            </VStack>
          </HStack>
        ))}
    </VStack>
  );
};

const Footer = () => {
  const { config } = useSanbbizConfig();
  const [isLargerThan1290] = useMediaQuery('(min-width: 1290px)');
  const groupedLinks = getLinksByGroup(FooterLinksGroup);

  return (
    <Box bg="#202C41" color="#FFFFFF" p={{ base: 5, md: 8 }}>
      <Stack
        direction={isLargerThan1290 ? 'row' : 'column'}
        spacing={{ base: 8, md: 10 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box textAlign="center" maxW={isLargerThan1290 ? '250px' : '350px'}>
          <Link href="/">
            <LogoContainer>
              <ImageLogo src={Logo} alt="Logo Sanbbiz" />
            </LogoContainer>
          </Link>
          <Text fontSize="md" mt={2}>
            SANBBIZ est une plateforme innovante et conviviale qui vise à
            simplifier la recherche, la réservation et la gestion de billets
            pour une variété d'événements et d'activités à Ouagadougou.
          </Text>
          <Stack
            spacing={2}
            direction={isLargerThan1290 ? 'row' : 'column'}
            mt={4}
          >
            <Button
              as={Link}
              href={config.url.googleplay}
              leftIcon={<DiAndroid color={'#051145'} />}
              colorScheme="teal"
              isExternal
            >
              Google Play
            </Button>
            <Button
              as={Link}
              href={config.url.appstore}
              leftIcon={<FaApple color={'#051145'} />}
              colorScheme="teal"
              isExternal
            >
              App Store
            </Button>
          </Stack>
        </Box>

        {Object.entries(groupedLinks).map(([groupName, groupItems]) => (
          <LinkGroup
            key={groupName}
            groupName={groupName}
            groupItems={groupItems}
          />
        ))}
        <OfficialInfoGroup />
      </Stack>
      <Divider my={4} />
      <Text fontSize="md" textAlign="center">
        Tous droits réservés @{' '}
        <Link
          href="/"
          textDecoration="underline"
          _hover={{ textDecoration: 'none' }}
        >
          Sanbbiz
        </Link>{' '}
        {new Date().getFullYear()}
      </Text>
    </Box>
  );
};

export default Footer;
