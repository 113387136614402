/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 30/03/2024
/* ******************************************* */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  setEventTypeEventId,
  setEventCategoryId,
  setEventName,
  setEventDescription,
  setEventLocation,
  setEventDates,
  setEventTicketVersions,
  setEventCreatorId,
  setEventMngtPlanId,
  setEventPaymentMethodId,
  setEventReview,
  setEventPosterPicture,
  removeEventPosterPicture,
  setEventCoverPicture,
  removeEventCoverPicture,
  setEventGalleryPictures,
  removeEventGalleryPicture,
} from '../../../actions/event.action';
import { useNavigate } from 'react-router-dom';
import CreateEventFormTypeNCategory from '../../molecules/CreateEventFormTypeNCategory/CreateEventFormTypeNCategory';
import CreateEventFormDetails from '../../molecules/CreateEventFormDetails/CreateEventFormDetails';
import CreateEventFormMedias from '../../molecules/CreateEventFormMedias/CreateEventFormMedias';
import styled from 'styled-components';
import { Button } from '@chakra-ui/button';
import { firestore_db } from '../../../api/firebase/firebase-config';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { uploadFile } from '../../../api/firebase/storage';

import CreateEventTicketForm from '../../molecules/CreateEventTicketForm/CreateEventTicketForm';

import './CreateEventForm.css';
import { TABLE_NAME } from '../../../utils/variables';

const FormsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // max-width: 80%;
  width: 80%;
  gap: 30px;
`;

const FormsSection = styled.div`
  display: flex;
  flex-direction: column;
  // min-width: 500px;
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  border-radius: 20px;
`;

function CreateEventForm({
  currentUser,
  typeId,
  categoryId,
  name,
  description,
  dates,
  location,
  ticketVersions,
  createdBy,
  mngtPlantId,
  paymentMethodsId,
  review,
  posterPicture,
  coverPicture,
  galleryPictures,
}) {
  const navigate = useNavigate();

  const handleCreateEvent = async () => {
    // Vérifier si toutes les données nécessaires sont présentes
    if (!typeId || !categoryId || !name || !dates || dates.length === 0) {
      alert('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    // Convertir chaque objet de date dans le tableau au format Timestamp de Firestore
    const convertedDates = dates.map((date) => ({
      start: Timestamp.fromDate(new Date(date.start)),
      end: Timestamp.fromDate(new Date(date.end)),
    }));

    // Préparation des données de l'événement pour l'envoi
    const eventData = {
      typeId,
      categoryId,
      name,
      description,
      dates: convertedDates,
      location,
      ticketVersions,
      createdBy,
      mngtPlantId,
      paymentMethodsId,
      review,
      posterPicture,
      coverPicture,
      galleryPictures,
    };

    // Tentative d'envoi des données à Firestore
    try {
      const docRef = await addDoc(
        collection(firestore_db, TABLE_NAME.events),
        eventData
      );
      console.log('Document written with ID: ', docRef.id);
      alert('Événement créé avec succès!');
      navigate('/test1'); // Redirection de l'utilisateur vers une autre page après la création
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('Erreur lors de la création de l’événement. Veuillez réessayer.');
    }
  };

  return (
    <FormsContainer>
      <FormsSection>
        <CreateEventFormTypeNCategory
          setEventCoverPicture={setEventCoverPicture}
          removeEventCoverPicture={removeEventCoverPicture}
          setEventTypeEventId={setEventTypeEventId}
          setEventCategoryId={setEventCategoryId}
        />
      </FormsSection>
      <FormsSection>
        <CreateEventFormDetails
          setEventName={setEventName}
          setEventDescription={setEventDescription}
          setEventDates={setEventDates}
          setEventLocation={setEventLocation}
        />
      </FormsSection>
      <FormsSection>
        <CreateEventFormMedias
          setEventPosterPicture={setEventPosterPicture}
          removeEventPosterPicture={removeEventPosterPicture}
          setEventGalleryPictures={setEventGalleryPictures}
          removeEventGalleryPicture={removeEventGalleryPicture}
        />
      </FormsSection>
      <Button onClick={handleCreateEvent} colorScheme="blue">
        Créer l'événement
      </Button>
    </FormsContainer>
  );
}
const mapStateToProps = (state) => ({
  typeId: state.eventReducer.typeId,
  categoryId: state.eventReducer.categoryId,
  name: state.eventReducer.name,
  description: state.eventReducer.description,
  location: state.eventReducer.location,
  dates: state.eventReducer.dates,
  ticketVersions: state.eventReducer.ticketVersions,
  createdBy: state.eventReducer.createdBy,
  mngtPlantId: state.eventReducer.mngtPlantId,
  paymentMethodsId: state.eventReducer.paymentMethodsId,
  review: state.eventReducer.review,
  posterPicture: state.eventReducer.posterPicture,
  coverPicture: state.eventReducer.coverPicture,
  galleryPictures: state.eventReducer.galleryPictures,
});

const mapDispatchToProps = (dispatch) => ({
  setEventTypeEventId: (typeId) => dispatch(setEventTypeEventId(typeId)),
  setEventCategoryId: (categoryId) => dispatch(setEventCategoryId(categoryId)),
  setName: (name) => dispatch(setEventName(name)),
  setDescription: (description) => dispatch(setEventDescription(description)),
  setEventLocation: (location) => dispatch(setEventLocation(location)),
  setEventDates: (dates) => dispatch(setEventDates(dates)),

  setEventPosterPicture: (posterPicture) =>
    dispatch(setEventPosterPicture(posterPicture)),
  removeEventPosterPicture: (posterPicture) =>
    dispatch(removeEventPosterPicture(posterPicture)),
  setEventGalleryPictures: (galleryPictures) =>
    dispatch(setEventGalleryPictures(galleryPictures)),
  removeEventGalleryPicture: (galleryPictures) =>
    dispatch(removeEventGalleryPicture(galleryPictures)),
  setEventCoverPicture: (coverPicture) =>
    dispatch(setEventCoverPicture(coverPicture)),
  removeEventCoverPicture: (coverPicture) =>
    dispatch(removeEventCoverPicture(coverPicture)),
});

// const mapDispatchToProps = (dispatch) => ({
//   setEventTypeEventId: (typeId) => dispatch(setEventTypeEventId(typeId)),
//   setEventCategoryId: (categoryId) => dispatch(setEventCategoryId(categoryId)),
//   setName: (name) => dispatch(setEventName(name)),
//   setDescription: (description) => dispatch(setEventDescription(description)),
//   setEventLocation: (place, geoLocationLink, city) =>
//     dispatch(setEventLocation({ place, geoLocationLink, city })),
//   setEventDates: (dates) => dispatch(setEventDates(dates)),
//   setEventTicketVersions: (ticketVersions) =>
//     dispatch(setEventTicketVersions(ticketVersions)),
//   setEventCreatorId: (createdBy) => dispatch(setEventCreatorId(createdBy)),
//   setEventMngtPlanId: (mngtPlantId) =>
//     dispatch(setEventMngtPlanId(mngtPlantId)),
//   setEventPaymentMethodId: (paymentMethodsId) =>
//     dispatch(setEventPaymentMethodId(paymentMethodsId)),
//   setEventReview: (review) => dispatch(setEventReview(review)),
//   setEventPosterPicture: (width, height, url) =>
//     dispatch(setEventPosterPicture({ width, height, url })),
//   setEventCoverPicture: (width, height, url) =>
//     dispatch(setEventCoverPicture({ width, height, url })),
//   setEventGalleryPictures: (galleryPictures) =>
//     dispatch(setEventGalleryPictures(galleryPictures)),
// });

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventForm);
