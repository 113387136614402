/* ******************************************** */
/* * Creator: Pale William
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/04/2024
/* ******************************************* */

import 'intl';
import 'intl/locale-data/jsonp/fr-BF';

/**
 * This function is used to compute the discounted price.
 * It finally returns the discounted price in XOF format.
 *
 * @param {number} price
 * @param {number} discount
 * @returns discounted price in XOF format
 */
export function format2XOF(price, discount, showZero = false) {
  let formatedPrice = '';
  let priceAfterDiscount = price;
  if (discount) {
    priceAfterDiscount -= price * (discount / 100);
  }
  if (priceAfterDiscount === 0 && showZero === false) {
    formatedPrice = 'Gratuit';
  } else {
    formatedPrice = new Intl.NumberFormat('fr-BF', {
      style: 'currency',
      currency: 'XOF',
    }).format(priceAfterDiscount);
  }
  return formatedPrice;
}

/**
 * Computes the final ticket price after applying a commission deduction.
 *
 * @param {number|string} price - The initial price of the ticket. If undefined, defaults to 2000.
 * @param {number} commission - The commission percentage to be deducted from the initial price.
 *
 * @returns {number} The final price of the ticket after deducting the commission.
 */
export function computeFinalTicketPrice(price, commission) {
  // Check if the price is provided, if not, set a default value of 2000
  const initialPrice = price === undefined ? 2000 : parseFloat(price);

  // Store the commission percentage
  const commissionPercentage = commission;

  // Calculate the commission amount by multiplying the initial price by the commission percentage and dividing by 100
  const commissionAmount = initialPrice * (commissionPercentage / 100);

  // Subtract the commission amount from the initial price to get the final price
  const finalPrice = initialPrice - commissionAmount;

  // Return the calculated final price
  return finalPrice;
}
