/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 08/06/2024
/* ******************************************* */

import React from 'react';
import styled from 'styled-components';

const TableOfContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 20px 10px;
  background-color: #ecf0f2;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  border-radius: 20px;
`;

const SectionGroupTitle = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  color: #051145;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableOfContentNav = styled.nav`
  width: 100%;
  padding: 0px 20px;
`;

const TableOfContentList = styled.ul`
  list-style: none;
  padding: 0;
`;

const TableOfContentItem = styled.li`
  margin-bottom: 10px;
`;

const TableOfContentItemLink = styled.a`
  color: blue;
  text-decoration: none;
  margin-left: ${(props) => (props.isSubTitle ? '40px' : '0')};

  &:hover {
    font-weight: bold;
  }
`;

export function TableOfContents() {
  return (
    <TableOfContentContainer>
      <SectionGroupTitle>Sommaire</SectionGroupTitle>
      <TableOfContentNav>
        <TableOfContentList>
          <TableOfContentItem>
            <TableOfContentItemLink href="#definitions-section">
              Définitions
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#contract-object-section">
              Objet du contrat
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#cgv-acceptation-section">
              Acceptation des CGV
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#article-caracteristics-section">
              Caractéristiques des articles
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#geo-place-section">
              Zone géographique
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#event-creation-section">
              Création d’un évènement / activité
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink
              href="#event-creation-process-section"
              isSubTitle
            >
              Processus de Création d'un Événement/Activité
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#ticket-sells-section" isSubTitle>
              Vente Unique des Billets
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#tarification-section">
              Tarifs
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#orders-section">
              Commandes
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#payment-modality-section">
              Modalités de paiement
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#delivery-section">
              Expédition et délais de livraison
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#ticket-usage-and-validity-section">
              Utilisation et validité des tickets
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#ticket-validity-section" isSubTitle>
              Validité du ticket
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#ticket-resells-section" isSubTitle>
              Revente de ticket
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#ticket-copy-section" isSubTitle>
              Reproduction de ticket
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#ticket-checking-section">
              Contrôle des tickets
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink
              href="#identity-checking-section"
              isSubTitle
            >
              Contrôle de l'identité
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#cancel-and-cash-back-section">
              Annulation et remboursements
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#responsability-section">
              Responsabilité
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#customer-services-section">
              Service Client
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#applicable-laws-and-disputes-section">
              Droit applicable et litiges
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#sanbbiz-contact-section">
              Contact
            </TableOfContentItemLink>
          </TableOfContentItem>
        </TableOfContentList>
      </TableOfContentNav>
    </TableOfContentContainer>
  );
}

const PrivacyPolicySection = styled.section`
  widht: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 10px;
`;

const PrivacyPolicySectionTitle = styled.h2`
  font-weight: bold;
  font-size: 1.3rem;
  color: black;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrivacyPolicySectionSubTitle = styled.h3`
  width: 100%;
  font-style: italic;
  font-weight: bold;
  font-size: 1.2rem;
  color: black;
  margin-left: 60px;
  margin-bottom: 10px;
  display: flex;
  text-align: left;
`;

const PrivacyPolicySectionText = styled.p`
  width: 100%;
  text-align: left;
`;

const OrderedList = styled.ol`
  width: 100%;
  padding: 0px 20px 0px 40px;
`;

const UnorderedList = styled.ul`
  width: 100%;
  padding: 0px 20px 0px 40px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

export function IntroSection() {
  return (
    <PrivacyPolicySection>
      <PrivacyPolicySectionText>
        Les présentes conditions générales de vente ci-après dénommées «CGV»
        sont conclues d'une part par la société SANBBIZ au capital de 1 000 000
        FCFA dont le siège social est situé à Ouagadougou, S/C 12 BP 154
        Ouagadougou 12, Parcelle 19, lot 30, secteur 19 section 709,
        immatriculée au registre du commerce et du crédit mobilier sous le
        numéro BF-OUA-01-2024-B12-00622 ci-après dénommé « SANBBIZ » et d'autre
        part, par toute personne physique ou morale ci-après dénommée « le
        Client », souhaitant effectuer un achat via le site internet
        https://sanbbiz.com/ et applications mobiles associées ci-après
        dénommées « la plateforme ».
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function DefinitionsSection() {
  return (
    <PrivacyPolicySection id="definitions-section">
      <PrivacyPolicySectionTitle>Définitions</PrivacyPolicySectionTitle>
      <UnorderedList>
        <ListItem>
          Catalogue : liste des événements/activités disponibles proposant des
          tickets sur la plateforme au moment de la consultation
        </ListItem>
        <ListItem>
          Organisateur : personne physique ou morale ayant publié un ou
          plusieurs événements/activités sur la plateforme
        </ListItem>
        <ListItem>
          Articles : tout bien ou service en vente sur la plateforme à savoir
          des tickets événements/activités et produits connexes
        </ListItem>
      </UnorderedList>
    </PrivacyPolicySection>
  );
}

export function ContractObjectSection() {
  return (
    <PrivacyPolicySection id="contract-object-section">
      <PrivacyPolicySectionTitle>Objet du contrat</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Les présentes CGV ont pour objet de définir les droits et obligations
        des parties dans le cadre de la vente de tickets d'événements/activités
        et de services connexes par l'intermédiaire de la plateforme de
        billetterie et de découverte d’évènement SANBBIZ.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function CGVAcceptationSection() {
  return (
    <PrivacyPolicySection id="cgv-acceptation-section">
      <PrivacyPolicySectionTitle>Acceptation des CGV</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Toute commande passée par le biais de la plateforme entraîne
        l'acceptation sans réserve des présentes CGV.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Ces CGV fonctionnent conjointement avec notre politique de
        confidentialité et conditions générales d'utilisation.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function ArticleCaracteristicsSection() {
  return (
    <PrivacyPolicySection id="article-caracteristics-section">
      <PrivacyPolicySectionTitle>
        Caractéristiques des articles
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Les tickets en vente sont pour les événements/activités mentionnés dans
        le catalogue. Cependant, nous pouvons également fournir d'autres
        articles liés aux tickets. Ces tickets sont disponibles à l'achat
        jusqu'à épuisement des stocks.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Chaque événement est représenté par une image, une description
        textuelle, un ensemble de types de tickets et de nombreuses autres
        informations provenant de l'organisateur.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Sur la plateforme, vous trouverez presque tous les types
        d'événements/activités, allant des concerts aux festivals en passant par
        les évènements sportives et culturelles et de bien-être.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function GeoPlaceSection() {
  return (
    <PrivacyPolicySection id="geo-place-section">
      <PrivacyPolicySectionTitle>Zone géographique</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        La vente de tickets d'événements/activités sur la plateforme n'est pas
        limitée à une zone géographique, bien que ces événements/activités se
        déroulent généralement au Burkina Faso.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Les dates des événements/activités et toutes les conditions se référant
        aux dates sauf indication contraire sont dans le fuseau horaire GMT
        (heure de Ouagadougou).
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function EventCreationSection() {
  return (
    <PrivacyPolicySection id="event-creation-section">
      <PrivacyPolicySectionTitle>
        Création d’un évènement / activité
      </PrivacyPolicySectionTitle>
    </PrivacyPolicySection>
  );
}

export function EventCreationProcessSection() {
  return (
    <PrivacyPolicySection id="event-creation-process-section">
      <PrivacyPolicySectionSubTitle>
        Processus de Création d'un Événement/Activité
      </PrivacyPolicySectionSubTitle>
      <PrivacyPolicySectionText>
        Lorsqu'un organisateur souhaite proposer un événement ou une activité
        sur la plateforme SANBBIZ, il doit suivre un processus rigoureux et
        transparent. Tout d'abord, l'organisateur doit s'inscrire sur la
        plateforme et fournir des informations précises et complètes sur
        l'événement ou l'activité qu'il souhaite organiser. Ces informations
        doivent inclure la date, l'heure, le lieu, la description de
        l'événement/activité, les modalités de participation, les tarifs et
        toutes autres informations pertinentes.
      </PrivacyPolicySectionText>
      <br />

      <PrivacyPolicySectionText>
        Une fois ces informations soumises, notre équipe vérifie attentivement
        chaque détail pour garantir la conformité aux normes de qualité et de
        sécurité de SANBBIZ. En cas de non-conformité ou de manquement aux
        règles établies, l'organisateur est informé des ajustements nécessaires
        à apporter pour que son événement/activité soit approuvé.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Une fois approuvé, l'organisateur est autorisé à mettre en vente les
        billets ou à promouvoir son événement/activité sur la plateforme.
        Pendant toute la durée de l'événement/activité, notre équipe reste à
        disposition pour assurer un suivi continu et répondre aux besoins et
        préoccupations des organisateurs et des participants.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function TicketSellsSection() {
  return (
    <PrivacyPolicySection id="ticket-sells-section">
      <PrivacyPolicySectionSubTitle>
        Vente Unique des Billets
      </PrivacyPolicySectionSubTitle>
      <PrivacyPolicySectionText>
        Lorsqu'un organisateur propose des billets à la vente sur la plateforme
        SANBBIZ, il s'engage à respecter le principe de vente unique. Cela
        signifie que chaque billet mis en vente doit être exclusivement vendu
        soit sur la plateforme SANBBIZ, soit en dehors de celle-ci. Le nombre
        total de billets en vente doit être réparti de manière équilibrée entre
        ces deux méthodes de vente, conformément aux proportions convenues entre
        l'organisateur et SANBBIZ. En cas d'épuisement du stock de billets
        disponible sur l’un des canaux de vente, les participants doivent être
        redirigés vers l'autre canal pour effectuer leur achat
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function TarificationSection() {
  return (
    <PrivacyPolicySection id="tarification-section">
      <PrivacyPolicySectionTitle>Tarifs</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Les prix indiqués dans le catalogue et sur la page de sélection des
        tickets sont les prix mentionnés par l'organisateur de
        l'événement/activité. SANBBIZ ne fixe pas le prix des tickets des
        événements/activités, chaque organisateur est libre de fixer les prix
        des tickets.
      </PrivacyPolicySectionText>
      <br />

      <PrivacyPolicySectionText>
        Des frais de service (taxes, frais de paiement...etc), dont le montant
        est indiqué sur la page de paiement, peuvent s'ajouter à ces prix. Le
        montant TTC payé au moment de l'achat est donc le prix des tickets fixé
        par l'organisateur, majoré éventuellement des frais de service,
        constituant ainsi le prix TTC tenant compte de la TVA applicable à la
        date de la commande. SANBBIZ se réserve le droit de modifier à tout
        moment le montant des frais de service, étant entendu que le montant
        figurant sur la page de paiement au jour de la commande sera le seul
        applicable au Client.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function OrdersSection() {
  return (
    <PrivacyPolicySection id="orders-section">
      <PrivacyPolicySectionTitle>Commandes</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Afin de passer une commande sur la plateforme, il est recommandé que le
        Client dispose d'une adresse e-mail valide et suive l'un des processus
        de commande suivants :
      </PrivacyPolicySectionText>
      <br />
      <UnorderedList>
        <ListItem>
          Processus autonome :
          <OrderedList>
            <ListItem>
              Sélectionner des articles (tickets, sièges et produits connexes)
            </ListItem>
            <ListItem>
              Saisir les coordonnées du Client : e-mail, numéro de téléphone et
              informations de facturation éventuellement (données automatiques
              si le client à un compte)
            </ListItem>
            <ListItem>
              Payer la commande avec l'un des moyens de paiement disponibles
            </ListItem>
          </OrderedList>
        </ListItem>
      </UnorderedList>
    </PrivacyPolicySection>
  );
}

export function PaymentModalitySection() {
  return (
    <PrivacyPolicySection id="payment-modality-section">
      <PrivacyPolicySectionTitle>
        Modalités de paiement
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Le règlement des achats s'effectue par l'un des moyens suivants, au
        choix du Client :
      </PrivacyPolicySectionText>
      <br />
      <UnorderedList>
        <ListItem>Mobile Money/Moov money</ListItem>
        <ListItem>Coris (monnaie électronique de Sanbbiz)</ListItem>
      </UnorderedList>
    </PrivacyPolicySection>
  );
}

export function DeliverySection() {
  return (
    <PrivacyPolicySection id="delivery-section">
      <PrivacyPolicySectionTitle>
        Expédition et délais de livraison
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Dès confirmation de l'achat, le Client reçoit son billet dans la section
        dédiée sur l’application.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        SANBBIZ s'engage à livrer les commandes passées par le Client dans les
        délais impartis. En cas de non livraison des articles plus de deux (2)
        heures après la commande, le Client pourra procéder à la résolution de
        la vente et demander le remboursement, sauf cas de force majeure
        constaté.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Le Client dispose d'un délai de sept (7) jours à compter de la date
        d'achat de la commande pour signaler la non réception. Passé ce délai,
        toute demande de résolution de la vente ne pourra être acceptée. Le
        Client devra adresser un courrier de réclamation à l'adresse suivante :
        hello.sanbbiz@gmail.com.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Le Client est tenu de vérifier le bon état des articles livrés au moment
        de la livraison. Toute anomalie constatée (articles manquants,
        informations erronées, etc.) devra être signalée à SANBBIZ, par tous
        moyens suivant la livraison, notamment en écrivant à
        hello.sanbbiz@gmail.com.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Avec les mêmes délais de livraison que l'envoi par email, les tickets
        d'événements/activités réservés sont également disponibles, via la
        plateforme avec le compte associé à l'email qui a été utilisé lors de la
        commande.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function TicketUsageNValiditySection() {
  return (
    <PrivacyPolicySection id="ticket-usage-and-validity-section">
      <PrivacyPolicySectionTitle>
        Utilisation et validité des tickets
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Tout ticket commandé et acheté sur la plateforme est soumis aux
        conditions suivantes :
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function TicketValiditySection() {
  return (
    <PrivacyPolicySection id="ticket-validity-section">
      <PrivacyPolicySectionSubTitle>
        Validité du ticket
      </PrivacyPolicySectionSubTitle>
      <PrivacyPolicySectionText>
        Un ticket n'est valable que pour l'événement qu'il concerne, à la date,
        à l'heure et dans les conditions indiquées sur le ticket. Il est
        disponible à tout moment dans le compte SANBBIZ associé à l'email
        utilisé lors de la réservation jusqu'à la fin de l'événement.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function TicketResellsSection() {
  return (
    <PrivacyPolicySection id="ticket-resells-section">
      <PrivacyPolicySectionSubTitle>
        Revente de ticket
      </PrivacyPolicySectionSubTitle>
      <PrivacyPolicySectionText>
        Sauf accord préalable et écrit de SANBBIZ, il est formellement et
        expressément interdit d'offrir à la vente, vendre, revendre, échanger ou
        transférer un ticket, d'une quelconque manière et à quelque fin que ce
        soit (en ce compris promotionnelles ou dans le cadre d'une activité
        commerciale). Il est ainsi notamment interdit de revendre, de permettre
        la revente d'un ticket sur des plateformes commerciales ou par le biais
        d'intermédiaires ou de proposer un ticket sur lesdites plateformes, sans
        l'accord préalable et écrit de SANBBIZ. La seule alternative
        envisageable est le transfert de ticket entre utilisateurs SANBBIZ en
        utilisant la plateforme.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function TicketCopySection() {
  return (
    <PrivacyPolicySection id="ticket-copy-section">
      <PrivacyPolicySectionSubTitle>
        Reproduction de ticket
      </PrivacyPolicySectionSubTitle>
      <PrivacyPolicySectionText>
        Toute quelconque falsification, d'une quelconque manière et à quelque
        fin que ce soit, est formellement et expressément interdite, sous peine
        d'éventuelles poursuites judiciaires.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Sauf stipulation expresse contraire de SANBBIZ, l'utilisation d'un
        ticket, quelle que soit sa forme, est unique, pour une seule entrée et
        pour un seul événement/activité.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        L'organisateur peut refuser l'accès au lieu de l'événement/activité s'il
        a des doutes sur l'origine ou la qualité du ticket qui lui est présenté.
        Compte tenu de la difficulté de vérifier avec certitude l'identité de
        l'acheteur, en cas de vérification obligatoire, seul le porteur du
        ticket original présenté directement sur la plateforme (dans
        l'application mobile par exemple) sera admis.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function TicketCheckingSection() {
  return (
    <PrivacyPolicySection id="ticket-checking-section">
      <PrivacyPolicySectionTitle>
        Contrôle des tickets
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        SANBBIZ met à la disposition de l'organisateur de l'événement/activité
        un système de validation, lecture du code QR des tickets présent dans
        l’application mobile. Chaque organisateur peut ainsi enregistrer un ou
        plusieurs scanners qui se chargeront de contrôler les tickets des
        participants.
      </PrivacyPolicySectionText>
      <br />

      <PrivacyPolicySectionText>
        Le jour de l'événement/activité, le Client ou participant à
        l'événement/activité doit présenter le QR code du ticket valide aux
        personnes en charge du contrôle des tickets. Le QR code et les
        informations du ticket doivent être suffisamment éclairés et facilement
        lisibles. Le QR code permet l'identification du participant et le détail
        du ticket.
      </PrivacyPolicySectionText>
      <br />

      <PrivacyPolicySectionText>
        Au cas où pour une quelconque raison il est impossible de scanner le QR
        code, le contrôleur disposera d’une liste des participants à
        l’évènement/activité. Il pourra ainsi et sous réserve de présentation
        d’un document d’identité officiel (carte d'identité, passeport, permis
        de conduire, titre de séjour ou tout autre document accepté par
        l'organisateur et les contrôleurs), valider et accepter le participant à
        l’évènement/activité.
      </PrivacyPolicySectionText>
      <br />

      <PrivacyPolicySectionText>
        L'organisateur pourra remettre éventuellement au Client, après contrôle,
        un ticket standard avec deux souches ou un bracelet lui permettant
        d'assister à l'événement/activité.
      </PrivacyPolicySectionText>
      <br />

      <PrivacyPolicySectionText>
        Chaque ticket ne peut être présenté qu'une seule fois au point de
        contrôle. Si un participant souhaite retourner au point de contrôle
        (après avoir quitté la salle par exemple), il est impératif de contacter
        les contrôleurs au préalable.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function IdentityCheckingSection() {
  return (
    <PrivacyPolicySection id="identity-checking-section">
      <PrivacyPolicySectionTitle>
        Contrôle de l'identité
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        L'organisateur et son équipe de contrôleurs se réservent le droit de
        contrôler l'identité du Client à l'entrée du lieu où se déroule
        l'événement/activité. Le Client doit donc être muni d'une pièce
        d'identité officielle, en cours de validité et avec photo si nécessaire
        : carte d'identité, passeport, permis de conduire, titre de séjour ou
        tout autre document accepté par l'organisateur et les contrôleurs.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function CancelNCashBackSection() {
  return (
    <PrivacyPolicySection id="cancel-and-cash-back-section">
      <PrivacyPolicySectionTitle>
        Annulation et remboursements
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Tout ticket acheté sur la plateforme n'est ni annulable ni remboursable.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Lorsqu’un événement/activité a été annulé par l'organisateur de
        l'événement/activité, tous les participants seront remboursés.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        En cas de remboursement d'un ticket, quelle que soit la raison, le
        montant remboursé sera partiel et équivaudra à 90% du prix du ticket. Le
        remboursement se fait par le biais de Coris, utilisable uniquement sur
        la plateforme jusqu'à épuisement de son montant.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function ResponsabilitySection() {
  return (
    <PrivacyPolicySection id="responsability-section">
      <PrivacyPolicySectionTitle>Responsabilité</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        SANBBIZ est responsable à l'égard des présentes CGV. Conformément à nos
        conditions générales d'utilisation, SANBBIZ décline toute responsabilité
        en cas d'indisponibilité ou dysfonctionnement du service résultant d'un
        cas de force majeure.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        SANBBIZ ne pourra être tenu responsable des incidents pouvant survenir
        lors de la commande. De même, SANBBIZ n'est pas responsable en cas de
        perte, de vol ou d'utilisation illicite d'un ticket.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        SANBBIZ n'est pas responsable des questions de santé et de sécurité des
        événements/activités du catalogue.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        SANBBIZ n'est pas responsable du déroulement de l'événement/activité
        (modification de contenu, changement de distribution artistique ou
        sportive, changement d'horaires, etc.) ou de son annulation.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Chaque organisateur fixe les règles propres à l'organisation de
        l'événement/activité. Ce règlement est communiqué par l'organisateur au
        Client.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Toute commande du Client implique son adhésion au règlement de
        l'organisateur, sous peine de voir sa responsabilité engagée.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function CustomerServiceSection() {
  return (
    <PrivacyPolicySection id="customer-services-section">
      <PrivacyPolicySectionTitle>Service Client</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Pour toute information, vous pouvez contacter le service Client de
        SANBBIZ par téléphone en appelant le +226 74 84 38 61 (prix d'un appel
        local) ou par mail hello.sanbbiz@gmail.com.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function LawsNDisputesSection() {
  return (
    <PrivacyPolicySection id="applicable-laws-and-disputes-section">
      <PrivacyPolicySectionTitle>
        Droit applicable et litiges
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Les ventes de tickets effectuées sur la plateforme sont soumises à la
        loi Burkinabé. En cas de litige, les tribunaux burkinabés seront seuls
        compétents.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function SanbbizContactSection() {
  return (
    <PrivacyPolicySection id="sanbbiz-contact-section">
      <PrivacyPolicySectionTitle>Consentement</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Si vous avez des questions, n'hésitez pas à nous contacter en utilisant
        ce qui suit :
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Sanbbiz Ouagadougou S/C 12 BP 154 Ouagadougou 12 Parcelle 19 lot 30
        secteur 19 section 709
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        hello.sanbbiz@gmail.com
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>+226 74 84 38 61</PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Date d'entrée en vigueur : le 31 Mai 2024
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

const DocumentContainer = styled.div`
  position: relative;
  padding: 30px 0px;
  background-color: white;
  width: 100%;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  padding: 10px;
  border-radius: 20px;
`;

export function CGVDoc() {
  return (
    <>
      <TableOfContents />
      <DocumentContainer>
        <SectionGroupTitle>
          Conditions Générales des ventes Sanbbiz
        </SectionGroupTitle>
        <IntroSection />
        <DefinitionsSection />
        <ContractObjectSection />
        <CGVAcceptationSection />
        <ArticleCaracteristicsSection />
        <GeoPlaceSection />
        <EventCreationSection />
        <EventCreationProcessSection />
        <TicketSellsSection />
        <TarificationSection />
        <OrdersSection />
        <PaymentModalitySection />
        <DeliverySection />
        <TicketUsageNValiditySection />
        <TicketValiditySection />
        <TicketResellsSection />
        <TicketCopySection />
        <TicketCheckingSection />
        <IdentityCheckingSection />
        <CancelNCashBackSection />
        <ResponsabilitySection />
        <CustomerServiceSection />
        <LawsNDisputesSection />
        <SanbbizContactSection />
      </DocumentContainer>
    </>
  );
}
