/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 01/05/2024
/* ******************************************* */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from '@chakra-ui/react';

import styled from 'styled-components';
import { FaRegUser, FaRegComments } from 'react-icons/fa';
import { extractDayAndMonth } from '../../../utils/date';

const BlogItemContainer = styled.div`
  position: relative;
  text-align: left;
  width: 400px;
  border-radius: 5px;
  overflow: hidden;
`;

const BlogItemPoster = styled.img`
  vertical-align: top;
  width: 100%;
  height: 250px;
  object-fit: cover;
`;

const BlogDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BlogItemInfos = styled.div`
  margin-right: 55px; /* 55 = Blog-date right position + Blog-date width */
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: #54595f;
  font-size: 16px;
`;

const BlogItemInfosIcon = styled.span`
  color: #f1360a;
  margin: 5px 10px;
  display: flex;
  align-items: center;
`;

const BlogName = styled.h2`
  padding: 10px 20px;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2em;
  height: 60px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const BlogDateContainer = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 250px;
  transform: translateY(-50%);
  right: 20px;
  background-color: #f1360a;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogDateDay = styled.span`
  font-weight: bold;
`;
const BlogDateMonth = styled.span`
  &::first-letter {
    text-transform: uppercase;
  }
`;

const BlogReadMore = styled(Link)`
  padding: 10px 20px;
  font-weight: bold;
`;

const formatText = (text) => {
  // Verifie si tout le texte est en majuscule
  if (text === text.toUpperCase()) {
    // Transforme le premier caractère en majuscule
    return text.charAt(0) + text.slice(1).toLowerCase();
  }
  return text;
};

function BlogItem({ currentBlog }) {
  // console.log('currentBlog', currentBlog);

  if (!currentBlog || !currentBlog.createdAt) {
    console.error('Blog data or createdAt date is missing');
    return null; // Ou un message d'erreur / composant de fallback
  }

  // Extraire le jour et le mois de la date la plus proche
  const startDate = extractDayAndMonth(currentBlog.createdAt.toDate());
  return (
    <BlogItemContainer>
      <BlogItemPoster
        src={currentBlog?.coverPicture?.url || null}
        alt={`${currentBlog.title} cover`}
      />
      <BlogDetails>
        <BlogItemInfos>
          <BlogItemInfosIcon>
            <FaRegUser />
          </BlogItemInfosIcon>
          Par {currentBlog.creatorName} /
          <BlogItemInfosIcon>
            <FaRegComments />
          </BlogItemInfosIcon>
          {currentBlog?.comments?.length === 0
            ? 'Pas de commentaire'
            : currentBlog?.comments.length === 1
            ? `${currentBlog?.comments.length} commentaire`
            : `${currentBlog?.comments.length} commentaires`}
        </BlogItemInfos>
        <BlogName>{formatText(currentBlog.title)}</BlogName>
        <BlogDateContainer>
          <BlogDateDay>{startDate.day}</BlogDateDay>
          <BlogDateMonth>{startDate.month}</BlogDateMonth>
        </BlogDateContainer>
        <BlogReadMore href={`/blogs/${currentBlog.id}`}>Voir plus</BlogReadMore>
      </BlogDetails>
    </BlogItemContainer>
  );
}

export default BlogItem;
