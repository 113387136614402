/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BoughtTicketItem from '../../molecules/BoughtTicketItem/BoughtTicketItem';
import {
  collection,
  query,
  where,
  getDocs,
  documentId,
} from 'firebase/firestore';

import { firestore_db } from '../../../api/firebase/firebase-config';
import { useAuth } from '../../../contexts/AuthContext';
import {
  fetchTicketsStart,
  fetchTicketsSuccess,
  fetchTicketsError,
} from '../../../actions/ticket.action';

import styled from 'styled-components';
import { ROUTES, TABLE_NAME } from '../../../utils/variables';
import RedirectComponent from '../../atoms/RedirectComponent/RedirectComponent';

const NoTicketsMessageContainer = styled.div`
  max-width: 700px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Ce composant est commun à NoTicketsMessage et Bought
const SectionTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
`;

const NoTicketsMessageText = styled.div`
  font-style: italic;
  font-size: 20px;
  padding-top: 10px;
`;

function NoTicketsMessage() {
  return (
    <NoTicketsMessageContainer>
      <SectionTitle>Au programme :</SectionTitle>
      <NoTicketsMessageText>
        Envie de sortir ? Regarde ce qu'on a au programme, prends ta place et
        invite tes ami.e.s.
      </NoTicketsMessageText>
    </NoTicketsMessageContainer>
  );
}

const TicketListContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 0px 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const TicketCard = styled.li`
  box-shadow: 0 5px 15px rgba(0, 0, 255, 0.1);
`;

const BoughtTicketsListComponent = ({ sectionName, boughtTickets }) => {
  // console.log('BoughtTicketsListComponent', boughtTickets);
  return (
    <div>
      <SectionTitle>{sectionName}</SectionTitle>
      <TicketListContainer>
        {boughtTickets.map((ticketGrouped) => {
          return (
            <TicketCard
              key={`${ticketGrouped.tickets.id}-${ticketGrouped.tickets.eventId}`}
            >
              <BoughtTicketItem
                boughtTicketGroup={ticketGrouped.tickets}
                linkedEvent={ticketGrouped?.event || null}
              />
            </TicketCard>
          );
        })}
      </TicketListContainer>
    </div>
  );
};

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

function YourTicketsList() {
  const ticketsState = useSelector((state) => state.tickets);
  // console.log(ticketsState); // Check what's inside ticketsState

  const dispatch = useDispatch();
  const { upcoming, past, loading, error } = useSelector(
    (state) => state.tickets
  );

  const { userLoggedIn, currentUser } = useAuth();

  useEffect(() => {
    const fetchEvents = async (eventIds) => {
      const MAX_IDS = 30;
      let eventsMap = {};
      for (let i = 0; i < eventIds.length; i += MAX_IDS) {
        const chunk = eventIds.slice(i, i + MAX_IDS);
        const eventsQuery = query(
          collection(firestore_db, TABLE_NAME.events),
          where(documentId(), 'in', chunk)
        );
        const eventDocs = await getDocs(eventsQuery);
        eventDocs.forEach((doc) => {
          eventsMap[doc.id] = doc.data();
        });
      }
      return eventsMap;
    };

    const fetchTransactions = async (transactionIds) => {
      const MAX_IDS = 30;
      let transactionsMap = {};
      for (let i = 0; i < transactionIds.length; i += MAX_IDS) {
        const chunk = transactionIds.slice(i, i + MAX_IDS);
        const transactionsQuery = query(
          collection(firestore_db, TABLE_NAME.transactions),
          where(documentId(), 'in', chunk)
        );
        const transactionDocs = await getDocs(transactionsQuery);
        transactionDocs.forEach((doc) => {
          transactionsMap[doc.id] = doc.data();
        });
      }
      return transactionsMap;
    };

    const fetchTicketsAndEvents = async () => {
      if (!currentUser?.authProfile?.uid) return;

      dispatch(fetchTicketsStart());
      try {
        const ticketsQuery = query(
          collection(firestore_db, TABLE_NAME.tickets),
          where('customerId', '==', currentUser.authProfile.uid)
        );
        const ticketDocs = await getDocs(ticketsQuery);
        const fetchedTickets = ticketDocs.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // recupère les ids events
        const eventIds = [
          ...new Set(fetchedTickets.map((ticket) => ticket.eventId)),
        ];
        if (eventIds.length === 0) {
          dispatch(fetchTicketsSuccess({ upcoming: [], past: [] }));
          return;
        }

        const eventsMap = await fetchEvents(eventIds);

        // recupére les transactions lié au ticket
        const transactionIds = [
          ...new Set(fetchedTickets.map((ticket) => ticket.transactionId)),
        ];
        let transactionsMap = {};
        if (transactionIds.length > 0) {
          transactionsMap = await fetchTransactions(transactionIds);
        }

        const groupedTickets = fetchedTickets.reduce(
          (acc, ticket) => {
            const isPast =
              new Date(ticket.validity.end.seconds * 1000) < new Date();
            const groupKey = isPast ? 'past' : 'upcoming';
            const eventKey = ticket.eventId;

            // Créer l'entrée de l'événement si elle n'existe pas déjà
            acc[groupKey][eventKey] = acc[groupKey][eventKey] || {
              event: eventsMap[ticket.eventId],
              tickets: [],
            };

            // Ajouter le ticket
            acc[groupKey][eventKey].tickets.push({
              ...ticket,
              transactionData: transactionsMap[ticket.transactionId],
            });

            return acc;
          },
          { upcoming: {}, past: {} }
        );

        dispatch(
          fetchTicketsSuccess({
            upcoming: Object.values(groupedTickets.upcoming),
            past: Object.values(groupedTickets.past),
          })
        );
      } catch (error) {
        dispatch(fetchTicketsError(error.message));
      }
    };

    fetchTicketsAndEvents();
  }, [currentUser?.authProfile?.uid, dispatch]);

  // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
  if (!userLoggedIn) {
    return <RedirectComponent to={ROUTES.loginPage} replace={true} />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (upcoming.length === 0 && past.length === 0) {
    return <NoTicketsMessage />;
  }

  return (
    <ListContainer>
      {upcoming.length > 0 && (
        <BoughtTicketsListComponent
          sectionName="Événements à venir"
          boughtTickets={upcoming} // Assurez-vous que la structure de données ici est correcte
        />
      )}
      {past.length > 0 && (
        <BoughtTicketsListComponent
          sectionName="Événements passés"
          boughtTickets={past} // Assurez-vous que la structure de données ici est correcte
        />
      )}
    </ListContainer>
  );
}

export default YourTicketsList;
