/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 19/05/2024
/* ******************************************* */

import React, { useState } from 'react';
import FormField from '../../atoms/FormField/FormField';
import EditBusinessAdvantagesForm from '../../molecules/EditBusinessAdvantagesForm/EditBusinessAdvantagesForm';
import ManageBusinessFormula from '../../molecules/ManageBusinessFormula/ManageBusinessFormula';
import ManageBusinessAdvantage from '../../molecules/ManageBusinessAdvantage/ManageBusinessAdvantage';
import EditBusinessFormulaForm from '../../molecules/EditBusinessFormulaForm/EditBusinessFormulaForm';
import { Button, useToast, useDisclosure } from '@chakra-ui/react';
import bgImage from '../../../assets/sanbbizLogos/logoWithColor.png';

import styled from 'styled-components';

const ManageAdvantagesFormContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 255, 0.15);
`;

const ManageAdvantagesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 20px;
`;

const LeftHeaderGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledDash = styled.div`
  width: 60px;
  height: 15px;
  background-color: #f1360a;
  border-radius: 20px;
  transform: translateY(50%);
`;

const ManageAdvantagesFormTitle = styled.h2`
  font-weight: bold;
  font-size: 36px;
  color: #051145;
`;
const ManageAdvantagesFormBanner = styled.div`
  width: 100%;
  position: relative;
  right: 0;
  background-size: cover;
  min-height: 200px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-image: url(${bgImage});
`;
const ManageAdvantagesFormContent = styled.div`
  margin-top: 40px;
  border-radius: 10px;
`;

function ManageBusinessPlanForms() {
  const [mngChoice, setMngChoice] = useState('');

  const handleMngChoice = (e) => {
    setMngChoice(e.target.value);
  };

  const {
    isOpen: isAdvantageOpen,
    onOpen: onAdvantageOpen,
    onClose: onAdvantageClose,
  } = useDisclosure();

  const {
    isOpen: isFormulaOpen,
    onOpen: onFormulaOpen,
    onClose: onFormulaClose,
  } = useDisclosure();

  return (
    <ManageAdvantagesFormContainer>
      <ManageAdvantagesHeader>
        <LeftHeaderGroup>
          <StyledDash />
          <ManageAdvantagesFormTitle>
            Avantanges et Formules Sanbbiz
          </ManageAdvantagesFormTitle>
        </LeftHeaderGroup>
      </ManageAdvantagesHeader>
      <ManageAdvantagesFormBanner />
      <ManageAdvantagesFormContent>
        <FormField
          id="mngAdvantagesCchoice"
          label="Quel opération voulez vous réaliser ?"
          inputType="select"
          placeholder="Faites un choix "
          onChange={handleMngChoice}
        >
          <option key="mngAdvantages" value="mngAdvantages">
            Gérer les avantages d'une formule
          </option>
          <option key="mngFormulas" value="mngFormulas">
            Gérer les formules sanbbiz
          </option>
        </FormField>
      </ManageAdvantagesFormContent>
      {mngChoice === 'mngAdvantages' && mngChoice && (
        <>
          <ManageBusinessAdvantage />
          <EditBusinessAdvantagesForm
            isOpen={isAdvantageOpen}
            onOpen={onAdvantageOpen}
            onClose={onAdvantageClose}
          />
          <Button
            boxShadow="base"
            colorScheme="red"
            type="submit"
            mt={4}
            onClick={onAdvantageOpen}
          >
            Créer un avantage
          </Button>
        </>
      )}
      {mngChoice === 'mngFormulas' && mngChoice && (
        <>
          <ManageBusinessFormula />
          <EditBusinessFormulaForm
            isOpen={isFormulaOpen}
            onOpen={onFormulaOpen}
            onClose={onFormulaClose}
          />
          <Button
            boxShadow="base"
            colorScheme="red"
            type="submit"
            mt={4}
            onClick={onFormulaClose}
          >
            Créer une nouvelle formule
          </Button>
        </>
      )}{' '}
    </ManageAdvantagesFormContainer>
  );
}

export default ManageBusinessPlanForms;
