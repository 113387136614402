/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 09/03/2024
/* ******************************************* */

import React from 'react';
import SearchForm from '../../molecules/SearchForm/SearchForm';

import {
  Link, // Link is an accessible element for navigation.
  Box, // Box is the most abstract component on top of which all other Chakra UI components are built. By default, it renders a `div` element
  Flex, //Flex is Box with display set to flex and comes with helpful style shorthand. It renders a `div` element.
  Text, //Text is the used to render text and paragraphs within an interface.
  Stack, // Stack is a layout component used to group elements together and apply a space between them.
} from '@chakra-ui/react';

import Logo from '../../atoms/Icon/SanbbizLogo';
import CloseIcon from '../../atoms/Icon/CloseIcon';
import MenuIcon from '../../atoms/Icon/MenuIcon';
import UserProfile from '../../molecules/UserProfile/UserProfile';
import TopHeader from './TopHeader';
import { ROUTES } from '../../../utils/variables';

const Header = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <TopHeader />
      <NavBarContainer {...props}>
        <Flex
          w={{ base: 'full', md: 'auto' }}
          align="center"
          justify="space-between"
          direction="row"
        >
          <Link href={'/'}>
            <Logo
              w="100px"
              color={['white', 'white', 'primary.500', 'primary.500']}
            />
          </Link>
          <MenuToggle toggle={toggle} isOpen={isOpen} />
        </Flex>
        <MenuLinks isOpen={isOpen} />
      </NavBarContainer>
    </>
  );
};

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: 'block', lg: 'none' }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuItem = ({ children, isLast, to = '/', ...rest }) => {
  return (
    <Link href={to}>
      <Text display="block" {...rest}>
        {children}
      </Text>
    </Link>
  );
};

const MenuLinks = ({ isOpen }) => {
  return (
    <Box
      display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
      flexBasis={{ base: '100%', md: 'auto' }}
    >
      <Stack
        spacing={8}
        align="center"
        justify={{ base: 'space-between', md: 'flex-end' }}
        direction={{ base: 'column', md: 'row' }}
        pt={[4, 4, 0, 0]}
      >
        <MenuItem to={ROUTES.homePage}>Accueil</MenuItem>
        <MenuItem to={ROUTES.eventListPage}>Événements </MenuItem>
        <MenuItem to={ROUTES.categoryListPage}>Catégories </MenuItem>
        <MenuItem to={ROUTES.aboutPage}>A propos </MenuItem>
        <MenuItem to={ROUTES.contactPage}>Nous contacter </MenuItem>
        <SearchForm />
        <UserProfile isCustomerProfile={true} />
      </Stack>
    </Box>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      direction={{ base: 'column', md: 'row' }}
      align="center"
      justify="space-between"
      wrap="nowrap"
      m={{ base: '0', lg: '0px 50px' }}
      p={0}
      pr={2}
      bg={{ base: 'primary.700', md: 'white' }}
      color={{ base: 'white', md: 'primary.700' }}
      position={'relative'}
      zIndex={'100'}
      transform={{ base: 'none', lg: 'translateY(-50%)' }}
      borderRadius={{ base: '0px', lg: '20px' }}
      boxShadow="0 6px 10px rgba(5, 17, 69, 0.2)"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default Header;
