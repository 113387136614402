/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import YourTicketsList from '../../organisms/YourTicketsList/YourTicketsList';
import PageLayout from '../../templates/PageLayout/PageLayout';

function YourTicketsPage() {
  const title = 'Mes Billets'; // Le titre de la page
  const routesPath = 'Profil / Mes billets';

  return (
    <>
      <PageLayout title={title} routesPath={routesPath}>
        <YourTicketsList />
      </PageLayout>
    </>
  );
}

export default YourTicketsPage;
