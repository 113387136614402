/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 05/05/2024
/* ******************************************* */

import React from 'react';
import DashboardPromoter from '../../organisms/DashboardPromoter/DashboardPromoter';
import SidebarLayout from '../../templates/SidebarLayout/SidebarLayout';

function UserDashboardPage() {
  return (
    <SidebarLayout
      menuTitle={'Tableau de bord'}
      menuRoutePath={'Tableau de bord '}
    >
      <DashboardPromoter />
    </SidebarLayout>
  );
}

export default UserDashboardPage;
