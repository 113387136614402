/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import bgImage1 from './../../../assets/medias/aboutPage/bgImage1.png';
import bgImage2 from './../../../assets/medias/aboutPage/bgImage2.png';
import bgImage3 from './../../../assets/medias/aboutPage/bgImage3.png';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../utils/variables';

const AboutContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;

  @media screen and (min-width: 1100px) {
    width: 70%;
  }
`;

const SanbbizName = styled.h1`
  font-weight: bold;
  font-size: 1.5rem;
  padding: 0;
`;

const AboutTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  text-align: left;
  padding: 40px 5%;
  border-radius: 10px;
`;

const AboutTextBlock = styled.p``;

const SanbbizDevise = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
`;

const CreateMemories = styled.div`
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  max-height: 600px;
`;

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  @media screen and (min-width: 780px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Organise = styled.div`
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  height: 400px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px 10px rgba(5, 17, 69, 0.2);
    transform: translateY(-2px);
    color: white;
    background-color: #051145;
  }
`;

const Discover = styled.div`
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  height: 400px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 6px 10px rgba(5, 17, 69, 0.2);
    transform: translateY(-2px);
    color: white;
    background-color: #051145;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ImageText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: bold;
  color: white;
  -webkit-text-stroke: 1px #051145;
  text-stroke: 1px #051145;
`;

function AboutPage() {
  const title = 'A propos'; // Le titre de la page
  const navigate = useNavigate();

  const navigateToMemories = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  const navigateToDiscover = () => {
    navigate('/events');
    window.scrollTo(0, 0);
  };

  const navigateToOrganize = () => {
    navigate(ROUTES.createEventPage);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <PageLayout title={title}>
        <AboutContainer>
          <SanbbizName> SANBBIZ</SanbbizName>
          <AboutTextContainer>
            <AboutTextBlock>
              Nous croyons fermement que sortir et participer à des événements
              est essentiel pour notre bien-être. Que ce soit pour assister à
              des concerts, se ploger dans l'ambiance des festivals, découvrir
              de nouveaux lieux culturels ou simplement passer une soirée entre
              amis. Ces moments sont précieux et nous permettent de créer des
              souvenirs durables.
            </AboutTextBlock>
            <AboutTextBlock>
              C'est pourquoi nous avons créer Sanbbiz, une plateforme dédiée à
              faciliter l'accès et la participation à une multitude d'événements
              et d'activités. Que vous soyez passionné de comédie, de bien-être,
              de gastronomie, d'art ou de tout autre domaine, Sanbbiz vous offre
              un moyen simple et pratique de découvrir, réserver et participer à
              des événements et activités qui nourrissent vos passions et
              enrichissent votre vie.
            </AboutTextBlock>
            <AboutTextBlock>
              Explorer les trésors cachés de votre ville, Créer des souvenirs
              inoubliables et connecter-vous avec d'autres personnes partageant
              les mêmes centres d'intérêt que vous.
            </AboutTextBlock>
            <SanbbizDevise> Sanbbiz, Explorez, Créez, Vivez !</SanbbizDevise>
          </AboutTextContainer>
          <CreateMemories onClick={navigateToMemories}>
            <Image src={bgImage3} alt="Créez des souvenirs inoubliables" />
            <ImageText>Créez des souvenirs inoubliables</ImageText>
          </CreateMemories>
          <GridContainer>
            <Discover onClick={navigateToDiscover}>
              <Image src={bgImage2} alt="Découvrez" />
              <ImageText>Découvrez</ImageText>
            </Discover>
            <Organise onClick={navigateToOrganize}>
              <Image src={bgImage1} alt="Organisez" />
              <ImageText>Organisez</ImageText>
            </Organise>
          </GridContainer>
        </AboutContainer>
      </PageLayout>
    </>
  );
}

export default AboutPage;
