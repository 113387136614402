/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 23/05/2024
/* ******************************************* */

import React, { useState, useEffect, useRef } from 'react';
import {
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';

import { firestore_db } from '../../../api/firebase/firebase-config';
import { uploadFile } from '../../../api/firebase/storage';
import AlertPopup from '../../atoms/AlertPopup/AlertPopup';

import FormField from '../../atoms/FormField/FormField';
import { Button, useToast } from '@chakra-ui/react';
import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const EditBlogFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  height: '500px';
  padding: 20px;
`;

const FormFields = styled.div``;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

const SectionsFormFields = styled.div`
  position: relative;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  box-shadow: 0 4px 8px rgba(202, 65, 65, 0.7);
  margin-top: 20px;
  padding: 5px;
`;

function EditBlogForm({ blog, isOpen, onOpen, onClose }) {
  const toast = useToast();

  const [creatorName, setCreatorName] = useState(
    blog ? blog.creatorName : null
  );
  const [title, setTitle] = useState(blog ? blog.title : null);

  const [coverPictureData, setCoverPictureData] = useState({
    url: blog && blog.coverPicture ? blog.coverPicture.url : null,
    width: blog && blog.coverPicture ? blog.coverPicture.width : null,
    height: blog && blog.coverPicture ? blog.coverPicture.height : null,
  });
  const [coverPictureFile, setCoverPictureFile] = useState(null);

  // Structure initiale d'une section vide
  const emptySection = {
    sectionTitle: '',
    sectionNote: '',
  };

  // Initialisation du state avec une section par défaut
  const [blogSections, setBlogSections] = useState(
    blog ? blog.blogSections : [emptySection]
  );

  const handleAddSection = () => {
    setBlogSections([...blogSections, emptySection]);
  };

  const handleSectionChange = (index, field, value) => {
    const updatedSections = blogSections.map((section, idx) => {
      if (idx === index) {
        return { ...section, [field]: value };
      }
      return section;
    });
    setBlogSections(updatedSections);
  };

  const handleRemoveSection = (index) => {
    const filteredSections = blogSections.filter((_, idx) => idx !== index);
    setBlogSections(filteredSections);
  };

  // Handler pour les fichiers sélectionnés de la photo de catégorie
  const handleCoverPictureSelected = (files) => {
    const [file] = files;
    setCoverPictureFile(file); // Save file to state for upload
    const img = new Image();
    const url = URL.createObjectURL(file);
    img.onload = () => {
      setCoverPictureData({
        url: url,
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
      URL.revokeObjectURL(url);
    };
    img.src = url;
  };

  // Handler pour la suppression de la photo
  const handleCoverPictureRemoved = () => {
    URL.revokeObjectURL(coverPictureFile.url); // Libérer la ressource
    setCoverPictureData({ url: null, width: null, height: null });
  };

  // Gère la creation
  const cancelRef = useRef();

  const prepareBlogData = async () => {
    let newPictureUrl = coverPictureData.url;

    // Ajouter les nouveaux fichiers s'ils existent
    if (coverPictureFile) {
      newPictureUrl = await uploadFile(
        'gs://sanbbiz-35578.appspot.com/dev_blogs/coverPictures',
        coverPictureFile
      );
    }

    const comments = [];

    return {
      creatorName,
      title,
      coverPicture: {
        url: newPictureUrl,
        width: coverPictureData.width,
        height: coverPictureData.height,
      },
      comments,
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const blogData = await prepareBlogData();
      blogData.blogSections = blogSections;

      if (blog) {
        // Mise à jour de la catégorie existante
        const docRef = doc(firestore_db, TABLE_NAME.blogs, blog.id);
        await updateDoc(docRef, blogData);
        toast({
          title: 'Article modifié',
          description: "L'article a été mis à jour avec succès.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Création d'une nouvelle catégorie
        blogData.createdAt = serverTimestamp();
        const docRef = await addDoc(
          collection(firestore_db, TABLE_NAME.blogs),
          blogData
        );
        toast({
          title: 'Article ajouté',
          description: "L'article a été mis à jour avec succès.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        console.log('New blog created with ID:', docRef.id);
      }
      onClose(); // Fermer le formulaire après l'opération
    } catch (error) {
      toast({
        title: 'Erreur de mise à jour',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertPopup
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      onNo={onClose}
      onYes={handleSubmit}
      windowTitle={blog ? "Modifier l'article" : 'Créer un nouveau article'}
    >
      <EditBlogFormContent>
        <FormFields>
          <FormField
            id="coverPicture"
            label="La photo de couverture du blog"
            inputType="imageUploader"
            onFilesSelected={handleCoverPictureSelected}
            onImageRemoved={handleCoverPictureRemoved}
            imgWidth="400px"
            imgHeight="250px"
            defaultImageUrl={coverPictureData.url ? [coverPictureData.url] : []}
            isRequired
          />
          <FormFieldsGrid>
            <FormField
              id="creatorName"
              label="Nom de créateur"
              inputType="text"
              defaultValue={creatorName}
              placeholder="Entrez le nouveau nom du créateur de l'article"
              value={creatorName}
              onChange={(e) => setCreatorName(e.target.value)}
              isRequired
            />
            <FormField
              id="blogName"
              label="Titre de l'article"
              inputType="text"
              defaultValue={title}
              placeholder="Entrez le titre de l'article"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              isRequired
            />
          </FormFieldsGrid>
          <FormFields>
            {blogSections.map((section, index) => (
              <SectionsFormFields key={index}>
                <FormField
                  id={`sectionTitle-${index}`}
                  label="Titre de la section"
                  inputType="text"
                  defaultValue={section.sectionTitle}
                  placeholder="Entrez le titre de la section"
                  onChange={(e) =>
                    handleSectionChange(index, 'sectionTitle', e.target.value)
                  }
                  isRequired
                />
                <FormField
                  id={`sectionNote-${index}`}
                  label="Note de la section"
                  inputType="textarea" // Assuming FormField can handle 'textarea' as an inputType
                  defaultValue={section.sectionNote}
                  placeholder="Entrez la note de la section"
                  onChange={(e) =>
                    handleSectionChange(index, 'sectionNote', e.target.value)
                  }
                  isRequired
                />
                <Button
                  position="absolute"
                  right="0"
                  top="0"
                  size="xs"
                  colorScheme="red"
                  zIndex="1"
                  onClick={() => handleRemoveSection(index)}
                >
                  &#10005; {/* Croix */}
                </Button>
              </SectionsFormFields>
            ))}

            <Button
              width="full"
              mt={6}
              type="submit"
              colorScheme="blue"
              onClick={handleAddSection}
            >
              {' '}
              Ajouter une nouvelle section
            </Button>
          </FormFields>
        </FormFields>
      </EditBlogFormContent>
    </AlertPopup>
  );
}

export default EditBlogForm;
