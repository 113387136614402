/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import ConnexionForm from '../../molecules/ConnexionForm/ConnexionForm';
import PageLayout from '../../templates/PageLayout/PageLayout';

function LoginPage() {
  const title = 'Se connecter'; // Le titre de la page

  return (
    <>
      <PageLayout title={title}>
        <ConnexionForm />
      </PageLayout>
    </>
  );
}

export default LoginPage;
