/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 05/06/2024
/* ******************************************* */

import React from 'react';
import styled from 'styled-components';
import sanbbizLogo from '../../../assets/sanbbizLogos/logo.png';
import { FaApple, FaTreeCity } from 'react-icons/fa6';
import { DiAndroid } from 'react-icons/di';
import { FaHeartbeat } from 'react-icons/fa';
import { IoTicket } from 'react-icons/io5';
import { useSanbbizConfig } from '../../../contexts/ConfigContext';
import { Link } from '@chakra-ui/react';
import bg_banner from '../../../assets/medias/banner/SpoligthedBackgroundPicture.png';

const IsMobilePageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const RightContent = styled.div`
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  padding: 10px;
  border-radius: 20px;
`;

const AccessToApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  background-color: blueviolet;
  background: url(${bg_banner}) no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 10px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background-image: linear-gradient(
      to right,
      rgba(255, 192, 203, 1),
      rgba(0, 0, 255, 1)
    );
    opacity: 0.76;
    z-index: 2;
    border-radius: 10px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
`;

const DownloadAppText = styled.h2`
  display: grid;
  padding: 20px;
  margin: 0;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: left;
`;

const AppDetailBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const AppFeatureSection = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 10px;
`;

const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  background-color: #ecf0f2;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding-top: 20px;
`;

const ActionButton = styled(Link)`
  background-color: #ecf0f2;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #051145;

  &:hover {
  }
`;

const ActionButtonText = styled.span`
  font-size: 1rem;
`;

function BlockedPage() {
  const { config } = useSanbbizConfig();

  return (
    <>
      <IsMobilePageContainer>
        <RightContent>
          <AccessToApp>
            <Image src={sanbbizLogo} alt="Logo Sanbbiz" />
          </AccessToApp>
          <DownloadAppText> Télécharger l'application SANBBIZ </DownloadAppText>
          <AppDetailBlock>
            <AppFeatureSection>
              <IconWrapper>
                <FaTreeCity />
              </IconWrapper>
              <FeatureDescription>
                Découvre les meilleurs événements de ta ville, et reçois des
                recommandations personnalisées en fonction de tes centres
                d'intérêt
              </FeatureDescription>
            </AppFeatureSection>
            <AppFeatureSection>
              <IconWrapper>
                <FaHeartbeat />
              </IconWrapper>
              <FeatureDescription>
                Garde un oeil sur ce qui se passe en mettant des événements en
                favoris, en les partageant avec tes ami.e.s ou encore en
                retrouvant vos organisateur favoris depuis l'appli.
              </FeatureDescription>
            </AppFeatureSection>
            <AppFeatureSection>
              <IconWrapper>
                <IoTicket />
              </IconWrapper>
              <FeatureDescription>
                On a facilité le remboursement de billets en cas d'annulation de
                l'événement. No Stress
              </FeatureDescription>
            </AppFeatureSection>
          </AppDetailBlock>

          <ButtonContainer>
            <ActionButton href={config.url.appstore} isExternal>
              <FaApple />
              <ActionButtonText>iOS</ActionButtonText>
            </ActionButton>
            <ActionButton href={config.url.googleplay} isExternal>
              <DiAndroid />
              <ActionButtonText>Android</ActionButtonText>
            </ActionButton>
          </ButtonContainer>
        </RightContent>
      </IsMobilePageContainer>
    </>
  );
}

export default BlockedPage;
