/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 08/05/2024
/* ******************************************* */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@chakra-ui/react';
import { timestampToDateOnlyString } from '../../../utils/date';
import { useAuth } from '../../../contexts/AuthContext';
import FormField from '../../atoms/FormField/FormField';
import { USER_SEX, USER_PROFESSION } from '../../../utils/constants';
import EditUserProfileForm from '../../molecules/EditUserProfileForm/EditUserProfileForm';
import { useDisclosure } from '@chakra-ui/react';
import defaultUserProfile from '../../../assets/medias/defaultUserProfilPicture.jpg';
import ReloadCauris from '../../molecules/ReloadCauris/ReloadCauris';

const ProfileContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 20px;
`;

const LeftHeaderGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
`;

const StyledDash = styled.div`
  width: 60px;
  height: 15px;
  background-color: #f1360a;
  border-radius: 20px;
  transform: translateY(50%);
`;

const ProfileTitle = styled.h2`
  font-weight: bold;
  font-size: 36px;
  color: #051145;
`;

const UserProfileDetails = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 10px 5px;
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  aling-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 255, 0.05);
`;

const UserProfileBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const ProfilPictureContainer = styled.div`
  align-self: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 10px;
  background-color: #ecf0f2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfilImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const UserInfos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const UserName = styled.h2`
  font-size: 26px;
  font-weight: bold;
`;
const NbSubscriber = styled.span`
  font-size: 20px;
  color: gray;
  span {
    color: #f1360a;
    font-weight: bold;
  }
`;

const UserProfileInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 255, 0.05);
`;

const FormFields = styled.div``;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

function ProfilePromoter() {
  const { currentUser, reloadUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isEditing, setIsEditing] = useState(false);

  const toggleEditing = () => {
    setIsEditing(true);
    onOpen();
  };

  // Callback pour rafraîchir les données après modification
  const handleUserUpdated = () => {
    onClose(); // Fermer la popup
    reloadUser();
    window.location.reload();
  };

  return (
    <ProfileContainer>
      <ProfileHeader>
        <LeftHeaderGroup>
          <StyledDash />
          <ProfileTitle> Informations Utilisateur </ProfileTitle>
        </LeftHeaderGroup>
        <Button
          size="md"
          color="white"
          bgColor="#f1360a"
          position="relative"
          onClick={toggleEditing}
        >
          {' '}
          Modifier votre profil
        </Button>
        {isEditing && (
          <EditUserProfileForm
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            user={currentUser.dbProfile}
            onUserUpdated={handleUserUpdated}
            isCustomerProfile={false}
          />
        )}
      </ProfileHeader>

      <UserProfileDetails>
        <LeftContent>
          <UserProfileBox>
            <ProfilPictureContainer>
              <ProfilImage
                src={
                  currentUser.dbProfile?.profilePicture?.url ||
                  defaultUserProfile
                }
                alt="Photo de profil"
              />
            </ProfilPictureContainer>
            <UserInfos>
              <UserName>{currentUser.dbProfile?.businessName}</UserName>
              <NbSubscriber>
                {' '}
                <span>
                  {
                    (
                      (currentUser &&
                        currentUser.dbProfile &&
                        currentUser.dbProfile?.followedBy) ||
                      []
                    ).length
                  }
                </span>{' '}
                abonnés
              </NbSubscriber>
              <ReloadCauris currentUserId={currentUser.authProfile.uid} />
            </UserInfos>
          </UserProfileBox>
        </LeftContent>
        <UserProfileInfos>
          <FormFields>
            <FormFieldsGrid>
              <FormField
                id="first-name"
                label="Prénom"
                inputType="text"
                placeholder={currentUser.dbProfile.fname}
                disabled
              />
              <FormField
                id="last-name"
                label="Nom"
                inputType="text"
                placeholder={currentUser.dbProfile.lname}
                disabled
              />
            </FormFieldsGrid>
            <FormFieldsGrid>
              <FormField
                id="birthdate"
                label="Date de naissance"
                inputType="date"
                value={timestampToDateOnlyString(
                  currentUser.dbProfile.birthdate
                )}
                disabled
              />
              <FormFieldsGrid>
                <FormField
                  id="sex"
                  label="Sexe"
                  defaultValue={currentUser.dbProfile.sex}
                  inputType="select"
                  disabled
                >
                  <option value="">Sélectionnez votre sexe</option>
                  <option value={USER_SEX.MALE}>Masculin</option>
                  <option value={USER_SEX.FEMALE}>Féminin</option>
                </FormField>
                <FormField
                  id="Profession"
                  label="Profession "
                  defaultValue={currentUser.dbProfile.profession}
                  inputType="select"
                  disabled
                >
                  <option value="">Sélectionnez votre profession</option>

                  <option value={USER_PROFESSION.SCHOOLBOY}>Élève</option>
                  <option value={USER_PROFESSION.STUDENT}>Étudiant</option>
                  <option value={USER_PROFESSION.EMPLOYEE}>Travailleur</option>
                  <option value={USER_PROFESSION.OTHER}>Autre</option>
                </FormField>
              </FormFieldsGrid>
            </FormFieldsGrid>
            <FormFieldsGrid>
              <FormField
                id="phone-number"
                label="Numéro de téléphone"
                inputType="tel"
                placeholder={'+226 ' + currentUser.dbProfile.phone}
                disabled
              />
              <FormField
                id="mail"
                label="Adresse email"
                inputType="email"
                placeholder={currentUser.dbProfile.email}
                disabled
              />
            </FormFieldsGrid>
          </FormFields>
        </UserProfileInfos>
      </UserProfileDetails>
    </ProfileContainer>
  );
}

export default ProfilePromoter;
