/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import { useState } from 'react';
import { Button, Flex, Link } from '@chakra-ui/react';
import { uploadFile } from '../../../api/firebase/storage';
import { useAuth } from '../../../contexts/AuthContext';
import FormField from '../../atoms/FormField/FormField';
import { doUpdateUserWithInfos } from '../../../api/firebase/auth';
import { useToast } from '@chakra-ui/react';
import { USER_SEX, USER_PROFESSION } from '../../../utils/constants';
import Popup from '../../atoms/Popup/Popup';
import { timestampToDateOnlyString } from '../../../utils/date';

import styled from 'styled-components';
import RedirectComponent from '../../atoms/RedirectComponent/RedirectComponent';
import { ROUTES } from '../../../utils/variables';

const FormFields = styled.div``;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

function EditUserProfileForm({
  isOpen,
  onOpen,
  onClose,
  user,
  onUserUpdated,
  isCustomerProfile,
}) {
  const { userLoggedIn } = useAuth();
  const toast = useToast();
  const [fname, setFname] = useState(user.fname);
  const [lname, setLname] = useState(user.lname);
  const [phone, setPhone] = useState(user.phone);
  const [email, setEmail] = useState(user.email);
  const [sex, setSex] = useState(user.sex);
  const [profession, setProfession] = useState(user.profession);
  const [birthdate, setBirthdate] = useState(
    timestampToDateOnlyString(user.birthdate)
  );
  const [businessName, setBusinessName] = useState(user.businessName);
  const [businessPhone, setBusinessPhone] = useState(user.businessPhone);

  const [profilePictureData, setProfilePictureData] = useState({
    url: user && user.profilePicture ? user.profilePicture.url : null,
    width: user && user.profilePicture ? user.profilePicture.width : null,
    height: user && user.profilePicture ? user.profilePicture.height : null,
  });

  const [profilePictureFile, setProfilePictureFile] = useState(null);

  const handleUpdateData = async (e) => {
    e.preventDefault();

    let newProfilePictureUrl = profilePictureData.url;

    if (profilePictureFile) {
      newProfilePictureUrl = await uploadFile(
        'gs://sanbbiz-35578.appspot.com/users/pp',
        profilePictureFile
      );

      setProfilePictureData({
        url: newProfilePictureUrl,
        width: profilePictureData.width,
        height: profilePictureData.height,
      });
    }
    const userUpdates = !isCustomerProfile
      ? {
          fname,
          lname,
          sex,
          profession,
          birthdate,
          businessName,
          businessPhone,
          profilePicture: {
            url: newProfilePictureUrl,
            width: profilePictureData.width,
            height: profilePictureData.height,
          },
        }
      : {
          fname,
          lname,
          sex,
          profession,
          birthdate,
          profilePicture: {
            url: newProfilePictureUrl,
            width: profilePictureData.width,
            height: profilePictureData.height,
          },
        };

    try {
      const result = await doUpdateUserWithInfos(
        userUpdates,
        !isCustomerProfile
      );

      if (result.success) {
        setProfilePictureFile(null);
        setProfilePictureData({ url: null, width: null, height: null });
        onUserUpdated();
        toast({
          title: 'Succès',
          description: 'Compte créé avec succès.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Erreur lors de la création du compte',
          description: result.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error in doUpdateUserWithInfos: ', error);
      toast({
        title: 'Erreur lors de la mise à jour du compte',
        description: error.message || 'Une erreur est survenue',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handler pour les fichiers sélectionnés de la photo de profil
  const handleProfilePictureChange = (files) => {
    const [file] = files;
    setProfilePictureFile(file); // Save file to state for upload
    const img = new Image();
    const url = URL.createObjectURL(file);
    img.onload = () => {
      setProfilePictureData({
        url: url,
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
      URL.revokeObjectURL(url);
    };
    img.src = url;
  };

  // Handler pour la suppression de l'icône de catégorie
  const handleProfilePictureRemoved = () => {
    URL.revokeObjectURL(profilePictureData.url);
    setProfilePictureData({
      url: null,
      width: null,
      height: null,
    });
  };

  const handleBirthdateChange = (value) => {
    setBirthdate(value);
  };

  const handleSexChange = (e) => {
    const selectedSex = Number(e.target.value);
    setSex(selectedSex);
  };

  const handleProfessionChange = (e) => {
    const selectedProfession = Number(e.target.value);
    setProfession(selectedProfession);
  };

  // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
  if (!userLoggedIn) {
    return <RedirectComponent to={ROUTES.loginPage} replace={true} />;
  }

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      popupTitle={'Modifier vos informations'}
      width={['100%', '70%', '50%', '40%']}
      isCentered
    >
      <FormFields>
        {!isCustomerProfile ? (
          <>
            <FormField
              id="picture"
              label="Photo de la catégorie"
              inputType="imageUploader"
              onFilesSelected={handleProfilePictureChange}
              onImageRemoved={handleProfilePictureRemoved}
              imgWidth="200px"
              imgHeight="200px"
              defaultImageUrl={
                user.profilePicture.url ? [user.profilePicture.url] : []
              }
            />
            <FormFieldsGrid>
              <FormField
                id="businessName"
                label="Société"
                inputType="text"
                defaultValue={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                isRequired
              />
              <FormField
                id="phone-pro"
                label="Téléphone pro"
                inputType="number"
                defaultValue={businessPhone}
                onChange={(e) => setBusinessPhone(e.target.value)}
                isRequired
              />
            </FormFieldsGrid>
          </>
        ) : (
          <>
            <FormField
              id="picture"
              label="Photo de la catégorie"
              inputType="imageUploader"
              onFilesSelected={handleProfilePictureChange}
              onImageRemoved={handleProfilePictureRemoved}
              imgWidth="200px"
              imgHeight="200px"
              defaultImageUrl={
                user?.profilePicture?.url ? [user?.profilePicture?.url] : []
              }
            />
          </>
        )}
        <FormFieldsGrid>
          <FormField
            id="first-name"
            label="Prénom"
            inputType="text"
            defaultValue={fname}
            onChange={(e) => setFname(e.target.value)}
            isRequired
          />
          <FormField
            id="last-name"
            label="Nom"
            inputType="text"
            defaultValue={lname}
            onChange={(e) => setLname(e.target.value)}
            isRequired
          />
        </FormFieldsGrid>
        <FormFieldsGrid>
          <FormField
            id="birthdate"
            label="Date de naissance"
            inputType="date"
            value={birthdate}
            updateDate={(value) => handleBirthdateChange(value)}
            isRequired
          />
          <FormFieldsGrid>
            <FormField
              id="sex"
              label="Sexe"
              defaultValue={sex}
              inputType="select"
              onChange={handleSexChange}
              isRequired
            >
              <option value="">Sélectionnez votre sexe</option>
              <option value={Number(USER_SEX.MALE)}>Masculin</option>
              <option value={Number(USER_SEX.FEMALE)}>Féminin</option>
            </FormField>
            <FormField
              id="profession"
              label="Profession"
              defaultValue={profession}
              inputType="select"
              onChange={handleProfessionChange}
              isRequired
            >
              <option value="">Sélectionnez votre profession</option>

              <option value={Number(USER_PROFESSION.SCHOOLBOY)}>Élève</option>
              <option value={Number(USER_PROFESSION.STUDENT)}>Étudiant</option>
              <option value={Number(USER_PROFESSION.EMPLOYEE)}>
                Travailleur
              </option>
              <option value={Number(USER_PROFESSION.OTHER)}>Autre</option>
            </FormField>
          </FormFieldsGrid>
        </FormFieldsGrid>
        <FormFieldsGrid>
          <FormField
            id="phone-number"
            label="Numéro de téléphone"
            inputType="tel"
            defaultValue={phone}
            onChange={(e) => setPhone(e.target.value)}
            disabled
          />
          <FormField
            id="mail"
            label="Adresse email"
            inputType="email"
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          />
        </FormFieldsGrid>
      </FormFields>
      <Button
        width="full"
        mt={10}
        type="submit"
        colorScheme="orange"
        onClick={handleUpdateData}
      >
        {' '}
        Sousmettre les modifications
      </Button>

      <Flex align="center" justify="center" mt={4}>
        <Link color="blue.500" href={ROUTES.deleteAccountPage}>
          Supprimer le compte
        </Link>
      </Flex>
    </Popup>
  );
}

export default EditUserProfileForm;
