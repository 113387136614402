/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 30/03/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import FormField from '../../atoms/FormField/FormField';
import EndDateSelector from '../EndDateSelector/EndDateSelector';
import './RecurrentDateCreatorForm.css';

function RecurrentDateCreatorForm({ selectedDate, onAddEvent, onClose }) {
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [recurrence, setRecurrence] = useState('');
  const [endRecurrence, setEndRecurrence] = useState('');
  const [endDate, setEndDate] = useState('');
  const [eventCount, setEventCount] = useState('');

  // Fonction pour calculer le prochain créneau horaire
  useEffect(() => {
    const calculateNextTimeSlot = () => {
      const now = new Date();
      if (now.getMinutes() < 30) {
        now.setMinutes(30);
      } else {
        now.setMinutes(0);
        now.setHours(now.getHours() + 1);
      }
      return now;
    };

    const startTimeDate = calculateNextTimeSlot();
    setStartTime(
      startTimeDate.toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
    );

    const endTimeDate = new Date(startTimeDate.getTime() + 30 * 60000); // Ajoute 30 minutes
    setEndTime(
      endTimeDate.toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
    );
  }, [selectedDate]);

  const handleRecurrenceChange = (e) => {
    setRecurrence(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const eventData = {
      title: 'Récurrent Event',
      start: `${selectedDate}T${startTime}`,
      end: `${selectedDate}T${endTime}`,
      recurrenceType: recurrence,
      endDate: endDate,
      eventCount: eventCount,
    };
    onAddEvent(eventData);
    onClose();
  };

  return (
    <form className="date-recurrent-form-container" onSubmit={handleSubmit}>
      <div className="date-recurrent-form-date-recurrent-container">
        <FormField
          id="eventStartDate"
          label="Date de début"
          inputType="date"
          value={selectedDate}
          onChange={(e) => selectedDate(e.target.value)}
        />
        <div className="date-recurrent-form-time-grid">
          <FormField
            id="eventStartTime"
            label="Heure de début"
            inputType="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
          <FormField
            id="eventEndTime"
            label="Heure de fin"
            inputType="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>
        <FormField
          id="eventRecurrenceType"
          label="Fréquence de l'événement"
          inputType="select"
          onChange={handleRecurrenceChange}
        >
          <option key="once" value="once">
            Une fois
          </option>
          <option key="daily" value="daily">
            Quotidien
          </option>
          <option key="weekly" value="weekly">
            Hebdomadaire
          </option>
          <option key="monthly" value="monthly">
            Mensuel
          </option>
        </FormField>
        {recurrence === 'daily' && (
          <>
            <EndDateSelector
              onEndDateChange={setEndDate}
              onEventCountChange={setEventCount}
            />
          </>
        )}
        {recurrence === 'weekly' && (
          <>
            <FormField
              id="eventDays"
              label="Ces jours là"
              inputType="daysPicker"
              onChange={() => {}}
              onDaysSelected={(days) => console.log('Selected days:', days)}
            />
            <EndDateSelector
              onEndDateChange={setEndDate}
              onEventCountChange={setEventCount}
            />
          </>
        )}
        {recurrence === 'monthly' && (
          <>
            <FormField
              id="eventDays"
              label="Le"
              inputType="monthDaysPicker"
              onDaysSelected={(days) => console.log('Selected days:', days)}
            />
            <EndDateSelector
              onEndDateChange={setEndDate}
              onEventCountChange={setEventCount}
            />
          </>
        )}
      </div>
      <button type="submit">Enregistrer l'événement</button>
    </form>
  );
}

export default RecurrentDateCreatorForm;
