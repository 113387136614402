/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 06/05/2024
/* ******************************************* */

import React from 'react';
import CreateEventPricing from '../../organisms/CreateEventPricing/CreateEventPricing.js';
import SidebarLayout from '../../templates/SidebarLayout/SidebarLayout.js';

function CreateEventPricingPage() {
  return (
    <SidebarLayout
      menuTitle={'Événement'}
      menuRoutePath={"événement / création d'événement / Plan de gestion "}
    >
      <CreateEventPricing />
    </SidebarLayout>
  );
}

export default CreateEventPricingPage;
