/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 08/06/2024
/* ******************************************* */

import React from 'react';
import styled from 'styled-components';

const TableOfContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 20px 10px;
  background-color: #ecf0f2;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  border-radius: 20px;
`;

const SectionGroupTitle = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  color: #051145;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableOfContentNav = styled.nav`
  width: 100%;
  padding: 0px 20px;
`;

const TableOfContentList = styled.ul`
  list-style: none;
  padding: 0;
`;

const TableOfContentItem = styled.li`
  margin-bottom: 10px;
`;

const TableOfContentItemLink = styled.a`
  color: blue;
  text-decoration: none;

  &:hover {
    font-weight: bold;
  }
`;

export function TableOfContents() {
  return (
    <TableOfContentContainer>
      <SectionGroupTitle>Sommaire</SectionGroupTitle>
      <TableOfContentNav>
        <TableOfContentList>
          <TableOfContentItem>
            <TableOfContentItemLink href="#intellectual-property-section">
              Propriété intellectuelle
            </TableOfContentItemLink>
          </TableOfContentItem>
          <TableOfContentItem>
            <TableOfContentItemLink href="#age-restriction-section">
              Restrictions d'âge
            </TableOfContentItemLink>
          </TableOfContentItem>
          <TableOfContentItem>
            <TableOfContentItemLink href="#accepted-usage-section">
              Utilisation acceptable
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#user-contribution-section">
              Contributions des utilisateurs
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#user-account-section">
              Comptes
            </TableOfContentItemLink>
          </TableOfContentItem>
          <TableOfContentItem>
            <TableOfContentItemLink href="#sells-and-services-section">
              Ventes de biens et services
            </TableOfContentItemLink>
          </TableOfContentItem>
          <TableOfContentItem>
            <TableOfContentItemLink href="#payment-section">
              Paiements
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#cash-back-section">
              Remboursement
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#liability-limitation-section">
              Limitation de responsabilité
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#compensation-section">
              Indemnité
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#applicable-laws-section">
              Lois applicables
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#divisibility-section">
              Divisibilité
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#cgu-modification-section">
              Modifications
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#sanbbiz-contact-section">
              Contact
            </TableOfContentItemLink>
          </TableOfContentItem>
        </TableOfContentList>
      </TableOfContentNav>
    </TableOfContentContainer>
  );
}

const PrivacyPolicySection = styled.section`
  widht: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 10px;
`;

const PrivacyPolicySectionTitle = styled.h2`
  font-weight: bold;
  font-size: 1.3rem;
  color: black;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrivacyPolicySectionSubTitle = styled.h3``;

const PrivacyPolicySectionText = styled.p`
  width: 100%;
  text-align: left;
`;

const OrderedList = styled.ol`
  width: 100%;
  padding: 0px 20px 0px 40px;
`;

const UnorderedList = styled.ul`
  width: 100%;
  padding: 0px 20px 0px 40px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

export function IntroSection() {
  return (
    <PrivacyPolicySection>
      <PrivacyPolicySectionText>
        Les présentes conditions générales régissent l'utilisation des
        plateformes SANBBIZ, y compris le site internet et les applications
        mobiles associées.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Ces plateformes sont administrées par SANBBIZ. En utilisant ces
        plateformes, vous confir
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function IntellectualPropertySection() {
  return (
    <PrivacyPolicySection id="intellectual-property-section">
      <PrivacyPolicySectionTitle>
        Propriété intellectuelle
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Tous les contenus disponibles sur la plateforme SANBBIZ, y compris mais
        sans s'y limiter les textes, images, vidéos, graphiques, logos, icônes
        et logiciels, sont protégés par les lois sur la propriété intellectuelle
        et sont la propriété exclusive de SANBBIZ ou de ses partenaires.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Les utilisateurs de la plateforme accordent à SANBBIZ une licence
        mondiale non exclusive, transférable, sous-licenciable et gratuite pour
        utiliser, reproduire, distribuer, créer des oeuvres dérivées, afficher
        et exécuter tout contenu qu'ils publient sur la plateforme dans le cadre
        de la fourniture des services de SANBBIZ et pour la promotion de la
        plateforme.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Toute reproduction, représentation, modification, publication ou
        adaptation de tout ou partie des éléments de la plateforme, quel que
        soit le moyen ou le procédé utilisé, est interdite sans autorisation
        écrite préalable de SANBBIZ. Toute utilisation non autorisée de la
        plateforme ou de l'un quelconque des éléments qu'elle contient sera
        considérée comme une contrefaçon et sera poursuivie conformément aux
        dispositions légales applicables.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function AgeRestrictionSection() {
  return (
    <PrivacyPolicySection id="age-restriction-section">
      <PrivacyPolicySectionTitle>Restrictions d'âge</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        L'âge minimum pour utiliser les plateformes SANBBIZ est de 13 ans. En
        utilisant nos plateformes, les utilisateurs déclarent avoir au moins 13
        ans. SANBBIZ décline toute responsabilité en cas de fausses déclarations
        sur l'âge.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function AcceptedUsageSection() {
  return (
    <PrivacyPolicySection id="accepted-usage-section">
      <PrivacyPolicySectionTitle>
        Utilisation acceptable
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Les utilisateurs s'engagent à utiliser les plateformes de manière légale
        et à ne pas les utiliser à des fins illicites, telles que :
      </PrivacyPolicySectionText>
      <br />
      <OrderedList>
        <ListItem>Harceler ou maltraiter les autres utilisateurs.</ListItem>
        <ListItem>Porter atteinte aux droits des autres utilisateurs.</ListItem>

        <ListItem>
          Violer les droits de propriété intellectuelle des propriétaires des
          plateformes ou de tiers.
        </ListItem>
        <ListItem>
          Créer et publier un événement dont ils ne sont pas propriétaires.
        </ListItem>
        <ListItem>Pirater le compte d'un autre utilisateur.</ListItem>

        <ListItem>Se livrer à des activités frauduleuses.</ListItem>
        <ListItem>Participer à toute activité illégale sur le site.</ListItem>
        <ListItem>
          Afficher des contenus jugés inappropriés ou offensants.
        </ListItem>
      </OrderedList>
      <br />
      <PrivacyPolicySectionText>
        En cas d'utilisation illégale ou en violation des conditions
        d'utilisation, SANBBIZ se réserve le droit de limiter, suspendre ou
        résilier l'accès aux plateformes. SANBBIZ se réserve également le droit
        de prendre toutes les mesures juridiques nécessaires pour empêcher
        l'accès aux plateformes.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function UserContributionSection() {
  return (
    <PrivacyPolicySection id="user-contribution-section">
      <PrivacyPolicySectionTitle>
        Contributions des utilisateurs
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Les utilisateurs peuvent publier les informations suivantes sur les
        plateformes en utilisant les moyens prévus :
      </PrivacyPolicySectionText>
      <br />
      <UnorderedList>
        <ListItem>
          Tickets d'événements et d’activités : Publication de tickets pour des
          événements et des activités organisés par les utilisateurs, avec des
          informations complètes et précises (date, heure, lieu, description,
          modalités de participation et tarifs).
        </ListItem>
        <ListItem>
          Contenus et Multimédia : Publication de contenus multimédias tels que
          des textes, photos et vidéos liés aux événements et activités, en
          respectant les droits de propriété intellectuelle.
        </ListItem>
        <ListItem>
          Liens Internet : Inclusion de liens vers des sites externes
          pertinents, à condition qu'ils ne renvoient pas vers des contenus
          illicites, offensants ou inappropriés.
        </ListItem>
      </UnorderedList>
    </PrivacyPolicySection>
  );
}

export function UserAccountSection() {
  return (
    <PrivacyPolicySection id="user-account-section">
      <PrivacyPolicySectionTitle>Comptes</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        En créant un compte sur les plateformes SANBBIZ, vous acceptez les
        conditions suivantes :
      </PrivacyPolicySectionText>
      <br />
      <OrderedList>
        <ListItem>
          Responsabilité du Compte : Vous êtes responsable de la sécurité et de
          la confidentialité de votre compte, y compris des mots de passe et des
          informations sensibles associées. Vous êtes responsable de toutes les
          activités effectuées via votre compte et devez informer SANBBIZ
          immédiatement en cas d'utilisation non autorisée de votre compte.
        </ListItem>
        <ListItem>
          Exactitude des Informations : Tous les renseignements personnels
          fournis doivent être à jour, exacts et véridiques. Vous vous engagez à
          mettre à jour vos informations personnelles en cas de changement.
        </ListItem>
        <ListItem>
          Sécurité des Comptes : Utilisez des mots de passe complexes,
          changez-les régulièrement et ne partagez pas vos identifiants de
          connexion. Signalez toute activité suspecte sur votre compte à
          SANBBIZ.
        </ListItem>
        <ListItem>
          Actions en cas de Violation : SANBBIZ se réserve le droit de suspendre
          ou de résilier votre compte en cas d'utilisation illégale ou de
          violation des conditions d'utilisation. En cas de violation de la
          sécurité, SANBBIZ peut suspendre ou désactiver votre compte pour
          protéger vos informations et celles des autres utilisateurs.
        </ListItem>
      </OrderedList>
    </PrivacyPolicySection>
  );
}

export function SellsNServicesSection() {
  return (
    <PrivacyPolicySection id="sells-and-services-section">
      <PrivacyPolicySectionTitle>
        Ventes de biens et services
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Reportez-vous aux conditions générales de vente pour plus de détails sur
        les ventes de biens et services.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function PaymentSection() {
  return (
    <PrivacyPolicySection id="payment-section">
      <PrivacyPolicySectionTitle>Paiements</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Reportez-vous aux conditions générales de vente pour plus de détails sur
        les paiements.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function CashBackSection() {
  return (
    <PrivacyPolicySection id="cash-back-section">
      <PrivacyPolicySectionTitle>Remboursement</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Reportez-vous aux conditions générales de vente pour plus de détails sur
        les remboursements.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function LiabilityLimitationSection() {
  return (
    <PrivacyPolicySection id="liability-limitation-section">
      <PrivacyPolicySectionTitle>
        Limitation de responsabilité
      </PrivacyPolicySectionTitle>
      <OrderedList>
        <ListItem>
          Service "en l'état" : Les plateformes SANBBIZ sont fournies "en
          l'état" et "selon disponibilité". SANBBIZ ne garantit pas que les
          services seront ininterrompus, sécurisés ou exempts d'erreurs.
        </ListItem>
        <ListItem>
          Exclusion de garantie : Dans la mesure permise par la loi, SANBBIZ
          exclut toutes garanties, expresses ou implicites, y compris les
          garanties implicites de qualité marchande, d'adéquation à un usage
          particulier et de non-violation des droits de propriété.
        </ListItem>

        <ListItem>
          Limitation des dommages : SANBBIZ ne saurait être tenu responsable des
          dommages indirects, spéciaux, accidentels ou consécutifs résultant de
          l'utilisation ou de l'incapacité à utiliser les plateformes, même si
          SANBBIZ a été informé de la possibilité de tels dommages. Cela inclut,
          sans s'y limiter, les pertes de profits, les interruptions d'activité,
          ou la perte de données.
        </ListItem>
        <ListItem>
          Force majeure : SANBBIZ ne sera pas tenu responsable des retards ou
          manquements à ses obligations résultant de circonstances indépendantes
          de sa volonté, y compris mais sans s'y limiter, les catastrophes
          naturelles, les actes de terrorisme, les guerres, les émeutes, les
          conditions météorologiques, les actes des autorités gouvernementales,
          les pannes d'électricité ou de réseau, et les grèves.
        </ListItem>
        <ListItem>
          Utilisation irrégulière : SANBBIZ et ses employés ne pourront être
          tenus responsables de tout problème découlant de l'utilisation
          irrégulière des plateformes par les utilisateurs, y compris mais sans
          s'y limiter, les violations des conditions d'utilisation, les actes de
          piratage, ou toute autre utilisation non autorisée des plateformes.
        </ListItem>
      </OrderedList>
    </PrivacyPolicySection>
  );
}

export function CompensationSection() {
  return (
    <PrivacyPolicySection id="compensation-section">
      <PrivacyPolicySectionTitle>Indemnité</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        En utilisant nos plateformes, vous acceptez de défendre, d'indemniser et
        de dégager de toute responsabilité SANBBIZ, ses dirigeants,
        administrateurs, employés, agents et partenaires de toute réclamation,
        responsabilité, perte, dommage, coût ou dépense, y compris les
        honoraires d'avocats raisonnables, découlant de :
      </PrivacyPolicySectionText>
      <br />
      <OrderedList>
        <ListItem>
          Votre Utilisation des Plateformes : Toute utilisation de nos
          plateformes, y compris mais sans s'y limiter la publication de
          contenu, la participation à des activités ou événements et
          l'utilisation de toute information obtenue à partir de nos
          plateformes.
        </ListItem>
        <ListItem>
          Violation des Conditions : Toute violation de votre part des présentes
          conditions générales d'utilisation ou de toute autre politique ou
          règle mise en place par SANBBIZ.
        </ListItem>
        <ListItem>
          Violation des Droits : Toute violation des droits d'un tiers, y
          compris mais sans s'y limiter les droits de propriété intellectuelle,
          de confidentialité, de publicité ou tout autre droit de propriété ou
          contractuel.
        </ListItem>
        <ListItem>
          Actions et Omissions : Toute action ou omission résultant de votre
          utilisation de nos plateformes qui cause un préjudice à SANBBIZ ou à
          tout tiers.
        </ListItem>
      </OrderedList>
    </PrivacyPolicySection>
  );
}

export function ApplicableLawsSection() {
  return (
    <PrivacyPolicySection id="applicable-laws-section">
      <PrivacyPolicySectionTitle>Lois applicables</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Les présentes conditions générales d'utilisation sont soumises aux lois
        en vigueur au Burkina Faso et doivent se conformer à ces règles et
        réglementations.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function DivisibilitySection() {
  return (
    <PrivacyPolicySection id="divisibility-section">
      <PrivacyPolicySectionTitle>Divisibilité</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Si une disposition des présentes est jugée invalide ou inapplicable en
        vertu de la loi applicable, cette disposition sera considérée comme
        nulle et sera supprimée des présentes conditions sans affecter la
        validité des autres dispositions.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function CGUModificationSection() {
  return (
    <PrivacyPolicySection id="cgu-modification-section">
      <PrivacyPolicySectionTitle>Modifications</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Ces conditions générales d'utilisation peuvent être modifiées de temps à
        autre pour se conformer à la loi et pour refléter tout changement dans
        la gestion de nos plateformes. Nous recommandons aux utilisateurs de
        vérifier régulièrement ces conditions pour rester informés des mises à
        jour. Si nécessaire, nous informerons les utilisateurs par e-mail des
        modifications apportées ou nous publierons un avis sur nos plateformes.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function SanbbizContactSection() {
  return (
    <PrivacyPolicySection id="sanbbiz-contact-section">
      <PrivacyPolicySectionTitle>Consentement</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Pour toute question ou préoccupation, veuillez nous contacter :
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Sanbbiz Ouagadougou S/C 12 BP 154 Ouagadougou 12 Parcelle 19 lot 30
        secteur 19 section 709
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        hello.sanbbiz@gmail.com
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>+226 74 84 38 61</PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

const DocumentContainer = styled.div`
  position: relative;
  padding: 30px 0px;
  background-color: white;
  width: 100%;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  padding: 10px;
  border-radius: 20px;
`;

export function CGUDoc() {
  return (
    <>
      <TableOfContents />

      <DocumentContainer>
        <SectionGroupTitle>Termes et Conditions Sanbbiz</SectionGroupTitle>
        <IntroSection />
        <IntellectualPropertySection />
        <AgeRestrictionSection />
        <AcceptedUsageSection />
        <UserContributionSection />
        <UserAccountSection />
        <SellsNServicesSection />
        <PaymentSection />
        <CashBackSection />
        <LiabilityLimitationSection />
        <CompensationSection />
        <ApplicableLawsSection />
        <DivisibilitySection />
        <CGUModificationSection />
        <SanbbizContactSection />
      </DocumentContainer>
    </>
  );
}
