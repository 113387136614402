/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 07/04/2024
/* ******************************************* */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import {
  FiHome,
  FiInfo,
  FiPhone,
  FiHelpCircle,
  FiChevronRight,
  FiChevronLeft,
} from 'react-icons/fi';
import logo from '../../../assets/sanbbizLogos/logo.png';
import { useAuth } from '../../../contexts/AuthContext'; // Assurez-vous que le chemin est correct

import { UserMenuList } from '../../../assets/datas/UserMenuList';

const SidebarContainer = styled.div`
  position: fixed;
  width: ${(props) => (props.collapsed ? '80px' : '250px')};
  height: 100vh;
  background-color: #ffffff; // Changé à blanc
  color: #333; // Couleur de texte pour contraste
  padding: 0;
  transition: width 0.3s;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.1); // Ajout d'une ombre pour la profondeur
  z-index: 1000; // Assurez-vous qu'il reste au-dessus des autres contenus
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto 30px;
`;

const SidebarMenuContainer = styled.ul``;

const StyledMenuItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: ${(props) => (props.isActive ? '#f8c198' : 'transparent')};
  color: ${(props) => (props.isActive ? '#f1360a' : '#051145')};
  transition: background-color 0.3s, color 0.3s;
  margin: 2px 10px;

  &:hover {
    background-color: #f8c198;
    color: #f1360a;
  }
`;

const Icon = styled.span`
  font-size: 1.5em;
  width: 30px;
`;

const Text = styled.span`
  margin-left: 10px;
  display: ${(props) => (props.show ? 'inline' : 'none')};
`;

const Tooltip = styled.div`
  display: none; // Changé de 'visibility: hidden' à 'display: none' pour une meilleure gestion du layout
  width: 120px;
  background-color: #007bff;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 100;
  bottom: 100%;
  left: 105%;
  margin-left: -60px;
  white-space: nowrap;

  ${StyledMenuItem}:hover & {
    display: block; // Changé de 'visibility: visible' à 'display: block'
  }
`;
const SidebarFooter = styled.ul`
  position: absolute;
  bottom: 0;
  list-style: none;
  padding: 0;
  width: 100%;
`;

const MenuLinkItem = ({ icon, label, to, collapsed }) => {
  const IconComponent = icon;
  return (
    <NavLink
      to={to}
      className={({ isActive }) => (isActive ? 'active' : undefined)}
    >
      {({ isActive }) => (
        <StyledMenuItem isActive={isActive}>
          <Icon>
            <IconComponent />
          </Icon>
          <Text show={!collapsed}>{label}</Text>
          {collapsed && <Tooltip>{label}</Tooltip>}
        </StyledMenuItem>
      )}
    </NavLink>
  );
};

const Sidebar = ({ collapsed, setCollapsed }) => {
  const { currentUser } = useAuth();

  // Filtrer les menus en fonction du rôle de l'utilisateur
  const userRoleMenus = currentUser
    ? UserMenuList.find((menu) => menu.role === currentUser.dbProfile.status)
        ?.menus || []
    : [];

  return (
    <SidebarContainer collapsed={collapsed}>
      <Link to="/">
        <Logo src={logo} alt="SANBBIZ Logo" />
      </Link>
      <SidebarMenuContainer>
        {userRoleMenus.map((menuItem) => (
          <MenuLinkItem
            key={menuItem.name}
            icon={menuItem.icon}
            label={menuItem.name}
            to={menuItem.path}
            collapsed={collapsed}
          />
        ))}
      </SidebarMenuContainer>
      <SidebarFooter>
        <MenuLinkItem
          icon={FiHelpCircle}
          label="Aide"
          to="/help"
          collapsed={collapsed}
        />
      </SidebarFooter>
    </SidebarContainer>
  );
};

export default Sidebar;
