import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../utils/variables';
import { extractDayMonthAndYearFormTimestamp } from '../../../utils/date';
import { Timestamp } from 'firebase/firestore';

const Container = styled.div`
  display: flex;
  height: 20%;
  cursor: pointer;
  padding: 5px 0px;
  margin-left: 5px;
`;
const ImgContainer = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
`;

const Title = styled.h2`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-height: 1.2em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin-right: 10px;
  font-weight: bold;
  font-size: 1rem;
`;

const EventDate = styled.h3`
  font-size: 0.9rem;
  color: gray;
`;

const EventPlace = styled.h3`
  font-size: 0.9rem;
  color: gray;
`;

function ResearchEventItem({ event, onCloseModal }) {
  const navigate = useNavigate();

  const onClose = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const goToDetail = () => {
    onClose();
    navigate(`events/${event.id}`);
    // navigate(`${ROUTES.eventDetailsPage}/${event.id}`);
    window.scrollTo(0, 0); // Scroller vers le haut de la page
  };
  let startDate = null;
  if (
    event?.dates?.length > 0 &&
    event.dates[0].start &&
    event.dates[0].start.seconds
  ) {
    try {
      startDate = extractDayMonthAndYearFormTimestamp(
        new Timestamp(event.dates[0].start.seconds)
      );
      // console.log(startDate);
    } catch (error) {
      console.error('Error extracting date:', error.message);
      startDate = null;
    }
  } else {
    console.warn('No valid date found in event object.');
  }

  const date = startDate ? `${startDate.day} ${startDate.month}` : '';
  return (
    <Container onClick={goToDetail}>
      <ImgContainer>
        <Img
          src={event.posterPicture?.url}
          alt={`${event?.name} cover`}
          width={event.posterPicture?.width}
          style={{}}
        />
      </ImgContainer>
      <TextContainer>
        <Title>{event?.name}</Title>
        {event.dates && <EventDate>{date}</EventDate>}
        <EventPlace>{`${event?.location?.place}, ${event?.location?.city}`}</EventPlace>
      </TextContainer>
    </Container>
  );
}

export default ResearchEventItem;
