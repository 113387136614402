/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 16/05/2024
/* ******************************************* */

export const fetchTicketsStart = () => ({
  type: 'FETCH_TICKETS_START',
});

export const fetchTicketsSuccess = (tickets) => ({
  type: 'FETCH_TICKETS_SUCCESS',
  payload: tickets,
});

export const fetchTicketsError = (error) => ({
  type: 'FETCH_TICKETS_ERROR',
  payload: error,
});

export const setCurrentTicket = (ticket) => ({
  type: 'SET_CURRENT_TICKET',
  payload: ticket,
});
