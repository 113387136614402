/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 27/05/2024
/* ******************************************* */

import React from 'react';
import SidebarLayout from '../../templates/SidebarLayout/SidebarLayout';
import PromoteurEventManager from '../../organisms/PromoteurEventManager/PromoteurEventManager';

function ManageEventPage() {
  return (
    <SidebarLayout showTopBar={false} isAlwaysCollapsed={true}>
      <PromoteurEventManager />
    </SidebarLayout>
  );
}

export default ManageEventPage;
