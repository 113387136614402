/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/05/2024
/* ******************************************* */

import React, { useState } from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import BlogDetail from '../../organisms/BlogDetail/BlogDetail';
import { formatText } from '../../../utils/function';

function BlogDetailPage() {
  const title = "Details de l'événement"; // Le titre de la page
  const [currentBlog, setCurrentBlog] = useState(null);

  return (
    <div>
      <PageLayout
        title={title}
        backgroundImage={currentBlog ? currentBlog.coverPicture.url : undefined}
        routesPath={'Blogs / ' + formatText(currentBlog?.title || '')}
      >
        <BlogDetail setCurrentBlog={setCurrentBlog} />
      </PageLayout>
    </div>
  );
}

export default BlogDetailPage;
