/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 19/05/2024
/* ******************************************* */

import React from 'react';
import styled from 'styled-components';
import FormField from '../../atoms/FormField/FormField';
import { Button } from '@chakra-ui/react';
import { collection, doc, getDoc } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';

import sanbbizLogo from '../../../assets/sanbbizLogos/logo.png';

const Plan = styled.div`
  border: ${({ isSelected }) =>
    isSelected ? '2px solid blue' : '1px solid #e1e1e1'};
  border-radius: 16px;
  padding: 24px;
  width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: ${({ isSelected }) => (isSelected ? 'blue' : 'white')};
  color: ${({ isSelected }) => (isSelected ? 'white' : 'black')};
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const PlanTitle = styled.h2`
  margin: 0;
  font-size: 24px;
`;

const PlanCommission = styled.p`
  margin: 12px 0 0 0;
  font-size: 32px;
  font-weight: bold;
`;
const BottomText = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const PlanFeatures = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 20px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PlanAdv = styled.li`
  margin-bottom: 8px;
  text-align: left;
  position: relative;
  padding-left: 24px;

  &:before {
    content: '';
    background-image: url(${sanbbizLogo});
    background-size: cover;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const BusinessFormulaItem = ({ currentFormula }) => {
  return (
    <Plan>
      <PlanTitle>{currentFormula.name}</PlanTitle>
      {/* <TopText>Je rentre du texte pour tester</TopText> */}
      <PlanCommission>
        {currentFormula.commission}
        {'%'}
      </PlanCommission>
      <BottomText>des ventes de billets</BottomText>
      <PlanFeatures>
        {currentFormula.advantages.map((adv, index) => {
          return <PlanAdv key={index}>{adv.description}</PlanAdv>;
        })}
      </PlanFeatures>
    </Plan>
  );
};

export default BusinessFormulaItem;
