/* ******************************************** */
/* * Author: Jamal Sidikou 
/* * Project : SANNBIZ
/* * Create at : 21/05/2024
/* ******************************************* */

import React from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import Checkout from '../../organisms/Checkout/Checkout';

function CheckoutPage() {
  const title = "Paiement"; // Le titre de la page

  return (
    <>
      <PageLayout title={title}>
        <Checkout />
      </PageLayout>
    </>
  );
}

export default CheckoutPage;
