/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 09/03/2024
/* ******************************************* */

import React, { useState } from 'react';
import FoldIcon from '../../atoms/Icon/FoldIcon';
import UnfoldIcon from '../../atoms/Icon/UnfoldIcon';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button, Link, Icon } from '@chakra-ui/react';

import defaultUserPicture from '../../../assets/medias/defaultUserProfilPicture.jpg';
import { getUserProfileMenuItems } from '../../../assets/datas/UserMenuList';

import { doSignOut } from '../../../api/firebase/auth';

import styled from 'styled-components';
import { ROUTES } from '../../../utils/variables';

const UserProfile = ({ isCustomerProfile }) => {
  const { userLoggedIn, currentUser } = useAuth();
  return (
    <>
      {userLoggedIn && (
        <UserProfileWidget
          userInfo={currentUser.dbProfile}
          isCustomerProfile={isCustomerProfile}
        />
      )}
      {!userLoggedIn && <ConnexionButton />}
    </>
  );
};

const ConnexionButton = () => {
  return (
    <Link href={ROUTES.loginPage}>
      <Button
        size="sm"
        rounded="md"
        color={['primary.500', 'primary.500', 'white', 'white']}
        bg={['white', 'white', 'primary.500', 'primary.500']}
        _hover={{
          bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
        }}
      >
        Se connecter
      </Button>
    </Link>
  );
};

function generateUserName(lName, fName) {
  if (!fName || !lName) {
    console.error('fName et lName doivent être fournis.');
    return '';
  }

  // Prendre le premier caractère de chaque (fName et lName)
  const userName = fName[0] + lName[0];

  return userName.toUpperCase();
}

const UserProfilShortButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #051145;
`;

const UserProfilShortName = styled.div`
  font-weight: bold;
`;

const UserProfileWidget = ({ userInfo, isCustomerProfile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <UserProfilShortButton onClick={toggle} toggle={toggle} isOpen={isOpen}>
        <UserProfilShortName>
          {generateUserName(userInfo.lname, userInfo.fname)}
        </UserProfilShortName>
        {isOpen ? <UnfoldIcon color="#051145" /> : <FoldIcon color="#051145" />}
      </UserProfilShortButton>
      <UserProfileMenu
        toShow={isOpen}
        userInfo={userInfo}
        isCustomerProfile={isCustomerProfile}
      />
    </>
  );
};

const UserMenuContainer = styled.div`
  display: inline-block;
  padding: 10px;
  position: absolute;
  background-color: white;
  color: #051145;
  top: 100px;
  right: 0px;
  width: 350px;
  z-index: 3000;
`;

const UserInfos = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr;
  border-bottom: solid 1px black;
  padding-bottom: 10px;
`;

const UserProfilContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 50%;
  margin: 5px;
  height: 70px;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
`;

const UserName = styled.span`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  font-weight: bold;
  font-size: 24px;
`;

const UserMenuGroupContent = styled.div`
  padding-bottom: 20px;
  background-color: ${(props) => (props.index % 2 === 0 ? '#ecf0f2' : 'white')};
  margin: 0 -10px;
`;

const UserMenuGroupName = styled.h3`
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 10px;
  margin-bottom: 10px;
  font-size: 1.2rem;
`;

const UserMenuGroupItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 20px;
`;

const UserMenuGroupItem = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 10px;
`;

const UserMenuGroupItemName = styled.span`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1.2rem;
`;

const UserProfileMenu = ({ userInfo, toShow, isCustomerProfile }) => {
  const navigate = useNavigate();

  // Use the function to get the items
  const userProfileMenuItems = getUserProfileMenuItems(
    userInfo.status,
    isCustomerProfile
  );

  // Filtre les élément en fonction du profil
  const visibleItems = userProfileMenuItems.filter((item) =>
    item.profiles.includes(userInfo.status)
  );

  // Organise les items par groupe
  const itemsByGroup = visibleItems.reduce((acc, item) => {
    // créer un groupe s'il n'existe pas
    if (!acc[item.group]) {
      acc[item.group] = [];
    }
    // ajouter élément à son groupe
    acc[item.group].push(item);
    return acc;
  }, {});

  return toShow ? (
    <UserMenuContainer>
      <UserInfos>
        <UserProfilContainer>
          <Image
            src={userInfo?.profilePicture?.url || defaultUserPicture}
            alt="Photo utilisateur"
            borderRadius="50%"
            boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
            boxSize="70px"
          />
        </UserProfilContainer>

        <UserName>
          {isCustomerProfile
            ? userInfo.fname + ' ' + userInfo.lname
            : userInfo.businessName}
        </UserName>
      </UserInfos>
      {Object.entries(itemsByGroup).map(([group, items], index) => (
        <UserMenuGroupContent index={index} key={group}>
          <UserMenuGroupName>{group}</UserMenuGroupName>
          <UserMenuGroupItemsList>
            {items.map((item) => (
              <Link href={item.windowPath} key={item.id}>
                <UserMenuGroupItem>
                  <Icon as={item.icon} w={8} h={8} color="#051145" />
                  <UserMenuGroupItemName>{item.title}</UserMenuGroupItemName>
                </UserMenuGroupItem>
              </Link>
            ))}
          </UserMenuGroupItemsList>
        </UserMenuGroupContent>
      ))}
      <Button
        colorScheme="red"
        w="full"
        mt={4}
        onClick={() => {
          doSignOut().then(() => {
            navigate(ROUTES.loginPage);
            window.scrollTo(0, 0); // Scroller vers le haut de la page
          });
        }}
      >
        Déconnexion
      </Button>
    </UserMenuContainer>
  ) : null;
};

export default UserProfile;
