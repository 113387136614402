/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 01/06/2024
/* ******************************************* */

import React from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import styled from 'styled-components';
import { PrivacyPolicyDoc } from '../../atoms/PrivacyPolicyAtoms/PrivacyPolicyAtoms';

const PrivacyPolicyContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 40px;
  overflow: hidden;

  @media screen and (min-width: 1100px) {
    width: 70%;
  }
`;

function PrivacyPolicyPage() {
  const title = 'Politique de confidentialité'; // Le titre de la page

  return (
    <>
      <PageLayout title={title}>
        <PrivacyPolicyContainer>
          <PrivacyPolicyDoc />
        </PrivacyPolicyContainer>
      </PageLayout>
    </>
  );
}

export default PrivacyPolicyPage;
