/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 21/05/2024
/* ******************************************* */

import React from 'react';
import SidebarLayout from '../../templates/SidebarLayout/SidebarLayout';
import ManageScanner from '../../organisms/ManageScanner/ManageScanner';

function ManageScannerPage() {
  return (
    <SidebarLayout
      menuTitle={'Scanneur '}
      menuRoutePath={'Tableau de bord / Scanneur'}
    >
      <ManageScanner />
    </SidebarLayout>
  );
}

export default ManageScannerPage;
