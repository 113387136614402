/* ******************************************** */
/* * Creator: Pale William
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/04/2024
/* ******************************************* */

import { firestore_db } from '../api/firebase/firebase-config';
import { collection, doc, getDoc } from 'firebase/firestore';

/**
 * Récupère le nom d'utilisateur et le nom de l'entreprise à partir de l'ID du créateur.
 * @param {string} creatorId - L'ID du créateur dont on souhaite récupérer les informations.
 * @returns {Promise<string>} Une promesse résolue avec le nom d'utilisateur et le nom de l'entreprise.
 */
export async function getCreatorSurnameNCompanyFromId(creatorId) {
  // Référence au document de l'utilisateur dans Firestore
  const userRef = doc(firestore_db, 'users', creatorId);

  try {
    // Récupération du document utilisateur
    const docSnapshot = await getDoc(userRef);

    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      let username;

      // Détermination du nom d'utilisateur en fonction de la disponibilité du surname
      if (userData.surname) {
        username = userData.surname;
      } else {
        // Utiliser fname et LName avec LName en majuscule si surname est nul
        const lnameUpperCase = userData.lname.toUpperCase();
        username = `${userData.fname} ${lnameUpperCase}`;
      }

      // Récupération du nom de l'entreprise
      const companyName = userData.companyName || 'N/A'; // Utilisez 'N/A' si companyName n'est pas défini

      return `Username: ${username}, Company Name: ${companyName}`;
    } else {
      return 'No user found with the given ID';
    }
  } catch (error) {
    console.error('Failed to fetch user:', error);
    return 'Error fetching user data';
  }
}

/**
 * Récupère uniquement le nom de l'entreprise à partir de l'ID du créateur.
 * @param {string} creatorId - L'ID du créateur dont on souhaite récupérer le nom de l'entreprise.
 * @returns {Promise<string>} Une promesse résolue avec le nom de l'entreprise.
 */
export async function getCompanyNameFromID(creatorId) {
  // Référence au document de l'utilisateur dans Firestore
  const userRef = doc(firestore_db, 'users', creatorId);

  try {
    // Récupération du document utilisateur
    const docSnapshot = await getDoc(userRef);

    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();

      // Récupération du nom de l'entreprise
      const companyName =
        userData.businessName || userData.fname + ' ' + userData.lname;

      return companyName;
    } else {
      return 'No user found with the given ID';
    }
  } catch (error) {
    console.error('Failed to fetch user:', error);
    return 'Error fetching user data';
  }
}

/**
 * Récupère uniquement le nom d'utilisateur à partir de l'ID du créateur.
 * @param {string} creatorId - L'ID du créateur dont on souhaite récupérer le nom d'utilisateur.
 * @returns {Promise<string>} Une promesse résolue avec le nom d'utilisateur.
 */
export async function getUsernameFromID(creatorId) {
  // Référence au document de l'utilisateur dans Firestore
  const userRef = doc(firestore_db, 'users', creatorId);

  try {
    // Récupération du document utilisateur
    const docSnapshot = await getDoc(userRef);

    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      let username;

      // Détermination du nom d'utilisateur en fonction de la disponibilité du surname
      if (userData.surname) {
        username = userData.surname;
      } else {
        // Utiliser fname et lName avec lName en majuscule si surname est nul
        username = `${userData.fname} ${userData.lname.toUpperCase()}`;
      }

      return username;
    } else {
      return 'No user found with the given ID';
    }
  } catch (error) {
    console.error('Failed to fetch user:', error);
    return 'Error fetching user data';
  }
}

/**
 * Récupère uniquement l'URL de la photo de profil à partir de l'ID du créateur.
 * @param {string} creatorId - L'ID du créateur dont on souhaite récupérer l'URL de la photo de profil.
 * @returns {Promise<string>} Une promesse résolue avec l'URL de la photo de profil.
 */
export async function getProfilePictureUrlFromID(creatorId) {
  // Référence au document de l'utilisateur dans Firestore
  const userRef = doc(firestore_db, 'users', creatorId);

  try {
    // Récupération du document utilisateur
    const docSnapshot = await getDoc(userRef);

    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();

      // Récupération de l'URL de la photo de profil
      const profilePictureUrl =
        userData.profilePicture && userData.profilePicture.url
          ? userData.profilePicture.url
          : 'default_profile_picture_url_here';

      return profilePictureUrl;
    } else {
      return 'No user found with the given ID';
    }
  } catch (error) {
    console.error('Failed to fetch user:', error);
    return 'Error fetching user data';
  }
}
