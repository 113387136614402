/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 20/04/2024
/* ******************************************* */

import cinetPay from '../medias/logo-cinetpay.png.webp';
import corisBank from '../medias/logo-coris-bank.png';

export const PartnersLogos = [
  {
    name: 'CinetPay',
    logoUrl: cinetPay,
  },
  {
    name: 'Coris Bank',
    logoUrl: corisBank,
  },
  {
    name: 'CinetPay',
    logoUrl: cinetPay,
  },
  {
    name: 'Coris Bank',
    logoUrl: corisBank,
  },
  {
    name: 'Coris Bank',
    logoUrl: corisBank,
  },
  {
    name: 'CinetPay',
    logoUrl: cinetPay,
  },
  {
    name: 'Coris Bank',
    logoUrl: corisBank,
  },
  {
    name: 'Coris Bank',
    logoUrl: corisBank,
  },
  {
    name: 'CinetPay',
    logoUrl: cinetPay,
  },
  {
    name: 'Coris Bank',
    logoUrl: corisBank,
  },
];
