/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 19/05/2024
/* ******************************************* */

import React from 'react';
import styled from 'styled-components';
import FormField from '../../atoms/FormField/FormField';
import { Button } from '@chakra-ui/react';

const AdvantageContainer = styled.div`
  position: relative;
  display: grid;
  gap: 20px;
  padding: 20px;
  padding-bottom: 50px;
  background-color: white;
`;

const AdvantageContent = styled.div``;

const FormFields = styled.div``;

const BusinessAdvantageItem = ({ currentAdvantage }) => {
  return (
    <AdvantageContainer>
      <AdvantageContent>
        <FormFields>
          <FormField
            id="description"
            label="Description"
            inputType="text"
            placeholder={currentAdvantage.description}
            disabled
          />
          <FormField
            id="Type"
            label="Type"
            inputType="text"
            placeholder={currentAdvantage.type}
            disabled
          />
          <FormField
            id="option"
            inputType="div"
            onChange={() => {}}
            justify="left"
            gap="20px"
            disabled
          >
            <Button
              size="md"
              bgColor={currentAdvantage.occurence ? '#f1360a' : '#ecf0f2'}
              color="black"
              _hover={{
                bgColor: currentAdvantage.occurence ? '#f1360a' : '#ecf0f2',
              }}
            >
              Occurence
            </Button>
            <Button
              size="md"
              bgColor={currentAdvantage.duration ? '#f1360a' : '#ecf0f2'}
              color="black"
              _hover={{
                bgColor: currentAdvantage.duration ? '#f1360a' : '#ecf0f2',
              }}
            >
              Durée
            </Button>
          </FormField>
        </FormFields>
      </AdvantageContent>
    </AdvantageContainer>
  );
};

export default BusinessAdvantageItem;
