/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 30/03/2024
/* ******************************************* */

import React from 'react';
import ManageBlog from '../../molecules/ManageBlog/ManageBlog';
import EditBlogForm from '../../molecules/EditBlogForm/EditBlogForm';
import { Button, useDisclosure } from '@chakra-ui/react';
import bgImage from '../../../assets/sanbbizLogos/admin_pages_cover.png';

import styled from 'styled-components';

const ManageBlogFormContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 255, 0.15);
`;

const ManageBlogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 20px;
`;

const LeftHeaderGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledDash = styled.div`
  width: 60px;
  height: 15px;
  background-color: #f1360a;
  border-radius: 20px;
  transform: translateY(50%);
`;

const ManageBlogFormTitle = styled.h2`
  font-weight: bold;
  font-size: 36px;
  color: #051145;
`;
const ManageBlogFormBanner = styled.div`
  width: 100%;
  position: relative;
  right: 0;
  background-size: cover;
  min-height: 200px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-image: url(${bgImage});
`;

function ManageBlogForms() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ManageBlogFormContainer>
      <ManageBlogHeader>
        <LeftHeaderGroup>
          <StyledDash />
          <ManageBlogFormTitle>Liste des articles </ManageBlogFormTitle>
        </LeftHeaderGroup>
      </ManageBlogHeader>

      <ManageBlogFormBanner />

      <>
        <ManageBlog />
        <EditBlogForm isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        <Button
          boxShadow="base"
          colorScheme="red"
          type="submit"
          mt={4}
          onClick={onOpen}
        >
          Créer un nouveau article
        </Button>
      </>
    </ManageBlogFormContainer>
  );
}

export default ManageBlogForms;
