// DevModeBanner.js
import React from 'react';
import styled from 'styled-components';

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  font-size: 14px;
  z-index: 1000;
  pointer-events: none;
`;

const DevModeBanner = () => {
  return (
    <Banner>
      Dev Mode
    </Banner>
  );
};

export default DevModeBanner;
