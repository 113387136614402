/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 01/04/2024
/* ******************************************* */

import React, { useState, useEffect, useRef } from 'react';
import {
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';

import { firestore_db } from '../../../api/firebase/firebase-config';
import { uploadFile } from '../../../api/firebase/storage';
import AlertPopup from '../../atoms/AlertPopup/AlertPopup';

import FormField from '../../atoms/FormField/FormField';
import { useToast } from '@chakra-ui/react';
import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const EditFeedbackFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  height: '500px';
  padding: 20px;
`;

const FormFields = styled.div``;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

function EditFeedbackForm({ feedback, isOpen, onOpen, onClose }) {
  const toast = useToast();

  const [writerName, setWriterName] = useState(
    feedback ? feedback.writerName : null
  );
  const [title, setTitle] = useState(feedback ? feedback.title : null);

  const [comment, setComment] = useState(feedback ? feedback.comment : null);

  const [writerPictureData, setWriterPictureData] = useState({
    url: feedback && feedback.picture ? feedback.picture.url : null,
    width: feedback && feedback.picture ? feedback.picture.width : null,
    height: feedback && feedback.picture ? feedback.picture.height : null,
  });
  const [writerPictureFile, setWriterPictureFile] = useState(null);

  const [rating, setRating] = useState(feedback ? feedback.rating : null);

  const handleRatingChange = (e) => {
    setRating(e.target.value);
  };

  // Handler pour les fichiers sélectionnés de la photo de catégorie
  const handleWriterPictureSelected = (files) => {
    const [file] = files;
    setWriterPictureFile(file); // Save file to state for upload
    const img = new Image();
    const url = URL.createObjectURL(file);
    img.onload = () => {
      setWriterPictureData({
        url: url,
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
      URL.revokeObjectURL(url);
    };
    img.src = url;
  };

  // Handler pour la suppression de la photo
  const handleWriterPictureRemoved = () => {
    URL.revokeObjectURL(writerPictureFile.url); // Libérer la ressource
    setWriterPictureData({ url: null, width: null, height: null });
  };

  // Gère la creation
  const cancelRef = useRef();

  const prepareFeedbackData = async () => {
    let newPictureUrl = writerPictureData.url;

    // Ajouter les nouveaux fichiers s'ils existent
    if (writerPictureFile) {
      newPictureUrl = await uploadFile(
        'gs://sanbbiz-35578.appspot.com/dev_customer_feedbacks/writerPictures',
        writerPictureFile
      );
    }

    return {
      writerName,
      title,
      rating,
      comment,
      writerPicture: {
        url: newPictureUrl,
        width: writerPictureData.width,
        height: writerPictureData.height,
      },
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const feedbackData = await prepareFeedbackData();

      if (feedback) {
        // Mise à jour de la catégorie existante
        const docRef = doc(
          firestore_db,
          TABLE_NAME.customerFeedbacks,
          feedback.id
        );
        await updateDoc(docRef, feedbackData);
        toast({
          title: 'Avis modifié',
          description: "L'avis a été mis à jour avec succès.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Création d'une nouvelle catégorie
        feedbackData.createdAt = serverTimestamp();
        const docRef = await addDoc(
          collection(firestore_db, TABLE_NAME.customerFeedbacks),
          feedbackData
        );
        toast({
          title: 'Avis ajouté',
          description: "L'avis a été mis à jour avec succès.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        console.log('New feedback created with ID:', docRef.id);
      }
      onClose(); // Fermer le formulaire après l'opération
    } catch (error) {
      toast({
        title: 'Erreur de mise à jour',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertPopup
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      onNo={onClose}
      onYes={handleSubmit}
      windowTitle={feedback ? "Modifier l'avis" : 'Créer un nouveau avis'}
    >
      <EditFeedbackFormContent>
        <FormFields>
          <FormFieldsGrid>
            <FormField
              id="writerName"
              label="Nom de du Client"
              inputType="text"
              defaultValue={writerName}
              placeholder="Entrez le nouveau nom de la catégorie"
              value={writerName}
              onChange={(e) => setWriterName(e.target.value)}
              isRequired
            />
            <FormField
              id="feedbackName"
              label="Titre de l'avis"
              inputType="text"
              defaultValue={title}
              placeholder="Entrez le titre de l'avis"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              isRequired
            />
          </FormFieldsGrid>
          <FormFieldsGrid>
            <FormField
              id="writerPicture"
              label="Photo de du client"
              inputType="imageUploader"
              onFilesSelected={handleWriterPictureSelected}
              onImageRemoved={handleWriterPictureRemoved}
              imgWidth="200px"
              imgHeight="200px"
              defaultImageUrl={
                writerPictureData.url ? [writerPictureData.url] : []
              }
              isRequired
            />

            <FormField
              id="feedbackRating"
              label="Note (x/5) "
              inputType="select"
              value={rating}
              onChange={handleRatingChange}
              isRequired
            >
              {!rating && <option value="">Sélectionnez une note</option>}
              <option key={1} value={1}>
                1
              </option>
              <option key={2} value={2}>
                2
              </option>
              <option key={3} value={3}>
                3
              </option>
              <option key={4} value={4}>
                4
              </option>
              <option key={5} value={5}>
                5
              </option>
            </FormField>
          </FormFieldsGrid>
          <FormField
            id="feedbackComment"
            label="Commentaire"
            inputType="textarea"
            defaultValue={comment}
            placeholder="Entrez le commentaire"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            isRequired
          />
        </FormFields>
      </EditFeedbackFormContent>
    </AlertPopup>
  );
}

export default EditFeedbackForm;
