/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 07/04/2024
/* ******************************************* */

import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import HeaderSidebar from '../../organisms/HeaderSidebar/HeaderSidebar';
import { GiHamburgerMenu } from 'react-icons/gi';

const SidebarLayoutPage = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

const SidebarLayoutOutletContainer = styled.div`
  width: 100%;
  transition: margin-left 0.3s ease;
  margin-left: ${(props) => (props.collapsed ? '80px' : '250px')};
`;

const HeaderContainer = styled.div`
  background-color: #f1360a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: padding-left 0.3s ease;
  height: ${(props) => (props.showTopBar ? '110px' : '70px')};
`;

const ContentArea = styled.div`
  padding-top: 120px;
  margin-top: -20px;
  padding: 30px;
  width: auto;
  display: flex;
  justify-content: center;
`;
const TopHeader = styled.div`
  position: relative;
  width: 100%;
  padding: 30px;
  height: 60px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ToggleButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  color: #051145;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #f8c198;
  }
`;

const MenuInfoContainer = styled.div`
  display: ${(props) => (props.showTopBar ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 20px 30px;
  margin: 0px 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-50%);
`;

const MenuName = styled.h1`
  font-size: 32px;
  font-weight: bold;
`;

const MenuPath = styled.span`
  font-size: 18px;
  font-style: italic;
`;

function SidebarLayout({
  menuTitle,
  menuRoutePath,
  children,
  showTopBar = true,
  isAlwaysCollapsed = false,
}) {
  const [collapsed, setCollapsed] = useState(isAlwaysCollapsed);

  // Effect to handle automatic collapsing of sidebar based on screen width
  useEffect(() => {
    const handleResize = () => {
      if (!isAlwaysCollapsed) {
        setCollapsed(window.innerWidth <= 1200);
      }
    };

    handleResize(); // Set initial state based on current window size

    window.addEventListener('resize', handleResize); // Setup event listener for window resize

    return () => window.removeEventListener('resize', handleResize); // Cleanup listener on component unmount
  }, [isAlwaysCollapsed]);

  const toggleSidebar = () => {
    if (!isAlwaysCollapsed) {
      setCollapsed(!collapsed);
    }
  };

  return (
    <SidebarLayoutPage>
      <Sidebar
        collapsed={collapsed || isAlwaysCollapsed}
        setCollapsed={setCollapsed}
      />
      <SidebarLayoutOutletContainer collapsed={collapsed || isAlwaysCollapsed}>
        <HeaderContainer
          collapsed={collapsed || isAlwaysCollapsed}
          showTopBar={showTopBar}
        >
          <TopHeader>
            <ToggleButton onClick={toggleSidebar}>
              <GiHamburgerMenu />
            </ToggleButton>
            <HeaderSidebar />
          </TopHeader>
        </HeaderContainer>
        <MenuInfoContainer showTopBar={showTopBar}>
          <MenuName>{menuTitle}</MenuName>
          <MenuPath>{menuRoutePath} </MenuPath>
        </MenuInfoContainer>
        <ContentArea>{children}</ContentArea>
      </SidebarLayoutOutletContainer>
    </SidebarLayoutPage>
  );
}

export default SidebarLayout;
