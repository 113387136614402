/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Button, Link } from '@chakra-ui/react';
import FormField from '../../atoms/FormField/FormField';
import MultipleImageBox from '../../atoms/MultipleImageBox/MultipleImageBox';
import styled from 'styled-components';
import { doPasswordReset } from '../../../api/firebase/auth';
import { useAuth } from '../../../contexts/AuthContext';
import RedirectComponent from '../../atoms/RedirectComponent/RedirectComponent';
import { ROUTES } from '../../../utils/variables';

const ResetPasswordFormContainer = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 0;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  border-radius: 10px;
  overflow: hidden;
  @media screen and (max-width: 930px) {
    display: inline;
  }
`;

const ResetPasswordFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  height: '500px';
  padding: 20px;
`;

function ResetPasswordForm() {
  const { userLoggedIn } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');

  const handleNewPwd = async (e) => {
    e.preventDefault();
    try {
      await doPasswordReset(email);
      // redirection vers la page de connexions
      navigate(ROUTES.loginPage);
      window.scrollTo(0, 0); // Scroller vers le haut de la page
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <>
      {userLoggedIn && (
        <RedirectComponent to={ROUTES.changePasswordPage} replace={true} />
      )}
      <ResetPasswordFormContainer>
        <MultipleImageBox />
        <ResetPasswordFormContent>
          <Heading mb={6}>Réinitialisez votre mot de passe</Heading>
          <FormField
            id="email"
            label="Email"
            inputType="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            isRequired
          />
          <Button width="full" mt={10} colorScheme="red" onClick={handleNewPwd}>
            Changer le mot de passe
          </Button>

          <Flex align="center" justify="center" mt={4}>
            <Link color="blue.500" href={ROUTES.loginPage}>
              Retour à la page de connexion
            </Link>
          </Flex>
        </ResetPasswordFormContent>
      </ResetPasswordFormContainer>
    </>
  );
}

export default ResetPasswordForm;
