/* ******************************************** */
/* * Creator: Pale William
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/04/2024
/* ******************************************* */

import { Timestamp } from 'firebase/firestore';

/**
 * Used to determine if a date is in the past
 *
 * @param {number} accuracy accuracy level
 * @param {object} date timestamp
 * @param {object} ref timestamp
 * @returns true is date is passed, otherwise false
 */
export function checkIfIsPastDate(accuracy, date, ref = new Date()) {
  let isPast = false;
  if (date !== undefined) {
    if (accuracy === 0) {
      if (
        date.getFullYear() < ref.getFullYear() ||
        date.getMonth() < ref.getMonth() ||
        (date.getMonth() === ref.getMonth() && date.getDate() < ref.getDate())
      ) {
        isPast = true;
      }
    } else {
      if (date.getTime() - ref.getTime() <= 0) {
        isPast = true;
      }
    }
  }
  return isPast;
}

/**
 * Convertit un timestamp Firestore en objet Date JavaScript.
 * @param {object} firestoreTimestamp - Un timestamp Firestore avec `seconds` et `nanoseconds`.
 * @return {Date} L'objet Date JavaScript correspondant.
 */
export function timestamp2DateString(firestoreTimestamp) {
  if (!firestoreTimestamp || typeof firestoreTimestamp.seconds !== 'number') {
    console.error('Invalid timestamp received:', firestoreTimestamp);
    return null; // Retourne null si le timestamp n'est pas valide
  }

  const date = new Timestamp(
    firestoreTimestamp.seconds,
    firestoreTimestamp.nanoseconds
  ).toDate();

  // Obtenir la date en format ISO
  const isoString = date.toISOString();

  // Extraire les composantes date et heure de la chaîne ISO
  const datePart = isoString.split('T')[0]; // YYYY-MM-DD
  const timePart = isoString.split('T')[1].split('.')[0]; // HH:mm:ss

  // Combinez la date et l'heure pour obtenir le format désiré
  return `${datePart} ${timePart}`;
}

export function timestampToDateOnlyString(firestoreTimestamp) {
  if (!firestoreTimestamp || typeof firestoreTimestamp.seconds !== 'number') {
    console.error('Invalid timestamp received:', firestoreTimestamp);
    return null; // Retourne null si le timestamp n'est pas valide
  }

  const date = new Timestamp(
    firestoreTimestamp.seconds,
    firestoreTimestamp.nanoseconds
  ).toDate();

  // Obtenir la date en format ISO
  const isoString = date.toISOString();

  // Extraire uniquement la partie de la date de la chaîne ISO
  const datePart = isoString.split('T')[0]; // YYYY-MM-DD

  return datePart;
}

/**
 * Convertit un objet Date JavaScript en timestamp Firestore.
 * @param {Date} date - L'objet Date JavaScript à convertir.
 * @return {Timestamp} Le timestamp Firestore correspondant.
 */
export function dateString2Timestamp(date) {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    console.error('Invalid date object provided:', date);
    return null; // Retourne null si l'objet date n'est pas valide
  }

  // Convertit l'objet Date en timestamp Firestore
  return Timestamp.fromDate(date);
}

export function checkIfIsSameDate(accuracy, date, ref = new Date()) {
  let isSame = false;
  if (date !== undefined) {
    if (accuracy === 0) {
      if (
        date.getFullYear() === ref.getFullYear() &&
        date.getMonth() === ref.getMonth() &&
        date.getDate() === ref.getDate()
      ) {
        isSame = true;
      }
    } else {
      if (date.getTime() - ref.getTime() === 0) {
        isSame = true;
      }
    }
  }
  return isSame;
}

/**
 * Extrait le jour et le mois d'une date JavaScript et les formate en français.
 * @param {Date} date - L'objet Date à partir duquel extraire les informations.
 * @return {object} Un objet contenant le jour et l'abréviation du mois.
 */
export function extractDayAndMonth(date) {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    console.error('Invalid date object provided:', date);
    return { day: '', month: '' }; // Retourne des valeurs par défaut pour une date invalide
  }

  const day = date.getDate(); // Obtient le jour du mois
  const formatter = new Intl.DateTimeFormat('fr-FR', { month: 'short' });
  const month = formatter.format(date).replace(/\./g, ''); // Enlève les points après l'abréviation si présents

  return { day, month };
}

export function extractDayMonthAndYearFormTimestamp(timestamp) {
  if (!timestamp) return { day: '', month: '', year: '' }; // on vérifie si timestamp est défini sinon on renvoie des strings vides
  // Créez un objet Date à partir des secondes
  const date = new Date(timestamp.seconds * 1000); // Multipliez par 1000 car Date() attend des millisecondes
  const day = date.getDate(); // Obtient le jour du mois
  const year = date.getFullYear(); // Obtient l'année

  // Obtient l'abréviation du mois en français
  const formatter = new Intl.DateTimeFormat('fr-FR', { month: 'short' });
  const month = formatter.format(date).replace(/\./g, ''); // Enlève les points après l'abréviation si présents

  return { day, month, year };
}

/**
 * Formate une date en français avec options pour inclure le jour de la semaine et l'heure, et retourne un objet.
 * @param {Date} date - La date à formater.
 * @param {boolean} includeWeekDay - Inclut le jour de la semaine dans le formatage si true.
 * @param {boolean} includeTime - Inclut l'heure dans le formatage si true.
 * @param {boolean} shortWeekDay - Utilise le format court pour le jour de la semaine si true, sinon long.
 * @return {object} Un objet avec les éléments de la date formatée séparément.
 */
export function getDateDetailsFromEventDetailSchedulerData(
  schedulerData,
  includeWeekDay = true,
  includeTime = true,
  shortWeekDay = true
) {
  if (!schedulerData) return;

  const startDate = toDate(schedulerData.start); // Convertir Timestamp en Date
  const endDate = schedulerData.end ? toDate(schedulerData.end) : null; // Convertir si end existe

  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  if (includeWeekDay) {
    options.weekday = shortWeekDay ? 'short' : 'long'; // 'short' pour "lun.", 'long' pour "lundi"
  }

  if (includeTime) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.second = 'numeric';
  }

  const formatter = new Intl.DateTimeFormat('fr-FR', options);
  const startParts = formatter.formatToParts(startDate).reduce((acc, part) => {
    if (part.type !== 'literal') {
      acc[part.type] = part.value;
    }
    return acc;
  }, {});

  let slot = '';
  if (endDate && includeTime) {
    // Formater les heures de début et de fin si endDate existe
    const timeFormatter = new Intl.DateTimeFormat('fr-FR', {
      hour: '2-digit',
      minute: '2-digit',
    });
    const startTime = timeFormatter.format(startDate);
    const endTime = timeFormatter.format(endDate);
    slot = `${startTime} - ${endTime}`;
  }

  // Mettre la première lettre de chaque partie en majuscule
  Object.keys(startParts).forEach((key) => {
    startParts[key] =
      startParts[key][0].toUpperCase() + startParts[key].substring(1);
  });

  return { ...startParts, slot };
}

/**
 * Formate une date en français sans l'heure si spécifié.
 * @param {Date} date - La date à formater.
 * @param {boolean} includeTime - Inclut l'heure dans le formatage si true.
 * @return {string} La date formatée.
 */
export function formatDate(date, includeWeekDay = true, includeTime = true) {
  const options = {
    day: 'numeric', // "29"
    month: 'long', // "juin"
    year: 'numeric', // "2024"
  };
  if (includeWeekDay) {
    options.weekday = 'short'; // "sam." pour Samedi
  }

  if (includeTime) {
    options.hour = 'numeric'; // "14"
    options.minute = 'numeric'; // "00"
  }

  return new Intl.DateTimeFormat('fr-FR', options)
    .format(date)
    .replace(/\./g, '');
}

export function toDate(date) {
  // Check if the object is a Firebase Timestamp
  if (date && typeof date.toDate === 'function') {
    return date.toDate();
  } else if (date instanceof Date) {
    // It's already a JavaScript Date object
    return date;
  } else if (typeof date === 'string') {
    // If the date is a string, parse it as a date object
    return new Date(date);
  }
  return new Date(); // Default to current date if none of the above
}

/**
 *
 * @param {*} date
 * @returns  la date formatée mais juste les heures
 */
export function formatDateToGetOnlyTime(date) {
  const options = {
    hour: 'numeric', // "14"
    minute: 'numeric', // "00"
  };

  return new Intl.DateTimeFormat('fr-FR', options).format(date);
}
