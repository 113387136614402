/* ******************************************** */
/* * Author: Jamal SIDIKOU 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* * Updated at: 28/03/2024 
/* ******************************************* */

import React from 'react';
import CreateEventHomePage from '../../organisms/CreateEventHomePage/CreateEventHomePage';
import FeedbackList from '../../organisms/FeedbackList/FeedbackList';
import TopCategories from '../../organisms/TopCategories/TopCategories';
import TopEvents from '../../organisms/TopEvents/TopEvents';
import SpotLightHomePage from '../../organisms/SpotLightHomePage/SpotLightHomePage';
import SpotlightedEvents from '../../organisms/SpotlightedEvents/SpotlightedEvents';
import UpcomingEvents from '../../organisms/UpcomingEvents/UpcomingEvents';
import OurBlogHome from '../../organisms/OurBlogHome/OurBlogHome';
import WelcomeHomePage from '../../organisms/WelcomehomePage/WelcomeHomePage';
import PartnersSection from '../../organisms/PartnersSection/PartnersSection';
import styled from 'styled-components';

const HomePageBlocksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

function HomePage() {
  return (
    <HomePageBlocksContainer>
      <WelcomeHomePage />
      <TopCategories />
      <TopEvents />
      <SpotLightHomePage />
      <SpotlightedEvents />
      <UpcomingEvents />
      <CreateEventHomePage />
      <FeedbackList />
      <OurBlogHome />
      <PartnersSection />
    </HomePageBlocksContainer>
  );
}

export default HomePage;
