/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 19/05/2024
/* ******************************************* */

import React, { useState, useEffect, useRef } from 'react';
import { collection, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import {
  useDisclosure,
  useToast,
  Button,
  Text,
  VStack,
} from '@chakra-ui/react';
import EditBusinessAdvantagesForm from '../EditBusinessAdvantagesForm/EditBusinessAdvantagesForm';
import BusinessAdvantageItem from '../BusinessAdvantageItem/BusinessAdvantageItem';
import AlertPopup from '../../atoms/AlertPopup/AlertPopup';
import styled from 'styled-components';

const ManageAdvantageListContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const ManageAdvantageCard = styled.div`
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
`;

function ManageBusinessAdvantage() {
  const [advantages, setAdvantages] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  // Gère la suppresion et la modification de l'avantage
  const cancelRef = useRef();
  const [selectedAdvantage, setSelectedAdvantage] = useState(null);
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  function openDeleteDialog(advantage) {
    setSelectedAdvantage(advantage);
    onDeleteOpen();
  }

  function closeDeleteDialog() {
    setSelectedAdvantage(null);
    onDeleteClose();
  }

  async function deleteAdvantage() {
    if (!selectedAdvantage) return;
    try {
      const advantageRef = doc(
        firestore_db,
        'dev_mngt_plan_advantages',
        selectedAdvantage.id
      );
      await deleteDoc(advantageRef);
      toast({
        title: 'Avantage supprimé',
        description: "L'avantage a été supprimé avec succès.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur de suppresion ',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    onDeleteClose(); // Fermez le modal après la suppression
  }

  // Modification de la Advantage
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  function openEditDialog(advantage) {
    setSelectedAdvantage(advantage);
    onEditOpen(); // Ouvrir la popup d'édition
  }

  // recupère les données de firestore
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, 'dev_mngt_plan_advantages'),
      (snapshot) => {
        const advantageSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAdvantages(advantageSnapshot);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  if (loading) {
    console.log('Loading...');
  }
  console.log('advantages :', advantages);
  return (
    <VStack pt={2} borderTop="1px" borderColor="gray.200" mt={10} mb={2}>
      <Text fontSize="2xl" as="b" textAlign="left">
        {' '}
        Liste des avantages{' '}
      </Text>

      <ManageAdvantageListContainer>
        {advantages.map((advantage) => (
          <ManageAdvantageCard key={advantage.id}>
            {' '}
            <BusinessAdvantageItem currentAdvantage={advantage} />
            <Button
              position="absolute"
              right="0"
              top="0"
              size="xs"
              colorScheme="red"
              zIndex="1"
              onClick={() => openDeleteDialog(advantage)}
            >
              &#10005; {/* Croix */}
            </Button>
            <Button
              position="absolute"
              right={0}
              bottom={0}
              size="sm"
              colorScheme="blue"
              px={3}
              onClick={() => openEditDialog(advantage)}
            >
              Modifier
            </Button>
          </ManageAdvantageCard>
        ))}
        {isDeleteOpen && (
          <AlertPopup
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={closeDeleteDialog}
            onNo={closeDeleteDialog}
            onYes={deleteAdvantage}
            windowTitle="Supprimer l'avantage"
          >
            Êtes-vous sûr de vouloir supprimer l'avantage : "
            {selectedAdvantage?.description}" ?
          </AlertPopup>
        )}
        {isEditOpen && (
          <EditBusinessAdvantagesForm
            advantage={selectedAdvantage}
            isOpen={isEditOpen}
            onClose={onEditClose}
          />
        )}
      </ManageAdvantageListContainer>
    </VStack>
  );
}

export default ManageBusinessAdvantage;
