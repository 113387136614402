/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 21/05/2024
/* ******************************************* */

import React from 'react';
import EventItem from '../../molecules/EventItem/EventItem';
import styled from 'styled-components';
import { sortEventsByNearestStartDate } from '../../../utils/event';

const EventsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SectionTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
`;

const EventItemListContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 768px) {
    justify-content: space-around;
  }
`;

const EventCard = styled.li`
  box-shadow: 0 5px 15px rgba(0, 0, 255, 0.1);
`;

function EventsListComponent({ events, showTitle }) {
  const sortedEvents = sortEventsByNearestStartDate(events);

  return (
    <EventsListContainer>
      {showTitle && <SectionTitle>Au Programme</SectionTitle>}
      <EventItemListContainer>
        {sortedEvents.map((event) => {
          return (
            <EventCard>
              <EventItem id={event.id} currentEvent={event} />
            </EventCard>
          );
        })}
      </EventItemListContainer>
    </EventsListContainer>
  );
}

export default EventsListComponent;
