/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import { useState } from 'react';
import { Flex, Heading, Button, Link, Text } from '@chakra-ui/react';
import MultipleImageBox from '../../atoms/MultipleImageBox/MultipleImageBox';
import { useAuth } from '../../../contexts/AuthContext';
import FormField from '../../atoms/FormField/FormField';
import { doCreateUserWithEmailAndPassword } from '../../../api/firebase/auth';
import { useToast } from '@chakra-ui/react';
import { USER_SEX, USER_PROFESSION } from '../../../utils/constants';

import styled from 'styled-components';
import RedirectComponent from '../../atoms/RedirectComponent/RedirectComponent';
import { ROUTES } from '../../../utils/variables';

const CreateAccountFormContainer = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 0;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  border-radius: 10px;
  overflow: hidden;
  @media screen and (max-width: 930px) {
    display: inline;
    width: 100%;
  }
`;

const CreateAccountFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  height: '500px';
  padding: 20px;
`;

const FormFields = styled.div``;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

function CreateAccountForm() {
  const toast = useToast();
  const [fname, setFname] = useState(null);
  const [lname, setLname] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [pwd, setPwd] = useState(null);
  const [confirmedPwd, setConfirmedPwd] = useState(null);
  const [idFile, setIdFile] = useState(null);
  const [sex, setSex] = useState(null);
  const [profession, setProfession] = useState(null);
  const [birthdate, setBirthdate] = useState(null);

  const { userLoggedIn } = useAuth();

  const handleSignUp = async (e) => {
    e.preventDefault();

    try {
      const result = await doCreateUserWithEmailAndPassword(
        fname,
        lname,
        phone,
        email,
        pwd,
        sex,
        profession,
        birthdate,
        confirmedPwd,
        idFile
      );
      if (result.success) {
        toast({
          title: 'Succès',
          description: 'Compte créé avec succès.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Erreur lors de la création du compte',
          description: result.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Gérer l'erreur de connexion ici
      toast({
        title: 'Erreur lors de la création du compte',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBirthdateChange = (value) => {
    setBirthdate(value);
  };

  const handleSexChange = (e) => {
    const selectedSex = Number(e.target.value);
    setSex(selectedSex);
  };

  const handleProfessionChange = (e) => {
    const selectedProfession = Number(e.target.value);
    setProfession(selectedProfession);
  };

  return (
    <>
      {userLoggedIn && <RedirectComponent to={'/'} replace={true} />}

      <CreateAccountFormContainer>
        <MultipleImageBox />
        <CreateAccountFormContent>
          <Heading>Créer un compte</Heading>
          <FormFields>
            <FormFieldsGrid>
              <FormField
                id="first-name"
                label="Prénom"
                inputType="text"
                placeholder="Prénom"
                onChange={(e) => setFname(e.target.value)}
                isRequired
              />
              <FormField
                id="last-name"
                label="Nom"
                inputType="text"
                placeholder="Nom"
                onChange={(e) => setLname(e.target.value)}
                isRequired
              />
            </FormFieldsGrid>
            <FormFieldsGrid>
              <FormField
                id="birthdate"
                label="Date de naissance"
                inputType="date"
                updateDate={(value) => handleBirthdateChange(value)}
                isRequired
              />
              <FormFieldsGrid>
                <FormField
                  id="sex"
                  label="Sexe"
                  value={sex}
                  inputType="select"
                  onChange={handleSexChange}
                  isRequired
                >
                  <option value="">Sélectionnez votre sexe</option>
                  <option value={Number(USER_SEX.MALE)}>Masculin</option>
                  <option value={Number(USER_SEX.FEMALE)}>Féminin</option>
                </FormField>
                <FormField
                  id="profession"
                  label="Profession"
                  value={profession}
                  inputType="select"
                  onChange={handleProfessionChange}
                  isRequired
                >
                  <option value="">Sélectionnez votre profession</option>

                  <option value={Number(USER_PROFESSION.SCHOOLBOY)}>
                    Élève
                  </option>
                  <option value={Number(USER_PROFESSION.STUDENT)}>
                    Étudiant
                  </option>
                  <option value={Number(USER_PROFESSION.EMPLOYEE)}>
                    Travailleur
                  </option>
                  <option value={Number(USER_PROFESSION.OTHER)}>Autre</option>
                </FormField>
              </FormFieldsGrid>
            </FormFieldsGrid>
            <FormFieldsGrid>
              <FormField
                id="phone-number"
                label="Numéro de téléphone"
                inputType="tel"
                placeholder="Numéro de téléphone"
                onChange={(e) => setPhone(e.target.value)}
                isRequired
              />
              <FormField
                id="mail"
                label="Adresse email"
                inputType="email"
                placeholder="Adresse email"
                onChange={(e) => setEmail(e.target.value)}
                isRequired
              />
            </FormFieldsGrid>
            <FormFieldsGrid>
              <FormField
                id="password"
                label="Mot de passe"
                inputType="password"
                placeholder="Mot de passe"
                onChange={(e) => setPwd(e.target.value)}
                isRequired
              />
              <FormField
                id="confirm-password"
                label="Confirmer le mot de passe"
                inputType="password"
                placeholder="Confirmer le mot de passe"
                onChange={(e) => setConfirmedPwd(e.target.value)}
                isRequired
              />
            </FormFieldsGrid>
          </FormFields>
          <Button
            width="full"
            mt={10}
            type="submit"
            colorScheme="orange"
            onClick={handleSignUp}
          >
            {' '}
            Créer un compte
          </Button>

          <Flex align="baseline" justify="center" mt={4}>
            <Text mr={2}>Vous avez déjà un compte ?</Text>
            <Link color="teal.500" href={ROUTES.loginPage}>
              Connexion
            </Link>
          </Flex>
        </CreateAccountFormContent>
      </CreateAccountFormContainer>
    </>
  );
}

export default CreateAccountForm;
