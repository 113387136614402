/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 16/05/2024
/* ******************************************* */

const initialState = {
  upcoming: [],
  past: [],
  currentTicket: null,
  loading: false,
  error: null,
};

export default function ticketsReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_TICKETS_START':
      return { ...state, loading: true };
    case 'FETCH_TICKETS_SUCCESS':
      return { ...state, ...action.payload, loading: false };
    case 'FETCH_TICKETS_ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'SET_CURRENT_TICKET':
      return { ...state, currentTicket: action.payload };
    default:
      return state;
  }
}
