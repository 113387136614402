/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import styled from 'styled-components';
import quote from '../../../assets/medias/feedbackQuote.png';
import { timestamp2DateString } from '../../../utils/date';
import sanbbizLogo from '../../../assets/sanbbizLogos/logo.png';

const FeedbackContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100px 410px;
  gap: 20px;
  width: 570px;
  padding: 20px;
  background-color: white;
  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 40px;
    width: 14px;
    height: 29px;
    background: url(${quote}) no-repeat;
    background-size: cover;
    z-index: 1;
  }

  &:before {
    right: 40px;
  }

  &:after {
    right: 20px;
  }
`;

const FeedbackContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
`;

const FeedbackCommentDetail = styled.div`
  width: 100%;
  text-align: left;
`;

const FeedbackUserImg = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
`;

const FeedbackStars = styled.div``;

const FilledStar = styled.span`
  color: gold;
  font-size: 20px;
`;

const EmptyStar = styled.span`
  color: grey;
  font-size: 20px;
`;

const FeedbackComment = styled.div`
  margin-top: 7px;
`;

const FeedbackUserDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const FeedbackUserName = styled.span`
  font-weight: bold;
`;
const FeedbackDate = styled.span`
  font-size: 1rem;
`;

const FeedbackItem = ({ currentFeedback }) => {
  // Fonction pour générer les étoiles de la note
  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, i) =>
      i < rating ? (
        <FilledStar key={i}>★</FilledStar>
      ) : (
        <EmptyStar key={i}>★</EmptyStar>
      )
    );
  };

  return (
    <FeedbackContainer>
      <FeedbackUserImg
        src={currentFeedback?.writerPicture?.url || sanbbizLogo}
        alt="Photo utilisateur"
      />
      <FeedbackContent>
        <FeedbackCommentDetail>
          <FeedbackStars>{renderStars(currentFeedback.rating)}</FeedbackStars>
          <FeedbackComment>{currentFeedback.comment}</FeedbackComment>
        </FeedbackCommentDetail>
        <FeedbackUserDetail>
          <FeedbackUserName>{currentFeedback.writerName}</FeedbackUserName>
          <FeedbackDate>
            {timestamp2DateString(currentFeedback.createdAt)}
          </FeedbackDate>
        </FeedbackUserDetail>
      </FeedbackContent>
    </FeedbackContainer>
  );
};

export default FeedbackItem;
