/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@chakra-ui/react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config'; // Assurez-vous que le chemin d'importation est correct

import CaurisIcon from '../../../assets/medias/money/coris.png';
import { TABLE_NAME } from '../../../utils/variables';

const ReloadCaurisComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const CaurisContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
`;

const CaurisAmontContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
`;

const CaurisAmontText = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: #051145;
`;

const IconContainer = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
`;
const Image = styled.img`
  width: auto;
  height: 32px;
  object-fit: cover;
`;

const EquiXOFAmontText = styled.span`
  font-size: 0.8rem;
  color: #051145;
`;

function ReloadCauris({ currentUserId }) {
  const [balanceInfo, setBalanceInfo] = useState(null);

  useEffect(() => {
    const loadUserBalance = async () => {
      try {
        const q = query(
          collection(firestore_db, TABLE_NAME.balances),
          where('customerId', '==', currentUserId)
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            setBalanceInfo(doc.data());
          });
        } else {
          setBalanceInfo({ amount: 0 });
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des balances:', error);
      }
    };

    if (currentUserId) {
      loadUserBalance();
    }
  }, [currentUserId]);

  // Calcul de nbCauris avec vérification conditionnelle
  const nbCauris =
    balanceInfo && balanceInfo.amount != null ? balanceInfo.amount / 10 : 0;

  return (
    <ReloadCaurisComponent>
      <CaurisContainer>
        <CaurisAmontContainer>
          <IconContainer>
            <CaurisAmontText>{nbCauris} </CaurisAmontText>{' '}
            <Image src={CaurisIcon} alt="Icône du Cauris" />
          </IconContainer>
          <EquiXOFAmontText>
            {balanceInfo && balanceInfo.amount != null
              ? 'Equivaut à ' + balanceInfo.amount + ' F CFA'
              : 'Utilisez les Cauris pour acheter des tickets sur Sanbbiz'}
          </EquiXOFAmontText>
        </CaurisAmontContainer>
        <Button size="md" bgColor="#051145" color="white" onClick={() => {}}>
          Recharger
        </Button>
      </CaurisContainer>
    </ReloadCaurisComponent>
  );
}

export default ReloadCauris;
