/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/05/2024
/* ******************************************* */

import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';
import { FaRegCopy } from 'react-icons/fa';

function CopyLinkComponent({ eventId }) {
  const toast = useToast();
  const copyToClipboard = () => {
    const url = `${window.location.origin}/events/${eventId}`;
    navigator.clipboard.writeText(url).then(() => {
      toast({
        title: 'Lien copié avec succès!',
        description: 'Lien copié dans votre presse-papier',
        status: 'success',
        duration: 1000,
        isClosable: true,
      });
    });
  };

  return (
    <InputGroup size="md">
      <Input value={`${window.location.origin}/events/${eventId}`} readOnly />
      <InputRightElement>
        <IconButton icon={<FaRegCopy />} size="md" onClick={copyToClipboard} />
      </InputRightElement>
    </InputGroup>
  );
}

export default CopyLinkComponent;
