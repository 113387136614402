/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 19/05/2024
/* ******************************************* */

import React from 'react';
import SidebarLayout from '../../templates/SidebarLayout/SidebarLayout';
import ManageBusinessPlanForms from '../../organisms/ManageBusinessPlanForms/ManageBusinessPlanForms';

function ManageBusinessPlanPage() {
  return (
    <SidebarLayout
      menuTitle={'Gestion des formules Sanbbiz'}
      menuRoutePath={'Gestion des formules client'}
    >
      <ManageBusinessPlanForms />
    </SidebarLayout>
  );
}

export default ManageBusinessPlanPage;
