/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 14/04/2024
/* ******************************************* */

import React, { useEffect, useState } from 'react';
import { Button, HStack, VStack, Box, Image, Flex } from '@chakra-ui/react';

// Composant pour l'uploader
function ImageUploader({
  id,
  onFilesSelected,
  onImageRemoved,
  buttonTextBefore,
  buttonTextAfter,
  imgWidth,
  imgHeight,
  multiple,
  defaultImageUrl, // prned un tableau
  ...props
}) {
  // Initialisation avec garantie que defaultImageUrl est un tableau
  const initialPreviews = Array.isArray(defaultImageUrl) ? defaultImageUrl : [];
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState(initialPreviews);

  const handleFilesChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const newImageUrls = selectedFiles.map((file) => URL.createObjectURL(file));

    if (!multiple) {
      setImagePreviews(newImageUrls);
      setImages(selectedFiles);
    } else {
      setImagePreviews((prev) => [...prev, ...newImageUrls]);
      setImages((prev) => [...prev, ...selectedFiles]);
    }

    if (onFilesSelected) {
      onFilesSelected(selectedFiles);
    }
  };

  const handleRemoveImage = (index) => {
    const filteredImages = images.filter((_, i) => i !== index);
    const filteredPreviews = imagePreviews.filter((_, i) => i !== index);
    setImagePreviews(filteredPreviews);
    setImages(filteredImages);

    if (onImageRemoved) {
      onImageRemoved(index);
    }
  };

  const handleFileInputClick = () => document.getElementById(id).click();

  return (
    <VStack spacing={4} align="left">
      <Button onClick={handleFileInputClick} colorScheme="blue">
        {imagePreviews.length > 0
          ? `${buttonTextAfter} `
          : `${buttonTextBefore}`}
      </Button>

      <input
        id={id}
        type="file"
        onChange={handleFilesChange}
        accept="image/*"
        hidden
        multiple={multiple}
        {...props}
      />
      <HStack align="center" justify="center" wrap="wrap">
        {imagePreviews.map((imagePreview, index) => (
          <Box
            key={`image-${index}`}
            position="relative"
            width={imgWidth}
            height={imgHeight}
            boxShadow="md"
            borderRadius="md"
            overflow="hidden"
          >
            <Image
              src={imagePreview}
              alt={`Aperçu ${index}`}
              objectFit="cover"
              boxSize="100%"
            />
            <Button
              position="absolute"
              right="0"
              top="0"
              size="xs"
              colorScheme="red"
              onClick={() => handleRemoveImage(index)} // Fonction pour supprimer l'image
            >
              &#10005; {/* Croix */}
            </Button>
            <Button
              position="absolute"
              right="0"
              bottom="0"
              size="xs"
              colorScheme="blue"
              onClick={() => {}}
            >
              ✂️ {/* Ciseaux pour indiquer le rognage */}
            </Button>
          </Box>
        ))}{' '}
      </HStack>
    </VStack>
  );
}

export default ImageUploader;
