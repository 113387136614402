/* ******************************************** */
/* * Author: Fabrice NIKIEMA 
/* * Project : SANNBIZ
/* * Create at : 01/05/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import HorizontalScrollList from '../../molecules/HorizontalScrollList/HorizontalScrollList';
import { sortCategoriesByEventsNumber } from '../../../utils/function';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { filterAcceptedEvents, filterFutureEvents } from '../../../utils/event';
import { TABLE_NAME } from '../../../utils/variables';

const CategoryCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 150px;
  padding: 5px;
  box-sizing: border-box;
`;

const CategoryContainer = styled.div`
  width: 100px;
  height: 100px;
  background-color: #ecf0f2;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 1px #1b7261;
  &:hover {
    box-shadow: 0 6px 10px rgba(5, 17, 69, 0.2);
    transform: translateY(-2px);
  }
`;

const CategoryIcon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  padding: 3px;
  transition: all 0.3s ease;
`;

const CategoryName = styled.p`
  padding-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #051145;
  text-align: center;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// Composant
function TopCategoriesListCard({ category }) {
  const navigate = useNavigate();
  const [clickStart, setClickStart] = useState(0);

  const redirectClick = (categoryId) => {
    const clickDuration = Date.now() - clickStart;
    if (clickDuration < 200) {
      navigate(`/events?category=${categoryId}`);
    }
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <CategoryCardContainer
      key={`${category.name}-${category.id}`}
      onMouseDown={() => setClickStart(Date.now())}
      onMouseUp={() => redirectClick(category.id)}
    >
      <CategoryContainer>
        <CategoryIcon
          src={category.icon?.url}
          alt={`Icon de la catégorie ${category.name}`}
        />
      </CategoryContainer>
      <CategoryName>{category.name}</CategoryName>
    </CategoryCardContainer>
  );
}

const TopCategoriesBLock = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 0 50px 0;
`;

const TopCategoriesBlockTitle = styled.h1`
  font-weight: bold;
  font-size: 32px;
  color: #051145;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopCategoryCardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  @media (max-width: 1228px) {
    justify-content: space-around;
  }
`;

function TopCategories() {
  const [categories, setCategories] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeCategories = onSnapshot(
      collection(firestore_db, TABLE_NAME.categories),
      (snapshot) => {
        const loadedCategories = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(loadedCategories);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    const unsubscribeEvents = onSnapshot(
      collection(firestore_db, TABLE_NAME.events),
      (snapshot) => {
        const loadedEvents = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(loadedEvents);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    return () => {
      unsubscribeCategories();
      unsubscribeEvents();
    };
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  // count only accepted futture events
  const onlyAcceptedFutureEvents = filterAcceptedEvents(
    filterFutureEvents(events)
  );

  const sortedCategories = sortCategoriesByEventsNumber(
    categories,
    onlyAcceptedFutureEvents
  ).slice(0, 8);

  return (
    <TopCategoriesBLock>
      <TopCategoriesBlockTitle> Meilleures catégories</TopCategoriesBlockTitle>
      <HorizontalScrollList>
        <TopCategoryCardsContainer>
          {sortedCategories.map((category) => (
            <TopCategoriesListCard key={`${category.id}`} category={category} />
          ))}
        </TopCategoryCardsContainer>
      </HorizontalScrollList>
    </TopCategoriesBLock>
  );
}

export default TopCategories;
