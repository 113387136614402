import React from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';

const Styles = styled.div`
  padding: 1rem;
`;

const TableComponent = styled.table`
  width: 100%;
  border-spacing: 0;
  //   border: 2px solid black;
`;

const TableHeader = styled.thead``;

const TableHeaderCell = styled.th`
  padding: 10px;
  background-color: lightgray;
  width: ${(props) => props.width || 'auto'};
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr``;

const TableCell = styled.td`
  width: ${(props) => props.width || 'auto'};
`;

function Array({
  columns,
  data,
  onRowClick,
  rowHeight = '200px',
  showHeader = true,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <Styles>
      <TableComponent {...getTableProps()}>
        {showHeader && (
          <TableHeader>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableHeaderCell
                    {...column.getHeaderProps({
                      style: { width: column.width },
                    })}
                  >
                    {column.render('Header')}
                  </TableHeaderCell>
                ))}
              </TableRow>
            ))}
          </TableHeader>
        )}
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              // Ajout du gestionnaire de clic ici
              <TableRow
                {...row.getRowProps({
                  style: { height: rowHeight },
                  onClick: () => onRowClick(row.original),
                })}
              >
                {row.cells.map((cell) => (
                  <TableCell
                    {...cell.getCellProps({
                      style: { width: cell.column.width },
                    })}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </TableComponent>
    </Styles>
  );
}

export default Array;
