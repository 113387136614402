/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 01/05/2024
/* ******************************************* */

import React, { useEffect, useState } from 'react';
import HorizontalScrollList from '../../molecules/HorizontalScrollList/HorizontalScrollList';
import BlogItem from '../../molecules/BlogItem/BlogItem';
import styled from 'styled-components';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import { TABLE_NAME } from '../../../utils/variables';

const BlogListBlock = styled.div`
  padding: 30px 20px 50px 20px;
`;

const OurBlogText = styled.span`
  display: block;
  text-align: center;
  color: red;
  font-size: 14px;
  margin-top: 40px;
`;

const BlogListTitle = styled.h1`
  display: block;
  text-align: center;
  color: #051145;
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const BlogListItemsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'space-around'};
  gap: 20px;
  padding-bottom: 20px;
`;

const BlogCard = styled.div`
  box-shadow: 0 5px 15px rgba(0, 0, 255, 0.1);
`;

function OurBlogHome() {
  // Obtenir les blog
  const [blogs, setBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeBlogs = onSnapshot(
      collection(firestore_db, TABLE_NAME.blogs),
      (snapshot) => {
        setBlogs(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
        setLoading(false);
      }
    );

    return () => {
      unsubscribeBlogs();
    };
  }, []);

  if (loading) {
    // console.log('Loading...');
  }

  const justifyContentStyle = blogs.length > 3 ? 'space-around' : 'center';

  return (
    <BlogListBlock>
      <OurBlogText>Notre Blog</OurBlogText>
      <BlogListTitle>Nos Dernières Annonces & Articles</BlogListTitle>
      <HorizontalScrollList>
        <BlogListItemsContainer justifyContent={justifyContentStyle}>
          {blogs.map((blog) => (
            <BlogCard key={blog.id}>
              <BlogItem currentBlog={blog} />
            </BlogCard>
          ))}
        </BlogListItemsContainer>
      </HorizontalScrollList>
    </BlogListBlock>
  );
}

export default OurBlogHome;
