/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 08/05/2024
/* ******************************************* */

import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore_db } from '../api/firebase/firebase-config';
import { look4NearestEventSlot } from './function';
import { checkIfIsPastDate } from './date';
import { EVENT_REVIEW_STATE } from './constants';
import { TABLE_NAME } from '../utils/variables';

/**
 * Filtre les événements par l'identifiant de leur créateur.
 *
 * @param {Array} events Liste des événements à filtrer.
 * @param {string} createdById Identifiant du créateur des événements à filtrer.
 * @returns {Array} Liste des événements créés par l'identifiant spécifié.
 */
export function filterEventsByCreator(events, createdById) {
  if (!events || !createdById) {
    console.error('Arguments invalides fournis à filterEventsByCreator:', {
      events,
      createdById,
    });
    return [];
  }

  // Filtrage des événements où le champ `createdBy` correspond à `createdById`
  const filteredEvents = events.filter(
    (event) => event.createdBy === createdById
  );

  return filteredEvents;
}

/**
 * Hook pour trouver les événements créés par l'utilisateur actuel.
 * @param {Array} allEvents - Liste de tous les événements.
 * @returns {Array} - Liste des événements créés par l'utilisateur actuel.
 */
export function useFindCurrentUserEvents(allEvents) {
  const [currentUserEvents, setCurrentUserEvents] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && allEvents.length > 0) {
      const createdBy = currentUser.authProfile.uid;
      if (createdBy) {
        const filteredEvents = filterEventsByCreator(allEvents, createdBy);
        setCurrentUserEvents(filteredEvents);
      }
    }
  }, [currentUser, allEvents]);

  return currentUserEvents;
}

/**
 * Calcule le nombre total de places disponibles pour un événement spécifique.
 *
 * @param {Object} event Un objet événement qui inclut un champ `ticketVersions`.
 * @returns {number} Le nombre total de places disponibles pour l'événement.
 */
export function getTotalAvailableTickets(event) {
  if (!event || !event.ticketVersions || !Array.isArray(event.ticketVersions)) {
    console.error("Données d'événement invalides fournies:", event);
    return 0;
  }

  // Accumuler le total des places disponibles en itérant sur chaque version de ticket
  const totalAvailable = event.ticketVersions.reduce((total, version) => {
    return total + (version?.stock || 0);
  }, 0);

  return totalAvailable;
}

/**
 * Calcule le nombre de tickets vendus pour un événement spécifique.
 *
 * @param {Object} event Un objet événement qui inclut l'ID de l'événement.
 * @returns {Promise<number>} Le nombre de tickets vendus pour l'événement.
 */
export async function getTotalTicketsSoldForEvent(event) {
  if (!event || !event.id) {
    console.error("Données d'événement invalides fournies:", event);
    return 0;
  }

  const ticketsCollection = collection(firestore_db, TABLE_NAME.tickets);
  const ticketsQuery = query(
    ticketsCollection,
    where('eventId', '==', event.id)
  );

  try {
    const querySnapshot = await getDocs(ticketsQuery);
    const totalTicketsSold = querySnapshot.docs.length;
    return totalTicketsSold;
  } catch (error) {
    console.error('Erreur lors de la récupération des tickets:', error);
    return 0; // Retourner 0 en cas d'erreur
  }
}

/**
 * Calcule le nombre total de tickets vendus pour une liste d'événements en gérant la pagination des requêtes.
 *
 * @param {Array} events Liste des événements pour lesquels compter les tickets vendus.
 * @returns {Promise<number>} Le nombre total de tickets vendus pour les événements donnés.
 */
export async function getTotalTicketsSoldForEventGroup(events) {
  if (!events || events.length === 0) {
    console.log('Aucun événement fourni pour calculer les tickets vendus.');
    return 0;
  }

  const MAX_QUERY_SIZE = 30; // Limite Firestore pour la clause 'in'
  let totalTicketsSold = 0;

  // Découper les ID des événements en morceaux de 10
  for (let i = 0; i < events.length; i += MAX_QUERY_SIZE) {
    const eventIdsChunk = events
      .slice(i, i + MAX_QUERY_SIZE)
      .map((event) => event.id);
    const ticketsCollection = collection(firestore_db, TABLE_NAME.tickets);
    const ticketsQuery = query(
      ticketsCollection,
      where('eventId', 'in', eventIdsChunk)
    );

    try {
      const querySnapshot = await getDocs(ticketsQuery);
      totalTicketsSold += querySnapshot.docs.length; // Accumuler les tickets vendus
    } catch (error) {
      console.error(
        'Erreur lors de la récupération des tickets pour le chunk:',
        error
      );
      // Vous pouvez décider de continuer avec les autres chunks ou de retourner 0/erreur
    }
  }

  return totalTicketsSold;
}

/**
 * Filtre les événements pour retourner ceux aimés par l'utilisateur courant.
 *
 * @param {Array} events Liste des événements à filtrer.
 * @param {Object} currentUser Objet représentant l'utilisateur courant.
 * @returns {Array} Liste des événements aimés par l'utilisateur courant.
 */
export function filterEventsLikedByCurrentUser(events, currentUser) {
  if (!events || events.length === 0) {
    console.error('Aucun événement fourni pour filtrer.');
    return [];
  }

  if (
    !currentUser ||
    !currentUser.authProfile ||
    !currentUser.authProfile.uid
  ) {
    console.error('Utilisateur courant non valide ou non connecté.');
    return [];
  }

  // Filtrer les événements où l'ID de l'utilisateur courant est dans le tableau `likedBy`
  const filteredEvents = events.filter(
    (event) =>
      event.likedBy && event.likedBy.includes(currentUser.authProfile.uid)
  );

  return filteredEvents;
}

/**
 * Vérifie si un événement donné se produit dans le futur.
 *
 * @param {object} event - L'événement à vérifier, qui doit contenir un champ `dates` avec des objets date.
 * @returns {boolean} - Retourne `true` si l'événement a au moins une date de fin dans le futur, sinon `false`.
 */
export function getOnlyFutureEvent(event) {
  // Trouver l'index du créneau le plus proche dans le futur
  const slotIndex = look4NearestEventSlot(event);

  // Vérifier si la date trouvée est dans le futur
  if (slotIndex !== undefined) {
    const nearestEndDate = event.dates[slotIndex].end.toDate();
    return !checkIfIsPastDate(1, nearestEndDate);
  }

  // Retourne false si aucun créneau n'est trouvé ou si toutes les dates sont passées
  return false;
}

/**
 * Filtre une liste d'événements pour retourner uniquement ceux qui ont lieu dans le futur.
 *
 * @param {Array} events - La liste des événements à filtrer. Chaque événement doit contenir un champ `dates` avec des objets date.
 * @returns {Array} - Retourne une liste des événements dont au moins une date de fin est dans le futur.
 */
export function filterFutureEvents(events) {
  return events.filter((event) => getOnlyFutureEvent(event));
}

/**
 * Filtre et retourne les événements dont l'état de révision est accepté.
 *
 * @param {Array} events - La liste des événements à filtrer.
 * @returns {Array} Les événements filtrés dont l'état de révision est accepté.
 */
export function filterAcceptedEvents(events) {
  return events.filter(
    (event) => event.review.state === EVENT_REVIEW_STATE.ACCEPTED
  );
}

/**
 * Trouve la date de début la plus proche dans le futur pour un événement donné.
 * @param {Object} event - Événement avec un tableau `dates` contenant des objets `{start, end}`.
 * @returns {Date | null} - La date de début la plus proche dans le futur, ou `null` si aucune n'est trouvée.
 */
export function getNearestFutureStartDate(event) {
  if (!event.dates || !Array.isArray(event.dates)) return null;

  const now = new Date();
  const futureDates = event.dates
    .map((date) => date.start.toDate())
    .filter((startDate) => startDate >= now);

  if (futureDates.length === 0) return null;

  return futureDates.reduce((a, b) => (a < b ? a : b));
}

/**
 * Trie une liste d'événements en fonction de leur prochaine date de début.
 * @param {Array} events - Liste des événements à trier.
 * @returns {Array} - Liste des événements triés par la date de début la plus proche.
 */
export function sortEventsByNearestStartDate(events) {
  return events
    .map((event) => ({
      ...event,
      nearestStartDate: getNearestFutureStartDate(event),
    }))
    .filter((event) => event.nearestStartDate !== null) // Filtrer les événements sans date future
    .sort((a, b) => a.nearestStartDate - b.nearestStartDate);
}
