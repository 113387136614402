/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import { useEffect, useState } from 'react';
import { firestore_db } from '../../../api/firebase/firebase-config';
import { Button, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { getDoc, updateDoc, doc, arrayUnion } from 'firebase/firestore';

import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { IoIosArrowRoundBack } from 'react-icons/io';
import FormField from '../../atoms/FormField/FormField';
import { useAuth } from '../../../contexts/AuthContext';
import { ROUTES, TABLE_NAME } from '../../../utils/variables';

const BlogDetailPageContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  @media screen and (max-width: 1300px) {
    width: 100%;
  }
`;

const PreviousPage = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 0 10px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

const BlogDetailContent = styled.div`
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  padding: 10px;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const CoverPictureContainer = styled.div`
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BlogCover = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
  border-radius: 20px;
`;

const BlogTitle = styled.h1`
  font-weight: bold;
  font-size: 3rem;
  text-align: left;
`;

const BlogSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
`;

const BlogSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  padding: 10px 30px;
  text-align: left;
`;

const BlogSectionTitle = styled.h2`
  font-weight: bold;
  font-size: 1.8rem;
`;

const BlogCommentSection = styled.div`
  position: relative;
  padding: 30px 0px;
  background-color: #ecf0f2;
  width: 100%;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  padding: 10px;
  border-radius: 20px;
`;

const BlogCommentSectionTitle = styled.h1`
  font-weight: bold;
  font-size: 32px;
  color: #051145;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BlogSectionContent = styled.p`
  white-space: pre-wrap;
  line-height: 20px;
`;

const BlogCommentList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const CommentCard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 70px 1fr;
  gap: 20px;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
`;

const CommentWriterPicture = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 50%;
  margin: 5px;
  height: 60px;
  object-fit: cover;
`;

const CommentDetails = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

const CommentWriterName = styled.h4``;

const CommentWriterComment = styled.p`
  margin-right: 70px;
`;
const CommentDate = styled.span`
  position: absolute;
  right: 10px;
  bottom: 50%;
  transform: translateY(50%);
`;

const AddCommentContainer = styled.div`
  margin-top: 30px;
  background-color: white;
  padding: 20px;
  padding-top: 0px;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
`;

function BlogDetail({ setCurrentBlog }) {
  const toast = useToast();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const { blogId } = useParams();
  const [blogDetail, setBlogDetail] = useState(null);
  const [comment, setComment] = useState('');

  const enrichCommentsWithUserDetails = async (comments) => {
    const userInfosPromises = comments.map(async (comment) => {
      const userRef = doc(firestore_db, 'users', comment.userId);
      const userSnap = await getDoc(userRef);
      return userSnap.exists()
        ? { ...comment, user: userSnap.data() }
        : comment;
    });

    return Promise.all(userInfosPromises);
  };

  useEffect(() => {
    const blogRef = doc(firestore_db, TABLE_NAME.blogs, blogId);
    getDoc(blogRef)
      .then(async (docSnap) => {
        if (docSnap.exists()) {
          const blogData = { id: docSnap.id, ...docSnap.data() };
          if (blogData.comments && blogData.comments.length > 0) {
            blogData.comments = await enrichCommentsWithUserDetails(
              blogData.comments
            );
          }
          setBlogDetail(blogData);
          setCurrentBlog(blogData);
        } else {
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
      });
  }, [blogId, setCurrentBlog, blogDetail?.comments]);

  // Condition pour vérifier si les données sont en train de charger ou si elles ne sont pas disponibles
  if (!blogDetail) {
    return <div>Chargement des détails du blog...</div>;
  } else if (!blogDetail.blogSections || blogDetail.blogSections.length === 0) {
    return <div>Aucune section de contenu disponible pour ce blog.</div>;
  }

  const handleAddComment = async () => {
    if (!currentUser) {
      navigate(ROUTES.loginPage);
      return;
    }

    if (!comment.trim()) {
      toast({
        title: 'Ajout refusé',
        description: 'Le commentaire ne peut pas être vide.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const newComment = {
      description: comment,
      userId: currentUser.authProfile.uid,
      writeAt: new Date(),
    };

    try {
      const blogRef = doc(firestore_db, TABLE_NAME.blogs, blogId);
      await updateDoc(blogRef, {
        comments: arrayUnion(newComment),
      });
      setComment('');

      toast({
        title: 'Succès',
        description: 'Commentaire ajouté avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de l'ajout du commentaire:", error);
      toast({
        title: 'Erreur',
        description: "Erreur lors de l'ajout du commentaire.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBackClick = () => {
    navigate(ROUTES.blogListPage);
    window.scrollTo(0, 0);
  };

  return (
    <BlogDetailPageContainer>
      <PreviousPage onClick={handleBackClick}>
        <IoIosArrowRoundBack size={'32px'} />
        Retour aux Blogs
      </PreviousPage>
      <BlogDetailContent>
        <CoverPictureContainer>
          <BlogCover
            src={blogDetail?.coverPicture?.url}
            alt="Couverture du blog"
          />
          <BlogTitle>{blogDetail.title}</BlogTitle>
        </CoverPictureContainer>

        <BlogSectionsContainer>
          {blogDetail.blogSections && blogDetail.blogSections.length > 0 ? (
            blogDetail.blogSections.map((section, index) => (
              <BlogSection key={index}>
                <BlogSectionTitle>{section.sectionTitle}</BlogSectionTitle>
                <BlogSectionContent>{section.sectionNote}</BlogSectionContent>
              </BlogSection>
            ))
          ) : (
            <p>Pas de contenu de section à afficher.</p>
          )}
        </BlogSectionsContainer>
      </BlogDetailContent>

      <BlogCommentSection>
        <BlogCommentSectionTitle>Commentaires</BlogCommentSectionTitle>
        <BlogCommentList>
          {blogDetail.comments && blogDetail.comments.length > 0 ? (
            blogDetail.comments.map((comment, index) => (
              <CommentCard key={index}>
                <CommentWriterPicture>
                  <Image
                    src={comment?.user?.profilePicture?.url || null}
                    alt="Photo de Profil de l'utilisateur"
                  />
                </CommentWriterPicture>
                <CommentDetails>
                  <CommentWriterName>
                    {comment?.user?.fname + ' ' + comment?.user?.lname}
                  </CommentWriterName>
                  <CommentWriterComment>
                    {comment?.description}
                  </CommentWriterComment>
                  <CommentDate>
                    {new Date(
                      comment?.writeAt?.seconds * 1000
                    ).toLocaleDateString()}
                  </CommentDate>
                </CommentDetails>
              </CommentCard>
            ))
          ) : (
            <p>Aucun commentaire</p>
          )}
        </BlogCommentList>
        <AddCommentContainer>
          <FormField
            id="comment"
            label="Votre Commentaire"
            inputType="textarea"
            placeholder="Entrez votre commentaire"
            height="100px"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            isRequired
          />
          <Button
            size="sm"
            mt={4}
            colorScheme="blue"
            onClick={handleAddComment}
          >
            Ajouter le commentaire
          </Button>
        </AddCommentContainer>
      </BlogCommentSection>
    </BlogDetailPageContainer>
  );
}

export default BlogDetail;
