/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import FeedbackItem from '../../molecules/FeedbackItem/FeedbackItem';
import HorizontalScrollList from '../../molecules/HorizontalScrollList/HorizontalScrollList';
import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const FeedbackListBlock = styled.div`
  background-color: #ecf0f2;
  padding: 30px 20px 50px 20px;
`;

const TestimonialText = styled.span`
  display: block;
  text-align: center;
  color: red;
  font-size: 14px;
  margin-top: 40px;
`;

const FeedbackListTitle = styled.h1`
  display: block;
  text-align: center;
  color: #051145;
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const FeedbackListItemsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 20px;
`;

function FeedbackList() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, TABLE_NAME.customerFeedbacks),
      (snapshot) => {
        const feedbacksSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFeedbacks(feedbacksSnapshot);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  if (loading) {
    // console.log('Loading...');
  }

  return (
    <FeedbackListBlock>
      <TestimonialText>Nos Témoignages</TestimonialText>
      <FeedbackListTitle>
        Le retour d'expérience de nos clients
      </FeedbackListTitle>
      <HorizontalScrollList>
        <FeedbackListItemsContainer>
          {feedbacks.map((feedback) => (
            <FeedbackItem currentFeedback={feedback} />
          ))}
        </FeedbackListItemsContainer>
      </HorizontalScrollList>
    </FeedbackListBlock>
  );
}

export default FeedbackList;
