/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import EventList from '../../organisms/EventList/EventList';
import event_page_banner from '../../../assets/medias/banner/PageBanner4.png';

function EventsPage() {
  const title = 'Événements'; // Le titre de la page

  return (
    <>
      <PageLayout title={title} backgroundImage={event_page_banner}>
        <EventList />
      </PageLayout>
    </>
  );
}

export default EventsPage;
