/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 29/05/2024
/* ******************************************* */

import React from 'react';
import DeleteAccountForm from '../../molecules/DeleteAccountForm/DeleteAccountForm';
import PageLayout from '../../templates/PageLayout/PageLayout';

function CreateAccountPage() {
  const title = 'Supprimer votre compte'; // Le titre de la page

  return (
    <>
      <PageLayout title={title}>
        <DeleteAccountForm />
      </PageLayout>
    </>
  );
}

export default CreateAccountPage;
