/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 08/05/2024
/* ******************************************* */

import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Button, useToast } from '@chakra-ui/react';

import { timestampToDateOnlyString } from '../../../utils/date';
import { useAuth } from '../../../contexts/AuthContext';
import FormField from '../../atoms/FormField/FormField';
import { USER_SEX, USER_PROFESSION } from '../../../utils/constants';
import EditUserProfileForm from '../../molecules/EditUserProfileForm/EditUserProfileForm';
import { useDisclosure } from '@chakra-ui/react';

import defaultUserProfile from '../../../assets/medias/defaultUserProfilPicture.jpg';
import ReloadCauris from '../../molecules/ReloadCauris/ReloadCauris';
import { filterEventsLikedByCurrentUser } from '../../../utils/event';
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import Array from '../../atoms/Array/Array';
import { useNavigate } from 'react-router-dom';
import {
  DateCell,
  EventDetails,
  EventPoster,
} from '../../atoms/DashboardEventSections/DashboardEventSections';
import { FaCalendarAlt, FaHeart, FaShareAlt } from 'react-icons/fa';
import { getEventDateRange } from '../../../utils/function';
import Popup from '../../atoms/Popup/Popup';
import CopyLinkComponent from '../../atoms/CopyLinkComponent/CopyLinkComponent';
import { ROUTES, TABLE_NAME } from '../../../utils/variables';

const EventDetailPageContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  @media screen and (max-width: 1300px) {
    width: 100%;
  }
`;

const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  @media screen and (max-width: 1300px) {
    width: 100%;
  }
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  padding: 10px;
  border-radius: 20px;
`;

const SectionTitle = styled.h1`
  font-weight: bold;
  font-size: 32px;
  color: #051145;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserProfileDetails = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 10px 5px;
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  aling-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 255, 0.05);
`;

const UserProfileBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
`;

const ProfilPictureContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 10px;
  background-color: #ecf0f2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const ProfilImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ActionButton = styled.button`
  background-color: rgb(235, 233, 233);
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  margin-left: 10px;
  color: ${(props) => (props.isLiked ? '#f1360a' : 'initial')};

  &:hover {
    color: #f1360a;
  }
`;

const UserInfos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
`;

const UserName = styled.h2`
  font-size: 26px;
  font-weight: bold;
`;
const NbSubscriber = styled.span`
  font-size: 20px;
  color: gray;
  span {
    color: #f1360a;
    font-weight: bold;
  }
`;

const UserProfileInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 255, 0.05);
`;

const FormFields = styled.div``;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;
const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 255, 0.05);
  background-color: #ecf0f2;
`;
const NoLikedEventMessageText = styled.div`
  font-style: italic;
  font-size: 20px;
  padding-top: 10px;
`;

const LikedEventsContainer = styled.div`
  width: 100%;
`;

const EventDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

function ProfileCustomer() {
  const toast = useToast();
  const navigate = useNavigate();
  const { userLoggedIn, currentUser, reloadUser } = useAuth();

  const {
    isOpen: isEditProfileOpen,
    onOpen: onEditProfileOpen,
    onClose: onEditProfileClose,
  } = useDisclosure();
  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure();

  const [isLiked, setIsLiked] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [events, setEvents] = useState([]);
  const [likedEvents, setLikedEvents] = useState([]);

  useEffect(() => {
    // Vérifier si la liste des événements likés contient des éléments
    setIsLiked(likedEvents.length > 0);
  }, [likedEvents]);

  const toggleEditing = () => {
    setIsEditing(true);
    onEditProfileOpen();
  };
  // Callback pour rafraîchir les données après modification
  const handleUserUpdated = () => {
    onEditProfileClose(); // Fermer la popup
    reloadUser();
    window.location.reload();
  };

  useEffect(() => {
    // Charger les événements
    const fetchEvents = async () => {
      const querySnapshot = await getDocs(
        collection(firestore_db, TABLE_NAME.events)
      );
      const types = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(types);
    };

    fetchEvents();

    const eventsLikedByUser = filterEventsLikedByCurrentUser(
      events,
      currentUser
    );

    setLikedEvents(eventsLikedByUser);
  }, [currentUser, events]);

  const handleEventClick = (event) => {
    navigate(`/events/${event.id}`);
    window.scrollTo(0, 0);
  };

  const handleLike = useCallback(
    (event, eventId) => {
      event.stopPropagation();
      if (!currentUser) {
        navigate(ROUTES.loginPage);
        return;
      }
      const eventRef = doc(firestore_db, TABLE_NAME.events, eventId);
      updateDoc(eventRef, {
        likedBy: isLiked
          ? arrayRemove(currentUser.authProfile.uid)
          : arrayUnion(currentUser.authProfile.uid),
      })
        .then(() => {
          setIsLiked(!isLiked);
          toast({
            title: isLiked ? 'Like retiré' : 'Événement aimé',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        })
        .catch((error) => {
          console.error('Error updating likes:', error);
          toast({
            title: 'Erreur lors de la mise à jour',
            description: 'Impossible de mettre à jour le statut du like.',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        });
    },
    [isLiked, currentUser, navigate, toast]
  );

  const handleShare = useCallback(
    (event) => {
      event.stopPropagation();
      onShareOpen();
    },
    [onShareOpen]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Postère de l'événement",
        accessor: '',
        width: 400,
        Cell: ({ row }) => (
          <EventDetailsContainer>
            <EventPoster src={row.original.posterPicture?.url} />
            <EventDetails
              title={row.original.name}
              location={
                row.original.location.place + ', ' + row.original.location.city
              }
              description={row.original.description}
            />
          </EventDetailsContainer>
        ),
      },
      {
        Header: 'Dates',
        accessor: '',
        width: 100,
        Cell: ({ row }) => (
          <DateCell
            icon={<FaCalendarAlt size="24px" color="#051145" />}
            date={getEventDateRange(row.original)}
          />
        ),
      },
      {
        Header: 'Actions',
        accessor: '',
        width: 100,
        Cell: ({ row }) => (
          <>
            <ButtonContainer>
              <ActionButton
                isLiked={isLiked}
                onClick={(e) => handleLike(e, row.original.id)}
              >
                <FaHeart />
              </ActionButton>
              <ActionButton onClick={(e) => handleShare(e)}>
                <FaShareAlt />
              </ActionButton>
            </ButtonContainer>
            <Popup
              isOpen={isShareOpen}
              onClose={onShareClose}
              popupTitle="Partager l'événement"
            >
              <CopyLinkComponent eventId={row.original.id} />
            </Popup>{' '}
          </>
        ),
      },
    ],
    [handleLike, handleShare, isLiked, isShareOpen, onShareClose]
  );

  return (
    <EventDetailPageContainer>
      <ProfileContainer>
        <SectionTitle>Profil</SectionTitle>
        <UserProfileDetails>
          <LeftContent>
            <UserProfileBox>
              <ProfilPictureContainer>
                <ProfilImage
                  src={
                    currentUser.dbProfile?.profilePicture?.url ||
                    defaultUserProfile
                  }
                  alt="Photo de profil"
                />
              </ProfilPictureContainer>
              <UserInfos>
                <UserName>
                  {currentUser.dbProfile.fname +
                    ' ' +
                    currentUser.dbProfile.lname}
                </UserName>
                <NbSubscriber>
                  {' '}
                  <span>
                    {
                      (
                        (currentUser &&
                          currentUser.dbProfile &&
                          currentUser.dbProfile.followedBy) ||
                        []
                      ).length
                    }
                  </span>{' '}
                  abonnements
                </NbSubscriber>
                <ReloadCauris currentUserId={currentUser.authProfile.uid} />
              </UserInfos>
            </UserProfileBox>
          </LeftContent>
          <UserProfileInfos>
            <FormFields>
              <FormFieldsGrid>
                <FormField
                  id="first-name"
                  label="Prénom"
                  inputType="text"
                  placeholder={currentUser.dbProfile.fname}
                  disabled
                />
                <FormField
                  id="last-name"
                  label="Nom"
                  inputType="text"
                  placeholder={currentUser.dbProfile.lname}
                  disabled
                />
              </FormFieldsGrid>
              <FormFieldsGrid>
                <FormField
                  id="birthdate"
                  label="Date de naissance"
                  inputType="date"
                  value={timestampToDateOnlyString(
                    currentUser.dbProfile.birthdate
                  )}
                  disabled
                />
                <FormFieldsGrid>
                  <FormField
                    id="sex"
                    label="Sexe"
                    defaultValue={currentUser.dbProfile.sex}
                    inputType="select"
                    disabled
                  >
                    <option value="">Sélectionnez votre sexe</option>
                    <option value={USER_SEX.MALE}>Masculin</option>
                    <option value={USER_SEX.FEMALE}>Féminin</option>
                  </FormField>
                  <FormField
                    id="Profession"
                    label="Profession "
                    defaultValue={currentUser.dbProfile.profession}
                    inputType="select"
                    disabled
                  >
                    <option value="">Sélectionnez votre profession</option>

                    <option value={USER_PROFESSION.SCHOOLBOY}>Élève</option>
                    <option value={USER_PROFESSION.STUDENT}>Étudiant</option>
                    <option value={USER_PROFESSION.EMPLOYEE}>
                      Travailleur
                    </option>
                    <option value={USER_PROFESSION.OTHER}>Autre</option>
                  </FormField>
                </FormFieldsGrid>
              </FormFieldsGrid>
              <FormFieldsGrid>
                <FormField
                  id="phone-number"
                  label="Numéro de téléphone"
                  inputType="tel"
                  placeholder={'+226 ' + currentUser.dbProfile.phone}
                  disabled
                />
                <FormField
                  id="mail"
                  label="Adresse email"
                  inputType="email"
                  placeholder={currentUser.dbProfile.email}
                  disabled
                />
              </FormFieldsGrid>
            </FormFields>

            {isEditing && (
              <EditUserProfileForm
                onUserUpdated={handleUserUpdated}
                isOpen={isEditProfileOpen}
                onOpen={onEditProfileOpen}
                onClose={onEditProfileClose}
                user={currentUser.dbProfile}
                isCustomerProfile={true}
              />
            )}
          </UserProfileInfos>
        </UserProfileDetails>
        <Button
          size="md"
          color="white"
          bgColor="#f1360a"
          position="relative"
          m={4}
          onClick={toggleEditing}
        >
          {' '}
          Modifier votre profil
        </Button>
      </ProfileContainer>
      <ListContainer>
        <SectionTitle>Vos Événements Favoris </SectionTitle>
        {likedEvents?.length > 0 ? (
          <LikedEventsContainer>
            <Array
              columns={columns}
              data={likedEvents}
              onRowClick={handleEventClick}
              rowHeight="120px"
              showHeader={false}
            />
          </LikedEventsContainer>
        ) : (
          <NoLikedEventMessageText>
            Vous n'avez pas encore liké d'événements. Parcourez les événements
            disponibles et cliquez sur le cœur pour ajouter des événements à
            votre liste de favoris !
          </NoLikedEventMessageText>
        )}
      </ListContainer>
    </EventDetailPageContainer>
  );
}

export default ProfileCustomer;
