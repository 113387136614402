/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 08/05/2024
/* ******************************************* */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import defaultPosterUrl from '../../../assets/medias/noImage.png';
import { checkIfIsPastEvent, formatText } from '../../../utils/function';
import { EVENT_REVIEW_STATE } from '../../../utils/constants';
import { CiMenuKebab } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import Popup from '../Popup/Popup';
import CopyLinkComponent from '../CopyLinkComponent/CopyLinkComponent';

const ImageContainer = styled.div`
  width: 150px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin-bottom: 10px;
`;

const Image = styled.img`
  vertical-align: top;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
`;

export const EventPoster = ({ src }) => (
  <ImageContainer>
    <Image src={src || defaultPosterUrl} alt="Event Poster" />
  </ImageContainer>
);

const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  height: 100px;
`;

const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  background-color: #ecf0f2;
`;

const Number = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #333;
`;

const Label = styled.div`
  font-size: 12px;
  color: #666;
`;

export const IconNText = ({ icon, number, label }) => (
  <Container>
    <IconWrapper>{icon}</IconWrapper>
    <Number>{number}</Number>
    <Label>{label}</Label>
  </Container>
);

const DateText = styled.p`
  font-size: 0.9rem;
  color: #666;
  text-align: center;
`;

export const DateCell = ({ icon, date }) => (
  <Container>
    <IconWrapper>{icon}</IconWrapper>
    <DateText>{date}</DateText>
  </Container>
);

const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 123px;
`;

const Title = styled.h4`
  margin: 0;
  width: 100%;
  color: #333;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
`;

const Location = styled.h5`
  margin: 0;
  width: 100%;
  text-align: left;
  font-size: 1rem;
`;
const Description = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-bottom: 5px;
  color: #051145;
  line-height: 1.2em;
  text-align: left;
  width: 100%;
  padding: 0px 20px 10px 0;
  height: 46px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  font-size: 0.8rem;
`;

export const EventDetails = ({ title, location, description }) => (
  <DetailsContainer>
    <Title>{title}</Title>
    <Location>{location}</Location>
    <Description>{description}</Description>
  </DetailsContainer>
);

const EventStatusContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 123px;
  background-color: ${(props) => props.backgroundColor};
  color: white;
  padding: 5px;
  border-radius: 20px;
`;

export const EventStatus = ({ event }) => {
  let eventStatus = '';
  let backgroundColor = '#ecf0f2'; // Default color

  if (checkIfIsPastEvent(event?.dates)) {
    eventStatus = 'Passé';
    backgroundColor = '#a0a0a0'; // Grey
  } else
    switch (event.review.state) {
      case EVENT_REVIEW_STATE.DENIED:
        eventStatus = 'Refusé';
        backgroundColor = '#b22222'; // Dark Red
        break;
      case EVENT_REVIEW_STATE.DRAFT:
        eventStatus = 'Avant-projets';
        backgroundColor = '#deb887'; // BurlyWood
        break;
      case EVENT_REVIEW_STATE.ACCEPTED:
        eventStatus = 'Publier';
        backgroundColor = '#228b22'; // Forest Green
        break;
      case EVENT_REVIEW_STATE.WAITING4_REVIEW:
        eventStatus = 'Vérification';
        backgroundColor = '#4682b4'; // Steel Blue
        break;
      default:
        eventStatus = 'Inconnu';
        backgroundColor = '#d3d3d3'; // Light Grey
    }

  return (
    <EventStatusContainer backgroundColor={backgroundColor}>
      {eventStatus}
    </EventStatusContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative; // Important pour le positionnement du menu
`;

const ActionButton = styled.button`
  background-color: #ecf0f2;
  border-radius: 50%;
  cursor: pointer;
  font-size: 32px;
  padding: 10px;
  color: #051145;

  &:hover {
    color: #f1360a;
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5eo;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 220px;
  z-index: 1000;
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

const MenuItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #f1360a;
    color: white;
  }
`;

export const EventActionsButton = ({ event }) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  const handleActionClick = (e) => {
    e.stopPropagation(); // Stop propagation to prevent closing when clicking on the button
    setShowMenu((prev) => !prev); // Toggle the display of the menu
  };

  // Gestionnaire pour fermer le menu quand on clique en dehors
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure();

  const handleShare = useCallback(
    (event) => {
      event.stopPropagation();
      onShareOpen();
    },
    [onShareOpen]
  );

  return (
    <>
      <ButtonContainer>
        <ActionButton onClick={handleActionClick}>
          <CiMenuKebab />
        </ActionButton>
        <Menu ref={menuRef} show={showMenu}>
          <MenuItem onClick={() => navigate('/manageEvent/promote')}>
            Promouvoir l'événement
          </MenuItem>
          <MenuItem onClick={() => navigate(`/events/${event?.id}`)}>
            Afficher
          </MenuItem>
          <MenuItem onClick={() => console.log('Modifier')}>Modifier</MenuItem>
          <MenuItem onClick={(e) => handleShare(e)}>Copier le lien</MenuItem>
          <Popup
            isOpen={isShareOpen}
            onClose={onShareClose}
            popupTitle="Partager l'événement"
          >
            <CopyLinkComponent eventId={event?.id} />
          </Popup>{' '}
          <MenuItem onClick={() => console.log('Supprimer')}>
            Supprimer
          </MenuItem>
        </Menu>
      </ButtonContainer>
    </>
  );
};
