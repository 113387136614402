import {authToken, firestore_db} from './firebase-config'; 
import { collection, getDocs, getDoc,addDoc, updateDoc,doc, setDoc,serverTimestamp } from 'firebase/firestore';

export async function addDocument2Collection(collectionName, documentId=undefined, document) {
  let status = true;
  const finalDocument = {
    ...document,
    createdAt: serverTimestamp(),
  };

  console.log(finalDocument)
  try {
    console.log('Check doc id ');
    if (documentId !== undefined) {
       // Création de la référence au document '
      const docRef = doc(firestore_db, collectionName, documentId);
      await setDoc(docRef, finalDocument);
    } else {
      // creattion de la collection avec id auto généreré
      const colRef = collection(firestore_db, collectionName);
      await addDoc(colRef, finalDocument);
    }
  } catch (error) {
    window.alert(`Le chargement des données a échoué: ${error}`);
    status = false;
  }
  return status;
}


export async function updateCollectionDocument(collectionName, documentId, data) {
    let status = true;
    try {
      // Création de la référence au document '
      const docRef = doc(firestore_db, collectionName, documentId);
      await updateDoc(docRef, data);
    } catch (error) {
      window.alert(`La mise à jour des données a échoué: ${error}`);
      status = false;
    }
    return status;
  }

  export async function getAllFromCollection(collectionName) {
    const querySnapshot = await getDocs(collection(firestore_db, collectionName));
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    });
    return querySnapshot.docs;
  }

/**
 * This function is used to read an entire collection in the database.
 *
 * @param {string} collectionName database collection
 * @returns data in case of success reading, otherwise null
 */
export async function readCollection(collectionName) {
  // let response = null;
  try {
    let events = [];
    let response = await getDocs(collection(firestore_db, collectionName));
    if (!response) return null;
    response.forEach((document) => {
      const event = { id: document.id, data: document.data() };
      events.push(event);
    });
    return events
  } catch (error) {
    console.log(
      // "Oups..."
      `La lecture des données a échoué: ${error}`
    );
  }
  // await getDocs(collection(firestore_db, collectionName))
  //   .then((query) => {
  //     let events = [];
  //     query.forEach((document) => {
  //       const event = { id: document.id, data: document.data() };
  //       events.push(event);
  //     });
  //     response = [...events];
  //   })
  //   .catch((error) => {
  //     console.log(
  //       // "Oups..."
  //       `La lecture des données a échoué: ${error}`
  //     );
  //   });
  // return response;
}
