/* ******************************************** */
/* * Author: Jamal SIDIKOU
/* * Project : SANNBIZ
/* * Create at : 03/06/2024
/* ******************************************* */

import React, { useEffect, useState } from 'react';
import sanbbizLogoGif from '../../../assets/medias/sanbbizLogoGif.gif';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../utils/variables';
import { motion } from 'framer-motion';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
  justify-content: center;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: x-large;
`;

const Subtitle = styled.h2`
  font-weight: bold;
`;

const LogoImg = styled.img`
  width: 300px;
  height: 300px;
  object-fit: contain;
  border: 0;
  /* border-radius: 10px; */
`;

function OrderSucceed() {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3); // Initialiser le décompte à 3 secondes

  useEffect(() => {
    // Définir un intervalle qui décrémente le décompte chaque seconde
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Définir un délai de 3 secondes pour la redirection
    const timer = setTimeout(() => {
      console.log(ROUTES.ordersPage);
      navigate(ROUTES.ordersPage);
    }, 5000);

    // Nettoyer les timers lorsque le composant est démonté pour éviter les fuites de mémoire
    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [navigate]);
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{
        scale: [1, 2, 2, 1, 1],
        opacity: 1,
        //  scale: 1
      }}
      transition={{
        duration: 2,
        ease: 'anticipate',
        times: [0, 1],
        // repeat: Infinity,
        // repeatDelay: 1
      }}
    >
      <Container>
        <LogoImg src={sanbbizLogoGif} />
        <Title> Félicitations !</Title>
        <Subtitle>Votre paiement a été effectué avec succès.</Subtitle>
        <Subtitle>
          Vous serez redirigé automatiquement vers la page des billets
          achetés...
        </Subtitle>
        <Subtitle>
          Redirection dans {countdown <= 0 ? 0 : countdown} secondes
        </Subtitle>
      </Container>
    </motion.div>
  );
}

export default OrderSucceed;
