/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RiTicketLine } from 'react-icons/ri';
import { getTicketDateMessage } from '../../../utils/function';
import { setCurrentTicket } from '../../../actions/ticket.action';
import { ROUTES } from '../../../utils/variables';

const BoughtTicketItemContainer = styled.div`
  position: relative;
  cursor: pointer;
  text-align: left;
  width: 300px;
  height: 300px;
  border-radius: 5px;
  overflow: hidden;

  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 3px 10px rgba(4, 4, 190, 0.7);
  }
`;

const EventImgContainer = styled.div`
  position: relative;
`;

const EventPoster = styled.img`
  vertical-align: top;
  width: 100%;
  object-fit: cover;
`;

const BoughtTicketDetails = styled.div`
  position: absolute;
  bottom: 0;
  color: white;
  z-index: 2;
  height: 150px;
  width: 100%;
  background: linear-gradient(to top, #000000, transparent);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  padding-right: 40px;
`;

const EventName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;

const EventPlace = styled.div`
  font-size: 1rem;
`;

const TicketDateDisplay = styled.div`
  font-size: 1rem;
  color: #f1360a;
`;

const TicketCountBox = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
`;

const TicketIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TicketText = styled.span`
  position: absolute;
  font-size: 16px;
  font-weight: bold;
`;

function BoughtTicketItem({ boughtTicketGroup, linkedEvent }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectClick = (event) => {
    if (window.getSelection().toString()) {
      // Si du texte est sélectionné, ne fait rien
      event.preventDefault();
      return;
    }
    const ticketDetails = {
      boughtTicketGroup,
      linkedEvent,
    };

    if (!event.defaultPrevented) {
      dispatch(setCurrentTicket(ticketDetails));
      navigate(ROUTES.orderDetailsPage);
      window.scrollTo(0, 0);
    }
  };

  return (
    <BoughtTicketItemContainer onClick={redirectClick}>
      <EventImgContainer>
        <EventPoster src={linkedEvent?.posterPicture?.url} alt=" cover" />
      </EventImgContainer>
      <BoughtTicketDetails>
        <EventName>{linkedEvent?.name}</EventName>
        <EventPlace>{linkedEvent?.location.place}</EventPlace>
        <TicketDateDisplay>
          {getTicketDateMessage(boughtTicketGroup)}
        </TicketDateDisplay>
      </BoughtTicketDetails>
      <TicketCountBox>
        <TicketIconContainer>
          <RiTicketLine size="40px" />
        </TicketIconContainer>
        <TicketText>{boughtTicketGroup?.length}</TicketText>
      </TicketCountBox>
    </BoughtTicketItemContainer>
  );
}

export default BoughtTicketItem;
