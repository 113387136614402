/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 06/05/2024
/* ******************************************* */

import React from 'react';
import CreateEventScheduler from '../../organisms/CreateEventScheduler/CreateEventScheduler';
import SidebarLayout from '../../templates/SidebarLayout/SidebarLayout.js';

function CreateEventSchedulerPage() {
  return (
    <SidebarLayout
      menuTitle={'Événement'}
      menuRoutePath={
        "événement / création d'événement / planification de dates"
      }
    >
      <CreateEventScheduler />
    </SidebarLayout>
  );
}

export default CreateEventSchedulerPage;
