/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 04/05/2024
/* ******************************************* */

const initialState = {
  typeId: null, // ID du type d'événement sélectionné dans l'étape 1
  categoryId: null, // ID de la catégorie sélectionnée dans l'étape 1
  name: null, // Nom de l'événement saisi dans l'étape 2
  description: null, // Description de l'événement saisie dans l'étape 2
  location: null,
  //  {
  //   place: null, // Nom du lieu de l'événement
  //   geoLocationLink: null, // Lien vers la localisation géographique
  //   city: null, // Ville où se déroule l'événement
  // },
  dates: [], // Tableau des dates d'événement, chaque élément est un objet { start: 'jj/mm/aa hh:mm', end: 'jj/mm/aa hh:mm' }
  ticketVersions: null,
  // [{
  //   name: null, // Nom de la version du ticket
  //   description: null, // Description de la version du ticket
  //   price: null, // Prix de la version du ticket
  //   stock: null, // Stock de la version du ticket
  // }],
  createdBy: null, // ID de l'utilisateur qui crée l'événement
  mngtPlantId: null, // ID du plan de gestion
  paymentMethodsId: null, // IDs des méthodes de paiement
  review: {
    by: null, // ID de l'utilisateur qui fait la révision
    comments: {
      createdBy: null, // ID de l'utilisateur qui laisse un commentaire
      message: null, // Message du commentaire
    },
    state: null, // État de l'événement
  },

  posterPicture: {
    width: null, // Largeur de l'image du poster
    height: null, // Hauteur de l'image du poster
    url: null, // URL de l'image du poster
  },
  coverPicture: {
    width: null, // Largeur de l'image du poster
    height: null, // Hauteur de l'image du poster
    url: null, // URL de l'image du poster
  },
  galleryPictures: [], // Tableau des images de la galerie
};

export default function eventReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_EVENT_TYPE_EVENT':
      return {
        ...state,
        typeId: action.payload,
      };
    case 'SET_EVENT_CATEGORY':
      return {
        ...state,
        categoryId: action.payload,
      };

    case 'SET_EVENT_NAME':
      return {
        ...state,
        name: action.payload,
      };
    case 'SET_EVENT_DESCRIPTION':
      return {
        ...state,
        description: action.payload,
      };
    case 'SET_EVENT_LOCATION':
      return {
        ...state,
        location: {
          ...state.location,
          ...action.payload,
        },
      };

    case 'SET_EVENT_DATES':
      return {
        ...state,
        dates: action.payload,
      };

    case 'ADD_EVENT_DATE':
      return {
        ...state,
        dates: [...state.dates, action.payload],
      };

    case 'UPDATE_EVENT_DATE':
      const updatedDates = state.dates.map((item, index) =>
        index === action.payload.index ? action.payload.date : item
      );
      return { ...state, dates: updatedDates };

    case 'REMOVE_EVENT_DATE':
      const filteredDates = state.dates.filter(
        (_, index) => index !== action.payload
      );
      return { ...state, dates: filteredDates };

    case 'SET_EVENT_TICKET_VERSION':
      return {
        ...state,
        ticketVersions: {
          ...state.ticketVersions,
          ...action.payload,
        },
      };
    case 'SET_EVENT_CREATOR_ID':
      return {
        ...state,
        createdBy: action.payload,
      };
    case 'SET_EVENT_MNGT_PLAN_ID':
      return {
        ...state,
        mngtPlantId: action.payload,
      };
    case 'SET_EVENT_PAYMENT_METHODS_ID':
      return {
        ...state,
        paymentMethodsId: action.payload,
      };
    case 'SET_EVENT_REVIEW':
      return {
        ...state,
        review: {
          ...state.review,
          comments: action.payload,
        },
      };
    case 'SET_EVENT_POSTER_PICTURE':
      return {
        ...state,
        posterPicture: {
          ...state.posterPicture,
          ...action.payload,
        },
      };
    case 'REMOVE_EVENT_POSTER_PICTURE':
      return {
        ...state,
        posterPicture: null,
      };
    case 'SET_EVENT_COVER_PICTURE':
      return {
        ...state,
        coverPicture: {
          ...state.coverPicture,
          ...action.payload,
        },
      };
    case 'REMOVE_EVENT_COVER_PICTURE':
      return {
        ...state,
        coverPicture: null,
      };
    case 'SET_EVENT_GALLERY_PICTURES':
      return {
        ...state,
        galleryPictures: action.payload,
      };

    case 'REMOVE_EVENT_GALLERY_PICTURE':
      return {
        ...state,
        galleryPictures: state.galleryPictures.filter(
          (_, idx) => idx !== action.payload
        ),
      };
    default:
      return state;
  }
}
