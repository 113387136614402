import React, { useCallback } from 'react';
import styled from 'styled-components';
import { MdOutlineLocationOn } from 'react-icons/md';
import { CiCalendar, CiClock2 } from 'react-icons/ci';
import { FaMinus, FaPlus } from 'react-icons/fa';
import {
  extractDayMonthAndYearFormTimestamp,
  formatDateToGetOnlyTime,
} from '../../../utils/date';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 4%;
  padding-right: 5%;
  margin-bottom: 10%;
  width: 100%;
`;

const LeftContainer = styled.div`
  display: flex;
  /* height:100%; */
`;
const RightContainer = styled.div``;

const ImgContainer = styled.div`
  /* height:100%; */
  width: 150px;
  height: 150px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 20px;
`;
const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  /* justify-items: right ; */
`;

const CPTContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-width: 1px;
  border-color: green;
  width: 80px;
  height: 25px;
`;

const SquareIcon = styled.div`
  display: flex;
  border-width: 1px;
  align-items: center;
  justify-content: center;
  border-color: green;
  height: 100%;
  width: 33.33%;
  background-color: #d8ebdf;
`;

const CircleIcon = styled.div`
  display: flex;
  border-width: 1px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border-color: green;
  height: 23px;
  width: 23px;
  margin-right: 5px;
  /* background-color: #D8EBDF; */
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: x-large;
  text-align: start;
`;

const QuantityTitle = styled.h3`
  text-align: end;
`;

const Price = styled.h2`
  color: darkgreen;
  font-size: x-large;
  white-space: nowrap;
`;

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  color: darkgray;
`;

const Line = styled.h3``;

const Img = styled.img`
  width: 150px;
  /* height: fit-content; */
  object-fit: cover;
  border: 0;
  border-radius: 10px;
`;
function PaymentTicketItem({
  title,
  startTimeStamp,
  endTimeStamp,
  location,
  price,
  quantity,
  updatePriceAndQuantity,
  img,
  availability,
}) {
  let endDate = extractDayMonthAndYearFormTimestamp(endTimeStamp);
  let startDate = extractDayMonthAndYearFormTimestamp(startTimeStamp);

  const dates = `${startDate.day} ${startDate.month} ${startDate.year} - ${endDate.day} ${endDate.month} ${endDate.year}`;

  const myTitle = title || '';

  const handleUpdatePriceAndQuantity = useCallback(
    (operation) => {
      updatePriceAndQuantity(operation);
    },
    [updatePriceAndQuantity] // Dépend uniquement de updatePriceAndQuantity
  );

  return (
    <Container>
      <LeftContainer>
        <ImgContainer style={{ maxWidth: '100%', height: 100 }}>
          <Img src={img?.url} />
        </ImgContainer>
        <InfoContainer>
          <Title>{myTitle}</Title>
          <LineContainer>
            <CircleIcon>
              <CiCalendar color="green" fontSize={25} />
            </CircleIcon>
            {dates}
          </LineContainer>
          <LineContainer>
            {/* <CircleIcon> */}
            <CiClock2 color="green" fontSize={25} />
            {/* </CircleIcon> */}
            {/* {startTimeStamp && endTimeStamp ? formatDateToGetOnlyTime(startTimeStamp?.seconds * 1000)} - ${formatDateToGetOnlyTime(endTimeStamp?.seconds * 1000)}} */}
            {startTimeStamp && endTimeStamp
              ? ` ${formatDateToGetOnlyTime(
                  startTimeStamp?.seconds * 1000
                )} - ${formatDateToGetOnlyTime(endTimeStamp?.seconds * 1000)}`
              : ''}
          </LineContainer>
          <LineContainer>
            <CircleIcon>
              <MdOutlineLocationOn color="green" fontSize={25} />
            </CircleIcon>
            {location}
          </LineContainer>
        </InfoContainer>
      </LeftContainer>
      <RightContainer>
        <QuantityContainer>
          <Price> {price} F CFA</Price>

          <QuantityTitle>Quantité</QuantityTitle>
          <div style={{ marginTop: '5px' }} />
          <CPTContainer>
            <SquareIcon onClick={() => handleUpdatePriceAndQuantity('-')}>
              <FaMinus fontSize={'10px'} />
            </SquareIcon>
            {quantity}
            <SquareIcon onClick={() => handleUpdatePriceAndQuantity('+')}>
              <FaPlus fontSize={'10px'} />
            </SquareIcon>
          </CPTContainer>
          {/* <h2>{`Il vous reste ${availability} - ${quantity} tickets`}</h2> */}
        </QuantityContainer>
      </RightContainer>
    </Container>
  );
}

export default PaymentTicketItem;
