/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 01/05/2024
/* ******************************************* */

import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const ScrollWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 80px;
`;

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Pour Firefox */
  &::-webkit-scrollbar {
    display: none; /* Pour Chrome, Safari et Opera */
  }
`;

const ScrollButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  z-index: 10;
  padding: 10px;
  border-radius: 50%;
  &:hover {
    background-color: #f0f0f0;
  }
  &:disabled {
    color: #ccc;
    cursor: default;
  }
  display: none; /*Par defaut ne pas afficher */
`;

const LeftButton = styled(ScrollButton)`
  left: 20px;
`;

const RightButton = styled(ScrollButton)`
  right: 20px;
`;

const HorizontalScrollList = ({ children }) => {
  const scrollContainer = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [showArrows, setShowArrows] = useState(false);

  const checkScrollButtons = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.current;
    const tolerance = 1; // Ajout d'une tolérance pour compenser les imprécisions de pixel
    setCanScrollLeft(scrollLeft > tolerance);
    setCanScrollRight(scrollLeft < scrollWidth - clientWidth - tolerance);
    setShowArrows(scrollWidth > clientWidth);
  };

  const scroll = (direction) => {
    if (scrollContainer.current) {
      const { scrollLeft, clientWidth } = scrollContainer.current;
      const scrollAmount = direction === 'left' ? -clientWidth : clientWidth;
      scrollContainer.current.scrollTo({
        left: scrollLeft + scrollAmount,
        behavior: 'smooth',
      });
      setTimeout(checkScrollButtons, 150);
    }
  };

  useEffect(() => {
    checkScrollButtons(); // Vérifie après le chargement initial et à chaque mise à jour
    const handleResize = () => {
      checkScrollButtons();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [children]);

  return (
    <ScrollWrapper>
      {showArrows && (
        <LeftButton
          onClick={() => scroll('left')}
          disabled={!canScrollLeft}
          style={{ display: 'block' }}
        >
          <FaArrowLeft size="24px" />
        </LeftButton>
      )}
      <ScrollContainer ref={scrollContainer} onScroll={checkScrollButtons}>
        {children}
      </ScrollContainer>
      {showArrows && (
        <RightButton
          onClick={() => scroll('right')}
          disabled={!canScrollRight}
          style={{ display: 'block' }}
        >
          <FaArrowRight size="24px" />
        </RightButton>
      )}
    </ScrollWrapper>
  );
};

export default HorizontalScrollList;
