/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 09/05/2024
/* ******************************************* */

import React from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import { useAuth } from '../../../contexts/AuthContext';
import ProfileCustomer from '../../organisms/ProfilCustomer/ProfileCustomer';
import Header from '../../organisms/Header/Header';
import Footer from '../../organisms/Footer/Footer';
import RedirectComponent from '../../atoms/RedirectComponent/RedirectComponent';
import { ROUTES } from '../../../utils/variables';

function CustomerProfilePage() {
  const { userLoggedIn, currentUser } = useAuth();

  // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
  if (!userLoggedIn) {
    return <RedirectComponent to={ROUTES.loginPage} replace={true} />;
  }

  if (!currentUser || !currentUser.dbProfile) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      <PageLayout title={'Profil'} routesPath={' Accueil / Profil'}>
        <ProfileCustomer />
      </PageLayout>
      <Footer />
    </>
  );
}

export default CustomerProfilePage;
