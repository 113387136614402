/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 08/06/2024
/* ******************************************* */

import React from 'react';
import styled from 'styled-components';

const TableOfContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 20px 10px;
  background-color: #ecf0f2;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  border-radius: 20px;
`;

const SectionGroupTitle = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  color: #051145;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableOfContentNav = styled.nav`
  width: 100%;
  padding: 0px 20px;
`;

const TableOfContentList = styled.ul`
  list-style: none;
  padding: 0;
`;

const TableOfContentItem = styled.li`
  margin-bottom: 10px;
`;

const TableOfContentItemLink = styled.a`
  color: blue;
  text-decoration: none;

  &:hover {
    font-weight: bold;
  }
`;

export function TableOfContents() {
  return (
    <TableOfContentContainer>
      <SectionGroupTitle>Sommaire</SectionGroupTitle>
      <TableOfContentNav>
        <TableOfContentList>
          <TableOfContentItem>
            <TableOfContentItemLink href="#doc-aim-section">
              Objectif de cette politique de confidentialité
            </TableOfContentItemLink>
          </TableOfContentItem>
          <TableOfContentItem>
            <TableOfContentItemLink href="#app-section">
              Application
            </TableOfContentItemLink>
          </TableOfContentItem>
          <TableOfContentItem>
            <TableOfContentItemLink href="#consent-section">
              Consentement
            </TableOfContentItemLink>
          </TableOfContentItem>
          <TableOfContentItem>
            <TableOfContentItemLink href="#perso-data-section">
              Données personnelles que nous collectons
            </TableOfContentItemLink>
          </TableOfContentItem>
          <TableOfContentItem>
            <TableOfContentItemLink href="#random-data-section">
              Données collectées de façon non automatique
            </TableOfContentItemLink>
          </TableOfContentItem>
          <TableOfContentItem>
            <TableOfContentItemLink href="#data-usage-section">
              Utilisation des données personnelles
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#data-sharing-section">
              Partage des données personnelles
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#other-disclosure-section">
              Autres divulgations
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#data-conservation-section">
              Durée de conservation des données personnelles
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#data-protection-section">
              Protection des données personnelles
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#minor-person-section">
              Mineurs
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#user-right-section">
              Droits des utilisateurs
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#data-mngt-section">
              Modification, suppression ou contestation des données collectées
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#data-modification-section">
              Modifications
            </TableOfContentItemLink>
          </TableOfContentItem>

          <TableOfContentItem>
            <TableOfContentItemLink href="#sanbbiz-contact-section">
              Contact
            </TableOfContentItemLink>
          </TableOfContentItem>
        </TableOfContentList>
      </TableOfContentNav>
    </TableOfContentContainer>
  );
}

const PrivacyPolicySection = styled.section`
  widht: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 10px;
`;

const PrivacyPolicySectionTitle = styled.h2`
  font-weight: bold;
  font-size: 1.3rem;
  color: black;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrivacyPolicySectionSubTitle = styled.h3``;

const PrivacyPolicySectionText = styled.p`
  width: 100%;
  text-align: left;
`;

const OrderedList = styled.ol`
  width: 100%;
  padding: 0px 20px 0px 40px;
`;

const UnorderedList = styled.ul`
  width: 100%;
  padding: 0px 20px 0px 40px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

export function DocAimSection() {
  return (
    <PrivacyPolicySection id="doc-aim-section">
      <PrivacyPolicySectionTitle>
        Objectif de cette politique de confidentialité
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Cette politique de confidentialité s'applique à toutes les données
        personnelles traitées par Sanbbiz en tant que responsable de traitement
        conformément à la législation burkinabé, notamment la loi n°001-2003/AN
        du 23 mai 2003 relative à la protection des données à caractère
        personnel. Les termes «Sanbbiz», «nous» ou «nos/notre» font référence à
        Sanbbiz. Le terme «plateforme» désigne le site internet www.sanbbiz.com
        et les applications mobiles associées pour Android et iOS. Les termes
        «vous» ou «vos/votre» incluent les clients potentiels et clients de
        Sanbbiz, ainsi que les visiteurs ou utilisateurs de la plateforme lors
        de la consultation ou de l'achat de services ou produits proposés par
        Sanbbiz.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Cette politique de confidentialité a pour but d'informer les
        utilisateurs de notre plateforme des données personnelles que nous
        collectons et des informations suivantes :
      </PrivacyPolicySectionText>
      <br />

      <OrderedList>
        <ListItem>Les données personnelles collectées</ListItem>
        <ListItem>L'utilisation des données recueillies</ListItem>
        <ListItem>L'accès aux données recueillies</ListItem>
        <ListItem>Les droits des utilisateurs de la plateforme</ListItem>
      </OrderedList>
      <br />
      <PrivacyPolicySectionText>
        Cette politique de confidentialité fonctionne conjointement avec nos
        conditions générales de vente et conditions générales d'utilisation.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function AppSection() {
  return (
    <PrivacyPolicySection id="app-section">
      <PrivacyPolicySectionTitle>Application</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Nous comprenons l'importance de la confidentialité en ligne pour les
        utilisateurs de notre plateforme. Conformément à la loi n°001-2003/AN et
        à nos engagements, cette politique de confidentialité respecte les
        principes suivants :
      </PrivacyPolicySectionText>
      <br />

      <OrderedList>
        <ListItem>
          Les données personnelles sont traitées de manière licite, loyale et
          transparente.
        </ListItem>
        <ListItem>
          Les données sont collectées pour des finalités déterminées, explicites
          et légitimes, et ne sont pas traitées ultérieurement de manière
          incompatible avec ces finalités.
        </ListItem>
        <ListItem>
          Les données collectées sont adéquates, pertinentes et limitées à ce
          qui est nécessaire.
        </ListItem>
        <ListItem>
          Les données sont conservées sous une forme permettant l'identification
          des personnes concernées pendant une durée n'excédant pas celle
          nécessaire pour atteindre les finalités pour lesquelles elles sont
          traitées.
        </ListItem>
        <ListItem>
          Les données sont traitées de manière à assurer leur sécurité, y
          compris la protection contre le traitement non autorisé ou illégal et
          contre la perte, la destruction ou les dommages accidentels.
        </ListItem>
      </OrderedList>
      <br />
      <PrivacyPolicySectionText>
        Le traitement des données est licite si au moins une des conditions
        suivantes est remplie :
      </PrivacyPolicySectionText>
      <br />

      <OrderedList>
        <ListItem>
          La personne concernée a consenti au traitement de ses données pour une
          ou plusieurs finalités spécifiques.
        </ListItem>
        <ListItem>
          Le traitement est nécessaire à l'exécution d'un contrat auquel la
          personne concernée est partie ou à l'exécution de mesures
          précontractuelles prises à sa demande.
        </ListItem>
        <ListItem>
          Le traitement est nécessaire pour respecter une obligation légale à
          laquelle le responsable du traitement est soumis.
        </ListItem>
        <ListItem>
          Le traitement est nécessaire pour protéger les intérêts vitaux de la
          personne concernée ou d'une autre personne physique.
        </ListItem>
        <ListItem>
          Le traitement est nécessaire à l'exécution d'une mission d'intérêt
          public ou relevant de l'exercice de l'autorité publique.
        </ListItem>

        <ListItem>
          Le traitement est nécessaire aux fins des intérêts légitimes
          poursuivis par le responsable du traitement ou par un tiers, sauf si
          les intérêts ou les libertés et droits fondamentaux de la personne
          concernée l'emportent.
        </ListItem>
      </OrderedList>
    </PrivacyPolicySection>
  );
}

export function ConsentSection() {
  return (
    <PrivacyPolicySection id="consent-section">
      <PrivacyPolicySectionTitle>Consentement</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Les utilisateurs acceptent, en utilisant notre plateforme :
      </PrivacyPolicySectionText>
      <br />
      <OrderedList>
        <ListItem>
          Les conditions énoncées dans la présente politique de confidentialité.
        </ListItem>
        <ListItem>
          La collecte, l'utilisation et la conservation des données mentionnées
          dans cette politique.
        </ListItem>
      </OrderedList>
    </PrivacyPolicySection>
  );
}

export function PersoDataSection() {
  return (
    <PrivacyPolicySection id="perso-data-section">
      <PrivacyPolicySectionTitle>
        Données personnelles que nous collectons
      </PrivacyPolicySectionTitle>
      {/* <PrivacyPolicySectionText>
        Données collectées automatiquement :
      </PrivacyPolicySectionText> */}
      <br />
      <PrivacyPolicySectionText>
        Lorsque vous visitez et utilisez notre plateforme, nous pouvons
        collecter et stocker automatiquement les informations suivantes :
      </PrivacyPolicySectionText>
      <br />

      <OrderedList>
        <ListItem>Adresse IP</ListItem>
        <ListItem>Localisation</ListItem>
        <ListItem>Détails matériels et logiciels</ListItem>
        <ListItem>Liens cliqués sur la plateforme</ListItem>
        <ListItem>Contenus consultés sur notre plateforme</ListItem>
      </OrderedList>
    </PrivacyPolicySection>
  );
}

export function RandomDataSection() {
  return (
    <PrivacyPolicySection id="random-data-section">
      <PrivacyPolicySectionTitle>
        Données collectées de façon non automatique
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Nous pouvons également collecter les données suivantes lorsque vous
        utilisez certaines fonctionnalités de notre plateforme :
      </PrivacyPolicySectionText>
      <br />

      <OrderedList>
        <ListItem>Prénom et nom</ListItem>
        <ListItem>Date de naissance</ListItem>
        <ListItem>Email</ListItem>
        <ListItem>Numéro de téléphone</ListItem>
        <ListItem>Adresse de domicile</ListItem>

        <ListItem>Documents d'identité</ListItem>
        <ListItem>Informations de paiement</ListItem>
        <ListItem>Données de remplissage automatique</ListItem>
      </OrderedList>
      <br />

      <PrivacyPolicySectionText>
        Ces données peuvent être collectées via les méthodes suivantes :
      </PrivacyPolicySectionText>
      <br />
      <UnorderedList>
        <ListItem>Enregistrement du compte</ListItem>
        <ListItem>Formulaires</ListItem>
      </UnorderedList>
      <br />

      <PrivacyPolicySectionText>
        Nous ne collectons que les données nécessaires pour atteindre les
        objectifs définis dans cette politique de confidentialité. Aucune donnée
        supplémentaire ne sera collectée sans vous en informer au préalable.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function DataUsageSection() {
  return (
    <PrivacyPolicySection id="data-usage-section">
      <PrivacyPolicySectionTitle>
        Utilisation des données personnelles :
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Les données collectées sur notre plateforme sont utilisées uniquement
        pour les finalités spécifiées dans cette politique ou indiquées sur les
        pages concernées de notre plateforme. Nous n'utiliserons pas vos données
        au-delà de ce qui est divulgué.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Les données collectées automatiquement sont utilisées pour :
      </PrivacyPolicySectionText>
      <br />

      <OrderedList>
        <ListItem>Amélioration du contenu</ListItem>
        <ListItem>Fonctionnement interne</ListItem>
        <ListItem>Statistiques</ListItem>
      </OrderedList>
      <br />

      <PrivacyPolicySectionText>
        Les données collectées lors de l'exécution de certaines fonctions
        peuvent être utilisées pour :
      </PrivacyPolicySectionText>
      <br />
      <OrderedList>
        <ListItem>Informations de contact</ListItem>
        <ListItem>Sécurité et résolution des litiges</ListItem>
        <ListItem>Publicité</ListItem>
      </OrderedList>
    </PrivacyPolicySection>
  );
}

export function DataSharingSection() {
  return (
    <PrivacyPolicySection id="data-sharing-section">
      <PrivacyPolicySectionTitle>
        Partage des données personnelles
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        <strong>Employés :</strong>
      </PrivacyPolicySectionText>
      <br />

      <PrivacyPolicySectionText>
        Nous pouvons divulguer à tout membre de notre organisation les données
        des utilisateurs dont il a besoin pour remplir les objectifs énoncés
        dans cette politique.
      </PrivacyPolicySectionText>
      <br />

      <PrivacyPolicySectionText>
        <strong>Tierces parties :</strong>
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Nous pouvons partager des données utilisateur avec les tiers suivants :
      </PrivacyPolicySectionText>
      <br />

      <PrivacyPolicySectionText>
        <strong>Organisateurs d'événements:</strong>
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Les tiers n'auront accès aux données de l'utilisateur que dans la mesure
        nécessaire pour atteindre les objectifs définis.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function OtherDisclosuresSection() {
  return (
    <PrivacyPolicySection id="other-disclosure-section">
      <PrivacyPolicySectionTitle>Autres divulgations</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Nous nous engageons à ne pas vendre ou partager vos données avec
        d'autres tiers, sauf dans les cas suivants :
      </PrivacyPolicySectionText>
      <br />

      <OrderedList>
        <ListItem>Si la loi l'exige</ListItem>
        <ListItem>
          Si cela est nécessaire pour toute procédure judiciaire
        </ListItem>
        <ListItem>
          Aux acquéreurs ou acquéreurs potentiels de Sanbbiz dans le cas où nous
          cherchons à vendre tout ou partie de l'entreprise
        </ListItem>
      </OrderedList>
      <br />
      <PrivacyPolicySectionText>
        Si vous suivez des hyperliens de notre plateforme vers un autre site,
        veuillez noter que nous ne sommes pas responsables de leurs politiques
        et pratiques de confidentialité.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function DataConservationSection() {
  return (
    <PrivacyPolicySection id="data-conservation-section">
      <PrivacyPolicySectionTitle>
        Durée de conservation des données personnelles
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Nous ne conservons pas les données des utilisateurs au-delà de ce qui
        est nécessaire pour atteindre les finalités pour lesquelles elles ont
        été collectées.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function DataProtectionSection() {
  return (
    <PrivacyPolicySection id="data-protection-section">
      <PrivacyPolicySectionTitle>
        Protection des données personnelles
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Pour assurer la sécurité de vos informations, nous utilisons le
        protocole TLS (Transport Layer Security) pour transmettre des
        informations personnelles via notre système.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Toutes les données stockées dans notre système sont bien sécurisées et
        accessibles uniquement à nos employés. Nos employés sont liés par des
        accords de confidentialité stricts, et une violation de cet accord
        entraînera des sanctions disciplinaires, pouvant aller jusqu'au
        licenciement.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Bien que nous prenions toutes les mesures raisonnables pour protéger les
        données des utilisateurs, il existe toujours un risque de préjudice.
        L'Internet peut parfois être peu sûr, et nous ne pouvons garantir la
        sécurité absolue des données des utilisateurs au-delà de ce qui est
        raisonnablement praticable.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function MinorPersonSection() {
  return (
    <PrivacyPolicySection id="minor-person-section">
      <PrivacyPolicySectionTitle>Mineurs</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Les mineurs doivent avoir le consentement d'un représentant légal pour
        que leurs données soient collectées, traitées et utilisées.
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Nous ne cherchons pas à collecter ou utiliser les données des
        utilisateurs mineurs. Si nous découvrons que nous avons collecté des
        données auprès d'un mineur, ces données seront immédiatement supprimées.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function UserRightSection() {
  return (
    <PrivacyPolicySection id="user-right-section">
      <PrivacyPolicySectionTitle>
        Droits des utilisateurs
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Dans le cadre de nos engagements en matière de gestion et de protection
        des données personnelles, les utilisateurs disposent des droits suivants
        :
      </PrivacyPolicySectionText>
      <br />

      <OrderedList>
        <ListItem>Droit d'accès</ListItem>
        <ListItem>Droit de rectification</ListItem>
        <ListItem>Droit à l'effacement</ListItem>
        <ListItem>Droit de restreindre le traitement</ListItem>
        <ListItem>Droit à la portabilité des données</ListItem>
        <ListItem>Droit d'opposition</ListItem>
      </OrderedList>
    </PrivacyPolicySection>
  );
}

export function DataManagementSection() {
  return (
    <PrivacyPolicySection id="data-mngt-section">
      <PrivacyPolicySectionTitle>
        Modification, suppression ou contestation des données collectées
      </PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Si vous souhaitez que vos informations soient supprimées ou modifiées,
        veuillez contacter notre responsable de la confidentialité des données à
        l'adresse suivante : hello.sanbbiz@gmail.com
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function ModificationSection() {
  return (
    <PrivacyPolicySection id="data-modification-section">
      <PrivacyPolicySectionTitle>Modifications</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Cette politique de confidentialité peut être mise à jour pour rester
        conforme à la loi et refléter les changements dans nos pratiques de
        collecte de données. Nous recommandons aux utilisateurs de vérifier
        cette politique régulièrement pour rester informés des mises à jour. Si
        nécessaire, nous informerons les utilisateurs par e-mail des
        modifications apportées.
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

export function SanbbizContactSection() {
  return (
    <PrivacyPolicySection id="sanbbiz-contact-section">
      <PrivacyPolicySectionTitle>Consentement</PrivacyPolicySectionTitle>
      <PrivacyPolicySectionText>
        Si vous avez des questions, veuillez nous contacter à :
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Sanbbiz Ouagadougou S/C 12 BP 154 Ouagadougou 12 Parcelle 19 lot 30
        secteur 19 section 709
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        hello.sanbbiz@gmail.com
      </PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>+226 74 84 38 61</PrivacyPolicySectionText>
      <br />
      <PrivacyPolicySectionText>
        Date d'entrée en vigueur : le 31 Mai 2024
      </PrivacyPolicySectionText>
    </PrivacyPolicySection>
  );
}

const DocumentContainer = styled.div`
  position: relative;
  padding: 30px 0px;
  background-color: white;
  width: 100%;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  padding: 10px;
  border-radius: 20px;
`;

export function PrivacyPolicyDoc() {
  return (
    <>
      <TableOfContents />

      <DocumentContainer>
        <SectionGroupTitle>Politique et confidentialité</SectionGroupTitle>
        <DocAimSection />
        <AppSection />
        <ConsentSection />
        <PersoDataSection />
        <RandomDataSection />
        <DataUsageSection />
        <DataSharingSection />
        <OtherDisclosuresSection />
        <DataConservationSection />
        <DataProtectionSection />
        <MinorPersonSection />
        <UserRightSection />
        <DataManagementSection />
        <ModificationSection />
        <SanbbizContactSection />
      </DocumentContainer>
    </>
  );
}
