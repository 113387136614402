/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 11/04/2024
/* ******************************************* */

import React, { useState } from 'react';
import { Box, RadioGroup, Radio, HStack, Text } from '@chakra-ui/react';
import FormField from '../../atoms/FormField/FormField';

function EndEventTicketDateSelector() {
  return (
    <Box pt={2} borderTop="1px" borderColor="gray.200" m={4} textAlign="center">
      <Text fontSize="md" as="b" textAlign="center">
        {' '}
        Fin de la vente{' '}
      </Text>
      <HStack spacing={4} justify="space-between">
        <FormField id="eventTicketEnd" inputType="number" onChange={() => {}} />
        <FormField
          id="eventTicketTimeItem"
          inputType="select"
          onChange={() => {}}
        >
          <option key="days" value="days">
            Jour(s)
          </option>
          <option key="hours" value="hours">
            Heure(s)
          </option>
          <option key="minutes" value="minutes">
            Minutes(s)
          </option>
        </FormField>
      </HStack>
      <FormField id="eventBeforeOrAfter" inputType="select" onChange={() => {}}>
        <option key="beforeEvent" value="beforeEvent">
          Avant le début de l'événement
        </option>
        <option key="afterEvent" value="afterEvent">
          Après le début de l'événement
        </option>
      </FormField>
    </Box>
  );
}

export default EndEventTicketDateSelector;
