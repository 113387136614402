/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 30/03/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { extractImageFileDetails } from '../../../utils/function';

import FormField from '../../atoms/FormField/FormField';

import './CreateEventFormStep3.css';

function CreateEventFormMedias({
  setEventPosterPicture,
  removeEventPosterPicture,
  setEventGalleryPictures,
  removeEventGalleryPicture,
}) {
  const dispatch = useDispatch();

  const [posterPicture, setPosterPicture] = useState(null);
  const [galleryPictures, setGalleryPictures] = useState([]);

  // Fonction pour gérer la sélection du poster
  const handlePosterPictureChange = (files) => {
    const fileData = files[0]; // Assumant que files est un tableau et nous prenons le premier élément
    if (fileData) {
      extractImageFileDetails(fileData)
        .then(({ width, height, url }) => {
          dispatch(setEventPosterPicture({ width, height, url }));
          setPosterPicture(url); // stocker l'URL pour l'aperçu dans l'UI
        })
        .catch((error) =>
          console.error('Failed to load image details:', error)
        );
    }
  };

  const handlePosterRemoved = () => {
    setPosterPicture(null);
    dispatch(removeEventPosterPicture());
  };

  // Fonction pour gérer la sélection des photos de la galerie
  const handleGalleryPicturesChange = async (fileDataArray) => {
    try {
      // Créer un tableau de promesses pour extraire les détails de chaque fichier image
      const promises = fileDataArray.map((fileData) =>
        extractImageFileDetails(fileData)
      );

      // Attend que toutes les promesses soient résolues
      const galleryData = await Promise.all(promises);

      // mise à jour par redux
      dispatch(setEventGalleryPictures(galleryData));

      // Mettre à jour le state local pour l'aperçu des images avec les URLs extraites
      setGalleryPictures(galleryData.map((data) => data.url));
    } catch (error) {
      console.error(
        'Erreur lors du traitement des images de la galerie :',
        error
      );
    }
  };

  const handleGalleryPictureRemoved = (indexToRemove) => {
    setGalleryPictures((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
    dispatch(removeEventGalleryPicture(indexToRemove));
  };

  return (
    <form className="step3-form-container">
      <h1 className="step3-form-title"> Médias de l'événement </h1>
      <FormField
        id="posterPicture"
        label="Poster de l'événement"
        isRequired={true}
        inputType="imageUploader"
        defaultImageUrl={posterPicture}
        onFilesSelected={handlePosterPictureChange}
        onImageRemoved={handlePosterRemoved}
      />
      <FormField
        id="galleryPictures"
        label="Gallerie photos de l'événement"
        inputType="imageUploader"
        defaultImageUrl={galleryPictures}
        buttonTextBefore={'Importer des photos'}
        buttonTextAfter={"Importer d'autres photos"}
        onFilesSelected={handleGalleryPicturesChange}
        onImageRemoved={handleGalleryPictureRemoved}
        multiple
      />
    </form>
  );
}

export default CreateEventFormMedias;
