/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 01/04/2024
/* ******************************************* */

import React, { useState } from 'react';
import { Input } from '@chakra-ui/react';

function DatePicker({
  id,
  placeholder,
  isRequired,
  value,
  updateDate,
  ...props
}) {
  const [date, setDate] = useState(value);

  // // Utilisez la date actuelle à l'initialisation
  // return new Date().toISOString().split('T')[0];

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setDate(newDate);
    updateDate(newDate); // fonction du parent pour mettre à jour l'état
  };

  return (
    <Input
      id={id}
      type="date"
      placeholder={placeholder}
      required={isRequired}
      value={date}
      onChange={handleDateChange}
      {...props}
    />
  );
}

export default DatePicker;
