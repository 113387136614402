/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 24/05/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import BlogItem from '../../molecules/BlogItem/BlogItem';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';

import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const BlogListContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 0px 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const BlogCard = styled.li`
  box-shadow: 0 5px 15px rgba(0, 0, 255, 0.1);
`;

function BlogList() {
  // Obtenir les blog
  const [blogs, setBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeBlogs = onSnapshot(
      collection(firestore_db, TABLE_NAME.blogs),
      (snapshot) => {
        setBlogs(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
        setLoading(false);
      }
    );

    return () => {
      unsubscribeBlogs();
    };
  }, []);

  if (loading) {
    console.log('Loading...');
  }

  return (
    <BlogListContainer>
      {blogs.map((blog) => {
        return (
          <BlogCard key={blog.id}>
            <BlogItem currentBlog={blog} />
          </BlogCard>
        );
      })}
    </BlogListContainer>
  );
}

export default BlogList;
