/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 08/05/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import styled from 'styled-components';
import Array from '../../atoms/Array/Array';
import {
  IconNText,
  DateCell,
  EventPoster,
  EventDetails,
} from '../../atoms/DashboardEventSections/DashboardEventSections';
import { IoTicketSharp } from 'react-icons/io5';
import { FaCalendarAlt, FaUserFriends } from 'react-icons/fa';
import { FaTicketSimple, FaChampagneGlasses } from 'react-icons/fa6';
import { Button } from '@chakra-ui/react';

import {
  useFindCurrentUserEvents,
  getTotalTicketsSoldForEventGroup,
  sortEventsByNearestStartDate,
} from '../../../utils/event';
import {
  calculateTotalProfit,
  getEventDateRange,
} from '../../../utils/function';

import {
  getTotalAvailableTickets,
  getTotalTicketsSoldForEvent,
} from '../../../utils/event';
import { useAuth } from '../../../contexts/AuthContext';
import { EVENT_REVIEW_STATE } from '../../../utils/constants';
import { ROUTES, TABLE_NAME } from '../../../utils/variables';

const DashboardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const TopBlocks = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 20px 5px;
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
`;

const Container = styled.div`
  position: relative;
  border-radius: 5px;
  box-shadow: 0 4px 20px rgba(0, 0, 255, 0.15);
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BalanceTitle = styled.h3`
  padding: 10px;
  border-radius: 5px;
  font-size: 2rem;
  // font-weight: bold;
`;

const TotalBalanceAmount = styled.span`
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  color: #f1360a;
  font-size: 1.8rem;
  font-weight: bold;
`;

const RedirectMessage = styled.p`
  gap: 10px;
  padding: 10px 20px;
  text-align: center;
  font-size: 1rem;
  font-style: italic;
`;

const UserBalance = ({ currentUser }) => {
  const [totalProfit, setTotalProfit] = useState(0);
  useEffect(() => {
    if (currentUser && currentUser.authProfile && currentUser.authProfile.uid) {
      calculateTotalProfit(currentUser.authProfile.uid).then((profit) => {
        setTotalProfit(profit);
      });
    }
  }, [currentUser]);

  const formattedTotalProfit = totalProfit.toLocaleString('fr-FR');

  return (
    <Container>
      <BalanceTitle>Votre Solde</BalanceTitle>
      <TotalBalanceAmount>{formattedTotalProfit + ' F CFA'}</TotalBalanceAmount>
      <RedirectMessage>
        Vous pouvez gérer vos finances dans le menu "Finance"
      </RedirectMessage>
    </Container>
  );
};

const SoldTicketContainer = styled.div`
  position: relative;
  background: linear-gradient(180deg, #f1360a 0%, #f47c50 50%, #f8c198 100%);

  border-radius: 5px;
  box-shadow: 0 4px 20px rgba(0, 0, 255, 0.15);
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center; // Centré verticalement
  align-items: left; // Centré horizontalement
  color: white;
  padding: 20px;
  font-size: 32px; // Taille de police principale
`;

const SoldTicketAmount = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: bold;
  font-size: 48px;
`;

const SoldTicketIcon = styled(FaTicketSimple)`
  color: #f8c198;
`;

const SoldTicketText = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
`;

export const SoldTicket = ({ events }) => {
  const [totalTickets, setTotalTickets] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (events && events.length > 0) {
      getTotalTicketsSoldForEventGroup(events)
        .then((total) => {
          setTotalTickets(total);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Failed to fetch tickets:', error);
          setLoading(false);
        });
    } else {
      setLoading(false); // Handle case where events might be an empty array
    }
  }, [events]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <SoldTicketContainer>
      <SoldTicketAmount>
        <SoldTicketIcon size="1.5em" />
        {totalTickets}
      </SoldTicketAmount>
      <SoldTicketText>Tickets Vendus</SoldTicketText>
    </SoldTicketContainer>
  );
};

const AllUserEventsContainer = styled.div`
  position: relative;
  background: linear-gradient(180deg, #f1360a 0%, #f47c50 50%, #f8c198 100%);
  border-radius: 5px;
  box-shadow: 0 4px 20px rgba(0, 0, 255, 0.15);
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center; // Centré verticalement
  align-items: left;
  color: white;
  padding: 20px;
  font-size: 32px;
`;

const AllUserEventsAmount = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: bold;
  font-size: 48px;
`;

const AllUserEventsIcon = styled(FaChampagneGlasses)`
  color: #f8c198;
`;

const AllUserEventsText = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
`;

export const AllUserEvents = ({ events }) => {
  return (
    <AllUserEventsContainer>
      <AllUserEventsAmount>
        <AllUserEventsIcon size="1.5em" />
        {events.length}
      </AllUserEventsAmount>
      <AllUserEventsText>Événements</AllUserEventsText>
    </AllUserEventsContainer>
  );
};

const BottomBlocks = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 255, 0.15);
`;

const BottomBlockHeader = styled.div`
  display: flex;
  justify-content: space-between; /* Changé de flex-start à space-between */
  align-items: center;
  padding: 10px 20px;
  gap: 20px;
`;

const LeftHeaderGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px; /* Garde l'espace de 20px entre StyledDash et BottomBlockTitle */
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledDash = styled.div`
  width: 60px;
  height: 15px;
  background-color: #f1360a;
  border-radius: 20px;
  transform: translateY(50%);
`;

const BottomBlockTitle = styled.h2`
  font-weight: bold;
  font-size: 36px;
  color: #051145;
`;

const UserUpcomingEvents = styled.div`
  width: 100%;
  min-height: 500px;
`;

function TicketSalesCell({ event }) {
  const [ticketsSold, setTicketsSold] = useState(0);

  useEffect(() => {
    getTotalTicketsSoldForEvent(event).then(setTicketsSold);
  }, [event]);

  return (
    <IconNText
      icon={<IoTicketSharp size="24px" color="#051145" />}
      number={ticketsSold}
      label="Places vendues"
    />
  );
}

function DashboardPromoter() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);

  const redirectClick = (event) => {
    if (window.getSelection().toString().length === 0) {
      navigate(`/events/${event.id}`);
      window.scrollTo(0, 0);
    }
  };

  // Obtenir les events
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  // Souscription aux événements acceptés
  useEffect(() => {
    const q = query(
      collection(firestore_db, TABLE_NAME.events),
      where('review.state', '==', EVENT_REVIEW_STATE.ACCEPTED)
    );
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        setEvents(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
        setLoading(false);
      }
    );
    return () => unsubscribe(); // Nettoyage
  }, []);

  if (loading) {
    console.log('Loading...');
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Postère de l'événement",
        accessor: '',
        width: 130,
        Cell: ({ row }) => (
          <EventPoster src={row.original.posterPicture?.url} />
        ),
      },
      {
        Header: 'Détails',
        accessor: '',
        width: 300,
        Cell: ({ row }) => (
          <EventDetails
            title={row.original.name}
            location={
              row.original.location.place + ', ' + row.original.location.city
            }
            description={row.original.description}
          />
        ),
      },
      {
        Header: 'Nombre participant',
        accessor: '',
        width: 100,
        Cell: ({ row }) => (
          <IconNText
            icon={<FaUserFriends size="24px" color="#051145" />}
            number={getTotalAvailableTickets(row.original)}
            label="Places"
          />
        ),
      },
      {
        Header: 'Ventes',
        accessor: '',
        width: 100,
        Cell: ({ row }) => <TicketSalesCell event={row.original} />,
      },
      {
        Header: 'Dates',
        accessor: '',
        width: 100,
        Cell: ({ row }) => (
          <DateCell
            icon={<FaCalendarAlt size="24px" color="#051145" />}
            date={getEventDateRange(row.original)}
          />
        ),
      },
    ],
    []
  );

  const currentUserEvents = useFindCurrentUserEvents(events);

  const currentUserSortedEvents =
    sortEventsByNearestStartDate(currentUserEvents);

  return (
    <DashboardContainer>
      <TopBlocks>
        <UserBalance currentUser={currentUser} />
        <SoldTicket events={currentUserEvents} />
        <AllUserEvents events={currentUserEvents} />
      </TopBlocks>
      <BottomBlocks>
        <BottomBlockHeader>
          <LeftHeaderGroup>
            <StyledDash />
            <BottomBlockTitle> Mes Événements </BottomBlockTitle>
          </LeftHeaderGroup>
          <ButtonContainer>
            <Button
              size="md"
              color="#f1360a"
              bgColor="#f8c198"
              position="relative"
              onClick={() => {
                navigate(ROUTES.manageEventPage);
              }}
            >
              {' '}
              {'Gérer mes événements'}
            </Button>
            <Button
              size="md"
              color="white"
              bgColor="#f1360a"
              position="relative"
              onClick={() => setShowAll(!showAll)}
            >
              {' '}
              {showAll ? 'Réduire' : ' Voir tout'}
            </Button>
          </ButtonContainer>
        </BottomBlockHeader>

        <UserUpcomingEvents>
          <Array
            columns={columns}
            data={
              showAll
                ? currentUserSortedEvents
                : currentUserSortedEvents.slice(0, 5)
            }
            onRowClick={redirectClick}
            rowHeight="120px"
            showHeader={false}
          />
        </UserUpcomingEvents>
      </BottomBlocks>
    </DashboardContainer>
  );
}

export default DashboardPromoter;
