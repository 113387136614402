/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 10/05/2024
/* ******************************************* */

import React, { useState, useEffect, useRef } from 'react';
import { collection, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import {
  useDisclosure,
  useToast,
  Button,
  Text,
  VStack,
} from '@chakra-ui/react';
import EditFeedbackForm from '../EditFeedbackForm/EditFeedbackForm';
import FeedbackItem from '../FeedbackItem/FeedbackItem';
import AlertPopup from '../../atoms/AlertPopup/AlertPopup';
import { CiEdit } from 'react-icons/ci';
import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const ManageFeedbackListContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const ManageFeedbackCard = styled.div`
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
`;

function ManageFeedback() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  // Gère la suppresion et la modification de l'avis
  const cancelRef = useRef();
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  function openDeleteDialog(feedback) {
    setSelectedFeedback(feedback);
    onDeleteOpen();
  }

  function closeDeleteDialog() {
    setSelectedFeedback(null);
    onDeleteClose();
  }

  async function deleteFeedback() {
    if (!selectedFeedback) return;
    try {
      const feedbackRef = doc(
        firestore_db,
        TABLE_NAME.customerFeedbacks,
        selectedFeedback.id
      );
      await deleteDoc(feedbackRef);
      toast({
        title: 'Avis supprimé',
        description: "L'avis a été supprimé avec succès.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur de suppresion ',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    onDeleteClose(); // Fermez le modal après la suppression
  }

  // Modification de la feedback
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  function openEditDialog(feedback) {
    setSelectedFeedback(feedback);
    onEditOpen();
  }

  // recupère les données de firestore
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, TABLE_NAME.customerFeedbacks),
      (snapshot) => {
        const feedbackSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFeedbacks(feedbackSnapshot);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  if (loading) {
    console.log('Loading...');
  }

  return (
    <VStack pt={2} borderTop="1px" borderColor="gray.200" mt={10} mb={2}>
      <Text fontSize="2xl" as="b" textAlign="left">
        {' '}
        Listes des avis client{' '}
      </Text>

      <ManageFeedbackListContainer>
        {feedbacks.map((feedback) => (
          <ManageFeedbackCard key={feedback.id}>
            {' '}
            <FeedbackItem currentFeedback={feedback} />
            <Button
              position="absolute"
              right="0"
              top="0"
              size="xs"
              colorScheme="red"
              zIndex="1"
              onClick={() => openDeleteDialog(feedback)}
            >
              &#10005; {/* Croix */}
            </Button>
            <Button
              position="absolute"
              right={2}
              bottom={2}
              size="sm"
              colorScheme="blue"
              p={0}
              onClick={() => openEditDialog(feedback)}
            >
              <CiEdit />
            </Button>
          </ManageFeedbackCard>
        ))}
        {isDeleteOpen && (
          <AlertPopup
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={closeDeleteDialog}
            onNo={closeDeleteDialog}
            onYes={deleteFeedback}
            windowTitle="Supprimer l'avis"
          >
            Êtes-vous sûr de vouloir supprimer l'avis de
            {selectedFeedback?.writerName} intitulé "{selectedFeedback?.title}"
            ?
          </AlertPopup>
        )}
        {isEditOpen && (
          <EditFeedbackForm
            feedback={selectedFeedback}
            isOpen={isEditOpen}
            onClose={onEditClose}
          />
        )}
      </ManageFeedbackListContainer>
    </VStack>
  );
}

export default ManageFeedback;
