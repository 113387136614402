/* ******************************************** */
/* * Author: Fabrice NIKIEMA
/* * Project : SANNBIZ
/* * Create at : 01/04/2024
/* ******************************************* */

import React from 'react';
import { Link, Button } from '@chakra-ui/react';
import { FaPlay } from 'react-icons/fa';
import styled from 'styled-components';
import Image1 from '../../../assets/medias/homePage/WelcomeHomePicture1.png';
import Image2 from '../../../assets/medias/homePage/WelcomeHomePicture2.png';
import Image3 from '../../../assets/medias/homePage/WelcomeHomePicture3.png';
import leaf1 from '../../../assets/medias/welcome_home_leaf_1.png';
import leaf2 from '../../../assets/medias/welcome_home_leaf_2.png';
import leaf3 from '../../../assets/medias/welcome_home_leaf_3.png';
import leaf4 from '../../../assets/medias/welcome_home_leaf_4.png';
import { ROUTES } from '../../../utils/variables';

const WelcomeHomeBlock = styled.div`
  padding: 80px;
  display: flex;
  align-items: center;
  background-color: #f1f7fd;
  margin-top: -100px;
`;

const WelcomeHomeLeft = styled.div`
  display: flex;
  flex-direction: column;
`;
const WelcomeHomeText = styled.span`
  color: #f1360a;
`;

const WelcomeHomeTitle = styled.div`
  font-weight: bold;
  font-size: 48px;
  color: #051145;
`;

const WelcomeHomeDescription = styled.p`
  margin: 10px 0px;
`;

const WelcomeHomeRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const WelcomeHomeImgLeft = styled.div`
  position: relative;
  z-index: 0;
  &:before {
    content: '';
    position: absolute;
    transform: translate(-70%, -70%);
    width: 167px;
    height: 72px;
    background: url(${leaf1}) no-repeat;
    background-size: cover;
    z-index: -1;
  }
  &:after {
    content: '';
    position: absolute;
    transform: translate(30%, -70%);
    width: 205px;
    height: 196px;
    background: url(${leaf4}) no-repeat;
    background-size: cover;
    z-index: -1;
  }
`;

const PlayIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  color: white;
  padding: 20px;
  border: solid 10px #ecf0f2;
  border-radius: 50%;
  display: inline-block;
  align-items: center;
  font-size: 30px;
  background-color: #f1360a;
  z-index: 100;
`;

const WelcomeHomeImgsRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -60%);
    width: 102px;
    height: 103px;
    background: url(${leaf3}) no-repeat;
    background-size: cover;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(70%, 50%);
    width: 144px;
    height: 72px;
    background: url(${leaf2}) no-repeat;
    background-size: cover;
    z-index: -1;
  }
`;

const WelcomeHomeImg = styled.img`
  position: relative;
  z-index: 0;
  width: 270px;
  height: 270px;
  object-fit: cover;
  border-radius: 5px;

  @media screen and (max-width: 1200px) {
    width: 220px;
    height: 220px;
  }
`;

function WelcomeHomePage() {
  return (
    <WelcomeHomeBlock>
      <WelcomeHomeLeft>
        <WelcomeHomeText> Bienvenue sur Sanbbiz</WelcomeHomeText>
        <WelcomeHomeTitle>
          {' '}
          Événements et Activités à faire au Burkina Faso
        </WelcomeHomeTitle>
        <WelcomeHomeDescription>
          {' '}
          Explorez, Réservez, Vivez: Découvrez l'Essence Culturelle du Burkina
          Faso avec Sanbbiz - Votre porte d'accès aux événements incontournables
          !
        </WelcomeHomeDescription>
        <Link color="blue.500" href={ROUTES.createEventPage}>
          <Button
            mt={10}
            bgColor="#F1360A"
            color="white"
            _hover={{
              bgColor: 'rgb(70, 36, 224)',
            }}
          >
            {' '}
            Créer votre événement{' '}
          </Button>
        </Link>
      </WelcomeHomeLeft>

      <WelcomeHomeRight>
        <PlayIcon>
          <FaPlay color={'#ecf0f2'} />
        </PlayIcon>
        <WelcomeHomeImgLeft>
          <WelcomeHomeImg alt="" src={Image1} />
        </WelcomeHomeImgLeft>
        <WelcomeHomeImgsRight>
          <WelcomeHomeImg alt="" src={Image2} />
          <WelcomeHomeImg alt="" src={Image3} />
        </WelcomeHomeImgsRight>
      </WelcomeHomeRight>
    </WelcomeHomeBlock>
  );
}

export default WelcomeHomePage;
