/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';

function SearchIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M15.192 16.606a7 7 0 1 1 1.414-1.414l3.222 3.222-1.414 1.414-3.222-3.222ZM11 16a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
}

export default SearchIcon;
