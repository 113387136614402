/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 04/05/2024
/* ******************************************* */

import React from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import BlogList from '../../organisms/BlogList/BlogList';

function BlogsPage() {
  const title = 'Annonces & Articles'; // Le titre de la page

  return (
    <>
      <PageLayout title={title}>
        <BlogList />
      </PageLayout>
    </>
  );
}

export default BlogsPage;
