/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import { Link, Button } from '@chakra-ui/react';

import CreateEventImg from '../../../assets/medias/create-events.png';
import styled from 'styled-components';
import { ROUTES } from '../../../utils/variables';

const CreateEventBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(to top, #eee1ff 80%, white 20%);
`;

const CreateEventBlockWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1150px) {
    width: 45%;
  }
`;

const CreateEventBlockImg = styled.img`
  width: 90%;
  object-fit: cover;
  @media (max-width: 1150px) {
    // width: 100%;
    // display: none; // Masque l'image sur les écrans de 1000px ou moins
  }
`;

const CreateEventBlockDetail = styled.div`
  width: 50%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-right: 10%;
  padding-top: 10%;
`;
const CreateEventBlockTitle = styled.span`
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  font-size: 2em;
  font-weight: bold;
  margin: 0;
`;

const CreateEventText = styled.p`
  max-width: 500px;
  color: #272727;
  margin-bottom: 1em;
  font-size: 1.1em;
`;

function CreateEventHomePage() {
  return (
    <CreateEventBlock>
      <CreateEventBlockWrapper>
        <CreateEventBlockImg src={CreateEventImg} alt="Create Event" />
      </CreateEventBlockWrapper>
      <CreateEventBlockDetail>
        <CreateEventBlockTitle>Créez votre événement </CreateEventBlockTitle>
        <CreateEventText>
          Créez des moments inoubliables en partageant votre passion ! Publiez
          votre événement dès maintenant sur Sanbbiz et connectez-vous avec une
          communauté enthousiaste.
        </CreateEventText>
        <Link color="blue.500" href={ROUTES.createEventPage}>
          <Button
            width="310px"
            p="30px"
            bgColor="#F5167E"
            boxShadow="0 6px 10px rgba(0, 0, 0, 0.2)"
            borderRadius="30px"
            color="white"
            fontSize="20px"
            fontWeight="bold"
            _hover={{
              bgColor: '#3F5EFB',
              boxShadow: '0 6px 15px rgba(0, 0, 0, 0.25)',
            }}
          >
            {' '}
            Créer un événement{' '}
          </Button>
        </Link>
      </CreateEventBlockDetail>
    </CreateEventBlock>
  );
}

export default CreateEventHomePage;
