/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 30/03/2024
/* ******************************************* */

import React from 'react';
import PageLayout from '../../templates/PageLayout/PageLayout';
import CreateEventForm from '../../organisms/CreateEventForm/CreateEventForm';
import Update2PromoterAccount from '../../molecules/Update2PromoterAccountForm/Update2PromoterAccountForm';
import { useAuth } from '../../../contexts/AuthContext';
import { USER_STATUS } from '../../../utils/constants';
import RedirectComponent from '../../atoms/RedirectComponent/RedirectComponent';
import { ROUTES } from '../../../utils/variables';

function CreateEventPage() {
  const { userLoggedIn, currentUser } = useAuth();
  const createEventTitle = 'Créez votre événement';
  const update2PromoterTitle = 'Denevez un PROMOTEUR';

  // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
  if (!userLoggedIn) {
    return <RedirectComponent to={ROUTES.loginPage} replace={true} />;
  }

  if (!currentUser || !currentUser.dbProfile) {
    return <div>Loading...</div>;
  }

  // Vérification du statut de l'utilisateur
  const isCustomer = currentUser.dbProfile.status === USER_STATUS.CUSTOMER;
  const isPromoterOrAdmin =
    currentUser.dbProfile.status === USER_STATUS.PROMOTER ||
    currentUser.dbProfile.status === USER_STATUS.ADMIN;

  return (
    <>
      {isCustomer && (
        <PageLayout title={update2PromoterTitle}>
          <Update2PromoterAccount currentUser={currentUser.dbProfile} />
        </PageLayout>
      )}
      {isPromoterOrAdmin && (
        <PageLayout title={createEventTitle}>
          <CreateEventForm currentUser={currentUser.dbProfile} />
        </PageLayout>
      )}
    </>
  );
}

export default CreateEventPage;
