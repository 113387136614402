import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 20%;
  cursor: pointer;
  padding: 5px 0px;
  margin-left: 5px;
`;
const ImgContainer = styled.div`
  width: 80px;
  height: 70px;
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

const TextContainer = styled.div`
  display: flex;
  padding-left: 10px;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 1rem;
`;

function ResearchCatItem({ category, onCloseModal }) {
  const { id } = category;

  const navigate = useNavigate();
  const [clickStart, setClickStart] = useState(0);

  const handleMouseDown = (event) => {
    if (event.button === 0) {
      // 0 est le code pour le bouton gauche de la souris
      setClickStart(Date.now());
    }
  };

  const redirectClick = (event, categoryId) => {
    if (event.button === 0) {
      // Vérifiez à nouveau pour le clic gauche
      const clickDuration = Date.now() - clickStart;
      if (clickDuration < 200) {
        // 200 millisecondes pour un clic simple
        navigate(`/events?category=${categoryId}`);
        window.scrollTo(0, 0); // Scroller vers le haut de la page
        onClose();
      }
    }
  };

  const onClose = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  return (
    <Container
      onMouseDown={handleMouseDown}
      onMouseUp={(category) => redirectClick(category, id)}
    >
      <ImgContainer>
        <Img src={category.picture?.url} alt={`${category.name} icon`} />
      </ImgContainer>
      <TextContainer>
        <Title>{category.name}</Title>
      </TextContainer>
    </Container>
  );
}

export default ResearchCatItem;
