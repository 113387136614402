/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 10/05/2024
/* ******************************************* */

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';

function AlertPopup({
  isOpen,
  onClose,
  leastDestructiveRef,
  windowTitle,
  onYes,
  onNo,
  children,
  size = 'xl', // Vous pouvez définir une taille prédéfinie ou passer un prop personnalisé
  maxWidth = '2xl',
}) {
  if (!isOpen) return null;

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={leastDestructiveRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent width={size} maxWidth={maxWidth}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {windowTitle}
          </AlertDialogHeader>
          <AlertDialogBody>{children}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={leastDestructiveRef} onClick={onNo}>
              Non
            </Button>
            <Button colorScheme="red" onClick={onYes} ml={3}>
              Oui
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default AlertPopup;
