/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 30/03/2024
/* ******************************************* */

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import FormField from '../../atoms/FormField/FormField';
import { Button } from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/react';
import { createEventPonctuelDate } from '../../../utils/function';

import './CreateEventFormStep2.css';

function CreateEventFormDetails({
  setEventName,
  setEventDescription,
  setEventDates,
  setEventLocation,
}) {
  const dispatch = useDispatch();
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [isSwitchOn, setSwitchOn] = useState(false);

  // Date and Time initialization
  const now = new Date();
  const currentMinutes = now.getMinutes();
  const roundedMinutes = currentMinutes < 15 ? 0 : currentMinutes < 45 ? 30 : 0;
  const additionalHour = currentMinutes >= 45 ? 1 : 0;
  const startHour = (now.getHours() + additionalHour) % 24;
  const initialStartTime = `${startHour
    .toString()
    .padStart(2, '0')}:${roundedMinutes.toString().padStart(2, '0')}`;

  const [startDate, setStartDate] = useState(now.toISOString().split('T')[0]);
  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(() => {
    const endTime = new Date(now);
    endTime.setHours(startHour + 2); // Plus two hours
    endTime.setMinutes(roundedMinutes);
    if (endTime.getHours() >= 24) {
      // Adjust for over midnight scenario
      return '23:59';
    }
    return `${endTime.getHours().toString().padStart(2, '0')}:${roundedMinutes
      .toString()
      .padStart(2, '0')}`;
  });

  const handleDateTimeUpdates = useCallback(() => {
    if (!isRecurrent) {
      try {
        const ponctuelDate = createEventPonctuelDate(
          startDate,
          startTime,
          isSwitchOn,
          endTime
        );
        dispatch(
          setEventDates([
            {
              start: ponctuelDate.startDate,
              end: ponctuelDate.endDate,
            },
          ])
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      dispatch(setEventDates(null));
    }
  }, [
    startDate,
    startTime,
    endTime,
    dispatch,
    setEventDates,
    isSwitchOn,
    isRecurrent,
  ]);

  useEffect(() => {
    handleDateTimeUpdates();
  }, [handleDateTimeUpdates]);

  // Handlers for form fields
  const handleNameChange = (event) =>
    dispatch(setEventName(event.target.value));
  const handleDescriptionChange = (event) =>
    dispatch(setEventDescription(event.target.value));
  const handleLocationChange = (id, value) =>
    dispatch(setEventLocation({ [id]: value }));
  const handleSwitchChange = (event) => setSwitchOn(event.target.checked);

  return (
    <div className="step2-form-container">
      <h1 className="step2-form-title">Détails de l'événement</h1>

      <FormField
        id="name"
        label="Nom de l'événement"
        isRequired={true}
        inputType="text"
        placeholder="Titre de votre événement"
        onChange={handleNameChange}
      />
      <FormField
        id="description"
        label="Description de l'événement"
        isRequired={true}
        inputType="textarea"
        placeholder="Entrez une description attirante de votre événement "
        onChange={handleDescriptionChange}
        height="128px"
      />
      <Box pt={2} borderTop="1px" borderColor="gray.200" mt={4}>
        <Text fontSize="xl" as="b">
          {' '}
          Date et localisation{' '}
        </Text>
      </Box>

      <FormField
        id="eventDate"
        label="Date et heure"
        inputType="div"
        isRequired={true}
        onChange={() => {}}
        justify="left"
        gap="20px"
      >
        <Button
          size="md"
          colorScheme={isRecurrent === false ? 'blue' : 'gray'}
          onClick={() => setIsRecurrent(false)}
          disabled={isRecurrent === true}
        >
          Événement unique
        </Button>
        <Button
          size="md"
          colorScheme={isRecurrent === true ? 'blue' : 'gray'}
          onClick={() => setIsRecurrent(true)}
          disabled={isRecurrent === false}
        >
          Événement récurrent
        </Button>
      </FormField>
      {isRecurrent === false ? (
        <div className="step2-form-date-ponctuel-container">
          <div className="step2-form-time-grid">
            <FormField
              id="eventDateStart"
              label="Date de début"
              isRequired={true}
              inputType="date"
              value={startDate}
              updateDate={(value) => {
                setStartDate(value);
              }}
            />
            <FormField
              id="eventTimeStart"
              label="Heure de début"
              isRequired={true}
              inputType="time"
              value={startTime}
              updateTime={(value) => {
                setStartTime(value);
              }}
            />
          </div>
          <div className="step2-form-time-grid">
            <FormField
              id="endsTheNextDay "
              label="Se termine le lendemain ? "
              isRequired={true}
              inputType="toggle"
              onChange={handleSwitchChange}
            />
            {isSwitchOn}
            <FormField
              id="eventTimeEnd"
              label="Heure de fin "
              isRequired={true}
              inputType="time"
              value={endTime}
              updateTime={(value) => {
                setEndTime(value);
              }}
            />
          </div>
        </div>
      ) : isRecurrent === true ? (
        <Text fontSize="md" color="gray.600" as="cite" textAlign="left" m={4}>
          {' '}
          À l'étape suivante, vous aurez la possibilité de programmer un
          calendrier pour votre série d'événements récurrents. Tant les détails
          spécifiques de l'événement que les catégories de billets seront
          valables pour chaque occurrence de l'événement.{' '}
        </Text>
      ) : null}
      <div className="step2-form-content-container">
        <FormField
          id="place"
          label="Lieu"
          isRequired={true}
          inputType="text"
          placeholder="Où se déroulera votre évévenement ?"
          onChange={(event) =>
            handleLocationChange('place', event.target.value)
          }
        />
        <FormField
          id="city"
          label="Ville"
          isRequired={true}
          inputType="text"
          placeholder="Dans quel ville ?"
          onChange={(event) => handleLocationChange('city', event.target.value)}
        />
      </div>
      <FormField
        id="geoLocationLink"
        label="Lien de géolocalisation du lieu "
        isRequired={true}
        inputType="url"
        placeholder="Entrez le lien de la géolocalisation "
        onChange={(event) =>
          handleLocationChange('geoLocationLink', event.target.value)
        }
      />
    </div>
  );
}

export default CreateEventFormDetails;
