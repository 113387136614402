/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import defaultCover from '../../../assets/sanbbizLogos/logoWithColor.png';

const CategoryItemContainer = styled.div`
  position: relative;
  width: 270px;
  border-radius: 5px;
  height: 250px;
  overflow: hidden;
`;
const CategoryItemPoster = styled.img`
  height: 200px;
  width: 100%;
  object-fit: cover;
`;
const CategoryName = styled.h2`
  margin: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

function CategoryItem({ id, cover, name }) {
  const navigate = useNavigate();
  const [clickStart, setClickStart] = useState(0);

  const handleMouseDown = (event) => {
    if (event.button === 0) {
      // 0 est le code pour le bouton gauche de la souris
      setClickStart(Date.now());
    }
  };

  const redirectClick = (event, categoryId) => {
    if (event.button === 0) {
      // Vérifiez à nouveau pour le clic gauche
      const clickDuration = Date.now() - clickStart;
      if (clickDuration < 200) {
        // 200 millisecondes pour un clic simple
        navigate(`/events?category=${categoryId}`);
        window.scrollTo(0, 0); // Scroller vers le haut de la page
      }
    }
  };
  return (
    <CategoryItemContainer
      onMouseDown={handleMouseDown}
      onMouseUp={(category) => redirectClick(category, id)}
    >
      {cover ? (
        <CategoryItemPoster src={cover} alt={`${name} cover`} />
      ) : (
        <CategoryItemPoster src={defaultCover} alt={`${name} cover`} />
      )}
      <CategoryName>{name}</CategoryName>
    </CategoryItemContainer>
  );
}

export default CategoryItem;
