/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 27/04/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PictureListLinksForFormType1 } from '../../../assets/datas/PictureListLinks';
import logoWithColor from '../../../assets/sanbbizLogos/logoWithColor.png';

const ImageContainer = styled.div`
  background-size: cover;
  width: '100%';
  background-image: url(${logoWithColor}); /* Image de fond par défaut */
  background-position: center; /* Centrer les images d'arrière-plan */

  @media screen and (max-width: 930px) {
    display: none;
  }
`;

function MultipleImageBox() {
  // Etat inital , index compris entra 0 et 10
  const [currentIndex, setCurrentIndex] = useState(
    Math.floor(Math.random() * 11)
  );

  useEffect(() => {
    // Précharger toutes les images
    PictureListLinksForFormType1.forEach((image) => {
      const img = new Image();
      img.src = image;
    });

    const timer = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % PictureListLinksForFormType1.length
      );
    }, 60000); // Change toutes les 60 secondes

    return () => clearInterval(timer);
  }, []);

  return (
    <ImageContainer
      style={{
        backgroundImage: `url(${PictureListLinksForFormType1[currentIndex]}), url(${logoWithColor})`,
        backgroundSize: 'cover, cover',
      }}
    />
  );
}

export default MultipleImageBox;
