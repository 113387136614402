/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import { useEffect, useState } from 'react';
import { doc, getDoc } from '@firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import HorizontalScrollList from '../../molecules/HorizontalScrollList/HorizontalScrollList';
import event_cover from '../../../assets/sanbbizLogos/logoWithColor.png';
import { Button, useDisclosure, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import EventItem from '../../molecules/EventItem/EventItem';
import { useNavigate } from 'react-router-dom';
import TicketItem from '../../molecules/TicketItem/TicketItem';
import {
  look4NearestEventSlot,
  getEventDateRange,
  formatText,
} from '../../../utils/function';
import {
  getCompanyNameFromID,
  getUsernameFromID,
  getProfilePictureUrlFromID,
} from '../../../utils/user';
import {
  checkIfIsPastDate,
  getDateDetailsFromEventDetailSchedulerData,
} from '../../../utils/date';
import { IoIosArrowRoundBack } from 'react-icons/io';

import MapDesign from '../../atoms/MapDesign/MapDesign';

import EventInfoContent from '../../atoms/EventInfoContent/EventInfoContent';

import styled from 'styled-components';
import EventDetailSchedulerItem from '../../molecules/EventDetailSchedulerItem/EventDetailSchedulerItem';
import Popup from '../../atoms/Popup/Popup';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

// Avec react calendar pour styliser le calendrier il faut utiliser les classes
// de react calendar, donc css obligé
import './EventDetail.css';
import { FaRegCalendarCheck } from 'react-icons/fa';
import { TABLE_NAME } from '../../../utils/variables';
import { sortEventsByNearestStartDate } from '../../../utils/event';

const EventDetailPageContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  @media screen and (max-width: 1300px) {
    width: 100%;
  }
`;

const PreviousPage = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 0 10px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

const EventDetailContent = styled.div`
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  padding: 10px;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const CoverPictureContainer = styled.div`
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const EventCover = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 5px 2px rgba(66, 64, 255, 0.3);
    transform: translateY(-2px);
  }
`;

const FullImageDisplay = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const EventInfoContainer = styled.div`
  margin: 0px 50px 0 50px;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  transform: translateY(-50%);
  border-radius: 10px;
`;

const TitleNBooking = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
`;

const EventTitle = styled.h1`
  font-weight: bold;
  font-size: 45px;
  text-align: left;
`;

const EventDescriptionContainer = styled.div`
  text-align: left;
  padding: 20px 0px 30px 0px;
`;

const EventDescription = styled.p`
  white-space: pre-wrap;
  line-height: 20px;
`;

const EventDetailSubSection = styled.div`
  position: relative;
  padding: 30px 0px;
  background-color: ${(props) =>
    props.index % 2 === 0 ? '#FFFFFF' : '#ecf0f2'};
  width: 100%;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  padding: 10px;
  border-radius: 20px;
`;

const EventDetailSubSectionTitle = styled.h1`
  font-weight: bold;
  font-size: 32px;
  color: #051145;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EventListItemsContainer = styled.div`
  width: 100%;
  padding: 10px 0px;
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'space-around'};
  gap: 10px;
`;

const TicketsContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const EventCard = styled.div`
  box-shadow: 0 3px 5px rgba(0, 0, 255, 0.2);
`;

const SchedulerSubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const SlotSectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const SelectedSlotContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 10px;
  padding: 10px;
`;

const SelectedScheduleText = styled.h1`
  font-weight: bold;
  font-size: 1.2rem;
  text-align: left;
  margin-left: 10px;
`;

const SelectScheduleOptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
`;

const CalendarModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  min-height: 145px;
  border: 1px solid #051145;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 255, 0.1);
  margin: 10px;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px 10px rgba(5, 17, 69, 0.2);
    transform: translateY(-2px);
    color: white;
    background-color: #051145;
  }
`;

const CalendarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 20px;
  cursor: pointer;
`;

const Highlight = styled.div`
  background-color: #f1360a;
  border-radius: 50%;
  width: 10px;
  height: 10px;
`;

const ScheduleInCalendarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  align-content: flex-start;
  padding: 0px 10px;
`;
const ScheduleInCalendarItem = styled.div`
  border-width: 2px;
  align-items: center;
  border-color: #f1360a;
  color: #f1360a;
  font-weight: bold;
  padding: 5px;
  margin: 5px;
  width: fit-content;
  border-radius: 10px;
  cursor: pointer;
`;

function EventDetail({ setCurrentEvent }) {
  const navigate = useNavigate();
  const toast = useToast();

  const { eventId } = useParams();
  const [eventDetail, setEventDetail] = useState(null);
  const [similarEvents, setSimilarEvents] = useState([]);
  const {
    isOpen: isFullImageOpen,
    onOpen: openFullImage,
    onClose: closeFullImage,
  } = useDisclosure();

  //  --------- For Calendar -------------

  const [isSelectSlotOpen, setIsSelectSlotOpen] = useState(false);
  const [markedDates, setMarkedDates] = useState([]);
  const [availableSchedules, setAvailableSchedules] = useState([]);
  const [scheduleSelected, setScheduleSelected] = useState(null);
  const [schedulesToShow, setSchedulesToShow] = useState([]);

  //utiliser pour indiquer le créneau selectioner

  useEffect(() => {
    const eventRef = doc(firestore_db, TABLE_NAME.events, eventId);
    getDoc(eventRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const eventData = { id: docSnap.id, ...docSnap.data() };
          setEventDetail(eventData);
          setCurrentEvent(eventData); // Mettre à jour l'état dans le composant parent
          setMarkedDates(eventData.dates);
          eventData.dates.forEach((date, index) => {
            if (index === look4NearestEventSlot(eventData)) {
              setScheduleSelected(eventData.dates[index]);
              return;
            }
          });
        } else {
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
      });
  }, [eventId, setCurrentEvent]);

  useEffect(() => {
    if (markedDates.length !== 0) {
      console.log(getSchedulesToShow(markedDates));
      setSchedulesToShow(getSchedulesToShow(markedDates));
    }
  }, [markedDates]);

  useEffect(() => {
    if (!eventDetail || !eventDetail.categoryId) return;

    const fetchSimilarEvents = async () => {
      const q = query(
        collection(firestore_db, TABLE_NAME.events),
        where('categoryId', '==', eventDetail.categoryId)
      );

      const querySnapshot = await getDocs(q);
      const similarEventsList = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((event) => event.id !== eventDetail.id); // Exclure l'événement actuel des résultats

      setSimilarEvents(similarEventsList);
    };

    fetchSimilarEvents();
  }, [eventDetail]);

  const [userSurname, setUserSurname] = useState('');
  const [userCompany, setUserCompany] = useState('');
  const [userProfilePicture, setUserProfilePicture] = useState('');

  useEffect(() => {
    if (eventDetail && eventDetail.createdBy) {
      const fetchUserInfo = async () => {
        const profilePictureUrl = await getProfilePictureUrlFromID(
          eventDetail.createdBy
        );
        setUserProfilePicture(profilePictureUrl);

        const surname = await getUsernameFromID(eventDetail.createdBy);
        setUserSurname(surname);
        const companyName = await getCompanyNameFromID(eventDetail.createdBy);
        setUserCompany(companyName);
      };

      fetchUserInfo();
    } else {
      // console.log('eventDetail.createdBy is undefined');
    }
  }, [eventDetail]);

  // Condition pour vérifier si les données sont en train de charger ou si elles ne sont pas disponibles
  if (!eventDetail) {
    return <div>Chargement des détails de l'événement...</div>;
  }

  const position_url =
    eventDetail.location && eventDetail.location.geoLocationLink
      ? eventDetail.location.geoLocationLink
      : null;

  // Obtenir la liste des events futurs  de la meme categories
  function hasUpcomingEvents(events) {
    return events.some((event) => {
      const slotIndex = look4NearestEventSlot(event);
      if (slotIndex === undefined) return false;

      const nearestDate =
        event.dates && event.dates[slotIndex]
          ? event.dates[slotIndex].end.toDate()
          : null;

      return nearestDate && !checkIfIsPastDate(1, nearestDate);
    });
  }

  const handleBackClick = () => {
    navigate('/events');
    window.scrollTo(0, 0);
  };

  // --------------- For Calendar -----------------

  const isDateMarked = (dateToCheck) => {
    return markedDates.some((date) => {
      const markedDate = new Date(date.end.seconds * 1000);
      return (
        markedDate.getDate() === dateToCheck.getDate() &&
        markedDate.getMonth() === dateToCheck.getMonth() &&
        markedDate.getFullYear() === dateToCheck.getFullYear() &&
        !checkIfIsPastDate(1, markedDate)
      );
    });
  };

  const getAvailableSchedules = (date) => {
    let availableSchedules = [];

    markedDates.forEach((markedDate) => {
      const realDate = new Date(markedDate.start.seconds * 1000);
      if (
        realDate.getDate() === date.getDate() &&
        realDate.getMonth() === date.getMonth() &&
        realDate.getFullYear() === date.getFullYear()
      )
        availableSchedules.push(markedDate);

      setAvailableSchedules(availableSchedules);
    });
  };
  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      if (isDateMarked(date)) {
        return <Highlight />;
      }
    }
    return null;
  };

  const getSchedulesToShow = (dates) => {
    let arrayShow = [];
    for (let i = 0; i < dates.length; i++) {
      let element = dates[i];
      if (!checkIfIsPastDate(1, element.end.toDate())) {
        arrayShow.push(element);
        if (arrayShow.length === 5) break;
      }
    }
    return arrayShow;
  };

  // Fonction pour défiler jusqu'à la div
  const scrollToBookNow = () => {
    let element = '';
    if (eventDetail.dates.length === 1) {
      element = document.getElementById('bookNow');
    } else {
      element = document.getElementById('selectSlot');
    }

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const selectScheduleAndCloseModal = (schedule) => {
    setScheduleSelected(
      markedDates[markedDates.findIndex((date) => date === schedule)]
    );
    setIsSelectSlotOpen(false);
    toast({
      title: 'Nouveau créneau sélectionné',
      description: 'Vous avez sélectionné un nouveau créneau',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const sortedsimilarEvents = sortEventsByNearestStartDate(similarEvents);

  const justifyContentStyle =
    sortedsimilarEvents.length > 2 ? 'space-around' : 'center';

  const { weekday, day, month, year, slot } =
    getDateDetailsFromEventDetailSchedulerData(
      scheduleSelected,
      true,
      true,
      false
    );

  const handleShowFullImage = (e) => {
    e.stopPropagation(); // Prevents bubbling up to higher elements
    openFullImage(); // Correctly opens the popup
  };

  return (
    <EventDetailPageContainer>
      <PreviousPage onClick={handleBackClick}>
        <IoIosArrowRoundBack size={'32px'} />
        Retour aux événements
      </PreviousPage>
      <EventDetailContent>
        <CoverPictureContainer>
          <EventCover
            src={
              eventDetail?.coverPicture?.url ||
              eventDetail?.posterPicture?.url ||
              event_cover
            }
            alt="Couverture de l'événement"
            onClick={handleShowFullImage}
          />
          <Popup
            width="600px"
            isOpen={isFullImageOpen}
            onClose={closeFullImage}
            // disableCloseButton
          >
            <FullImageDisplay
              src={
                eventDetail?.coverPicture?.url ||
                eventDetail?.posterPicture?.url ||
                event_cover
              }
              alt="Image détaillée de l'événement"
            />
          </Popup>

          <EventInfoContainer>
            <EventInfoContent
              contentType="date"
              text={getEventDateRange(eventDetail)}
            />
            <EventInfoContent
              contentType="location"
              text={formatText(eventDetail.location.place)}
            />
            <EventInfoContent
              contentType="userInfo"
              imageUrl={userProfilePicture}
              surname={userSurname}
              company={userCompany}
            />
          </EventInfoContainer>
        </CoverPictureContainer>

        <TitleNBooking>
          <EventTitle>{eventDetail.name}</EventTitle>
          <Button
            p={6}
            color="white"
            bgColor="#F1360A"
            fontSize="1.2rem"
            onClick={scrollToBookNow}
          >
            Réserver Maintenant
          </Button>
        </TitleNBooking>

        <EventDescriptionContainer>
          <EventDescription>{eventDetail.description}</EventDescription>
        </EventDescriptionContainer>

        <MapDesign url={position_url} address={eventDetail.location.place} />
      </EventDetailContent>
      {/* {eventDetail &&
        eventDetail.galleryPictures &&
        eventDetail.galleryPictures.length > 0 && (
          <EventDetailSubSection>
            <EventDetailSubSectionTitle>Galerie</EventDetailSubSectionTitle>
            <HorizontalScrollList>
              {eventDetail.galleryPictures.map((picture, index) => (
                <img
                  key={index}
                  src={picture.url}
                  alt={`Gallery ${index + 1}`}
                  style={{ marginRight: '10px' }}
                />
              ))}
            </HorizontalScrollList>
          </EventDetailSubSection>
        )} */}

      {/* Si il ya plus d'un créneau on affiche les 5 premiers créneaux et le popup Calendar */}
      {eventDetail.dates.length > 1 && (
        <EventDetailSubSection id="selectSlot">
          <EventDetailSubSectionTitle>
            Sélectionner un créneau
          </EventDetailSubSectionTitle>

          <SchedulerSubContainer>
            <SelectedSlotContainer>
              <FaRegCalendarCheck size={'24px'} />
              <SelectedScheduleText>
                {`${weekday} ${day} ${month} ${year}, ${slot}`}
              </SelectedScheduleText>
            </SelectedSlotContainer>
            <SlotSectionContainer>
              <SelectScheduleOptionContainer>
                {eventDetail.dates.length > 0 &&
                  schedulesToShow.map((schedule, index) =>
                    index < 5 &&
                    !checkIfIsPastDate(
                      1,
                      new Date(schedule.end.seconds * 1000)
                    ) ? (
                      <EventDetailSchedulerItem
                        schedulerData={schedule}
                        isSelected={scheduleSelected === schedule}
                        onClick={(schedule) => {
                          setScheduleSelected(
                            eventDetail.dates[
                              markedDates.findIndex((date) => date === schedule)
                            ]
                          );
                        }}
                      />
                    ) : (
                      <></>
                    )
                  )}
              </SelectScheduleOptionContainer>
              {eventDetail.dates.length > 5 && (
                <CalendarModalContainer
                  onClick={() => setIsSelectSlotOpen(true)}
                >
                  Plus de dates
                  <Popup
                    popupTitle={'Sélectionner un créneau'}
                    isOpen={isSelectSlotOpen}
                    onClose={() => setIsSelectSlotOpen(false)}
                    disableCloseButton
                  >
                    <CalendarContainer>
                      <Calendar
                        onChange={(value) => getAvailableSchedules(value)}
                        tileContent={tileContent}
                        view="month"
                        locale="fr-FR"
                        showDoubleView={false}
                      />
                    </CalendarContainer>

                    {availableSchedules.length > 0 && (
                      <ScheduleInCalendarContainer>
                        {availableSchedules.map((schedule) => (
                          <ScheduleInCalendarItem
                            onClick={() => {
                              setScheduleSelected(schedule);
                              selectScheduleAndCloseModal(schedule);
                            }}
                          >
                            {`${
                              getDateDetailsFromEventDetailSchedulerData(
                                schedule,
                                true,
                                true,
                                false
                              ).slot
                            }`}
                          </ScheduleInCalendarItem>
                        ))}
                      </ScheduleInCalendarContainer>
                    )}
                  </Popup>
                </CalendarModalContainer>
              )}
            </SlotSectionContainer>
          </SchedulerSubContainer>
        </EventDetailSubSection>
      )}

      {eventDetail.ticketVersions && eventDetail.ticketVersions.length > 0 && (
        <EventDetailSubSection id="bookNow">
          <EventDetailSubSectionTitle>
            Prendre un billet
          </EventDetailSubSectionTitle>
          <TicketsContainer>
            {eventDetail.ticketVersions.map((ticket, index) => (
              <TicketItem
                index={index}
                ticketName={ticket.name}
                ticketType={ticket.isStand}
                price={ticket.price}
                remainTicket={ticket.stock}
                ticketDesc={ticket.description}
                eventId={eventId}
                scheduleSelected={scheduleSelected}
                // index du créneau dans l'array des dates
                schedule={markedDates.findIndex(
                  (date) => date === scheduleSelected
                )}
              />
            ))}
          </TicketsContainer>
        </EventDetailSubSection>
      )}

      {hasUpcomingEvents(sortedsimilarEvents) > 0 && (
        <EventDetailSubSection>
          <EventDetailSubSectionTitle>
            Événements Similaires
          </EventDetailSubSectionTitle>
          <HorizontalScrollList>
            <EventListItemsContainer justifyContent={justifyContentStyle}>
              {sortedsimilarEvents.map((event) => {
                return (
                  <EventCard>
                    <EventItem id={event.id} currentEvent={event} />
                  </EventCard>
                );
              })}
            </EventListItemsContainer>
          </HorizontalScrollList>
        </EventDetailSubSection>
      )}
    </EventDetailPageContainer>
  );
}

export default EventDetail;
