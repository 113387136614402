/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/04/2024
/* ******************************************* */

import { checkIfIsPastDate, formatDate, toDate } from './date';
import { format2XOF } from './price';
import {
  MARKETING_CAMPAIGN_REVIEW_STATE,
  EVENT_MNGT_PLAN_ADVANTAGES_TYPE,
} from './constants';
import { firestore_db } from '../api/firebase/firebase-config';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { TABLE_NAME } from './variables';

/**
 * This function is used to find the nearest slot of an event.
 *
 * @param {object} event event
 * @returns nearest event
 */
export function look4NearestEventSlot(event) {
  if (!event || !event.dates) {
    console.error('Objet événement invalide ou mal formé:', event);
    return undefined; // Retourne undefined si l'objet événement n'est pas conforme
  }

  let slotIdx = undefined;
  let nearestEndDate = undefined;
  let dates = event.dates;

  if (dates.length > 1) {
    if (checkIfIsPastEvent(dates) === true) {
      slotIdx = 0;
    } else {
      nearestEndDate = dates[dates.length - 1].end;
      for (let index = 0; index < dates.length; index++) {
        const { end } = dates[index];
        if (checkIfIsPastDate(1, end.toDate()) === false) {
          if (
            nearestEndDate &&
            checkIfIsPastDate(1, end.toDate(), nearestEndDate.toDate()) === true
          ) {
            nearestEndDate = end;
            slotIdx = index;
          }
        }
      }
    }
  } else if (dates.length === 1) {
    // Gérer le cas où il n'y a qu'une seule date
    slotIdx = 0;
  }

  return slotIdx;
}

/**
 * Used to check phone number format
 *
 * @param {string} phoneNumber user phone number
 * @returns true if phone number format is correct, otherwise false
 */
export function checkPhoneNumberFormat(phoneNumber) {
  let status = true;
  if (phoneNumber.length !== 8) {
    status = false;
  }
  return status;
}

/**
 * Used to check if an event is past.
 *
 * @param {object} dates event dates array
 * @returns true if event is past, otherwise false
 */
export function checkIfIsPastEvent(dates) {
  // Vérifie si le tableau dates est défini et non vide
  if (!dates || dates.length === 0) return false;

  // Obtient la dernière date de l'événement
  const lastDate = dates[dates.length - 1];

  // Vérifie si l'objet lastDate contient bien une propriété end
  if (!lastDate || !lastDate.end) {
    console.error('Invalid date object:', lastDate);
    return false;
  }

  // Assure que end est un Timestamp et convertit en objet Date
  const endDate = lastDate.end.toDate
    ? lastDate.end.toDate()
    : new Date(lastDate.end);

  // Retourne vrai si la date de fin est dans le passé
  return checkIfIsPastDate(1, endDate);
}

/**
 * Generates a message that describes the pricing of tickets based on their versions.
 * @param {Array} ticketVersions - An array of ticket version objects, each containing a price.
 * @returns {string} A message indicating the minimum price of the tickets, or that they are free.
 */
export function getPriceFromEventTickets(ticketVersions) {
  let message = ''; // Initializes the message string that will eventually be returned.
  let prefix = ''; // Initializes a prefix that may be added to the message to indicate "starting from".
  let min = 0; // Initializes the minimum price variable.

  // Iterates through each ticket version in the array
  for (let index = 0; index < ticketVersions?.length; index++) {
    const ticketVersion = ticketVersions[index]; // Gets the current ticket version object.
    const finalPrice = ticketVersion.price; // Extracts the price from the ticket version.

    // Checks if the current price is less than or equal to the current minimum price
    if (finalPrice <= min) {
      min = finalPrice; // Updates the minimum price if the current price is lower.
    } else if (index === 0) {
      // If it's the first item and no minimum has been set, set the minimum to the price of the first ticket
      min = finalPrice;
    } else if (finalPrice > 0) {
      // If the current price is greater than zero and not the first item, set the prefix.
      prefix = 'À partir de ';
    } else {
      // Placeholder for additional logic if needed
      // do something...
    }
  }

  // Finalizes the message based on the computed minimum price
  if (min > 0) {
    // If there is a minimum price greater than zero, format the price and prepend the prefix.
    message = `${prefix}${format2XOF(min)}`;
  } else {
    // If the minimum price is zero or all tickets are free, set the message to indicate free tickets.
    message = 'Gratuit';
  }

  return message; // Returns the final message.
}

/**
 * Used to compute total number of tickets sold
 *
 * @param {object} event event
 * @param {object} tickets array of tickets
 * @returns total number of tickets sold
 */
function computeEventTotalTicketsSold(event, tickets) {
  const eventTickets = tickets.filter((ticket) => ticket.eventId === event.id);
  return eventTickets.length;
}

/**
 * Used to sort a list of events by number of tickets sold
 *
 * @param {object} events events list
 * @param {object} tickets array of tickets
 * @returns events list sorted by number of tickets sold
 */
export function sortEventsByTicketsSold(events, tickets) {
  let changed = false;
  let sortedEvents = events.filter((event) => {
    const slotIndex = look4NearestEventSlot(event);
    if (slotIndex === undefined) return false; // Ignore les événements sans slot valide
    const nearestDate = event.dates[slotIndex].end.toDate();
    return !checkIfIsPastDate(1, nearestDate); // Retourne vrai pour les dates futures seulement
  });

  do {
    changed = false;
    for (let index = 0; index < sortedEvents.length - 1; index++) {
      const event_n0 = sortedEvents[index];
      const event_n1 = sortedEvents[index + 1];
      const ticketsSold_n0 = computeEventTotalTicketsSold(event_n0, tickets);
      const ticketsSold_n1 = computeEventTotalTicketsSold(event_n1, tickets);
      if (ticketsSold_n1 > ticketsSold_n0) {
        const temp = event_n0;
        sortedEvents[index] = event_n1;
        sortedEvents[index + 1] = temp;
        changed = true;
      }
    }
  } while (changed);
  return sortedEvents;
}

/**
 * Trie les catégories en fonction du nombre d'événements futurs dans chaque catégorie.
 *
 * @param {Array} categories - Un tableau d'objets catégorie, chaque objet contenant au moins un `id`.
 * @param {Array} events - Un tableau d'objets événement déjà filtré pour les événements futurs, chaque objet contenant au moins un `categoryId`.
 * @returns {Array} Un tableau de catégories trié par le nombre d'événements futurs décroissant.
 */
export function sortCategoriesByEventsNumber(categories, events) {
  // Création d'un objet pour compter les événements futurs par catégorie
  const eventCountByCategory = {};

  // Comptage des événements pour chaque catégorie
  events.forEach((event) => {
    if (event.categoryId in eventCountByCategory) {
      eventCountByCategory[event.categoryId]++;
    } else {
      eventCountByCategory[event.categoryId] = 1;
    }
  });

  // Ajout du compte d'événements à chaque catégorie
  const categoriesWithCounts = categories.map((category) => ({
    ...category,
    eventCount: eventCountByCategory[category.id] || 0, // Assurer qu'une catégorie sans événements a un compte de 0
  }));

  // Tri des catégories par le nombre d'événements futurs décroissant
  categoriesWithCounts.sort((a, b) => b.eventCount - a.eventCount);

  // Retourne les catégories triées
  return categoriesWithCounts;
}

/**
 * Récupère les détails de l'événement pour chaque vidéo et filtre les vidéos
 * selon des critères spécifiques (type de vidéo, état de la revue, et dates).
 *
 * @param {Array} marketingDocs - Un tableau contenant les vidéos à filtrer.
 * @returns {Promise<Array>} Un tableau de vidéos filtrées enrichi des détails des événements associés.
 */
export function filterSpotlightVideos(marketingDocs) {
  return marketingDocs.filter((marketingDoc) => {
    // Vérifier d'abord le type de vidéo
    if (marketingDoc.type !== EVENT_MNGT_PLAN_ADVANTAGES_TYPE.MARKETING_VIDEO) {
      return false;
    }

    // Vérifier ensuite l'état de la revue
    if (
      !marketingDoc.review ||
      marketingDoc.review.state !== MARKETING_CAMPAIGN_REVIEW_STATE.ACCEPTED
    ) {
      return false;
    }

    // Vérifier les dates de début et de fin avec les fonctions fournies
    if (
      !marketingDoc.date ||
      !marketingDoc.date.start ||
      !marketingDoc.date.end
    ) {
      return false;
    }

    // checkIfIsPastDate pour vérifier si la date de début est passée
    // et checkIfIsSameDate pour vérifier si maintenant est toujours avant ou le même jour que la date de fin
    return (
      checkIfIsPastDate(1, marketingDoc.date.start.toDate()) &&
      !checkIfIsPastDate(1, marketingDoc.date.end.toDate())
    );
  });
}

/**
 * Enrichit un document marketing avec les détails de son événement associé.
 */
export async function enrichMarketingGroupWithEventDetails(marketingDoc) {
  if (!marketingDoc.eventId) {
    console.error('No eventID provided in the marketing document.');
    return { ...marketingDoc, error: 'No eventID provided' }; // Add error information directly to the object
  }

  const eventRef = doc(firestore_db, TABLE_NAME.events, marketingDoc.eventId);
  const eventSnapshot = await getDoc(eventRef);

  if (!eventSnapshot.exists()) {
    console.error(`Event not found for event ID: ${marketingDoc.eventId}`);
    return {
      ...marketingDoc,
      error: `Event not found for event ID: ${marketingDoc.eventId}`,
    }; // Include error in the returned object
  }

  const eventData = eventSnapshot.data();
  return {
    ...marketingDoc,
    eventDetails: eventData,
  };
}

export function fromMarketingDoc2SpotlightedEventIds(marketingDocs) {
  const eventIdsSet = new Set(); // Créer un Set pour stocker les IDs d'événements uniques

  marketingDocs.forEach((marketingDoc) => {
    // Vérifier le type de marketingDoc
    if (
      marketingDoc.type !== EVENT_MNGT_PLAN_ADVANTAGES_TYPE.MARKETING_BOOST_FEED
    ) {
      return;
    }

    // Vérifier l'état de la revue
    if (
      !marketingDoc.review ||
      marketingDoc.review.state !== MARKETING_CAMPAIGN_REVIEW_STATE.ACCEPTED
    ) {
      return;
    }

    // Vérifier les dates de début et de fin
    if (
      !marketingDoc.date ||
      !marketingDoc.date.start ||
      !marketingDoc.date.end
    ) {
      return;
    }

    const startDate = marketingDoc.date.start.toDate();
    const endDate = marketingDoc.date.end.toDate();

    // Utiliser les fonctions pour vérifier les dates
    if (!checkIfIsPastDate(0, endDate)) {
      eventIdsSet.add(marketingDoc.eventId); // Ajouter l'ID de l'événement au Set s'il passe toutes les vérifications
    }
  });

  return Array.from(eventIdsSet); // Convertir le Set en Array avant de retourner les IDs d'événements
}

export async function getSpotlightedEvents(eventIds) {
  const eventsCollection = collection(firestore_db, TABLE_NAME.events);
  const queries = eventIds.map((id) => getDoc(doc(eventsCollection, id)));

  try {
    const documents = await Promise.all(queries);
    return documents
      .map((doc) => {
        if (doc.exists()) {
          return { id: doc.id, ...doc.data() };
        } else {
          return null;
        }
      })
      .filter((doc) => doc !== null); // Filtrer les documents inexistants
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
}

/**
 * Formate la plage de dates d'un événement selon qu'il soit ponctuel ou récurrent.
 * @param {object} event - L'objet événement contenant un tableau de dates avec des objets de date {start, end}.
 * @returns {string} Chaîne de dates formatée.
 */
export function getEventDateRange(event) {
  const { dates } = event;

  // Vérifier si les dates sont fournies pour l'événement.
  if (!dates || dates.length === 0) {
    console.error("Aucune date fournie pour l'événement:", event);
    return "Données de l'événement invalides"; // Retourne un message si aucune date n'est disponible.
  }

  // Pour un événement ponctuel, retourner la date de début formatée.
  if (dates.length === 1) {
    const startDate = dates[0].start.toDate();
    return startDate.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  }

  // Pour les événements récurrents, format la date de début du premier élément et la date de fin du dernier élément.
  const startDate = dates[0].start.toDate();
  const endDate = dates[dates.length - 1].end.toDate();
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const formatter = new Intl.DateTimeFormat('fr-FR', {
    day: '2-digit',
    month: 'long',
    year: startYear !== endYear ? 'numeric' : undefined, // Ajouter l'année seulement si les années sont différentes
  });

  const startFormatted = formatter.format(startDate);
  const endFormatted = formatter.format(endDate);

  // Condition pour ajouter l'année à la fin si elle n'est pas déjà incluse
  const yearSuffix = startYear !== endYear ? ` ${endYear}` : '';
  return `Du ${startFormatted} au ${endFormatted}${yearSuffix}`;
}

export function formatText(text) {
  // Verifie si tout le texte est en majuscule
  if (text === text.toUpperCase()) {
    // Transforme le premier caractère en majuscule
    return text.charAt(0) + text.slice(1).toLowerCase();
  }
  return text;
}

export function createEventPonctuelDate(
  startDate,
  startTime,
  isSwitchOn,
  endTime
) {
  // Vérification de la validité des entrées
  if (!startDate || !startTime || !endTime) {
    throw new Error('Les dates de début et de fin doivent être fournies.');
  }

  // Convertir les chaînes de date et d'heure en objets Date
  let startDateTime = new Date(`${startDate}T${startTime}`);
  let endDateTime = new Date(`${startDate}T${endTime}`);

  // Assurer que les objets Date sont valides
  if (isNaN(startDateTime.getTime()) || isNaN(endDateTime.getTime())) {
    throw new Error('Les dates fournies ne sont pas valides.');
  }

  // Vérifier que l'heure de début est avant l'heure de fin lorsque isSwitchOn est faux
  if (!isSwitchOn && startDateTime >= endDateTime) {
    throw new Error("L'heure de début doit être antérieure à l'heure de fin.");
  }

  // Si isSwitchOn est vrai, ajuster la date de fin pour ajouter un jour
  if (isSwitchOn) {
    endDateTime.setDate(endDateTime.getDate() + 1);
  }

  // Créer les timestamps pour Firestore
  const startDateTimestamp = startDateTime.getTime(); // Firestore utilise des millisecondes
  const endDateTimestamp = endDateTime.getTime(); // Firestore utilise des millisecondes

  return {
    startDate: startDateTimestamp,
    endDate: endDateTimestamp,
  };
}

/**
 * Extrait les détails d'un fichier image et les retourne sous forme de promesse.
 * @param {File} fileData - Le fichier image à partir duquel extraire les détails.
 * @returns {Promise<Object>} Une promesse qui résout avec les détails de l'image (largeur, hauteur, URL).
 */
export function extractImageFileDetails(fileData) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(fileData); //  URL temporaire pour le fichier image

    // quand l'image est chargé
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      URL.revokeObjectURL(url); // Libérer l'URL temporaire pour éviter les fuites de mémoire
      resolve({ width, height, url }); // Résoudre la promesse avec les détails de l'image
    };

    // Définir la gestion des erreurs en cas de problème au chargement de l'image
    img.onerror = reject; // Rejeter la promesse si une erreur survient

    img.src = url; // Définir la source de l'image pour déclencher le chargement
  });
}

/**
 * Formate la plage de dates des événements à partir des validités données.
 * Si toutes les dates de début sont les mêmes, affiche cette date unique.
 * Si les dates de début varient, affiche la plus petite et la plus grande date de début.
 * @param {Array} tickets - Tableau contenant des objets avec des propriétés `validity.start` et `validity.end` sous forme de Timestamp Firestore.
 * @return {string} La date ou la plage de dates formatée, ou une chaîne vide si aucune date valide n'est trouvée.
 */
export function getTicketDateMessage(tickets) {
  // console.log('Tickets received:', tickets);
  if (!tickets || !tickets.length) {
    console.error('Aucune validité fournie ou tableau vide');
    return '';
  }

  // Convertir les Timestamps en objets Date
  const dates = tickets.map((ticket) => ({
    start: toDate(ticket.validity.start),
    end: toDate(ticket.validity.end),
  }));

  // Trier les dates par `start`
  dates.sort((a, b) => a.start - b.start);
  const startDate = dates[0].start;
  const endDate = dates[dates.length - 1].start; // Notez que nous utilisons 'start' pour la fin aussi dans le cas de plages différentes

  // Vérifier si toutes les dates de début sont les mêmes
  const allStartsAreSame = dates.every(
    (date) => date.start.getTime() === startDate.getTime()
  );

  if (allStartsAreSame) {
    // Si toutes les dates de début sont les mêmes, formattez et retournez cette date
    return formatDate(startDate);
  } else {
    // Sinon, retournez une plage de la date de début la plus petite à la date de début la plus grande
    return `Du ${formatDate(startDate, false, false)} au ${formatDate(
      endDate,
      false,
      false
    )}`;
  }
}

/**
 * Récupère et calcule le bénéfice total d'un promoteur à partir des documents de balances.
 * @param {string} promoterId - L'ID du promoteur actuellement connecté.
 * @returns {Promise<number>} - Promesse qui résout le bénéfice total calculé.
 */
export async function calculateTotalProfit(promoterId) {
  console.log('Liste des documents recupérer');
  if (!promoterId) {
    console.error('Promoter ID is required');
    return 0; // Retourne 0 si l'ID du promoteur n'est pas fourni
  }

  try {
    const balancesCollection = collection(firestore_db, TABLE_NAME.balances);
    const q = query(balancesCollection, where('promoterId', '==', promoterId));
    const querySnapshot = await getDocs(q);

    console.log('Liste des documents recupérer', querySnapshot);

    let totalProfit = 0;

    querySnapshot.forEach((doc) => {
      const data = doc.data();

      if (data.isLocked || data.isCashedOut) {
        return;
      }

      if (data.amount && typeof data.commission === 'number') {
        const profit = (data.amount * (100 - data.commission)) / 100;
        totalProfit += profit;
      } else {
        console.error('Invalid or incomplete balance data:', data);
      }
    });

    return totalProfit;
  } catch (error) {
    console.error('Failed to fetch or process balances:', error);
    return 0; // Retourne 0 en cas d'erreur lors de la récupération ou du traitement des données
  }
}

/**
 * Supprime user id de followedBy à la suppression de compte
 * @param {string} userId Id de l'utilisateur à supprimer.
 */
export async function cleanUpUserSubscriptions(userId) {
  try {
    const usersCollection = collection(firestore_db, 'users');
    const q = query(
      usersCollection,
      where('followedBy', 'array-contains', userId)
    );

    const querySnapshot = await getDocs(q);
    const updates = [];

    querySnapshot.forEach((docSnapshot) => {
      const user = docSnapshot.data();
      const newFollowedBy = user.followedBy.filter((id) => id !== userId);

      // Create a promise to update each document
      const updatePromise = updateDoc(
        doc(firestore_db, 'users', docSnapshot.id),
        {
          followedBy: newFollowedBy,
        }
      );

      updates.push(updatePromise);
    });

    // Wait for all updates to complete
    await Promise.all(updates);
    console.log('All subscriptions have been cleaned up for userId:', userId);
    return {
      success: true,
      message: 'User subscriptions cleaned up successfully.',
    };
  } catch (error) {
    console.error('Error cleaning up user subscriptions:', error);
    return { success: false, message: error.message };
  }
}
