/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import CreateAccountForm from '../../molecules/CreateAccountForm/CreateAccountForm';
import PageLayout from '../../templates/PageLayout/PageLayout';

function CreateAccountPage() {
  const title = 'Créer un compte'; // Le titre de la page

  return (
    <>
      <PageLayout title={title}>
        <CreateAccountForm />
      </PageLayout>
    </>
  );
}

export default CreateAccountPage;
