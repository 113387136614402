import React from 'react';
import styled from 'styled-components';
import whatsappLogo from '../../../assets/medias/whatsappLogo.png';
import { useSanbbizConfig } from '../../../contexts/ConfigContext';

const WhatsAppButtonContainer = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 10px;
  background-color: #25d366;
  color: white;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 15px;
  z-index: 1000;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const WhatsAppButton = () => {
  const { config } = useSanbbizConfig();
  const phoneNumber = config.contact.whatsapp;
  const defaultMessage =
    "Bonjour, J'ai besoin d'aide. Pouvez-vous me recontacter après lecture ce message?";

  const openWhatsApp = () => {
    const encodedMessage = encodeURIComponent(defaultMessage);
    const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(url, '_blank');
  };

  return (
    <WhatsAppButtonContainer onClick={openWhatsApp}>
      <Img src={whatsappLogo} />
    </WhatsAppButtonContainer>
  );
};

export default WhatsAppButton;
