/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import { useEffect } from 'react';
import BoughtTicketItem from '../../molecules/BoughtTicketItem/BoughtTicketItem';
import { useSelector } from 'react-redux';
import { FaApple } from 'react-icons/fa';
import { DiAndroid } from 'react-icons/di';
import { IoTicket } from 'react-icons/io5';
import { FaHeartbeat } from 'react-icons/fa';
import { FaTreeCity } from 'react-icons/fa6';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { MdNavigateNext } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import sanbbizLogo from '../../../assets/sanbbizLogos/logo.png';
import bg_banner from '../../../assets/medias/banner/SpoligthedBackgroundPicture.png';
import styled from 'styled-components';
import { ROUTES } from '../../../utils/variables';
import { useSanbbizConfig } from '../../../contexts/ConfigContext';
import { Link } from '@chakra-ui/react';

const TicketDetailContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1300px) {
    width: 100%;
  }
`;

const PreviousPage = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 0 10px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

const TicketDetailContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 320px 1fr;
  gap: 20px;

  @media screen and (max-width: 780px) {
    grid-template-columns: 1fr;
  }
`;

const LeftContent = styled.div`
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  padding: 10px;
`;

const TicketItemsContainer = styled.div`
  width: 100%;
  text-align: left;
  font-size: 0.9rem;
  padding: 5px 20px;
  background-color: #ecf0f2;
  border-radius: 20px 20px 10px 10px;
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 10px;
`;

const TicketIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
`;

const TicketItems = styled.div`
  font-size: 1rem;
`;

const TicketVersions = styled.p`
  font-size: 0.8rem;
`;

const TicketsPriceContainer = styled.div`
  width: 100%;
  text-align: left;
  font-size: 1rem;
  padding: 10px 20px;
  background-color: #ecf0f2;
  border-radius: 10px 20px;
`;

const TotalPrice = styled.p`
  font-size: 1rem;
  font-weight: bold;
`;

const GoToEventDetails = styled.div`
  width: 100%;
  text-align: left;
  font-size: 1.1rem;
  padding: 10px 20px;
  background-color: #ecf0f2;
  border-radius: 20px 10px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #ccd1d9;
  }
`;

const RightContent = styled.div`
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  padding: 10px;
  border-radius: 20px;
`;

const AccessToApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  background-color: blueviolet;
  background: url(${bg_banner}) no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 10px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background-image: linear-gradient(
      to right,
      rgba(255, 192, 203, 1),
      rgba(0, 0, 255, 1)
    );
    opacity: 0.76;
    z-index: 2;
    border-radius: 10px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
`;

const DownloadAppText = styled.h2`
  display: grid;
  padding: 20px;
  margin: 0;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: left;
`;

const AppDetailBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const AppFeatureSection = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 10px;
`;

const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  background-color: #ecf0f2;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding-top: 20px;
`;

const ActionButton = styled(Link)`
  background-color: #ecf0f2;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #051145;

  &:hover {
  }
`;

const ActionButtonText = styled.span`
  // color: white;
  font-size: 1rem;
`;

function YourTicketDetail({ setCurrentEvent }) {
  const { config } = useSanbbizConfig();
  const navigate = useNavigate();
  const currentTicket = useSelector((state) => state.tickets.currentTicket);

  useEffect(() => {
    if (!currentTicket) {
      console.log('No current ticket available, redirecting...');
      navigate(ROUTES.ordersPage);
      window.scrollTo(0, 0);
    } else {
      setCurrentEvent(currentTicket.linkedEvent);
    }
  }, [currentTicket, setCurrentEvent, navigate]);

  // Additional useEffect to handle redirection if no current ticket is found in Redux store
  useEffect(() => {
    if (!currentTicket) {
      navigate(ROUTES.ordersPage);
      window.scrollTo(0, 0);
      return;
    }
    // Pour le haut de la page (routePath)
    setCurrentEvent(currentTicket.linkedEvent);
  }, [currentTicket, setCurrentEvent, navigate]);

  if (!currentTicket) {
    return <div> Chargements</div>;
  }

  const handleBackClick = () => {
    navigate(ROUTES.ordersPage);
    window.scrollTo(0, 0);
  };

  const handleGoToEventDetails = () => {
    navigate(`/events/${currentTicket?.boughtTicketGroup[0]?.eventId}`);
    window.scrollTo(0, 0);
  };

  const groupTicketsByVersionId = (tickets) => {
    const grouped = {};
    tickets?.boughtTicketGroup.forEach((ticket) => {
      const versionId = ticket.versionId;
      if (!grouped[versionId]) {
        grouped[versionId] = {
          tickets: [],
          name: tickets.linkedEvent.ticketVersions[versionId].name,
          price: tickets.linkedEvent.ticketVersions[versionId].price,
        };
      }
      grouped[versionId].tickets.push(ticket);
    });
    return grouped;
  };

  // get total ticket price from groupTicketsByVersionId
  const getTotalPrice = (ticketGroupedByVersionId) => {
    let totalPrice = 0;

    Object.values(ticketGroupedByVersionId).forEach((group) => {
      const pricePerTicket = group.price;
      const numberOfTickets = group.tickets.length;
      totalPrice += pricePerTicket * numberOfTickets;
    });

    return totalPrice === 0 ? 'Gratuit' : `${totalPrice} F CFA`;
  };

  const ticketGroupedByVersionId = groupTicketsByVersionId(currentTicket);

  return (
    <TicketDetailContainer>
      <PreviousPage onClick={handleBackClick}>
        <IoIosArrowRoundBack size={'32px'} />
        Retour aux billets
      </PreviousPage>
      <TicketDetailContent>
        {' '}
        <LeftContent>
          <BoughtTicketItem
            boughtTicketGroup={currentTicket.boughtTicketGroup}
            linkedEvent={currentTicket.linkedEvent}
          />
          <TicketItemsContainer>
            <TicketIconWrapper>
              <IoTicket size={'32px'} />
            </TicketIconWrapper>

            <TicketItems>
              Mes billets
              {Object.entries(ticketGroupedByVersionId).map(
                ([versionId, group]) => (
                  <TicketVersions key={versionId}>
                    {group.tickets.length + 'x Ticket ' + group.name}
                  </TicketVersions>
                )
              )}
            </TicketItems>
          </TicketItemsContainer>
          <TicketsPriceContainer>
            Total
            <TotalPrice> {getTotalPrice(ticketGroupedByVersionId)}</TotalPrice>
          </TicketsPriceContainer>
          <GoToEventDetails onClick={handleGoToEventDetails}>
            Détails de l'événement <MdNavigateNext size={'32px'} />
          </GoToEventDetails>
        </LeftContent>
        <RightContent>
          <AccessToApp>
            <Image src={sanbbizLogo} alt="Logo Sanbbiz" />
          </AccessToApp>
          <DownloadAppText>Télécharger l'application SANBBIZ</DownloadAppText>
          <AppDetailBlock>
            <AppFeatureSection>
              <IconWrapper>
                <FaTreeCity />
              </IconWrapper>
              <FeatureDescription>
                Découvre les meilleurs événements de ta ville, et reçois des
                recommandations personnalisées en fonction de tes centres
                d'intérêt
              </FeatureDescription>
            </AppFeatureSection>
            <AppFeatureSection>
              <IconWrapper>
                <FaHeartbeat />
              </IconWrapper>
              <FeatureDescription>
                Garde un oeil sur ce qui se passe en mettant des événements en
                favoris, en les partageant avec tes ami.e.s ou encore en
                retrouvant vos organisateur favoris depuis l'appli.
              </FeatureDescription>
            </AppFeatureSection>
            <AppFeatureSection>
              <IconWrapper>
                <IoTicket />
              </IconWrapper>
              <FeatureDescription>
                On a facilité le remboursement de billets en cas d'annulation de
                l'événement. No Stress
              </FeatureDescription>
            </AppFeatureSection>
          </AppDetailBlock>

          <ButtonContainer>
            <ActionButton href={config.url.appstore} isExternal>
              <FaApple />
              <ActionButtonText>iOS</ActionButtonText>
            </ActionButton>
            <ActionButton href={config.url.googleplay} isExternal>
              <DiAndroid />
              <ActionButtonText>Android</ActionButtonText>
            </ActionButton>
          </ButtonContainer>
        </RightContent>
      </TicketDetailContent>
    </TicketDetailContainer>
  );
}

export default YourTicketDetail;
