/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useContext, useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { authToken, firestore_db } from '../api/firebase/firebase-config';
// import { GoogleAuthProvider } from "firebase/auth";
import { onAuthStateChanged } from 'firebase/auth';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isEmailUser, setIsEmailUser] = useState(false);
  //   const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authToken, (user) => {
      if (user) {
        initializeUser(user);
      } else {
        setCurrentUser(null);
        setUserLoggedIn(false);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, []);

  async function initializeUser(user) {
    if (user) {
      try {
        const userRef = doc(firestore_db, 'users', user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            authProfile: user,
            dbProfile: userData,
          });
          setIsEmailUser(
            user.providerData.some(
              (provider) => provider.providerId === 'password'
            )
          );
          setUserLoggedIn(true);
        } else {
          console.log('No user data found in Firestore');
          setCurrentUser({
            authProfile: user,
            dbProfile: {},
          });
          setUserLoggedIn(false); // Utilisateur n'est pas trouvé dans Firestore
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setUserLoggedIn(false);
      }
      setLoading(false);
    }
  }

  async function reloadUser() {
    const user = authToken.currentUser;
    if (user) {
      try {
        const userRef = doc(firestore_db, 'users', user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            authProfile: user,
            dbProfile: userData,
          });
          setUserLoggedIn(true);
        } else {
          console.log('No user data found in Firestore');
          setCurrentUser({
            authProfile: user,
            dbProfile: {},
          });
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    }
  }

  const value = {
    userLoggedIn,
    isEmailUser,
    // isGoogleUser,
    currentUser,
    setCurrentUser,
    reloadUser,
    setUserLoggedIn,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
