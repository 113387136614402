/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import { Box } from '@chakra-ui/react';

function Logo(props) {
  return (
    <Box {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0.00 0.00 500.00 500.00"
      >
        <g stroke-width="2.00" fill="none" stroke-linecap="butt">
          <path
            stroke="#753634"
            vector-effect="non-scaling-stroke"
            d="
  M 264.39 122.56
  Q 258.91 130.02 256.26 136.24
  Q 251.26 147.96 249.01 164.47
  Q 248.94 165.04 248.47 164.70
  Q 248.24 164.53 248.22 164.25
  Q 247.57 155.06 244.78 145.84"
          />
          <path
            stroke="#1d3b55"
            vector-effect="non-scaling-stroke"
            d="
  M 244.78 145.84
  Q 247.64 145.98 248.34 144.02
  Q 249.00 142.18 248.77 139.94"
          />
          <path
            stroke="#876636"
            vector-effect="non-scaling-stroke"
            d="
  M 248.77 139.94
  L 257.41 125.54
  Q 257.69 125.06 257.34 124.65
  L 256.96 124.21"
          />
          <path
            stroke="#1d3b55"
            vector-effect="non-scaling-stroke"
            d="
  M 256.96 124.21
  Q 257.75 122.25 257.36 119.13
  Q 256.49 112.37 256.03 110.46"
          />
          <path
            stroke="#264c67"
            vector-effect="non-scaling-stroke"
            d="
  M 256.03 110.46
  Q 254.49 98.32 248.71 86.93"
          />
          <path
            stroke="#1d3b55"
            vector-effect="non-scaling-stroke"
            d="
  M 248.71 86.93
  L 247.95 72.59"
          />
          <path
            stroke="#2f607f"
            vector-effect="non-scaling-stroke"
            d="
  M 248.71 86.93
  Q 244.71 96.08 242.36 105.27
  Q 240.24 113.57 240.14 122.13
  Q 240.13 123.10 240.66 124.43"
          />
          <path
            stroke="#907a4e"
            vector-effect="non-scaling-stroke"
            d="
  M 240.66 124.43
  Q 240.18 124.78 240.14 125.48
  Q 240.11 125.94 240.46 126.51
  Q 244.47 133.12 248.77 139.94"
          />
          <path
            stroke="#7d4b4c"
            vector-effect="non-scaling-stroke"
            d="
  M 244.78 145.84
  Q 241.09 134.18 234.03 123.73
  Q 233.65 123.18 233.63 121.25
  Q 233.63 121.01 233.43 120.89
  L 233.18 120.73"
          />
          <path
            stroke="#e8762c"
            vector-effect="non-scaling-stroke"
            d="
  M 303.07 93.06
  Q 301.06 103.48 297.71 113.08
  Q 297.31 114.19 296.77 114.52
  Q 290.31 118.44 285.13 123.79"
          />
          <path
            stroke="#e99e83"
            vector-effect="non-scaling-stroke"
            d="
  M 285.13 123.79
  Q 271.27 134.17 263.30 144.05
  C 255.80 153.35 252.26 164.82 249.76 176.44
  Q 248.68 181.45 247.78 176.40
  Q 245.82 165.31 240.60 153.97
  Q 237.92 148.16 234.12 143.84
  Q 229.26 138.34 224.72 134.12
  Q 222.27 131.84 218.89 129.23
  Q 215.72 126.79 212.97 124.28"
          />
          <path
            stroke="#e8762c"
            vector-effect="non-scaling-stroke"
            d="
  M 212.97 124.28
  Q 208.94 119.55 203.45 116.55
  C 201.53 115.50 200.00 114.27 199.35 112.41
  Q 196.55 104.42 194.36 93.08"
          />
          <path
            stroke="#2f607f"
            vector-effect="non-scaling-stroke"
            d="
  M 256.03 110.46
  C 254.05 112.95 251.87 112.87 249.23 113.69
  A 1.20 1.19 -85.1 0 0 248.51 114.30
  Q 248.34 114.65 248.50 114.78"
          />
          <path
            stroke="#998b60"
            vector-effect="non-scaling-stroke"
            d="
  M 248.50 114.78
  L 240.66 124.43"
          />
          <path
            stroke="#907a4e"
            vector-effect="non-scaling-stroke"
            d="
  M 256.96 124.21
  Q 256.88 124.22 256.71 124.19
  Q 256.60 124.17 256.52 124.08
  L 248.50 114.78"
          />
          <path
            stroke="#898e8c"
            vector-effect="non-scaling-stroke"
            d="
  M 333.62 113.15
  Q 322.88 115.21 320.00 116.21
  Q 302.85 122.17 287.81 132.57"
          />
          <path
            stroke="#e99e83"
            vector-effect="non-scaling-stroke"
            d="
  M 287.81 132.57
  Q 270.44 144.55 257.84 163.34
  Q 253.51 169.80 251.39 176.57"
          />
          <path
            stroke="#898e8c"
            vector-effect="non-scaling-stroke"
            d="
  M 251.39 176.57
  L 249.13 179.53
  A 0.54 0.53 44.7 0 1 248.28 179.53
  C 246.33 177.01 245.86 174.07 244.26 171.20
  Q 240.65 164.79 236.45 158.76"
          />
          <path
            stroke="#9bb3b6"
            vector-effect="non-scaling-stroke"
            d="
  M 236.45 158.76
  Q 225.37 143.47 209.72 132.73"
          />
          <path
            stroke="#898e8c"
            vector-effect="non-scaling-stroke"
            d="
  M 209.72 132.73
  Q 195.12 122.72 179.50 117.02
  Q 173.72 114.91 165.36 113.44
  Q 164.76 113.34 164.40 112.56"
          />
          <path
            stroke="#fccd85"
            vector-effect="non-scaling-stroke"
            d="
  M 212.97 124.28
  Q 203.35 118.04 193.64 114.62"
          />
          <path
            stroke="#fccd85"
            vector-effect="non-scaling-stroke"
            d="
  M 303.43 114.33
  Q 301.87 115.32 299.03 116.43
  Q 296.49 117.43 294.64 118.48
  Q 290.01 121.11 285.13 123.79"
          />
          <path
            stroke="#876636"
            vector-effect="non-scaling-stroke"
            d="
  M 284.45 163.37
  L 285.61 167.21
  A 1.04 1.04 0.0 0 0 286.37 167.93
  Q 291.70 169.23 297.17 171.66
  Q 297.68 171.88 298.20 171.70
  L 304.00 169.62"
          />
          <path
            stroke="#1d3b55"
            vector-effect="non-scaling-stroke"
            d="
  M 312.62 171.98
  L 305.71 176.05
  Q 305.28 176.30 305.47 176.76
  Q 306.64 179.48 306.66 182.36"
          />
          <path
            stroke="#1d3b55"
            vector-effect="non-scaling-stroke"
            d="
  M 190.78 182.39
  Q 190.68 179.35 192.18 176.78
  Q 192.44 176.35 191.99 176.12
  L 185.50 172.75
  A 0.51 0.51 0.0 0 1 185.51 171.84
  Q 188.25 170.46 191.01 169.70
  C 193.72 168.96 196.27 170.43 198.67 171.47
  A 2.82 2.81 -45.1 0 0 200.91 171.46
  Q 205.59 169.43 210.68 168.18
  Q 212.72 167.68 212.94 163.98"
          />
          <path
            stroke="#264c67"
            vector-effect="non-scaling-stroke"
            d="
  M 236.45 158.76
  L 230.31 167.24
  Q 229.97 167.70 229.41 167.59
  Q 225.24 166.73 221.00 166.72
  A 2.06 2.02 61.5 0 1 219.86 166.37
  Q 217.41 164.73 215.05 162.03
  Q 214.72 161.64 214.28 161.89
  Q 213.41 162.38 212.94 163.98"
          />
          <path
            stroke="#2f607f"
            vector-effect="non-scaling-stroke"
            d="
  M 212.94 163.98
  C 208.75 163.70 204.26 165.28 200.21 166.39
  Q 199.73 166.52 199.26 166.34
  Q 195.87 164.99 194.42 162.15"
          />
          <path
            stroke="#264c67"
            vector-effect="non-scaling-stroke"
            d="
  M 209.72 132.73
  Q 214.66 140.07 219.93 146.65
  C 222.66 150.07 224.74 153.50 226.83 157.23
  A 0.46 0.46 0.0 0 1 226.36 157.91
  Q 212.74 155.91 199.51 160.09
  Q 196.16 161.14 194.42 162.15"
          />
          <path
            stroke="#1d3b55"
            vector-effect="non-scaling-stroke"
            d="
  M 194.42 162.15
  Q 193.65 161.97 192.85 161.42"
          />
          <path
            stroke="#876636"
            vector-effect="non-scaling-stroke"
            d="
  M 192.85 161.42
  Q 188.80 153.66 184.65 146.32
  Q 184.36 145.82 184.02 145.97"
          />
          <path
            stroke="#876636"
            vector-effect="non-scaling-stroke"
            d="
  M 313.33 146.05
  Q 308.72 152.63 304.64 161.61"
          />
          <path
            stroke="#753634"
            vector-effect="non-scaling-stroke"
            d="
  M 304.64 161.61
  Q 304.28 161.83 303.73 161.93
  Q 303.32 162.00 302.84 161.81
  Q 287.80 155.66 271.44 157.94
  A 0.66 0.66 0.0 0 1 270.78 156.94
  Q 278.26 144.74 288.52 133.49
  A 0.68 0.68 0.0 0 0 288.20 132.37
  Q 287.89 132.29 287.81 132.57"
          />
          <path
            stroke="#e8762c"
            vector-effect="non-scaling-stroke"
            d="
  M 304.64 161.61
  Q 305.51 163.13 307.87 163.71"
          />
          <path
            stroke="#7d4b4c"
            vector-effect="non-scaling-stroke"
            d="
  M 322.39 176.36
  L 312.62 171.98"
          />
          <path
            stroke="#753634"
            vector-effect="non-scaling-stroke"
            d="
  M 312.62 171.98
  Q 309.08 170.47 306.28 169.83
  Q 305.21 169.58 304.00 169.62"
          />
          <path
            stroke="#e8762c"
            vector-effect="non-scaling-stroke"
            d="
  M 304.00 169.62
  Q 304.09 169.49 304.12 169.36
  Q 304.17 169.14 303.96 169.05
  Q 294.71 164.94 284.45 163.37"
          />
          <path
            stroke="#753634"
            vector-effect="non-scaling-stroke"
            d="
  M 284.45 163.37
  Q 284.26 162.11 283.61 161.82
  Q 283.12 161.60 282.72 161.94
  L 277.76 166.19
  Q 277.34 166.55 276.79 166.55
  Q 262.71 166.55 251.39 176.57"
          />
          <path
            stroke="#907a4e"
            vector-effect="non-scaling-stroke"
            d="
  M 192.85 161.42
  Q 192.60 162.87 189.72 163.30"
          />
          <path
            stroke="#753634"
            vector-effect="non-scaling-stroke"
            d="
  M 218.00 120.08
  Q 225.70 125.14 232.22 131.64
  C 234.47 133.88 236.24 136.83 238.21 139.38
  Q 238.23 139.41 238.26 139.39
  L 238.46 139.24
  Q 238.62 139.11 238.51 138.95
  Q 235.02 133.78 231.29 128.47
  C 228.36 124.29 225.15 120.70 221.73 116.90"
          />
          <path
            stroke="#7d4b4c"
            vector-effect="non-scaling-stroke"
            d="
  M 221.73 116.90
  Q 218.23 113.25 213.82 109.67
  C 211.97 108.17 207.62 103.99 205.44 107.61
  C 203.35 111.07 207.37 113.65 209.88 114.91
  Q 214.19 117.07 218.00 120.08"
          />
          <path
            stroke="#1d3b55"
            vector-effect="non-scaling-stroke"
            d="
  M 221.73 116.90
  Q 219.98 117.83 218.00 120.08"
          />
          <path
            stroke="#7d4b4c"
            vector-effect="non-scaling-stroke"
            d="
  M 260.17 139.13
  C 263.23 133.86 267.80 129.15 272.44 125.36
  Q 279.82 119.33 289.88 113.29
  Q 291.61 112.25 292.39 110.53
  C 293.24 108.68 292.32 105.92 289.97 106.02
  Q 288.23 106.09 286.62 107.29
  C 275.42 115.68 266.39 126.84 259.55 138.76
  A 0.36 0.36 0.0 0 0 259.85 139.30
  Q 260.06 139.31 260.17 139.13"
          />
          <path
            stroke="#3b6d7c"
            vector-effect="non-scaling-stroke"
            d="
  M 210.83 151.11
  Q 201.74 140.82 191.18 133.32"
          />
          <path
            stroke="#753634"
            vector-effect="non-scaling-stroke"
            d="
  M 191.18 133.32
  L 180.64 126.10
  Q 179.45 125.29 180.19 126.52
  Q 180.95 127.78 181.87 129.14
  C 187.42 137.23 191.89 146.27 195.23 155.53
  A 0.94 0.93 73.0 0 0 196.33 156.12
  L 211.00 152.40
  Q 211.59 152.25 211.41 151.66
  Q 211.28 151.23 210.83 151.11"
          />
          <path
            stroke="#9c7d72"
            vector-effect="non-scaling-stroke"
            d="
  M 191.18 133.32
  L 197.73 146.61
  A 3.44 3.44 0.0 0 0 200.01 148.44
  L 210.83 151.11"
          />
          <path
            stroke="#3b6d7c"
            vector-effect="non-scaling-stroke"
            d="
  M 307.01 132.74
  Q 295.49 140.85 286.28 151.32
  Q 285.99 151.66 286.06 151.88
  Q 286.15 152.18 286.74 152.24"
          />
          <path
            stroke="#753634"
            vector-effect="non-scaling-stroke"
            d="
  M 286.74 152.24
  L 286.98 152.46
  Q 287.21 152.67 287.52 152.74
  L 300.51 156.01
  A 1.53 1.52 17.7 0 0 302.31 155.09
  Q 308.34 139.89 316.88 127.50
  Q 318.86 124.61 315.97 126.60
  L 307.01 132.74"
          />
          <path
            stroke="#9c7d72"
            vector-effect="non-scaling-stroke"
            d="
  M 286.74 152.24
  C 289.59 149.83 294.57 148.99 298.07 147.95
  A 2.65 2.61 5.2 0 0 299.64 146.66
  L 307.01 132.74"
          />
          <path
            stroke="#876636"
            vector-effect="non-scaling-stroke"
            d="
  M 244.01 190.32
  Q 243.55 193.36 241.38 195.10"
          />
          <path
            stroke="#898e8c"
            vector-effect="non-scaling-stroke"
            d="
  M 241.38 195.10
  Q 239.51 193.36 236.61 195.97
  Q 234.08 198.25 233.12 198.80
  C 221.07 205.62 209.69 199.81 202.33 189.23
  Q 202.01 188.78 201.46 188.75
  Q 199.28 188.66 197.71 189.69
  Q 196.79 190.30 197.63 191.04"
          />
          <path
            stroke="#3b6d7c"
            vector-effect="non-scaling-stroke"
            d="
  M 197.63 191.04
  L 199.11 202.01
  A 2.15 2.14 7.8 0 0 200.40 203.70
  L 218.95 211.60
  A 0.80 0.79 -61.0 0 0 219.91 211.32
  L 223.73 205.80"
          />
          <path
            stroke="#898e8c"
            vector-effect="non-scaling-stroke"
            d="
  M 223.73 205.80
  L 230.62 204.85
  A 0.49 0.49 0.0 0 1 231.09 205.62
  C 227.60 210.54 224.09 215.42 221.31 220.77
  Q 219.03 225.17 219.30 229.89
  C 220.10 243.87 230.34 255.92 243.05 261.67
  C 250.76 265.15 257.33 260.90 263.42 256.33
  C 271.58 250.19 278.11 239.94 278.10 229.23
  Q 278.10 222.06 273.41 215.56
  Q 269.99 210.82 266.71 205.66
  A 0.47 0.47 0.0 0 1 267.17 204.95
  L 274.59 206.03"
          />
          <path
            stroke="#3b6d7c"
            vector-effect="non-scaling-stroke"
            d="
  M 274.59 206.03
  L 277.58 211.43
  A 0.53 0.53 0.0 0 0 278.25 211.66
  L 297.78 203.33
  Q 298.28 203.12 298.35 202.59
  L 300.14 190.35"
          />
          <path
            stroke="#898e8c"
            vector-effect="non-scaling-stroke"
            d="
  M 300.14 190.35
  Q 298.96 188.70 295.89 188.62
  Q 295.40 188.60 295.14 189.01
  C 286.92 202.17 270.36 206.91 258.82 194.32
  A 0.73 0.72 58.9 0 0 258.06 194.13
  Q 256.93 194.51 255.80 195.42"
          />
          <path
            stroke="#876636"
            vector-effect="non-scaling-stroke"
            d="
  M 255.80 195.42
  Q 253.96 193.07 253.56 190.12"
          />
          <path
            stroke="#898e8c"
            vector-effect="non-scaling-stroke"
            d="
  M 253.56 190.12
  Q 271.14 174.98 293.25 182.51
  Q 296.62 183.66 299.81 184.75
  Q 300.70 185.06 300.71 184.11
  C 300.71 182.42 300.86 181.31 299.31 180.13
  C 286.13 170.10 270.60 168.48 257.01 178.25
  Q 254.79 179.85 249.37 186.18"
          />
          <path
            stroke="#876636"
            vector-effect="non-scaling-stroke"
            d="
  M 249.37 186.18
  Q 248.54 186.72 247.74 185.91"
          />
          <path
            stroke="#898e8c"
            vector-effect="non-scaling-stroke"
            d="
  M 247.74 185.91
  Q 232.34 165.83 208.33 174.26
  Q 204.29 175.68 197.28 180.83
  Q 196.75 181.23 196.75 181.89
  L 196.77 184.43
  Q 196.77 185.23 197.52 184.94
  C 201.92 183.19 206.72 181.28 211.31 180.54
  Q 229.67 177.58 244.01 190.32"
          />
          <path
            stroke="#fccd85"
            vector-effect="non-scaling-stroke"
            d="
  M 247.74 185.91
  L 244.01 190.32"
          />
          <path
            stroke="#fccd85"
            vector-effect="non-scaling-stroke"
            d="
  M 255.80 195.42
  Q 255.68 196.33 256.62 197.48"
          />
          <path
            stroke="#e59120"
            vector-effect="non-scaling-stroke"
            d="
  M 256.62 197.48
  L 256.43 205.85"
          />
          <path
            stroke="#876636"
            vector-effect="non-scaling-stroke"
            d="
  M 256.43 205.85
  Q 255.34 209.32 255.53 210.70"
          />
          <path
            stroke="#fccd85"
            vector-effect="non-scaling-stroke"
            d="
  M 255.53 210.70
  Q 253.77 215.02 249.87 217.57
  Q 249.42 217.86 248.89 217.78
  C 246.97 217.48 245.11 215.49 243.80 214.12
  Q 243.45 213.75 243.38 213.25
  Q 243.13 211.33 242.09 210.45"
          />
          <path
            stroke="#876636"
            vector-effect="non-scaling-stroke"
            d="
  M 242.09 210.45
  L 240.99 205.37"
          />
          <path
            stroke="#e59120"
            vector-effect="non-scaling-stroke"
            d="
  M 240.99 205.37
  L 241.44 197.74
  Q 241.46 197.45 241.25 197.26
  Q 241.05 197.08 240.74 197.21"
          />
          <path
            stroke="#fccd85"
            vector-effect="non-scaling-stroke"
            d="
  M 240.74 197.21
  Q 242.43 195.47 241.38 195.10"
          />
          <path
            stroke="#fccd85"
            vector-effect="non-scaling-stroke"
            d="
  M 253.56 190.12
  L 249.37 186.18"
          />
          <path
            stroke="#898e8c"
            vector-effect="non-scaling-stroke"
            d="
  M 242.09 210.45
  Q 239.61 213.41 236.82 215.84
  Q 235.61 216.89 235.42 218.15
  A 1.54 1.54 0.0 0 0 236.91 219.93
  C 241.53 220.04 244.49 219.96 247.89 223.53
  A 1.07 1.07 0.0 0 0 249.22 223.70
  Q 250.75 222.74 252.11 221.66
  C 254.37 219.90 257.62 219.76 260.32 220.05
  A 1.52 1.51 -88.4 0 0 261.99 218.46
  C 261.85 215.66 257.60 212.53 255.53 210.70"
          />
          <path
            stroke="#898e8c"
            vector-effect="non-scaling-stroke"
            d="
  M 260.87 225.24
  Q 256.50 223.87 252.91 226.41
  Q 250.96 227.80 249.28 229.03
  A 1.24 1.23 -35.5 0 1 247.54 228.74
  Q 243.60 223.18 236.59 225.27"
          />
          <path
            stroke="#725228"
            vector-effect="non-scaling-stroke"
            d="
  M 240.99 205.37
  Q 237.93 208.33 235.48 210.99
  Q 231.91 214.88 231.52 216.78
  Q 230.21 223.08 236.59 225.27"
          />
          <path
            stroke="#e6b977"
            vector-effect="non-scaling-stroke"
            d="
  M 236.59 225.27
  Q 233.05 226.46 231.42 228.16
  Q 226.44 233.35 229.48 238.22"
          />
          <path
            stroke="#725228"
            vector-effect="non-scaling-stroke"
            d="
  M 229.48 238.22
  L 234.09 245.33"
          />
          <path
            stroke="#e6b977"
            vector-effect="non-scaling-stroke"
            d="
  M 234.09 245.33
  Q 235.54 252.08 241.99 253.57
  Q 249.15 255.23 256.76 253.35
  Q 262.26 251.99 263.44 245.47"
          />
          <path
            stroke="#725228"
            vector-effect="non-scaling-stroke"
            d="
  M 263.44 245.47
  L 268.34 238.30"
          />
          <path
            stroke="#e6b977"
            vector-effect="non-scaling-stroke"
            d="
  M 268.34 238.30
  Q 271.30 232.08 264.94 227.57
  Q 263.19 226.33 260.87 225.24"
          />
          <path
            stroke="#725228"
            vector-effect="non-scaling-stroke"
            d="
  M 260.87 225.24
  C 267.91 222.57 267.09 217.29 263.13 212.33
  Q 260.24 208.71 256.43 205.85"
          />
          <path
            stroke="#b0d5cb"
            vector-effect="non-scaling-stroke"
            d="
  M 300.14 190.35
  Q 297.01 193.74 294.62 196.37
  Q 286.52 205.25 274.59 206.03"
          />
          <path
            stroke="#b0d5cb"
            vector-effect="non-scaling-stroke"
            d="
  M 223.73 205.80
  Q 211.70 205.77 203.99 197.50
  Q 199.73 192.92 197.63 191.04"
          />
          <path
            stroke="#e6b977"
            vector-effect="non-scaling-stroke"
            d="
  M 240.74 197.21
  C 238.43 200.01 235.61 202.11 233.81 205.16
  Q 230.71 210.38 226.89 215.61
  Q 223.59 220.10 222.31 224.55
  C 220.44 231.04 222.65 238.67 226.34 244.38
  Q 232.22 253.51 242.47 258.87
  Q 246.92 261.20 250.69 260.32
  C 265.76 256.83 282.94 234.92 273.21 219.59
  Q 269.26 213.38 263.30 204.80
  C 261.41 202.07 258.99 199.95 256.62 197.48"
          />
          <path
            stroke="#898e8c"
            vector-effect="non-scaling-stroke"
            d="
  M 268.34 238.30
  Q 264.67 236.53 261.37 235.02
  C 256.51 232.80 252.97 230.68 249.14 234.48
  Q 248.74 234.87 248.35 234.48
  Q 244.97 231.07 240.51 232.95
  Q 236.83 234.50 229.48 238.22"
          />
          <path
            stroke="#898e8c"
            vector-effect="non-scaling-stroke"
            d="
  M 263.44 245.47
  Q 255.66 248.28 251.50 248.73
  Q 243.78 249.55 234.09 245.33"
          />
          <path
            stroke="#725228"
            vector-effect="non-scaling-stroke"
            d="
  M 232.95 190.71
  Q 221.43 186.23 211.22 187.51
  Q 210.63 187.58 210.96 188.08
  C 216.41 196.15 225.26 198.10 233.14 192.08
  Q 234.26 191.22 232.95 190.71"
          />
          <path
            stroke="#725228"
            vector-effect="non-scaling-stroke"
            d="
  M 263.79 191.81
  C 271.24 198.38 281.27 195.86 286.43 188.05
  A 0.39 0.39 0.0 0 0 286.14 187.44
  Q 274.18 186.47 263.91 190.99
  A 0.49 0.49 0.0 0 0 263.79 191.81"
          />
          <path
            stroke="#1d3b55"
            vector-effect="non-scaling-stroke"
            d="
  M 205.47 219.89
  Q 207.48 225.42 211.08 230.52"
          />
          <path
            stroke="#753634"
            vector-effect="non-scaling-stroke"
            d="
  M 211.08 230.52
  Q 212.17 236.41 214.80 226.75
  C 216.95 218.83 210.11 215.48 203.28 215.04
  Q 202.72 215.01 202.89 215.55
  Q 203.71 218.14 205.47 219.89"
          />
          <path
            stroke="#7d4b4c"
            vector-effect="non-scaling-stroke"
            d="
  M 211.08 230.52
  Q 212.49 227.00 211.91 224.01
  Q 210.99 219.22 205.47 219.89"
          />
          <path
            stroke="#1d3b55"
            vector-effect="non-scaling-stroke"
            d="
  M 286.37 230.73
  L 292.71 219.20"
          />
          <path
            stroke="#753634"
            vector-effect="non-scaling-stroke"
            d="
  M 292.71 219.20
  L 294.42 215.56
  Q 294.66 215.05 294.09 215.09
  C 287.88 215.60 281.75 217.78 282.62 225.12
  Q 282.96 227.99 285.00 232.98
  Q 285.21 233.48 285.54 233.06
  Q 286.26 232.12 286.37 230.73"
          />
          <path
            stroke="#7d4b4c"
            vector-effect="non-scaling-stroke"
            d="
  M 292.71 219.20
  C 285.10 219.85 284.55 224.47 286.37 230.73"
          />
          <path
            stroke="#753634"
            vector-effect="non-scaling-stroke"
            d="
  M 236.12 241.45
  C 243.92 245.51 253.64 245.07 261.54 241.57
  A 0.53 0.53 0.0 0 0 261.68 240.70
  Q 257.47 236.80 251.24 237.89
  Q 248.64 238.35 246.14 237.87
  C 242.35 237.15 238.78 238.03 236.02 240.71
  A 0.47 0.46 -53.3 0 0 236.12 241.45"
          />
        </g>
        <path
          fill="#14263d"
          d="
  M 264.39 122.56
  Q 258.91 130.02 256.26 136.24
  Q 251.26 147.96 249.01 164.47
  Q 248.94 165.04 248.47 164.70
  Q 248.24 164.53 248.22 164.25
  Q 247.57 155.06 244.78 145.84
  Q 247.64 145.98 248.34 144.02
  Q 249.00 142.18 248.77 139.94
  L 257.41 125.54
  Q 257.69 125.06 257.34 124.65
  L 256.96 124.21
  Q 257.75 122.25 257.36 119.13
  Q 256.49 112.37 256.03 110.46
  Q 254.49 98.32 248.71 86.93
  L 247.95 72.59
  Q 247.54 72.67 248.30 71.93
  Q 248.77 71.48 249.13 72.02
  C 250.24 73.71 251.73 75.29 252.56 77.11
  Q 258.34 89.68 260.60 99.49
  Q 262.51 107.82 264.39 122.56
  Z"
        />
        <path
          fill="#254f6d"
          d="
  M 247.95 72.59
  L 248.71 86.93
  Q 244.71 96.08 242.36 105.27
  Q 240.24 113.57 240.14 122.13
  Q 240.13 123.10 240.66 124.43
  Q 240.18 124.78 240.14 125.48
  Q 240.11 125.94 240.46 126.51
  Q 244.47 133.12 248.77 139.94
  Q 249.00 142.18 248.34 144.02
  Q 247.64 145.98 244.78 145.84
  Q 241.09 134.18 234.03 123.73
  Q 233.65 123.18 233.63 121.25
  Q 233.63 121.01 233.43 120.89
  L 233.18 120.73
  Q 233.96 114.81 235.04 108.90
  Q 236.73 99.64 240.28 88.26
  Q 242.89 79.88 247.95 72.59
  Z"
        />
        <path
          fill="#387191"
          d="
  M 248.71 86.93
  Q 254.49 98.32 256.03 110.46
  C 254.05 112.95 251.87 112.87 249.23 113.69
  A 1.20 1.19 -85.1 0 0 248.51 114.30
  Q 248.34 114.65 248.50 114.78
  L 240.66 124.43
  Q 240.13 123.10 240.14 122.13
  Q 240.24 113.57 242.36 105.27
  Q 244.71 96.08 248.71 86.93
  Z"
        />
        <path
          fill="#d5462a"
          d="
  M 233.18 120.73
  L 233.43 120.89
  Q 233.63 121.01 233.63 121.25
  Q 233.65 123.18 234.03 123.73
  Q 241.09 134.18 244.78 145.84
  Q 247.57 155.06 248.22 164.25
  Q 248.24 164.53 248.47 164.70
  Q 248.94 165.04 249.01 164.47
  Q 251.26 147.96 256.26 136.24
  Q 258.91 130.02 264.39 122.56
  Q 276.30 106.31 292.35 95.15
  C 294.38 93.75 297.16 91.63 299.24 91.28
  Q 302.06 90.80 303.07 93.06
  Q 301.06 103.48 297.71 113.08
  Q 297.31 114.19 296.77 114.52
  Q 290.31 118.44 285.13 123.79
  Q 271.27 134.17 263.30 144.05
  C 255.80 153.35 252.26 164.82 249.76 176.44
  Q 248.68 181.45 247.78 176.40
  Q 245.82 165.31 240.60 153.97
  Q 237.92 148.16 234.12 143.84
  Q 229.26 138.34 224.72 134.12
  Q 222.27 131.84 218.89 129.23
  Q 215.72 126.79 212.97 124.28
  Q 208.94 119.55 203.45 116.55
  C 201.53 115.50 200.00 114.27 199.35 112.41
  Q 196.55 104.42 194.36 93.08
  Q 195.74 90.81 198.26 91.28
  Q 199.91 91.58 201.64 92.76
  Q 213.10 100.53 221.25 108.61
  C 225.26 112.57 228.47 117.10 232.22 121.18
  A 0.56 0.56 0.0 0 0 232.92 121.27
  Q 232.95 121.26 233.18 120.73
  Z
  M 218.00 120.08
  Q 225.70 125.14 232.22 131.64
  C 234.47 133.88 236.24 136.83 238.21 139.38
  Q 238.23 139.41 238.26 139.39
  L 238.46 139.24
  Q 238.62 139.11 238.51 138.95
  Q 235.02 133.78 231.29 128.47
  C 228.36 124.29 225.15 120.70 221.73 116.90
  Q 218.23 113.25 213.82 109.67
  C 211.97 108.17 207.62 103.99 205.44 107.61
  C 203.35 111.07 207.37 113.65 209.88 114.91
  Q 214.19 117.07 218.00 120.08
  Z
  M 260.17 139.13
  C 263.23 133.86 267.80 129.15 272.44 125.36
  Q 279.82 119.33 289.88 113.29
  Q 291.61 112.25 292.39 110.53
  C 293.24 108.68 292.32 105.92 289.97 106.02
  Q 288.23 106.09 286.62 107.29
  C 275.42 115.68 266.39 126.84 259.55 138.76
  A 0.36 0.36 0.0 0 0 259.85 139.30
  Q 260.06 139.31 260.17 139.13
  Z"
        />
        <path
          fill="#faa52e"
          d="
  M 194.36 93.08
  Q 196.55 104.42 199.35 112.41
  C 200.00 114.27 201.53 115.50 203.45 116.55
  Q 208.94 119.55 212.97 124.28
  Q 203.35 118.04 193.64 114.62
  Q 194.58 112.75 193.78 110.32
  C 191.85 104.47 191.02 98.56 194.36 93.08
  Z"
        />
        <path
          fill="#faa52e"
          d="
  M 303.07 93.06
  Q 304.75 95.16 304.95 96.49
  Q 306.21 104.66 303.43 114.33
  Q 301.87 115.32 299.03 116.43
  Q 296.49 117.43 294.64 118.48
  Q 290.01 121.11 285.13 123.79
  Q 290.31 118.44 296.77 114.52
  Q 297.31 114.19 297.71 113.08
  Q 301.06 103.48 303.07 93.06
  Z"
        />
        <path
          fill="#254f6d"
          d="
  M 221.73 116.90
  Q 219.98 117.83 218.00 120.08
  Q 214.19 117.07 209.88 114.91
  C 207.37 113.65 203.35 111.07 205.44 107.61
  C 207.62 103.99 211.97 108.17 213.82 109.67
  Q 218.23 113.25 221.73 116.90
  Z"
        />
        <path
          fill="#254f6d"
          d="
  M 272.44 125.36
  C 267.80 129.15 263.23 133.86 260.17 139.13
  Q 260.06 139.31 259.85 139.30
  A 0.36 0.36 0.0 0 1 259.55 138.76
  C 266.39 126.84 275.42 115.68 286.62 107.29
  Q 288.23 106.09 289.97 106.02
  C 292.32 105.92 293.24 108.68 292.39 110.53
  Q 291.61 112.25 289.88 113.29
  Q 279.82 119.33 272.44 125.36
  Z"
        />
        <path
          fill="#fdf5db"
          d="
  M 193.64 114.62
  Q 203.35 118.04 212.97 124.28
  Q 215.72 126.79 218.89 129.23
  Q 222.27 131.84 224.72 134.12
  Q 229.26 138.34 234.12 143.84
  Q 237.92 148.16 240.60 153.97
  Q 245.82 165.31 247.78 176.40
  Q 248.68 181.45 249.76 176.44
  C 252.26 164.82 255.80 153.35 263.30 144.05
  Q 271.27 134.17 285.13 123.79
  Q 290.01 121.11 294.64 118.48
  Q 296.49 117.43 299.03 116.43
  Q 301.87 115.32 303.43 114.33
  C 312.91 110.67 322.59 108.97 331.97 112.11
  Q 332.53 112.30 333.62 113.15
  Q 322.88 115.21 320.00 116.21
  Q 302.85 122.17 287.81 132.57
  Q 270.44 144.55 257.84 163.34
  Q 253.51 169.80 251.39 176.57
  L 249.13 179.53
  A 0.54 0.53 44.7 0 1 248.28 179.53
  C 246.33 177.01 245.86 174.07 244.26 171.20
  Q 240.65 164.79 236.45 158.76
  Q 225.37 143.47 209.72 132.73
  Q 195.12 122.72 179.50 117.02
  Q 173.72 114.91 165.36 113.44
  Q 164.76 113.34 164.40 112.56
  C 172.23 108.12 185.68 111.34 193.64 114.62
  Z"
        />
        <path
          fill="#254f6d"
          d="
  M 256.03 110.46
  Q 256.49 112.37 257.36 119.13
  Q 257.75 122.25 256.96 124.21
  Q 256.88 124.22 256.71 124.19
  Q 256.60 124.17 256.52 124.08
  L 248.50 114.78
  Q 248.34 114.65 248.51 114.30
  A 1.20 1.19 -85.1 0 1 249.23 113.69
  C 251.87 112.87 254.05 112.95 256.03 110.46
  Z"
        />
        <path
          fill="#14263d"
          d="
  M 164.40 112.56
  Q 164.76 113.34 165.36 113.44
  Q 173.72 114.91 179.50 117.02
  Q 195.12 122.72 209.72 132.73
  Q 214.66 140.07 219.93 146.65
  C 222.66 150.07 224.74 153.50 226.83 157.23
  A 0.46 0.46 0.0 0 1 226.36 157.91
  Q 212.74 155.91 199.51 160.09
  Q 196.16 161.14 194.42 162.15
  Q 193.65 161.97 192.85 161.42
  Q 188.80 153.66 184.65 146.32
  Q 184.36 145.82 184.02 145.97
  Q 183.93 144.92 183.21 143.75
  Q 173.76 128.36 163.67 113.38
  Q 163.32 112.85 164.40 112.56
  Z
  M 210.83 151.11
  Q 201.74 140.82 191.18 133.32
  L 180.64 126.10
  Q 179.45 125.29 180.19 126.52
  Q 180.95 127.78 181.87 129.14
  C 187.42 137.23 191.89 146.27 195.23 155.53
  A 0.94 0.93 73.0 0 0 196.33 156.12
  L 211.00 152.40
  Q 211.59 152.25 211.41 151.66
  Q 211.28 151.23 210.83 151.11
  Z"
        />
        <path
          fill="#14263d"
          d="
  M 333.62 113.15
  Q 333.44 114.47 332.37 115.83
  Q 330.83 117.78 330.60 118.14
  Q 322.77 130.42 315.00 142.73
  Q 314.83 143.01 314.22 144.39
  Q 313.92 145.08 313.33 146.05
  Q 308.72 152.63 304.64 161.61
  Q 304.28 161.83 303.73 161.93
  Q 303.32 162.00 302.84 161.81
  Q 287.80 155.66 271.44 157.94
  A 0.66 0.66 0.0 0 1 270.78 156.94
  Q 278.26 144.74 288.52 133.49
  A 0.68 0.68 0.0 0 0 288.20 132.37
  Q 287.89 132.29 287.81 132.57
  Q 302.85 122.17 320.00 116.21
  Q 322.88 115.21 333.62 113.15
  Z
  M 307.01 132.74
  Q 295.49 140.85 286.28 151.32
  Q 285.99 151.66 286.06 151.88
  Q 286.15 152.18 286.74 152.24
  L 286.98 152.46
  Q 287.21 152.67 287.52 152.74
  L 300.51 156.01
  A 1.53 1.52 17.7 0 0 302.31 155.09
  Q 308.34 139.89 316.88 127.50
  Q 318.86 124.61 315.97 126.60
  L 307.01 132.74
  Z"
        />
        <path
          fill="#faa52e"
          d="
  M 248.50 114.78
  L 256.52 124.08
  Q 256.60 124.17 256.71 124.19
  Q 256.88 124.22 256.96 124.21
  L 257.34 124.65
  Q 257.69 125.06 257.41 125.54
  L 248.77 139.94
  Q 244.47 133.12 240.46 126.51
  Q 240.11 125.94 240.14 125.48
  Q 240.18 124.78 240.66 124.43
  L 248.50 114.78
  Z"
        />
        <path
          fill="#14263d"
          d="
  M 221.73 116.90
  C 225.15 120.70 228.36 124.29 231.29 128.47
  Q 235.02 133.78 238.51 138.95
  Q 238.62 139.11 238.46 139.24
  L 238.26 139.39
  Q 238.23 139.41 238.21 139.38
  C 236.24 136.83 234.47 133.88 232.22 131.64
  Q 225.70 125.14 218.00 120.08
  Q 219.98 117.83 221.73 116.90
  Z"
        />
        <path
          fill="#d5462a"
          d="
  M 286.74 152.24
  C 289.59 149.83 294.57 148.99 298.07 147.95
  A 2.65 2.61 5.2 0 0 299.64 146.66
  L 307.01 132.74
  L 315.97 126.60
  Q 318.86 124.61 316.88 127.50
  Q 308.34 139.89 302.31 155.09
  A 1.53 1.52 17.7 0 1 300.51 156.01
  L 287.52 152.74
  Q 287.21 152.67 286.98 152.46
  L 286.74 152.24
  Z"
        />
        <path
          fill="#d5462a"
          d="
  M 191.18 133.32
  L 197.73 146.61
  A 3.44 3.44 0.0 0 0 200.01 148.44
  L 210.83 151.11
  Q 211.28 151.23 211.41 151.66
  Q 211.59 152.25 211.00 152.40
  L 196.33 156.12
  A 0.94 0.93 73.0 0 1 195.23 155.53
  C 191.89 146.27 187.42 137.23 181.87 129.14
  Q 180.95 127.78 180.19 126.52
  Q 179.45 125.29 180.64 126.10
  L 191.18 133.32
  Z"
        />
        <path
          fill="#d5462a"
          d="
  M 287.81 132.57
  Q 287.89 132.29 288.20 132.37
  A 0.68 0.68 0.0 0 1 288.52 133.49
  Q 278.26 144.74 270.78 156.94
  A 0.66 0.66 0.0 0 0 271.44 157.94
  Q 287.80 155.66 302.84 161.81
  Q 303.32 162.00 303.73 161.93
  Q 304.28 161.83 304.64 161.61
  Q 305.51 163.13 307.87 163.71
  Q 317.58 169.97 320.13 171.87
  Q 322.79 173.86 322.39 176.36
  L 312.62 171.98
  Q 309.08 170.47 306.28 169.83
  Q 305.21 169.58 304.00 169.62
  Q 304.09 169.49 304.12 169.36
  Q 304.17 169.14 303.96 169.05
  Q 294.71 164.94 284.45 163.37
  Q 284.26 162.11 283.61 161.82
  Q 283.12 161.60 282.72 161.94
  L 277.76 166.19
  Q 277.34 166.55 276.79 166.55
  Q 262.71 166.55 251.39 176.57
  Q 253.51 169.80 257.84 163.34
  Q 270.44 144.55 287.81 132.57
  Z"
        />
        <path
          fill="#62b4ba"
          d="
  M 307.01 132.74
  L 299.64 146.66
  A 2.65 2.61 5.2 0 1 298.07 147.95
  C 294.57 148.99 289.59 149.83 286.74 152.24
  Q 286.15 152.18 286.06 151.88
  Q 285.99 151.66 286.28 151.32
  Q 295.49 140.85 307.01 132.74
  Z"
        />
        <path
          fill="#387191"
          d="
  M 209.72 132.73
  Q 225.37 143.47 236.45 158.76
  L 230.31 167.24
  Q 229.97 167.70 229.41 167.59
  Q 225.24 166.73 221.00 166.72
  A 2.06 2.02 61.5 0 1 219.86 166.37
  Q 217.41 164.73 215.05 162.03
  Q 214.72 161.64 214.28 161.89
  Q 213.41 162.38 212.94 163.98
  C 208.75 163.70 204.26 165.28 200.21 166.39
  Q 199.73 166.52 199.26 166.34
  Q 195.87 164.99 194.42 162.15
  Q 196.16 161.14 199.51 160.09
  Q 212.74 155.91 226.36 157.91
  A 0.46 0.46 0.0 0 0 226.83 157.23
  C 224.74 153.50 222.66 150.07 219.93 146.65
  Q 214.66 140.07 209.72 132.73
  Z"
        />
        <path
          fill="#62b4ba"
          d="
  M 191.18 133.32
  Q 201.74 140.82 210.83 151.11
  L 200.01 148.44
  A 3.44 3.44 0.0 0 1 197.73 146.61
  L 191.18 133.32
  Z"
        />
        <path
          fill="#faa52e"
          d="
  M 307.87 163.71
  Q 305.51 163.13 304.64 161.61
  Q 308.72 152.63 313.33 146.05
  Q 322.51 143.02 331.27 144.19
  A 1.38 1.37 19.4 0 1 332.27 146.26
  Q 330.79 148.70 329.16 150.99
  C 324.53 157.51 315.00 160.70 307.87 163.71
  Z"
        />
        <path
          fill="#faa52e"
          d="
  M 184.02 145.97
  Q 184.36 145.82 184.65 146.32
  Q 188.80 153.66 192.85 161.42
  Q 192.60 162.87 189.72 163.30
  C 180.06 160.44 169.57 155.39 165.24 145.83
  A 0.95 0.95 0.0 0 1 165.95 144.50
  Q 172.67 143.42 179.25 145.18
  Q 182.60 146.07 184.02 145.97
  Z"
        />
        <path
          fill="#14263d"
          d="
  M 236.45 158.76
  Q 240.65 164.79 244.26 171.20
  C 245.86 174.07 246.33 177.01 248.28 179.53
  A 0.54 0.53 44.7 0 0 249.13 179.53
  L 251.39 176.57
  Q 262.71 166.55 276.79 166.55
  Q 277.34 166.55 277.76 166.19
  L 282.72 161.94
  Q 283.12 161.60 283.61 161.82
  Q 284.26 162.11 284.45 163.37
  L 285.61 167.21
  A 1.04 1.04 0.0 0 0 286.37 167.93
  Q 291.70 169.23 297.17 171.66
  Q 297.68 171.88 298.20 171.70
  L 304.00 169.62
  Q 305.21 169.58 306.28 169.83
  Q 309.08 170.47 312.62 171.98
  L 305.71 176.05
  Q 305.28 176.30 305.47 176.76
  Q 306.64 179.48 306.66 182.36
  Q 306.40 185.18 306.34 188.12
  C 305.98 205.54 300.11 222.85 290.53 237.68
  C 281.22 252.10 267.41 264.25 250.96 269.10
  C 249.17 269.63 246.83 269.44 245.12 268.77
  Q 233.57 264.27 227.23 259.28
  C 223.82 256.60 220.18 253.88 217.30 250.76
  Q 190.80 222.04 190.78 182.39
  Q 190.68 179.35 192.18 176.78
  Q 192.44 176.35 191.99 176.12
  L 185.50 172.75
  A 0.51 0.51 0.0 0 1 185.51 171.84
  Q 188.25 170.46 191.01 169.70
  C 193.72 168.96 196.27 170.43 198.67 171.47
  A 2.82 2.81 -45.1 0 0 200.91 171.46
  Q 205.59 169.43 210.68 168.18
  Q 212.72 167.68 212.94 163.98
  Q 213.41 162.38 214.28 161.89
  Q 214.72 161.64 215.05 162.03
  Q 217.41 164.73 219.86 166.37
  A 2.06 2.02 61.5 0 0 221.00 166.72
  Q 225.24 166.73 229.41 167.59
  Q 229.97 167.70 230.31 167.24
  L 236.45 158.76
  Z
  M 244.01 190.32
  Q 243.55 193.36 241.38 195.10
  Q 239.51 193.36 236.61 195.97
  Q 234.08 198.25 233.12 198.80
  C 221.07 205.62 209.69 199.81 202.33 189.23
  Q 202.01 188.78 201.46 188.75
  Q 199.28 188.66 197.71 189.69
  Q 196.79 190.30 197.63 191.04
  L 199.11 202.01
  A 2.15 2.14 7.8 0 0 200.40 203.70
  L 218.95 211.60
  A 0.80 0.79 -61.0 0 0 219.91 211.32
  L 223.73 205.80
  L 230.62 204.85
  A 0.49 0.49 0.0 0 1 231.09 205.62
  C 227.60 210.54 224.09 215.42 221.31 220.77
  Q 219.03 225.17 219.30 229.89
  C 220.10 243.87 230.34 255.92 243.05 261.67
  C 250.76 265.15 257.33 260.90 263.42 256.33
  C 271.58 250.19 278.11 239.94 278.10 229.23
  Q 278.10 222.06 273.41 215.56
  Q 269.99 210.82 266.71 205.66
  A 0.47 0.47 0.0 0 1 267.17 204.95
  L 274.59 206.03
  L 277.58 211.43
  A 0.53 0.53 0.0 0 0 278.25 211.66
  L 297.78 203.33
  Q 298.28 203.12 298.35 202.59
  L 300.14 190.35
  Q 298.96 188.70 295.89 188.62
  Q 295.40 188.60 295.14 189.01
  C 286.92 202.17 270.36 206.91 258.82 194.32
  A 0.73 0.72 58.9 0 0 258.06 194.13
  Q 256.93 194.51 255.80 195.42
  Q 253.96 193.07 253.56 190.12
  Q 271.14 174.98 293.25 182.51
  Q 296.62 183.66 299.81 184.75
  Q 300.70 185.06 300.71 184.11
  C 300.71 182.42 300.86 181.31 299.31 180.13
  C 286.13 170.10 270.60 168.48 257.01 178.25
  Q 254.79 179.85 249.37 186.18
  Q 248.54 186.72 247.74 185.91
  Q 232.34 165.83 208.33 174.26
  Q 204.29 175.68 197.28 180.83
  Q 196.75 181.23 196.75 181.89
  L 196.77 184.43
  Q 196.77 185.23 197.52 184.94
  C 201.92 183.19 206.72 181.28 211.31 180.54
  Q 229.67 177.58 244.01 190.32
  Z
  M 232.95 190.71
  Q 221.43 186.23 211.22 187.51
  Q 210.63 187.58 210.96 188.08
  C 216.41 196.15 225.26 198.10 233.14 192.08
  Q 234.26 191.22 232.95 190.71
  Z
  M 263.79 191.81
  C 271.24 198.38 281.27 195.86 286.43 188.05
  A 0.39 0.39 0.0 0 0 286.14 187.44
  Q 274.18 186.47 263.91 190.99
  A 0.49 0.49 0.0 0 0 263.79 191.81
  Z
  M 205.47 219.89
  Q 207.48 225.42 211.08 230.52
  Q 212.17 236.41 214.80 226.75
  C 216.95 218.83 210.11 215.48 203.28 215.04
  Q 202.72 215.01 202.89 215.55
  Q 203.71 218.14 205.47 219.89
  Z
  M 286.37 230.73
  L 292.71 219.20
  L 294.42 215.56
  Q 294.66 215.05 294.09 215.09
  C 287.88 215.60 281.75 217.78 282.62 225.12
  Q 282.96 227.99 285.00 232.98
  Q 285.21 233.48 285.54 233.06
  Q 286.26 232.12 286.37 230.73
  Z"
        />
        <path
          fill="#254f6d"
          d="
  M 192.85 161.42
  Q 193.65 161.97 194.42 162.15
  Q 195.87 164.99 199.26 166.34
  Q 199.73 166.52 200.21 166.39
  C 204.26 165.28 208.75 163.70 212.94 163.98
  Q 212.72 167.68 210.68 168.18
  Q 205.59 169.43 200.91 171.46
  A 2.82 2.81 -45.1 0 1 198.67 171.47
  C 196.27 170.43 193.72 168.96 191.01 169.70
  Q 188.25 170.46 185.51 171.84
  A 0.51 0.51 0.0 0 0 185.50 172.75
  L 191.99 176.12
  Q 192.44 176.35 192.18 176.78
  Q 190.68 179.35 190.78 182.39
  L 174.46 176.09
  A 0.61 0.60 31.7 0 1 174.23 175.12
  C 178.53 170.35 184.43 167.01 189.72 163.30
  Q 192.60 162.87 192.85 161.42
  Z"
        />
        <path
          fill="#faa52e"
          d="
  M 304.00 169.62
  L 298.20 171.70
  Q 297.68 171.88 297.17 171.66
  Q 291.70 169.23 286.37 167.93
  A 1.04 1.04 0.0 0 1 285.61 167.21
  L 284.45 163.37
  Q 294.71 164.94 303.96 169.05
  Q 304.17 169.14 304.12 169.36
  Q 304.09 169.49 304.00 169.62
  Z"
        />
        <path
          fill="#fdf5db"
          d="
  M 247.74 185.91
  L 244.01 190.32
  Q 229.67 177.58 211.31 180.54
  C 206.72 181.28 201.92 183.19 197.52 184.94
  Q 196.77 185.23 196.77 184.43
  L 196.75 181.89
  Q 196.75 181.23 197.28 180.83
  Q 204.29 175.68 208.33 174.26
  Q 232.34 165.83 247.74 185.91
  Z"
        />
        <path
          fill="#fdf5db"
          d="
  M 253.56 190.12
  L 249.37 186.18
  Q 254.79 179.85 257.01 178.25
  C 270.60 168.48 286.13 170.10 299.31 180.13
  C 300.86 181.31 300.71 182.42 300.71 184.11
  Q 300.70 185.06 299.81 184.75
  Q 296.62 183.66 293.25 182.51
  Q 271.14 174.98 253.56 190.12
  Z"
        />
        <path
          fill="#254f6d"
          d="
  M 312.62 171.98
  L 322.39 176.36
  L 306.66 182.36
  Q 306.64 179.48 305.47 176.76
  Q 305.28 176.30 305.71 176.05
  L 312.62 171.98
  Z"
        />
        <path
          fill="#faa52e"
          d="
  M 247.74 185.91
  Q 248.54 186.72 249.37 186.18
  L 253.56 190.12
  Q 253.96 193.07 255.80 195.42
  Q 255.68 196.33 256.62 197.48
  L 256.43 205.85
  Q 255.34 209.32 255.53 210.70
  Q 253.77 215.02 249.87 217.57
  Q 249.42 217.86 248.89 217.78
  C 246.97 217.48 245.11 215.49 243.80 214.12
  Q 243.45 213.75 243.38 213.25
  Q 243.13 211.33 242.09 210.45
  L 240.99 205.37
  L 241.44 197.74
  Q 241.46 197.45 241.25 197.26
  Q 241.05 197.08 240.74 197.21
  Q 242.43 195.47 241.38 195.10
  Q 243.55 193.36 244.01 190.32
  L 247.74 185.91
  Z"
        />
        <path
          fill="#cf7d12"
          d="
  M 232.95 190.71
  Q 234.26 191.22 233.14 192.08
  C 225.26 198.10 216.41 196.15 210.96 188.08
  Q 210.63 187.58 211.22 187.51
  Q 221.43 186.23 232.95 190.71
  Z"
        />
        <path
          fill="#cf7d12"
          d="
  M 286.43 188.05
  C 281.27 195.86 271.24 198.38 263.79 191.81
  A 0.49 0.49 0.0 0 1 263.91 190.99
  Q 274.18 186.47 286.14 187.44
  A 0.39 0.39 0.0 0 1 286.43 188.05
  Z"
        />
        <path
          fill="#fdf5db"
          d="
  M 300.14 190.35
  Q 297.01 193.74 294.62 196.37
  Q 286.52 205.25 274.59 206.03
  L 267.17 204.95
  A 0.47 0.47 0.0 0 0 266.71 205.66
  Q 269.99 210.82 273.41 215.56
  Q 278.10 222.06 278.10 229.23
  C 278.11 239.94 271.58 250.19 263.42 256.33
  C 257.33 260.90 250.76 265.15 243.05 261.67
  C 230.34 255.92 220.10 243.87 219.30 229.89
  Q 219.03 225.17 221.31 220.77
  C 224.09 215.42 227.60 210.54 231.09 205.62
  A 0.49 0.49 0.0 0 0 230.62 204.85
  L 223.73 205.80
  Q 211.70 205.77 203.99 197.50
  Q 199.73 192.92 197.63 191.04
  Q 196.79 190.30 197.71 189.69
  Q 199.28 188.66 201.46 188.75
  Q 202.01 188.78 202.33 189.23
  C 209.69 199.81 221.07 205.62 233.12 198.80
  Q 234.08 198.25 236.61 195.97
  Q 239.51 193.36 241.38 195.10
  Q 242.43 195.47 240.74 197.21
  C 238.43 200.01 235.61 202.11 233.81 205.16
  Q 230.71 210.38 226.89 215.61
  Q 223.59 220.10 222.31 224.55
  C 220.44 231.04 222.65 238.67 226.34 244.38
  Q 232.22 253.51 242.47 258.87
  Q 246.92 261.20 250.69 260.32
  C 265.76 256.83 282.94 234.92 273.21 219.59
  Q 269.26 213.38 263.30 204.80
  C 261.41 202.07 258.99 199.95 256.62 197.48
  Q 255.68 196.33 255.80 195.42
  Q 256.93 194.51 258.06 194.13
  A 0.73 0.72 58.9 0 1 258.82 194.32
  C 270.36 206.91 286.92 202.17 295.14 189.01
  Q 295.40 188.60 295.89 188.62
  Q 298.96 188.70 300.14 190.35
  Z"
        />
        <path
          fill="#62b4ba"
          d="
  M 300.14 190.35
  L 298.35 202.59
  Q 298.28 203.12 297.78 203.33
  L 278.25 211.66
  A 0.53 0.53 0.0 0 1 277.58 211.43
  L 274.59 206.03
  Q 286.52 205.25 294.62 196.37
  Q 297.01 193.74 300.14 190.35
  Z"
        />
        <path
          fill="#62b4ba"
          d="
  M 197.63 191.04
  Q 199.73 192.92 203.99 197.50
  Q 211.70 205.77 223.73 205.80
  L 219.91 211.32
  A 0.80 0.79 -61.0 0 1 218.95 211.60
  L 200.40 203.70
  A 2.15 2.14 7.8 0 1 199.11 202.01
  L 197.63 191.04
  Z"
        />
        <path
          fill="#cf7d12"
          d="
  M 240.74 197.21
  Q 241.05 197.08 241.25 197.26
  Q 241.46 197.45 241.44 197.74
  L 240.99 205.37
  Q 237.93 208.33 235.48 210.99
  Q 231.91 214.88 231.52 216.78
  Q 230.21 223.08 236.59 225.27
  Q 233.05 226.46 231.42 228.16
  Q 226.44 233.35 229.48 238.22
  L 234.09 245.33
  Q 235.54 252.08 241.99 253.57
  Q 249.15 255.23 256.76 253.35
  Q 262.26 251.99 263.44 245.47
  L 268.34 238.30
  Q 271.30 232.08 264.94 227.57
  Q 263.19 226.33 260.87 225.24
  C 267.91 222.57 267.09 217.29 263.13 212.33
  Q 260.24 208.71 256.43 205.85
  L 256.62 197.48
  C 258.99 199.95 261.41 202.07 263.30 204.80
  Q 269.26 213.38 273.21 219.59
  C 282.94 234.92 265.76 256.83 250.69 260.32
  Q 246.92 261.20 242.47 258.87
  Q 232.22 253.51 226.34 244.38
  C 222.65 238.67 220.44 231.04 222.31 224.55
  Q 223.59 220.10 226.89 215.61
  Q 230.71 210.38 233.81 205.16
  C 235.61 202.11 238.43 200.01 240.74 197.21
  Z"
        />
        <path
          fill="#14263d"
          d="
  M 240.99 205.37
  L 242.09 210.45
  Q 239.61 213.41 236.82 215.84
  Q 235.61 216.89 235.42 218.15
  A 1.54 1.54 0.0 0 0 236.91 219.93
  C 241.53 220.04 244.49 219.96 247.89 223.53
  A 1.07 1.07 0.0 0 0 249.22 223.70
  Q 250.75 222.74 252.11 221.66
  C 254.37 219.90 257.62 219.76 260.32 220.05
  A 1.52 1.51 -88.4 0 0 261.99 218.46
  C 261.85 215.66 257.60 212.53 255.53 210.70
  Q 255.34 209.32 256.43 205.85
  Q 260.24 208.71 263.13 212.33
  C 267.09 217.29 267.91 222.57 260.87 225.24
  Q 256.50 223.87 252.91 226.41
  Q 250.96 227.80 249.28 229.03
  A 1.24 1.23 -35.5 0 1 247.54 228.74
  Q 243.60 223.18 236.59 225.27
  Q 230.21 223.08 231.52 216.78
  Q 231.91 214.88 235.48 210.99
  Q 237.93 208.33 240.99 205.37
  Z"
        />
        <path
          fill="#fdf5db"
          d="
  M 242.09 210.45
  Q 243.13 211.33 243.38 213.25
  Q 243.45 213.75 243.80 214.12
  C 245.11 215.49 246.97 217.48 248.89 217.78
  Q 249.42 217.86 249.87 217.57
  Q 253.77 215.02 255.53 210.70
  C 257.60 212.53 261.85 215.66 261.99 218.46
  A 1.52 1.51 -88.4 0 1 260.32 220.05
  C 257.62 219.76 254.37 219.90 252.11 221.66
  Q 250.75 222.74 249.22 223.70
  A 1.07 1.07 0.0 0 1 247.89 223.53
  C 244.49 219.96 241.53 220.04 236.91 219.93
  A 1.54 1.54 0.0 0 1 235.42 218.15
  Q 235.61 216.89 236.82 215.84
  Q 239.61 213.41 242.09 210.45
  Z"
        />
        <path
          fill="#d5462a"
          d="
  M 211.08 230.52
  Q 212.49 227.00 211.91 224.01
  Q 210.99 219.22 205.47 219.89
  Q 203.71 218.14 202.89 215.55
  Q 202.72 215.01 203.28 215.04
  C 210.11 215.48 216.95 218.83 214.80 226.75
  Q 212.17 236.41 211.08 230.52
  Z"
        />
        <path
          fill="#d5462a"
          d="
  M 292.71 219.20
  C 285.10 219.85 284.55 224.47 286.37 230.73
  Q 286.26 232.12 285.54 233.06
  Q 285.21 233.48 285.00 232.98
  Q 282.96 227.99 282.62 225.12
  C 281.75 217.78 287.88 215.60 294.09 215.09
  Q 294.66 215.05 294.42 215.56
  L 292.71 219.20
  Z"
        />
        <path
          fill="#254f6d"
          d="
  M 292.71 219.20
  L 286.37 230.73
  C 284.55 224.47 285.10 219.85 292.71 219.20
  Z"
        />
        <path
          fill="#254f6d"
          d="
  M 211.08 230.52
  Q 207.48 225.42 205.47 219.89
  Q 210.99 219.22 211.91 224.01
  Q 212.49 227.00 211.08 230.52
  Z"
        />
        <path
          fill="#fdf5db"
          d="
  M 260.87 225.24
  Q 263.19 226.33 264.94 227.57
  Q 271.30 232.08 268.34 238.30
  Q 264.67 236.53 261.37 235.02
  C 256.51 232.80 252.97 230.68 249.14 234.48
  Q 248.74 234.87 248.35 234.48
  Q 244.97 231.07 240.51 232.95
  Q 236.83 234.50 229.48 238.22
  Q 226.44 233.35 231.42 228.16
  Q 233.05 226.46 236.59 225.27
  Q 243.60 223.18 247.54 228.74
  A 1.24 1.23 -35.5 0 0 249.28 229.03
  Q 250.96 227.80 252.91 226.41
  Q 256.50 223.87 260.87 225.24
  Z"
        />
        <path
          fill="#14263d"
          d="
  M 268.34 238.30
  L 263.44 245.47
  Q 255.66 248.28 251.50 248.73
  Q 243.78 249.55 234.09 245.33
  L 229.48 238.22
  Q 236.83 234.50 240.51 232.95
  Q 244.97 231.07 248.35 234.48
  Q 248.74 234.87 249.14 234.48
  C 252.97 230.68 256.51 232.80 261.37 235.02
  Q 264.67 236.53 268.34 238.30
  Z
  M 236.12 241.45
  C 243.92 245.51 253.64 245.07 261.54 241.57
  A 0.53 0.53 0.0 0 0 261.68 240.70
  Q 257.47 236.80 251.24 237.89
  Q 248.64 238.35 246.14 237.87
  C 242.35 237.15 238.78 238.03 236.02 240.71
  A 0.47 0.46 -53.3 0 0 236.12 241.45
  Z"
        />
        <path
          fill="#d5462a"
          d="
  M 261.54 241.57
  C 253.64 245.07 243.92 245.51 236.12 241.45
  A 0.47 0.46 -53.3 0 1 236.02 240.71
  C 238.78 238.03 242.35 237.15 246.14 237.87
  Q 248.64 238.35 251.24 237.89
  Q 257.47 236.80 261.68 240.70
  A 0.53 0.53 0.0 0 1 261.54 241.57
  Z"
        />
        <path
          fill="#fdf5db"
          d="
  M 234.09 245.33
  Q 243.78 249.55 251.50 248.73
  Q 255.66 248.28 263.44 245.47
  Q 262.26 251.99 256.76 253.35
  Q 249.15 255.23 241.99 253.57
  Q 235.54 252.08 234.09 245.33
  Z"
        />
        <rect
          fill="#14263d"
          x="340.80"
          y="270.75"
          width="18.24"
          height="12.72"
          rx="0.45"
        />
        <path
          fill="#14263d"
          d="
  M 181.44 357.20
  Q 177.19 368.32 165.20 365.11
  C 158.63 363.36 156.55 357.25 155.67 351.10
  Q 155.53 350.13 155.28 351.08
  C 151.62 364.98 140.49 367.62 127.78 364.99
  C 111.58 361.64 112.43 344.37 112.78 331.76
  Q 112.78 331.50 113.04 331.50
  L 129.73 331.50
  Q 130.32 331.50 130.31 332.09
  Q 130.24 338.05 130.32 343.71
  C 130.35 345.73 131.09 348.54 133.31 349.16
  C 135.62 349.80 138.12 348.93 138.58 346.32
  C 139.79 339.41 137.76 333.31 132.84 328.51
  Q 126.40 322.22 119.82 315.33
  Q 110.99 306.08 113.14 291.92
  C 115.42 276.93 126.99 272.51 140.47 275.22
  C 153.19 277.78 156.03 289.05 156.10 300.37
  A 0.62 0.62 0.0 0 1 155.48 301.00
  L 138.26 301.01
  A 0.42 0.42 0.0 0 1 137.84 300.60
  C 137.72 297.68 138.92 289.68 133.06 291.06
  C 126.63 292.58 130.93 300.67 133.89 303.45
  Q 145.39 314.27 149.19 318.56
  C 152.92 322.76 154.65 326.65 156.06 331.72
  Q 156.27 332.46 156.51 331.73
  C 158.78 324.68 163.60 321.79 170.43 319.42
  Q 175.69 317.60 181.17 315.79
  Q 181.67 315.63 181.71 315.10
  C 181.88 312.46 182.86 298.88 176.21 301.98
  C 173.41 303.28 173.93 307.98 174.02 310.38
  A 0.72 0.71 88.8 0 1 173.31 311.13
  L 156.93 311.12
  Q 156.28 311.12 156.25 310.47
  C 155.44 294.24 162.95 287.71 178.72 287.75
  C 187.30 287.78 196.47 291.51 199.03 300.05
  Q 200.09 303.57 200.08 307.92
  Q 200.02 338.14 200.03 364.47
  Q 200.03 365.00 199.50 365.00
  L 182.62 365.00
  Q 181.99 365.00 181.98 364.37
  L 181.86 357.28
  Q 181.84 356.16 181.44 357.20
  Z
  M 176.74 327.40
  Q 173.81 329.86 173.74 333.68
  Q 173.62 339.99 173.75 345.98
  C 173.82 349.01 175.37 353.00 179.28 351.27
  C 181.20 350.42 181.62 347.28 181.60 345.48
  Q 181.51 336.68 181.69 327.01
  Q 181.77 323.17 178.83 325.64
  Q 177.93 326.40 176.74 327.40
  Z"
        />
        <path
          fill="#14263d"
          d="
  M 266.41 358.07
  L 266.46 364.52
  A 0.48 0.47 -0.6 0 1 265.98 365.00
  L 248.24 365.00
  A 0.52 0.51 0.0 0 1 247.72 364.49
  L 247.69 275.18
  A 0.38 0.38 0.0 0 1 248.07 274.80
  L 266.02 274.83
  A 0.44 0.44 0.0 0 1 266.46 275.27
  L 266.43 291.54
  A 0.28 0.28 0.0 0 0 266.90 291.75
  C 273.66 285.42 286.77 286.46 290.87 295.59
  Q 293.14 300.67 293.18 305.93
  Q 293.29 325.15 293.21 345.50
  C 293.17 355.87 288.99 366.15 276.81 365.78
  Q 270.11 365.57 266.85 357.98
  Q 266.40 356.92 266.41 358.07
  Z
  M 274.7338 306.0028
  A 4.04 4.04 0.0 0 0 270.6867 301.9699
  L 270.5067 301.9702
  A 4.04 4.04 0.0 0 0 266.4738 306.0172
  L 266.5462 347.5372
  A 4.04 4.04 0.0 0 0 270.5933 351.5701
  L 270.7733 351.5698
  A 4.04 4.04 0.0 0 0 274.8062 347.5228
  L 274.7338 306.0028
  Z"
        />
        <path
          fill="#14263d"
          d="
  M 313.50 291.69
  Q 319.90 285.80 329.58 288.70
  C 337.84 291.16 339.73 300.28 339.76 307.84
  Q 339.83 328.46 339.63 347.13
  C 339.54 355.01 336.03 363.77 327.36 365.47
  Q 317.25 367.44 313.55 358.19
  Q 313.23 357.39 313.15 358.25
  Q 312.85 361.30 313.07 364.35
  Q 313.11 365.00 312.46 365.00
  L 294.79 365.00
  Q 294.14 365.00 294.14 364.35
  L 294.16 275.45
  Q 294.16 274.81 294.80 274.81
  L 312.52 274.81
  Q 312.98 274.81 312.98 275.27
  L 312.97 291.46
  Q 312.97 292.18 313.50 291.69
  Z
  M 321.2237 305.9529
  A 4.02 4.02 0.0 0 0 317.1967 301.9399
  L 317.0167 301.9402
  A 4.02 4.02 0.0 0 0 313.0037 305.9672
  L 313.0763 347.5871
  A 4.02 4.02 0.0 0 0 317.1033 351.6001
  L 317.2833 351.5998
  A 4.02 4.02 0.0 0 0 321.2963 347.5728
  L 321.2237 305.9529
  Z"
        />
        <path
          fill="#14263d"
          d="
  M 220.10 296.78
  C 221.86 291.24 225.68 287.53 231.92 287.76
  C 242.12 288.14 246.23 295.76 246.24 305.03
  Q 246.27 334.74 246.26 364.53
  A 0.47 0.47 0.0 0 1 245.79 365.00
  L 228.70 365.00
  A 0.39 0.38 -0.0 0 1 228.31 364.62
  Q 228.40 335.68 228.28 307.23
  C 228.27 304.48 226.81 301.57 223.89 301.98
  Q 219.85 302.54 219.81 307.79
  Q 219.59 335.95 219.73 364.50
  A 0.50 0.50 0.0 0 1 219.23 365.00
  L 201.84 365.00
  A 0.42 0.41 -0.0 0 1 201.42 364.59
  L 201.39 289.23
  A 0.63 0.63 0.0 0 1 202.02 288.60
  L 218.90 288.60
  A 0.81 0.81 0.0 0 1 219.70 289.41
  L 219.72 296.72
  Q 219.72 297.96 220.10 296.78
  Z"
        />
        <path
          fill="#14263d"
          d="
  M 378.74 351.34
  L 395.67 351.32
  Q 396.27 351.32 396.27 351.92
  L 396.25 364.50
  Q 396.25 365.00 395.75 365.00
  L 341.28 365.00
  Q 340.80 365.00 340.80 364.53
  L 340.79 288.96
  Q 340.79 288.62 341.12 288.63
  L 395.54 288.66
  A 0.56 0.56 0.0 0 1 396.10 289.23
  Q 396.00 293.50 396.13 297.93
  Q 396.21 300.63 395.23 303.40
  Q 386.65 327.65 378.30 350.72
  Q 378.07 351.35 378.74 351.34
  Z
  M 377.16 303.19
  A 0.14 0.14 0.0 0 0 377.03 303.00
  L 359.54 303.00
  Q 359.04 302.99 359.04 303.50
  Q 359.02 323.62 359.05 349.36
  Q 359.05 350.00 359.35 350.56
  Q 359.69 351.20 359.73 350.48
  Q 359.78 349.71 360.04 349.00
  Q 368.62 325.95 377.16 303.19
  Z"
        />
      </svg>
    </Box>
  );
}

export default Logo;
