/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 01/04/2024
/* ******************************************* */

import React, { useState, useEffect, useRef } from 'react';
import {
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';

import { firestore_db } from '../../../api/firebase/firebase-config';
import { uploadFile } from '../../../api/firebase/storage';
import AlertPopup from '../../atoms/AlertPopup/AlertPopup';

import FormField from '../../atoms/FormField/FormField';
import { useToast } from '@chakra-ui/react';
import styled from 'styled-components';
import { TABLE_NAME } from '../../../utils/variables';

const EditCategoryFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  height: '500px';
  padding: 20px;
`;

const FormFields = styled.div``;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

function EditCategoryForm({ category, isOpen, onOpen, onClose }) {
  const toast = useToast();
  // recupère les données de firestore
  const [eventTypes, setEventTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, TABLE_NAME.eventTypes),
      (snapshot) => {
        const eventTypeSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEventTypes(eventTypeSnapshot);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  if (loading) {
    console.log('Loading...');
  }

  const [name, setName] = useState(category ? category.name : null);
  const [typeId, setTypeId] = useState(category ? category.typeId : null);
  const [pictureData, setPictureData] = useState({
    url: category && category.picture ? category.picture.url : null,
    width: category && category.picture ? category.picture.width : null,
    height: category && category.picture ? category.picture.height : null,
  });
  const [iconData, setIconData] = useState({
    url: category && category.icon ? category.icon.url : null,
    width: category && category.icon ? category.icon.width : null,
    height: category && category.icon ? category.icon.height : null,
  });

  const [pictureFile, setPictureFile] = useState(null);
  const [iconFile, setIconFile] = useState(null);

  const handleEventTypeChange = (e) => {
    console.log('Current Type before:', typeId);
    setTypeId(e.target.value);
    console.log('Current Type after:', typeId);
  };

  // Handler pour les fichiers sélectionnés de la photo de catégorie
  const handlePictureSelected = (files) => {
    const [file] = files;
    setPictureFile(file); // Save file to state for upload
    const img = new Image();
    const url = URL.createObjectURL(file);
    img.onload = () => {
      setPictureData({
        url: url,
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
      URL.revokeObjectURL(url);
    };
    img.src = url;
  };

  // Handler pour la suppression de la photo de catégorie
  const handlePictureRemoved = () => {
    URL.revokeObjectURL(pictureData.url); // Libérer la ressource
    setPictureData({ url: null, width: null, height: null });
  };

  // Handler pour les fichiers sélectionnés de l'icône de catégorie
  const handleIconSelected = (files) => {
    const [file] = files;
    setIconFile(file); // Save file to state for upload
    const img = new Image();
    const url = URL.createObjectURL(file);
    img.onload = () => {
      setIconData({
        url: url,
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
      URL.revokeObjectURL(url);
    };
    img.src = url;
  };

  // Handler pour la suppression de l'icône de catégorie
  const handleIconRemoved = () => {
    URL.revokeObjectURL(iconData.url);
    setIconData({ url: null, width: null, height: null });
  };

  // Gère la creation
  const cancelRef = useRef();

  const prepareCategoryData = async () => {
    let newPictureUrl = pictureData.url;
    let newIconUrl = iconData.url;

    // Ajouter les nouveaux fichiers s'ils existent
    if (pictureFile) {
      newPictureUrl = await uploadFile(
        'gs://sanbbiz-35578.appspot.com/dev_event_categories/pictures',
        pictureFile
      );
    }
    if (iconFile) {
      newIconUrl = await uploadFile(
        'gs://sanbbiz-35578.appspot.com/dev_event_categories/icons',
        iconFile
      );
    }

    return {
      name,
      typeId,
      picture: {
        url: newPictureUrl,
        width: pictureData.width,
        height: pictureData.height,
      },
      icon: {
        url: newIconUrl,
        width: iconData.width,
        height: iconData.height,
      },
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const categoryData = await prepareCategoryData();

      if (category) {
        // Mise à jour de la catégorie existante
        const docRef = doc(firestore_db, TABLE_NAME.categories, category.id);
        await updateDoc(docRef, categoryData);
        toast({
          title: 'Catégorie modifiée',
          description: 'La catégorie a été mise à jour avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Création d'une nouvelle catégorie
        categoryData.createdAt = serverTimestamp(); // Ajout du champ createdAt uniquement ici
        const docRef = await addDoc(
          collection(firestore_db, TABLE_NAME.categories),
          categoryData
        );
        toast({
          title: 'Catégorie ajoutée',
          description: 'La catégorie a été mise à jour avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        console.log('New category created with ID:', docRef.id);
      }
      onClose(); // Fermer le formulaire après l'opération
    } catch (error) {
      toast({
        title: 'Erreur de mise à jour',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertPopup
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      onNo={onClose}
      onYes={handleSubmit}
      windowTitle={
        category ? 'Modifier la catégorie' : 'Créer une nouvelle catégorie'
      }
    >
      <EditCategoryFormContent>
        {category ? (
          <></>
        ) : (
          <h1 className="edit-cat-form-title">
            {' '}
            Création d'une nouvelle catégorie{' '}
          </h1>
        )}
        <FormFields>
          <FormFieldsGrid>
            <FormField
              id="picture"
              label="Photo de la catégorie"
              inputType="imageUploader"
              onFilesSelected={handlePictureSelected}
              onImageRemoved={handlePictureRemoved}
              imgWidth="200px"
              imgHeight="200px"
              defaultImageUrl={pictureData.url ? [pictureData.url] : []}
            />
            <FormField
              id="typeId"
              label="Choisir le type de catégorie"
              inputType="select"
              value={typeId}
              onChange={handleEventTypeChange}
              isRequired
            >
              {!category && <option value="">Sélectionnez un type</option>}
              {eventTypes.map((eventType) => {
                console.log('Event Type Option:', eventType.id);
                return (
                  <option key={eventType.id} value={eventType.id}>
                    {eventType.name}
                  </option>
                );
              })}
            </FormField>
          </FormFieldsGrid>
          <FormFieldsGrid>
            <FormField
              id="catIcon"
              label="Icon de la catégorie"
              inputType="imageUploader"
              onFilesSelected={handleIconSelected}
              onImageRemoved={handleIconRemoved}
              imgWidth="100px"
              imgHeight="100px"
              defaultImageUrl={iconData.url ? [iconData.url] : []}
            />
            <FormField
              id="catName"
              label="Nom de la catégorie"
              inputType="text"
              defaultValue={name}
              placeholder="Entrez le nouveau nom de la catégorie"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormFieldsGrid>
        </FormFields>
      </EditCategoryFormContent>
    </AlertPopup>
  );
}

export default EditCategoryForm;
