const env = process.env.REACT_APP_ENV;

const TABLE_NAME =
  env === 'prod'
    ? {
        events: 'events',
        tickets: 'tickets',
        balances: 'balances',
        transactions: 'transactions',
        categories: 'event_categories',
        blogs: 'blogs',
        customerFeedbacks: 'customer_feedbacks',
        eventTypes: 'event_types',
        marketingCampaigns: 'marketing_campaigns',
        paymentMethods: 'payment_methods',
        scanners: 'scanners',
      }
    : {
        events: 'dev_events',
        tickets: 'dev_tickets',
        balances: 'dev_balances',
        transactions: 'dev_transactions',
        blogs: 'dev_blogs',
        categories: 'dev_event_categories',
        customerFeedbacks: 'dev_customer_feedbacks',
        eventTypes: 'dev_event_types',
        marketingCampaigns: 'dev_marketing_campaigns',
        paymentMethods: 'dev_payment_methods',
        scanners: 'dev_scanners',
      };

const ROUTES = {
  homePage: '/',
  eventDetailsPage: '/eventDetailPage',
  checkoutPage: '/checkout',
  eventListPage: '/events',
  categoryListPage: '/categories',
  aboutPage: '/about',
  contactPage: '/contactUs',
  loginPage: '/login',
  bookingPage: '/booking',
  resetPasswordPage: '/resetPassword',
  changePasswordPage: '/changePassword',
  createAccountPage: '/createAccount',
  createEventPage: '/createEvent',
  deleteAccountPage: '/deleteAccount',
  feedbackListPage: '/testimonials',
  CGUPage: '/termsNconditions',
  CGVPage: '/termsNconditionsSale',
  privacyPolicyPage: '/policyNconfidentiality',

  blogListPage: '/blogs',
  ordersPage: '/orders',
  orderDetailsPage: '/orders/details',
  orderSucceedPage: '/orderSucceedPage',

  // For Promoter and Admin
  userDashboardPage: '/dashboard',
  customerProfilePage: '/customerProfile',
  mainProfilePage: '/mainProfile',
  manageBlogPage: '/manageBlogs',
  manageFeedbacksPage: '/manageFeedbacks',
  manageCategoryPage: '/manageCategories',
  manageScannerPage: '/scanners',
  manageEventPage: '/manageEvents',
  manageBusinessPlanPage: '/manageBusinessPlans',
};

const CINETPAY = {
  APIKEY: process.env.REACT_APP_CINETPAY_API_KEY,
  SITE_ID: process.env.REACT_APP_CINETPAY_SITE_ID,
  PAYMENT_URL: 'https://api-checkout.cinetpay.com/v2/payment',
  PAYMENT_RESULT_CHECK_URL:
    'https://api-checkout.cinetpay.com/v2/payment/check',
  RESULTS: {
    PAYMENT: {
      URL_CREATED: 201,
    },
    PAYMENT_CHECK: {
      WAITING_CUSTOMER_PAYMENT: 662,
      PAYMENT_FAILED: 600,
      TRANSACTION_CANCEL: 627,
      OTP_CODE_ERROR: 604,
    },
  },
};

const USER_STATUS = {
  CUSTOMER: 0,
  PROMOTER: 1,
  ADMIN: 2,
  GUEST: 3,
};

module.exports = {
  env,
  TABLE_NAME,
  ROUTES,
  CINETPAY,
  USER_STATUS,
};
