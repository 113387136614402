/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Heading, Button, Link } from '@chakra-ui/react';
import FormField from '../../atoms/FormField/FormField';
import MultipleImageBox from '../../atoms/MultipleImageBox/MultipleImageBox';
import styled from 'styled-components';
import { doPasswordChange } from '../../../api/firebase/auth';
import { useAuth } from '../../../contexts/AuthContext';
import { useToast } from '@chakra-ui/react';
import RedirectComponent from '../../atoms/RedirectComponent/RedirectComponent';
import { ROUTES } from '../../../utils/variables';

const ChangePasswordFormContainer = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  border-radius: 10px;
  overflow: hidden;
  @media screen and (max-width: 930px) {
    display: inline;
  }
`;

const ChangePasswordFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  height: '500px';
  padding: 20px;
`;

const FormFields = styled.div``;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

function ChangePasswordForm() {
  const { userLoggedIn, currentUser } = useAuth();

  const toast = useToast();
  const navigate = useNavigate();

  const [pwd, setPwd] = useState('');
  const [confirmedPwd, setConfirmedPwd] = useState('');

  const handleNewPwd = async (e) => {
    e.preventDefault();
    try {
      if (pwd === confirmedPwd) {
        await doPasswordChange(pwd);
        navigate('/');
        window.scrollTo(0, 0); // Scroller vers le haut de la page
      } else {
        toast({
          title: 'Echec du changement de mot de passe ',
          description: 'Les mots de passe ne sont pas identiques',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <>
      {!userLoggedIn && (
        <RedirectComponent to={ROUTES.resetPasswordPage} replace={true} />
      )}
      <ChangePasswordFormContainer>
        <MultipleImageBox />
        <ChangePasswordFormContent>
          <Heading mb={4}>Définissez un nouveau mot de passe </Heading>
          <FormFields>
            <FormField
              id="email"
              label="Email"
              inputType="email"
              placeholder={currentUser?.dbProfile.email}
              value={currentUser?.dbProfile.email}
              disabled
            />
            <FormFieldsGrid>
              <FormField
                id="password"
                label="Mot de passe"
                inputType="password"
                placeholder="Mot de passe"
                onChange={(e) => setPwd(e.target.value)}
                isRequired
              />
              <FormField
                id="confirm-password"
                label="Confirmer le mot de passe"
                inputType="password"
                placeholder="Confirmer le mot de passe"
                onChange={(e) => setConfirmedPwd(e.target.value)}
                isRequired
              />
            </FormFieldsGrid>
          </FormFields>
          <Button width="full" mt={10} colorScheme="red" onClick={handleNewPwd}>
            Changer le mot de passe
          </Button>

          <Flex align="center" justify="center" mt={4}>
            <Link color="blue.500" href={ROUTES.createAccountPage}>
              Problème de connexion ?
            </Link>
          </Flex>
        </ChangePasswordFormContent>
      </ChangePasswordFormContainer>
    </>
  );
}

export default ChangePasswordForm;
