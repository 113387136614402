/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 12/04/2024
/* ******************************************* */

import React from 'react';
import { Input, InputRightAddon, InputGroup } from '@chakra-ui/react';

const InputPrice = ({ id, placeholder, isRequired, ...props }) => {
  return (
    <InputGroup>
      <Input
        id={id}
        type="number"
        placeholder={placeholder}
        required={isRequired}
        {...props}
      />
      <InputRightAddon children="XOF" />
    </InputGroup>
  );
};

export default InputPrice;
