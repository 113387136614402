/* ******************************************** */
/* * Author: Sidikou Jamal  & Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 21/04/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import EventItem from '../../molecules/EventItem/EventItem';
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from 'firebase/firestore';

import { EVENT_REVIEW_STATE } from '../../../utils/constants';
import { firestore_db } from '../../../api/firebase/firebase-config';
import HorizontalScrollList from '../../molecules/HorizontalScrollList/HorizontalScrollList';
import styled from 'styled-components';
import { sortEventsByNearestStartDate } from '../../../utils/event';
import { TABLE_NAME } from '../../../utils/variables';

const UpcomingEventListBlock = styled.div`
  position: relative;
  padding: 30px 0px;
  background-color: #ecf0f2;
`;

const UpcomingEventListItemsContainer = styled.div`
  width: 100%;
  padding: 10px 0px;
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'space-around'};
  gap: 10px;
`;
const UpcomingEventListTitle = styled.h1`
  font-weight: bold;
  font-size: 32px;
  color: #051145;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EventCard = styled.div`
  box-shadow: 0 3px 5px rgba(0, 0, 255, 0.2);
`;

function UpcomingEvents() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchEvents() {
      const eventsQuery = query(
        collection(firestore_db, TABLE_NAME.events),
        where('review.state', '==', EVENT_REVIEW_STATE.ACCEPTED)
      );

      try {
        const snapshot = await getDocs(eventsQuery);
        const eventsArray = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Appliquer la fonction de tri et conserver uniquement les 6 premiers
        const sortedEvents = sortEventsByNearestStartDate(eventsArray).slice(
          0,
          15
        );
        setEvents(sortedEvents);
      } catch (error) {
        console.error('Erreur lors de la récupération des documents:', error);
      }

      setLoading(false);
    }
    fetchEvents();
  }, []);

  if (loading) {
    // console.log('Loading...');
  }

  const justifyContentStyle = events.length > 3 ? 'space-around' : 'center';

  const sortedEvents = sortEventsByNearestStartDate(events);

  return (
    <UpcomingEventListBlock>
      <UpcomingEventListTitle> Événements à Venir</UpcomingEventListTitle>
      <HorizontalScrollList>
        <UpcomingEventListItemsContainer justifyContent={justifyContentStyle}>
          {sortedEvents.map((event) => {
            return (
              <EventCard>
                <EventItem id={event.id} currentEvent={event} />
              </EventCard>
            );
          })}
        </UpcomingEventListItemsContainer>
      </HorizontalScrollList>
    </UpcomingEventListBlock>
  );
}

export default UpcomingEvents;
