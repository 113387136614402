// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBgNqolTjMICENnveCt8-nYxqA8kvCKHx0',
  authDomain: 'sanbbiz-35578.firebaseapp.com',
  projectId: 'sanbbiz-35578',
  storageBucket: 'sanbbiz-35578.appspot.com',
  messagingSenderId: '1018095961210',
  appId: '1:1018095961210:web:c240633384a8b4ca99e7c3',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const authToken = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
const firestore_db = getFirestore(app);

// Initialise Storage firebase
const storage_firebase = getStorage(app);

export { app, authToken, firestore_db, storage_firebase };
