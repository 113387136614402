/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  InputGroup,
  HStack,
  InputRightElement,
  Box,
  Image,
  Switch,
  Checkbox,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import DaysPicker from '../DaysPicker/DaysPicker';
import MonthDaysPicker from '../MonthDaysPicker/MonthDaysPicker';
import ImageUploader from '../ImageUploader/ImageUploader';
import DatePicker from '../DatePicker/DatePicker';
import TimePicker from '../TimePicker/TimePicker';
import InputPrice from '../InputPrice/InputPrice';
import SearchIcon from '../../atoms/Icon/SearchIcon';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';

const RequiredAsterik = styled.span`
  color: red;
  margin-left: 4px;
`;

const FormField = ({
  children,
  id,
  label,
  inputType,
  placeholder,
  isRequired,
  onFilesSelected,
  onImageRemoved,
  buttonTextBefore,
  buttonTextAfter,
  imgWidth,
  imgHeight,
  defaultImageUrl,
  canBeCrop,
  onDaysSelected,
  updateDate,
  updateTime,
  value,
  checked,
  ...props
}) => {
  // Sélection du type d'entrée en fonction de la prop `inputType`
  let InputComponent;

  switch (inputType) {
    case 'text':
      InputComponent = (
        <Input
          id={id}
          placeholder={placeholder}
          isRequired={isRequired}
          {...props}
        />
      );
      break;
    case 'select':
      InputComponent = (
        <Select id={id} placeholder={placeholder} {...props}>
          {children}
        </Select>
      );
      break;
    case 'checkbox':
      return (
        <Checkbox id={id} isChecked={checked} value={value} {...props}>
          {label}
        </Checkbox>
      );

    case 'date':
      InputComponent = (
        <DatePicker
          id={id}
          placeholder={placeholder}
          isRequired={isRequired}
          updateDate={updateDate}
          value={value}
          {...props}
        />
      );
      break;
    case 'time':
      InputComponent = (
        <TimePicker
          id={id}
          placeholder={placeholder}
          isRequired={isRequired}
          updateTime={updateTime}
          value={value}
          {...props}
        />
      );
      break;
    case 'textarea':
      InputComponent = (
        <Textarea
          id={id}
          value={value}
          placeholder={placeholder}
          isRequired={isRequired}
          {...props}
        />
      );
      break;

    case 'imageUploader':
      InputComponent = (
        <ImageUploader
          id={id}
          onFilesSelected={onFilesSelected}
          buttonTextBefore={buttonTextBefore}
          buttonTextAfter={buttonTextAfter}
          multiple={props.multiple}
          imgWidth={imgWidth}
          imgHeight={imgHeight}
          onImageRemoved={onImageRemoved}
          defaultImageUrl={defaultImageUrl}
          canBeCrop={canBeCrop}
          {...props}
        />
      );
      break;
    case 'div':
      InputComponent = (
        <HStack
          id={id}
          {...props}
          size="sm"
          borderColor="gray.200" // Utilisez une couleur de bordure spécifique de la palette de Chakra UI
          borderRadius="md" // Applique un rayon de bordure pour des coins arrondis
          h="40px"
        >
          {children}
        </HStack>
      );
      break;

    case 'daysPicker':
      InputComponent = <DaysPicker id={id} />;
      break;

    case 'monthDaysPicker':
      InputComponent = (
        <MonthDaysPicker id={id} onDaysSelected={onDaysSelected} />
      );
      break;
    case 'currency':
      InputComponent = (
        <InputPrice
          id={id}
          placeholder={placeholder}
          isRequired={isRequired}
          {...props}
        />
      );
      break;

    case 'search':
      InputComponent = (
        <InputGroup>
          <Input
            id={id}
            placeholder={placeholder}
            isRequired={isRequired}
            {...props}
          />
          <InputRightElement children={<SearchIcon />} />
        </InputGroup>
      );
      break;
    case 'toggle':
      return (
        <FormControl mt={4}>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Switch
              id={id}
              isChecked={props.isChecked}
              onChange={props.onChange}
              colorScheme="blue"
              size="lg"
              sx={{
                transformOrigin: 'center', // Centrage du grossissement si nécessaire
              }}
              {...props}
            />
            {label && (
              <FormLabel
                htmlFor={id}
                mt={2}
                fontSize="sm"
                style={{ marginBottom: 0, alignSelf: 'flex-start' }}
              >
                {label}
                {isRequired && <RequiredAsterik>*</RequiredAsterik>}
              </FormLabel>
            )}
          </Box>
        </FormControl>
      );

    // AJout d'autres cas
    default:
      InputComponent = (
        <Input
          id={id}
          placeholder={placeholder}
          isRequired={isRequired}
          type={inputType}
          {...props}
        />
      );
  }

  return (
    <FormControl mt={4}>
      {label && (
        <FormLabel htmlFor={id}>
          {label}
          {isRequired && <span className="required-asterisk">*</span>}
        </FormLabel>
      )}
      {InputComponent}
    </FormControl>
  );
};
FormField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  inputType: PropTypes.oneOf([
    'text',
    'select',
    'date',
    'time',
    'email',
    'number',
    'password',
    'textarea',
    'imageUploader',
    'daysPicker',
    'monthsPicker',
    'currency',
    'search',
    'toggle',
    'checkbox',
  ]),
  placeholder: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date),
  buttonTextBefore: PropTypes.string,
  buttonTextAfter: PropTypes.string,
};

FormField.defaultProps = {
  inputType: 'text',
  placeholder: '',
  selectedDate: new Date(), // Default to today's date
  onFilesSelected: () => {},
  buttonTextBefore: 'Importer une image',
  buttonTextAfter: "Changer d'image",
  imgWidth: '200px',
  imgHeight: '200px',
};

export default FormField;
