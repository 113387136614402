/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import MultipleImageBox from '../../atoms/MultipleImageBox/MultipleImageBox';

import {
  Flex,
  Heading,
  Button,
  Checkbox,
  Stack,
  Link,
  Text,
} from '@chakra-ui/react';
import { doSignInWithEmailAndPassword } from '../../../api/firebase/auth';
import { useAuth } from '../../../contexts/AuthContext';

import FormField from '../../atoms/FormField/FormField';
import Cookies from 'js-cookie';
import { useToast } from '@chakra-ui/react';

import styled from 'styled-components';
import RedirectComponent from '../../atoms/RedirectComponent/RedirectComponent';
import { ROUTES } from '../../../utils/variables';

const LoginFormContainer = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 0;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  border-radius: 10px;
  overflow: hidden;
  @media screen and (max-width: 930px) {
    display: inline;
  }
`;

const LoginFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  height: '500px';
  padding: 20px;
`;

const FormFields = styled.div``;

function ConnexionForm() {
  const toast = useToast();
  const { userLoggedIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    // Vérifier si l'utilisateur est déjà considéré comme connecté avant de vérifier les cookies
    if (!userLoggedIn) {
      const isLoggedIn = Cookies.get('userLoggedIn');
    }
  }, [userLoggedIn]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const result = await doSignInWithEmailAndPassword(email, password);
      if (result && result.user) {
        const options = rememberMe ? { expires: 7 } : undefined;
        Cookies.set('userLoggedIn', 'true', options);
      } else if (!result.user) {
        // Afficher le toast ici pour les erreurs d'authentification
        toast({
          title: 'Erreur de connexion',
          description: 'Adresse électronique ou mot de passe incorrect',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Ce cas ne devrait pas normalement se produire
        throw new Error('Aucune donnée de connexion reçue');
      }
    } catch (error) {
      // Gérer les erreurs non spécifiques ou les exceptions lancées manuellement
      toast({
        title: 'Erreur de connexion',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {userLoggedIn && <RedirectComponent to={'/'} />}

      <LoginFormContainer>
        <MultipleImageBox />

        <LoginFormContent>
          <Heading mb={6}>Connectez-vous à votre compte</Heading>
          <FormFields>
            <FormField
              id="email"
              label="Email"
              inputType="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              isRequired
            />

            <FormField
              id="password"
              label="Mot de passe"
              inputType="password"
              placeholder="Entrez votre mot de passe "
              onChange={(e) => setPassword(e.target.value)}
              isRequired
            />
          </FormFields>

          <Stack
            spacing={6}
            direction="row"
            mt={4}
            justifyContent="space-between"
          >
            <Checkbox
              isChecked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            >
              Souvenez-vous de moi
            </Checkbox>
            <Link color="blue.500" href={ROUTES.resetPasswordPage}>
              Mot de passe oublié ?
            </Link>
          </Stack>

          <Button width="full" mt={10} colorScheme="red" onClick={handleLogin}>
            Se connecter maintenant
          </Button>

          <Flex align="center" justify="center" mt={4}>
            <Text mr={2}>Vous n'avez pas de compte ?</Text>
            <Link color="blue.500" href={ROUTES.createAccountPage}>
              Créer un compte
            </Link>
          </Flex>
        </LoginFormContent>
      </LoginFormContainer>
    </>
  );
}

export default ConnexionForm;
