/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import GlobalStyle from './utils/style/GlobalStyle';
import HomePage from './components/pages/HomePage/HomePage';
import AboutPage from './components/pages/AboutPage/AboutPage';
import CGUPage from './components/pages/CGUPage/CGUPage';
import CGVPage from './components/pages/CGVPage/CGVPage';
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage/PrivacyPolicyPage';
import EventsPage from './components/pages/EventsPage/EventsPage';
import ErrorPage from './components/pages/ErrorPage/ErrorPage';
import CategoriesPage from './components/pages/CategoriesPage/CategoriesPage';
import ContactPage from './components/pages/ContactPage/ContactPage';
import LoginPage from './components/pages/LoginPage/LoginPage';
import ResetPasswordPage from './components/pages/ResetPasswordPage/ResetPasswordPage';
import ChangePasswordPage from './components/pages/ChangePasswordPage/ChangePasswordPage';
import BookingPage from './components/pages/BookingPage/BookingPage';
import BlogsPage from './components/pages/BlogsPage/BlogsPage';
import FeedbacksPage from './components/pages/FeedbacksPage/FeedbacksPage';
import YourTicketsPage from './components/pages/YourTicketsPage/YourTicketsPage';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import CreateAccountPage from './components/pages/CreateAccountPage/CreateAccountPage';
import DeleteAccountPage from './components/pages/DeleteAccountPage/DeleteAccountPage';
import CreateEventPage from './components/pages/CreateEventPage/CreateEventPage';
import ManageCategoryPage from './components/pages/ManageCategoryPage/ManageCategoryPage';
import ManageScannerPage from './components/pages/ManageScannerPage/ManageScannerPage';
import ManageEventPage from './components/pages/ManageEventPage/ManageEventPage';
import EventDetailPage from './components/pages/EventDetailsPage/EventDetailsPage';
import BlogDetailPage from './components/pages/BlogDetailPage/BlogDetailPage';
import BoughtTicketDetailsPage from './components/pages/BoughtTicketDetailsPage/BoughtTicketDetailsPage';
import UserDashboardPage from './components/pages/UserDashboardPage/UserDashboardPage';
import CustomerProfilePage from './components/pages/CustomerProfilePage/CustomerProfilePage';
import { ROUTES, env } from './utils/variables.js';
import ClientLayout from './components/templates/ClientLayout/ClientLayout.js';
import CreateEventSchedulerPage from './components/pages/CreateEventSchedulerPage/CreateEventSchedulerPage';
import CreateEventTicketsPage from './components/pages/CreateEventTicketsPage/CreateEventTicketsPage';
import CreateEventPricingPage from './components/pages/CreateEventPricingPage/CreateEventPricingPage';
import ManageBlogPage from './components/pages/ManageBlogPage/ManageBlogPage';
import ManageFeedbackPage from './components/pages/ManageFeedbackPage/ManageFeedbackPage';
import ManageBusinessPlanPage from './components/pages/ManageBusinessPlanPage/ManageBusinessPlanPage';
import MainProfilePage from './components/pages/MainProfilePage/MainProfilePage.js';
import CheckoutPage from './components/pages/CheckoutPage/CheckoutPage.js';
import OrderSucceedPage from './components/pages/OrderSucceedPage/OrderSucceedPage.js';
import { useMediaQuery } from '@chakra-ui/react';
import IsMobilePage from './components/pages/IsMobilePage/IsMobilePage.js';
import BlockedPage from './components/pages/BlockedPage/BlockedPage.js';
import WhatsappButton from './components/molecules/WhatsappButton/WhatsappButton.js';
import DevModeBanner from './components/atoms/DevModeBanner/DevModeBanner.js';

function AppContent() {
  const location = useLocation(); // Utilisez useLocation ici
  const [isMobile] = useMediaQuery('(max-width: 800px)');
  const noMobileBlockRoutes = [
    ROUTES.deleteAccountPage,
    ROUTES.CGUPage,
    ROUTES.CGVPage,
    ROUTES.privacyPolicyPage,
    ROUTES.aboutPage,
  ];

  const blockedRoutes = [ROUTES.createEventPage];

  // check si la route actuelle est dans la liste des noMobileBlockRoutes
  const isExcludedRoute = noMobileBlockRoutes.includes(location.pathname);

  // Check si la route actuelle est dans la liste des routes bloquées
  const isBlockedRoute = blockedRoutes.includes(location.pathname);

  // if (isMobile) {
  if (isBlockedRoute) {
    return <BlockedPage />;
  }
  //   else if (!isExcludedRoute) {
  //     return <IsMobilePage />;
  //   }
  // }

  return (
    <>
      {isMobile && !isExcludedRoute ? (
        <IsMobilePage />
      ) : (
        <Routes>
          {/* Routes avec Client Layout */}
          <Route Component={ClientLayout}>
            <Route path={ROUTES.homePage} element={<HomePage />} />
            <Route path={ROUTES.aboutPage} element={<AboutPage />} />
            <Route path={ROUTES.CGUPage} element={<CGUPage />} />
            <Route path={ROUTES.CGVPage} element={<CGVPage />} />
            <Route
              path={ROUTES.privacyPolicyPage}
              element={<PrivacyPolicyPage />}
            />
            <Route path={ROUTES.eventListPage} element={<EventsPage />} />
            <Route path="/events/:eventId" element={<EventDetailPage />} />
            <Route
              path={ROUTES.categoryListPage}
              element={<CategoriesPage />}
            />
            <Route path={ROUTES.contactPage} element={<ContactPage />} />
            <Route path={ROUTES.loginPage} element={<LoginPage />} />
            <Route
              path={ROUTES.resetPasswordPage}
              element={<ResetPasswordPage />}
            />
            <Route
              path={ROUTES.changePasswordPage}
              element={<ChangePasswordPage />}
            />
            <Route path={ROUTES.bookingPage} element={<BookingPage />} />
            <Route
              path={ROUTES.createAccountPage}
              element={<CreateAccountPage />}
            />
            <Route
              path={ROUTES.deleteAccountPage}
              element={<DeleteAccountPage />}
            />
            <Route
              path={ROUTES.createEventPage}
              element={<CreateEventPage />}
            />
            <Route path={ROUTES.checkoutPage} element={<CheckoutPage />} />
            <Route
              path={ROUTES.orderSucceedPage}
              element={<OrderSucceedPage />}
            />
            <Route path={ROUTES.blogListPage} element={<BlogsPage />} />
            <Route path={'/blogs/:blogId'} element={<BlogDetailPage />} />
            <Route path={ROUTES.ordersPage} element={<YourTicketsPage />} />
            <Route
              path={ROUTES.orderDetailsPage}
              element={<BoughtTicketDetailsPage />}
            />
            <Route path={ROUTES.feedbackListPage} element={<FeedbacksPage />} />
          </Route>

          <Route path="/test1" element={<CreateEventSchedulerPage />} />
          <Route path="/test2" element={<CreateEventTicketsPage />} />
          <Route path="/test3" element={<CreateEventPricingPage />} />
          <Route
            path={ROUTES.userDashboardPage}
            element={<UserDashboardPage />}
          />
          <Route
            path={ROUTES.customerProfilePage}
            element={<CustomerProfilePage />}
          />
          <Route path={ROUTES.mainProfilePage} element={<MainProfilePage />} />
          <Route path={ROUTES.manageBlogPage} element={<ManageBlogPage />} />
          <Route
            path={ROUTES.manageFeedbacksPage}
            element={<ManageFeedbackPage />}
          />
          <Route
            path={ROUTES.manageCategoryPage}
            element={<ManageCategoryPage />}
          />
          <Route
            path={ROUTES.manageScannerPage}
            element={<ManageScannerPage />}
          />
          <Route path={ROUTES.manageEventPage} element={<ManageEventPage />} />

          <Route
            path={ROUTES.manageBusinessPlanPage}
            element={<ManageBusinessPlanPage />}
          />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      )}
    </>
  );
}

function App() {
  return (
    <Router className="App">
      <GlobalStyle />
      <AppContent />
      <WhatsappButton />
    </Router>
  );
}

export default App;
