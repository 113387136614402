/* ******************************************** */
/* * Author: Jamal SIDIKOU
/* * Project : SANNBIZ
/* * Create at : 30/05/2024
/* ******************************************* */

import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ROUTES, TABLE_NAME } from '../../../utils/variables';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import soldout from '../../../assets/medias/soldout.png';

const TicketContent = styled.div`
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.ticketType
      ? '0 2px 10px rgba(202, 65, 65, 0.7)'
      : '0 2px 10px rgba(55, 151, 230, 0.7)'};
  width: 400px;
  /* si le ticket pour le créneau est soldout */
  background-color: ${(props) =>
    props.isSoldout === true ? 'lightGray' : 'white'};
`;

const TicketHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
`;

const TicketName = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  padding: 5px 20px;
  line-height: 1.2em;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const TicketPrice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #f1360a;
  font-weight: bold;
`;

const TicketDesc = styled.p`
  padding: 20px;
  text-align: left;
  white-space: pre-wrap;
  line-height: 20px;
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 60px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

function TicketItem({
  index,
  eventId,
  ticketName,
  ticketType, // stand ou billet
  price,
  remainTicket,
  ticketDesc,
  schedule,
  scheduleSelected,
}) {
  const theSchedule = schedule;
  const navigate = useNavigate();
  const [isSoldout, setIsSoldout] = useState(false);

  // Je récupère tous les tickets vendus de ce créneau pour savoir si c'est soldout
  useEffect(() => {
    const checkIfIsSoldout = async () => {
      try {
        const q = query(
          collection(firestore_db, TABLE_NAME.tickets),
          where('eventId', '==', eventId),
          where('versionId', '==', index)
        );
        const querySnapshot = await getDocs(q);

        let stockSold = [];
        querySnapshot.forEach((document) => {
          const doc = { id: document.id, data: document.data() };
          stockSold.push(doc);
        });
        stockSold = stockSold.filter(
          (ticketInStock) =>
            scheduleSelected.start.seconds ===
              ticketInStock.data.validity.start.seconds &&
            scheduleSelected.end.seconds ===
              ticketInStock.data.validity.end.seconds
        );
        const stock = remainTicket - stockSold.length;

        if (stock <= 0) {
          console.log('soldout');
          setIsSoldout(true);
        } else setIsSoldout(false);
      } catch (error) {
        console.log('error in check');
      }
    };
    checkIfIsSoldout();
  }, [scheduleSelected, schedule]);

  const goToCheckout = () => {
    navigate({
      pathname: ROUTES.checkoutPage,
      search: `?eventId=${eventId}&ticket=${index}&scheduleSelected=${schedule}`,
    });
    window.scrollTo(0, 0);
  };

  return (
    <TicketContent ticketType={ticketType} isSoldout={isSoldout}>
      <TicketHeader>
        <TicketName>{ticketName}</TicketName>
        {/* <p className="remain-ticket">{remainTicket} tickets restants</p> */}
        <TicketPrice>{price} F CFA</TicketPrice>
      </TicketHeader>
      <TicketDesc>{ticketDesc}</TicketDesc>
      {/* Si c'est soldout on affiche l'image soldout sinon on affiche le bouton */}
      {isSoldout === true ? (
        <ImgContainer>
          <Img src={soldout} />
        </ImgContainer>
      ) : (
        <Button
          size="md"
          bgColor="#F1360A"
          color="white"
          onClick={goToCheckout}
          m="10px"
        >
          {!ticketType ? 'Prendre un billet' : 'Réserver un stand'}
        </Button>
      )}
    </TicketContent>
  );
}

export default TicketItem;
