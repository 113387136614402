/* ******************************************** */
/* * Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 21/04/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import EventItem from '../../molecules/EventItem/EventItem';
import { firestore_db } from '../../../api/firebase/firebase-config';
import { collection, onSnapshot } from 'firebase/firestore';
import HorizontalScrollList from '../../molecules/HorizontalScrollList/HorizontalScrollList';
import {
  fromMarketingDoc2SpotlightedEventIds,
  getSpotlightedEvents,
} from '../../../utils/function';
import styled from 'styled-components';
import { sortEventsByNearestStartDate } from '../../../utils/event';
import { TABLE_NAME } from '../../../utils/variables';

const SpotlightedEventListBlock = styled.div`
  position: relative;
  padding: 30px 0px;
`;

const SpotlightedEventListItemsContainer = styled.div`
  width: 100%;
  padding: 10px 0px;
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'space-around'};
  gap: 10px;
`;

const SpotlightedEventListTitle = styled.h1`
  font-weight: bold;
  font-size: 32px;
  color: #051145;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EventCard = styled.div`
  box-shadow: 0 3px 5px rgba(0, 0, 255, 0.2);
`;

function SpotlightedEvents() {
  const [marketingDoc, setMarketingDoc] = useState([]);
  // recupère la promesse
  const [spotlightedEvents, setSpotlightedEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, TABLE_NAME.marketingCampaigns),
      (snapshot) => {
        const marketingDocsSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMarketingDoc(marketingDocsSnapshot);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const loadEvents = async () => {
      const eventIds = fromMarketingDoc2SpotlightedEventIds(marketingDoc);
      const events = await getSpotlightedEvents(eventIds);
      setSpotlightedEvents(events);
      setLoading(false);
    };

    if (marketingDoc.length > 0) {
      loadEvents();
    }
  }, [marketingDoc]);

  if (loading) {
    // console.log('Loading...');
  }

  const justifyContentStyle =
    spotlightedEvents.length > 3 ? 'space-around' : 'center';

  const sortedSpotlightedEvents =
    sortEventsByNearestStartDate(spotlightedEvents);

  return (
    <SpotlightedEventListBlock>
      <SpotlightedEventListTitle> En Vedette </SpotlightedEventListTitle>
      <HorizontalScrollList>
        <SpotlightedEventListItemsContainer
          justifyContent={justifyContentStyle}
        >
          {sortedSpotlightedEvents.map((event) => {
            return (
              <EventCard key={event.id}>
                <EventItem id={event.id} currentEvent={event} />
              </EventCard>
            );
          })}
        </SpotlightedEventListItemsContainer>
      </HorizontalScrollList>
    </SpotlightedEventListBlock>
  );
}

export default SpotlightedEvents;
