/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHeart, FaShareAlt, FaRegCopy } from 'react-icons/fa';
import styled from 'styled-components';
import Popup from '../../atoms/Popup/Popup';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { useAuth } from '../../../contexts/AuthContext';
import {
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import {
  look4NearestEventSlot,
  getEventDateRange,
  getPriceFromEventTickets,
  formatText,
} from '../../../utils/function';
import { checkIfIsPastDate, extractDayAndMonth } from '../../../utils/date';
import sanbbizLogo from '../../../assets/sanbbizLogos/logoWithColor.png';
import CopyLinkComponent from '../../atoms/CopyLinkComponent/CopyLinkComponent';
import { ROUTES, TABLE_NAME } from '../../../utils/variables';

const EventItemContainer = styled.div`
  position: relative;
  cursor: pointer;
  text-align: left;
  width: 350px;
  border-radius: 5px;
  overflow: hidden;
`;
const EventImgContainer = styled.div`
  position: relative;
`;
const EventItemPoster = styled.img`
  vertical-align: top;
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
`;

const EventName = styled.h2`
  margin-right: 55px; /* 55 = Event-date right position + event-date width */
  padding: 5px 20px;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2em;
  height: 60px;
  // display: flex;
  // align-items: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.4em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const EventDescritpion = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  padding: 0px 20px;
  margin-bottom: 5px;
  color: #051145;
  line-height: 1.2em;
  text-align: left;
  width: 100%;
  height: 76px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
`;

const EventDateContainer = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 300px;
  transform: translateY(-50%);
  right: 20px;
  background-color: #f1360a;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EventDateDay = styled.span`
  font-weight: bold;
`;
const EventDateMonth = styled.span`
  &::first-letter {
    text-transform: uppercase;
  }
`;

const EventPrice = styled.span`
  padding: 0 20px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionButton = styled.button`
  background-color: rgb(235, 233, 233);
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  margin-left: 10px;
  color: ${(props) => (props.isLiked ? '#f1360a' : 'initial')};

  &:hover {
    color: #f1360a;
  }
`;

function EventItem({ id, currentEvent }) {
  const toast = useToast();
  const navigate = useNavigate();
  const { userLoggedIn, currentUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    if (currentEvent && currentUser && currentEvent.likedBy) {
      const isAlreadyLiked = currentEvent.likedBy.includes(
        currentUser.authProfile.uid
      );
      setIsLiked(isAlreadyLiked);
    }
  }, [currentEvent, currentUser]);

  // Extraction du jour et du mois à partir du timestamp
  const slotIndex = look4NearestEventSlot(currentEvent);
  if (slotIndex === undefined) return null; // Si aucun slot n'est trouvé, ne pas afficher l'événement

  const nearestDate =
    currentEvent.dates && currentEvent.dates[slotIndex]
      ? currentEvent.dates[slotIndex].end.toDate() // Conversion du timestamp Firestore
      : null;

  // Vérifier si la date est passée
  if (!nearestDate || checkIfIsPastDate(1, nearestDate)) {
    return null; // Ne pas afficher l'événement si la date est passée
  }

  // Extraire le jour et le mois de la date la plus proche
  const startDate = extractDayAndMonth(nearestDate);

  // recupère le prix minmum ou sinon affiche le prix
  const eventPrice = getPriceFromEventTickets(currentEvent?.ticketVersions);

  const redirectClick = (e) => {
    if (window.getSelection().toString()) {
      // Si du texte est sélectionné, ne fait rien
      e.preventDefault();
      return;
    }

    if (!e.defaultPrevented) {
      // Vérifie si l'événement n'a pas déjà été traité
      navigate(`/events/${id}`);
      window.scrollTo(0, 0);
    }
  };

  const handleLike = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!userLoggedIn) {
      navigate(ROUTES.loginPage);
      return;
    }
    const eventRef = doc(firestore_db, TABLE_NAME.events, id);
    const updatedLikes = isLiked
      ? arrayRemove(currentUser.authProfile.uid)
      : arrayUnion(currentUser.authProfile.uid);

    try {
      await updateDoc(eventRef, {
        likedBy: updatedLikes,
      });
      setIsLiked(!isLiked); // bascule l'état de isLiked
      toast({
        title: isLiked ? 'Like retiré' : 'Événement aimé',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating likes', error);
      toast({
        title: 'Erreur lors de la mise à jour',
        description: 'Impossible de mettre à jour le statut du like.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleShare = (event) => {
    event.stopPropagation(); // Stop la propagation avant tout
    onOpen();
  };

  return (
    <EventItemContainer onClick={redirectClick}>
      <EventImgContainer>
        <EventItemPoster
          src={currentEvent?.posterPicture?.url || sanbbizLogo}
          alt={`${currentEvent?.name} cover`}
        />
        <ButtonContainer>
          <ActionButton isLiked={isLiked} onClick={handleLike}>
            <FaHeart />
          </ActionButton>
          <ActionButton onClick={handleShare}>
            <FaShareAlt />
          </ActionButton>
        </ButtonContainer>
        <Popup
          isOpen={isOpen}
          onClose={onClose}
          popupTitle="Partager l'événement"
        >
          <CopyLinkComponent eventId={id} />
        </Popup>
      </EventImgContainer>

      <EventDetails>
        <EventName>{currentEvent.name}</EventName>
        <EventDescritpion>{currentEvent.description}</EventDescritpion>
        <EventDateContainer>
          <EventDateDay>{startDate.day}</EventDateDay>
          <EventDateMonth>{startDate.month}</EventDateMonth>
        </EventDateContainer>
        <EventPrice>{eventPrice}</EventPrice>
      </EventDetails>
    </EventItemContainer>
  );
}

export default EventItem;
