/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 10/05/2024
/* ******************************************* */

import React, { useState, useEffect, useRef } from 'react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import {
  useDisclosure,
  useToast,
  Button,
  Text,
  VStack,
} from '@chakra-ui/react';
import EditBusinessFormulaForm from '../EditBusinessFormulaForm/EditBusinessFormulaForm';
import BusinessFormulaItem from '../BusinessFormulaItem/BusinessFormulaItem';
import AlertPopup from '../../atoms/AlertPopup/AlertPopup';
import styled from 'styled-components';

const ManageFormulaListContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const ManageFormulaCard = styled.div`
  position: relative;
`;

function ManageBusinessFormula() {
  const [formulas, setFormulas] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  // Gère la suppresion et la modification de la catégorie
  const cancelRef = useRef();
  const [selectedFormula, setSelectedFormula] = useState(null);
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  function openDeleteDialog(formula) {
    setSelectedFormula(formula);
    onDeleteOpen();
  }

  function closeDeleteDialog() {
    setSelectedFormula(null);
    onDeleteClose();
  }

  async function deleteFormula() {
    if (!selectedFormula) return;
    try {
      const formulaRef = doc(
        firestore_db,
        'dev_mngt_plans',
        selectedFormula.id
      );
      await deleteDoc(formulaRef);
      toast({
        title: 'Formule supprimée',
        description: 'La formule a été supprimée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur de suppresion ',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    onDeleteClose(); // Fermez le modal après la suppression
  }

  // Modification de la Formula
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  function openEditDialog(formula) {
    setSelectedFormula(formula); // Définir la catégorie à éditer
    onEditOpen(); // Ouvrir la popup d'édition
  }

  // recupère les données de firestore
  useEffect(() => {
    const fetchFormulas = async () => {
      setLoading(true);
      try {
        const snapshot = await getDocs(
          collection(firestore_db, 'dev_mngt_plans')
        );
        const formulasWithAdvantages = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const formulaData = docSnapshot.data();
            const advantages = await Promise.all(
              formulaData.advantages.map(async (adv) => {
                const advDocSnapshot = await getDoc(
                  doc(firestore_db, 'dev_mngt_plan_advantages', adv.id)
                );
                return advDocSnapshot.exists()
                  ? { ...adv, ...advDocSnapshot.data() }
                  : adv;
              })
            );
            return { ...formulaData, advantages, id: docSnapshot.id };
          })
        );
        setFormulas(formulasWithAdvantages);
      } catch (error) {
        console.error('Erreur lors de la récupération des documents:', error);
      }
      setLoading(false);
    };

    fetchFormulas();
  }, []);

  if (loading) {
    console.log('Loading...');
  }

  return (
    <VStack pt={2} borderTop="1px" borderColor="gray.200" mt={10} mb={2}>
      <Text fontSize="2xl" as="b" textAlign="left">
        {' '}
        Liste des formules{' '}
      </Text>

      <ManageFormulaListContainer>
        {formulas.map((formula) => (
          <ManageFormulaCard key={formula.id}>
            {' '}
            <BusinessFormulaItem currentFormula={formula} />
            <Button
              position="absolute"
              right="0"
              top="0"
              size="xs"
              colorScheme="red"
              zIndex="1"
              onClick={() => openDeleteDialog(formula)}
            >
              &#10005; {/* Croix */}
            </Button>
            <Button
              position="absolute"
              right={0}
              bottom={0}
              size="sm"
              colorScheme="blue"
              px={2}
              onClick={() => openEditDialog(formula)}
            >
              Modifier
            </Button>
          </ManageFormulaCard>
        ))}
        {isDeleteOpen && (
          <AlertPopup
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={closeDeleteDialog}
            onNo={closeDeleteDialog}
            onYes={deleteFormula}
            windowTitle="Supprimer la formule"
          >
            Êtes-vous sûr de vouloir supprimer la formule: "
            {selectedFormula?.name}" ?
          </AlertPopup>
        )}
        {isEditOpen && (
          <EditBusinessFormulaForm
            formula={selectedFormula}
            isOpen={isEditOpen}
            onClose={onEditClose}
          />
        )}
      </ManageFormulaListContainer>
    </VStack>
  );
}

export default ManageBusinessFormula;
