/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 04/05/2024
/* ******************************************* */

export const setEventTypeEventId = (typeId) => {
  console.log('Dispatching setEventTypeEventId with typeId:', typeId);
  return {
    type: 'SET_EVENT_TYPE_EVENT',
    payload: typeId,
  };
};

export const setEventCategoryId = (categoryId) => {
  console.log('Dispatching setEventCategoryId with categoryId:', categoryId);
  return {
    type: 'SET_EVENT_CATEGORY',
    payload: categoryId,
  };
};

export const setEventName = (name) => ({
  type: 'SET_EVENT_NAME',
  payload: name,
});

export const setEventDescription = (description) => ({
  type: 'SET_EVENT_DESCRIPTION',
  payload: description,
});

export const setEventLocation = (location) => ({
  type: 'SET_EVENT_LOCATION',
  payload: location,
});

export const setEventDates = (dates) => ({
  type: 'SET_EVENT_DATES',
  payload: dates,
});

// Ajouter une nouvelle date
export const addEventDate = (date) => ({
  type: 'ADD_EVENT_DATE',
  payload: date,
});

// Mettre à jour une date existante
export const updateEventDate = (index, date) => ({
  type: 'UPDATE_EVENT_DATE',
  payload: { index, date },
});

// Supprimer une date
export const removeEventDate = (index) => ({
  type: 'REMOVE_EVENT_DATE',
  payload: index,
});

export const setEventTicketVersions = (ticketVersions) => ({
  type: 'SET_EVENT_TICKET_VERSION',
  payload: ticketVersions,
});

export const setEventCreatorId = (createdBy) => ({
  type: 'SET_EVENT_CREATOR_ID',
  payload: createdBy,
});

export const setEventMngtPlanId = (mngtPlantId) => ({
  type: 'SET_EVENT_MNGT_PLAN_ID',
  payload: mngtPlantId,
});

export const setEventPaymentMethodId = (paymentMethodsId) => ({
  type: 'SET_EVENT_PAYMENT_METHODS_ID',
  payload: paymentMethodsId,
});

export const setEventReview = (review) => ({
  type: 'SET_EVENT_REVIEW',
  payload: review,
});

export const setEventPosterPicture = (posterPicture) => ({
  type: 'SET_EVENT_POSTER_PICTURE',
  payload: posterPicture,
});

export const removeEventPosterPicture = () => {
  return {
    type: 'REMOVE_EVENT_POSTER_PICTURE',
  };
};

export const setEventCoverPicture = (coverPicture) => ({
  type: 'SET_EVENT_COVER_PICTURE',
  payload: coverPicture,
});

export const removeEventCoverPicture = () => {
  return {
    type: 'REMOVE_EVENT_COVER_PICTURE',
  };
};

export const setEventGalleryPictures = (galleryPictures) => ({
  type: 'SET_EVENT_GALLERY_PICTURES',
  payload: galleryPictures,
});

export const removeEventGalleryPicture = (index) => {
  return {
    type: 'REMOVE_EVENT_GALLERY_PICTURE',
    payload: index,
  };
};
