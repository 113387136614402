/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React, { useState } from 'react';
import { Heading, Button } from '@chakra-ui/react';
import MultipleImageBox from '../../atoms/MultipleImageBox/MultipleImageBox';
import FormField from '../../atoms/FormField/FormField';
import styled from 'styled-components';

const BookingFormContainer = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 0;
  max-width: 1000px;
  box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
  border-radius: 10px;
  overflow: hidden;
  @media screen and (max-width: 930px) {
    display: inline;
  }
`;

const BookingFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  height: '500px';
  padding: 20px;
`;

const FormFields = styled.div`
  margin-top: 10px;
`;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

function BookingForm() {
  const [fname, setFname] = useState(null);
  const [lname, setLname] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [city, setCity] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [message, setMessage] = useState(null);

  return (
    <BookingFormContainer>
      <MultipleImageBox />
      <BookingFormContent>
        <Heading>Nous avons décidé de vivre ensemble pour toujours</Heading>
        <FormFields>
          <FormFieldsGrid>
            <FormField
              id="last-name"
              label="Nom"
              inputType="text"
              placeholder="Nom"
              onChange={(e) => setLname(e.target.value)}
              isRequired
            />
            <FormField
              id="mail"
              label="Adresse email"
              inputType="email"
              placeholder="Adresse email"
              onChange={(e) => setEmail(e.target.value)}
              isRequired
            />
          </FormFieldsGrid>
          <FormFieldsGrid>
            <FormField
              id="phone-number"
              label="Numéro de téléphone"
              inputType="tel"
              placeholder="Numéro de téléphone"
              onChange={(e) => setPhone(e.target.value)}
              isRequired
            />
            <FormField
              id="city"
              label="Ville"
              inputType="text"
              value="Ouagadougou"
              onChange={(e) => setCity(e.target.value)}
              isRequired
              disabled
            />
          </FormFieldsGrid>
          <FormFieldsGrid>
            <FormField
              id="date"
              label="Date"
              inputType="date"
              onChange={(e) => setDate(e.target.value)}
              isRequired
            />
            <FormField
              id="time"
              label="Heure"
              inputType="time"
              onChange={(e) => setTime(e.target.value)}
              isRequired
            />
          </FormFieldsGrid>
          <FormField
            id="contact-message"
            label="Votre Message"
            inputType="textarea"
            placeholder="Votre Message"
            height="128px"
            onChange={(e) => setMessage(e.target.value)}
            isRequired
          />
          <Button
            width="full"
            mt={10}
            type="submit"
            colorScheme="orange"
            onClick={() => {}}
          >
            Réserver un rendez-vous
          </Button>
        </FormFields>
      </BookingFormContent>
    </BookingFormContainer>
  );
}

export default BookingForm;
