/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 06/05/2024
/* ******************************************* */

import React from 'react';
import HorizontalScrollList from '../../molecules/HorizontalScrollList/HorizontalScrollList';
import { PartnersLogos } from '../../../assets/datas/PartnersLogos';

import styled from 'styled-components';

const PartnersBlock = styled.div`
  background-color: #ecf0f2;
  padding: 30px 20px 50px 20px;
`;

const PartnersTitle = styled.h1`
  display: block;
  text-align: center;
  color: #051145;
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const PartnersItemsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
`;

const PartnerCard = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
`;

const PartnerLogo = styled.img`
  width: 100%;
  object-fit: contain;
`;

function PartnersSection() {
  return (
    <PartnersBlock>
      <PartnersTitle>Ils nous font confiance </PartnersTitle>
      <HorizontalScrollList>
        <PartnersItemsContainer>
          {PartnersLogos.map((company) => {
            return (
              <PartnerCard>
                <PartnerLogo
                  key={company.name}
                  src={company.logoUrl}
                  alt={company.name}
                />
              </PartnerCard>
            );
          })}
        </PartnersItemsContainer>
      </HorizontalScrollList>
    </PartnersBlock>
  );
}

export default PartnersSection;
