/* ******************************************** */
/* * Author: Jamal SIDIKOU
/* * Project : SANNBIZ
/* * Create at : 03/06/2024
/* ******************************************* */

import React, { useEffect, useState } from 'react';
import SearchIcon from '../../atoms/Icon/SearchIcon';
// import CloseIcon from '../../atoms/Icon/CloseIcon';
import './SearchForm.css';
import 'react-responsive-modal/styles.css';
// import { getAllFromCollection } from '../../../api/firebase/firestore';
import {
  collection,
  onSnapshot,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';

import ResearchEventItem from '../ResearchEventItem/ResearchEventItem';
import ResearchCatItem from '../ResearchCatItem/ResearchCatItem';
import styled from 'styled-components';
import Popup from '../../atoms/Popup/Popup';
import {
  Divider,
  ModalCloseButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { TABLE_NAME, USER_STATUS } from '../../../utils/variables';
import ResearchPromoterItem from '../ResearchPromoterItem/ResearchPromoterItem';
import { EVENT_REVIEW_STATE } from '../../../utils/constants';
import { checkIfIsPastDate } from '../../../utils/date';
import { checkIfIsPastEvent } from '../../../utils/function';
// import { dependencyContainer } from '../../../api/dependencyContainer';

const Container = styled.div`
  /* height: 50%; */
`;
const ModalContainer = styled.div`
  /* width: 80%; */
  /* height:   30%; */
`;
const IconContainer = styled.div``;

const InputModal = styled.input`
  width: 100%;
  line-height: 50px;
  outline: none;
  :focus {
    outline: none;
  }
  margin-left: 10px;
  /* border:0px; */
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  align-items: center;
  border-radius: 25px;
  overflow: hidden;
  padding: 0 10px;
`;

const ListContainer = styled.div`
  overflow-y: scroll;
  background-color: white;
  margin-top: 10px;
  border-radius: 5px 5px;
  height: 100%;
  max-height: 350px;
`;

const TypeTitle = styled.h2`
  font-weight: bold;
  font-size: 1rem;
`;

const ContainerType = styled.div`
  margin-left: 5px;
  color: #f1360a;
`;

const SearchForm = ({ onSubmit, alwaysShowField }) => {
  const [events, setEvents] = useState([]);
  const [eventsToShow, setEventsToShow] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesToShow, setCategoriesToShow] = useState([]);
  const [promoters, setPromoters] = useState([]);
  const [promotersToShow, setPromotersToShow] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => setIsOpen(false);

  // Obtenir les events
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(
      collection(firestore_db, TABLE_NAME.events),
      where('review.state', '==', EVENT_REVIEW_STATE.ACCEPTED)
    );
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const eventsSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const eventsToDisplay = eventsSnapshot.filter(
          (event) => !checkIfIsPastEvent(event.dates)
        );
        setEvents(eventsToDisplay);
        setEventsToShow(eventsToDisplay);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  // Obtenir les categories
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore_db, TABLE_NAME.categories),
      (snapshot) => {
        const categoriesSnapshot = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesSnapshot);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, []);

  // Obtenir les promoters
  useEffect(() => {
    const getPromoters = async () => {
      try {
        const q = query(
          collection(firestore_db, 'users'),
          where('status', '==', USER_STATUS.PROMOTER)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) console.log('No such document!');
        else {
          const promotersSnapshot = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          // console.log(promotersSnapshot);
          setPromoters(promotersSnapshot);
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    };
    getPromoters();
  }, []);

  const filterByInput = (text) => {
    const newCategories = filterCategory(text);
    const newPromoters = filterPromoters(text);
    const newEvents =filterEvent(text);

    const isEventEmpty = newEvents.length === 0;
    const isCategorieEmpty = newCategories.length === 0;
    const isPromoterEmpty = newPromoters.length === 0;

    // console.log((newEvents.length === 0) && (newCategories === 0) && (newPromoters === 0));
    // console.log((newEvents.length === 0) && (newCategories === 0) && (newPromoters === 0));

    if (isEventEmpty && isCategorieEmpty && isPromoterEmpty) {
      setEventsToShow(events);
    }
  };

  const filterCategory = (text) => {
    var newCategories = [];
    var lowerText = text?.toLowerCase();
    if (text === '') newCategories = [];
    else
      newCategories = categories.filter((category) =>
        category.name?.toLowerCase().includes(lowerText)
      );
    setCategoriesToShow(newCategories);
    return newCategories;
  };

  const filterPromoters = (text) => {
    var newPromoters = [];
    var lowerText = text?.toLowerCase();
    if (text === '') newPromoters = [];
    else
      newPromoters = promoters.filter((promoter) =>
        promoter.businessName?.toLowerCase().includes(lowerText)
      );
    setPromotersToShow(newPromoters);
    return newPromoters;
  };

  const filterEvent = (text) => {
    var newEvents = [];
    var lowerText = text?.toLowerCase();
    if ( 
      // si le texte egale null
      !text || 
      // ou si le texte est vide
      text === '' || 
      // ou si le texte est undefined
      text === undefined 
      // si y'a rien à afficher
      // (promotersToShow.length === 0 && categoriesToShow === 0)
    ) newEvents = events;
    else
      newEvents = events.filter((event) =>
        event.name?.toLowerCase().includes(lowerText)
      );
    setEventsToShow(newEvents);
    return newEvents;
  };

  const handleChange = (event) => {
    let text = event?.target.value;
    if (!text) text = '';
    setSearchTerm(text);
    filterByInput(text);
  };

  // Utilisation de useBreakpointValue pour définir la largeur basée sur les breakpoints
  const width = useBreakpointValue({
    base: '100%',
    md: '80%',
    lg: '50%',
    xl: '40%',
  });

  return (
    <Container>
      <ModalContainer>
        <IconContainer onClick={onOpenModal}>
          <SearchIcon width="24px" className="search-icon" />
        </IconContainer>
        <Popup
          isOpen={isOpen}
          onClose={onCloseModal}
          width={width}
          backgroundColor={`transparent`}
          disableCloseButton
        >
          <InputContainer>
            <SearchIcon width="30px" className="search-icon" />
            <InputModal
              type="text"
              placeholder="Que voulez-vous rechercher ?"
              value={searchTerm}
              onChange={handleChange}
              name="search"
            />
            <IconContainer onClick={onOpenModal}>
              {/* <CloseIcon width="24px" className="search-icon" /> */}
            </IconContainer>
          </InputContainer>
          <ListContainer>
            <div style={{ marginTop: '10px' }} />

            {promotersToShow.length !== 0 && (
              <ContainerType>
                <TypeTitle> Promoteurs</TypeTitle>
                <Divider borderWidth="1px" borderColor="#F1360A" w={'90%'} />

                <div style={{ marginTop: '15px' }} />
              </ContainerType>
            )}

            {promotersToShow.length !== 0 &&
              promotersToShow.map((promoter) => (
                <ResearchPromoterItem
                  promoter={promoter}
                  onCloseModal={onCloseModal}
                />
              ))}

            {categoriesToShow.length !== 0 && (
              <ContainerType>
                <TypeTitle> Catégories</TypeTitle>
                <Divider borderWidth="1px" borderColor="#F1360A" w={'90%'} />
              </ContainerType>
            )}
            {categoriesToShow.length !== 0 &&
              categoriesToShow.map((category) => (
                <ResearchCatItem
                  category={category}
                  onCloseModal={onCloseModal}
                />
              ))}
            {eventsToShow.length !== 0 && (
              <ContainerType>
                <TypeTitle> Événements</TypeTitle>
                <Divider borderWidth="1px" borderColor="#F1360A" w={'90%'} />
                <div style={{ marginTop: '10px' }} />
              </ContainerType>
            )}
            {eventsToShow &&
              eventsToShow.map((event) => (
                <ResearchEventItem event={event} onCloseModal={onCloseModal} />
              ))}
          </ListContainer>
        </Popup>
      </ModalContainer>
    </Container>
  );
};

export default SearchForm;
