import React from 'react';
import Footer from '../../organisms/Footer/Footer';
import Header from '../../organisms/Header/Header';
import { Outlet } from 'react-router-dom';

function ClientLayout() {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default ClientLayout;
