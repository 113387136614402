/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 19/05/2024
/* ******************************************* */

import React, { useState, useEffect, useRef } from 'react';
import {
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';

import { firestore_db } from '../../../api/firebase/firebase-config';
import { uploadFile } from '../../../api/firebase/storage';
import AlertPopup from '../../atoms/AlertPopup/AlertPopup';

import FormField from '../../atoms/FormField/FormField';
import { useToast } from '@chakra-ui/react';
import styled from 'styled-components';

import { EVENT_MNGT_PLAN_ADVANTAGES_TYPE } from '../../../utils/constants';

const EditCategoryFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: 'center';
  justify-content: 'center';
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  height: '500px';
  padding: 20px;
`;

const FormFields = styled.div``;

const FormFieldsGrid = styled.div`
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

function EditBusinessAdvantagesForm({ advantage, isOpen, onOpen, onClose }) {
  const toast = useToast();

  const [description, setDescription] = useState(
    advantage ? advantage.description : null
  );
  const [type, setType] = useState(advantage ? advantage.type : null);

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const [occurence, setOccurence] = useState(
    advantage ? advantage.occurence : false
  );
  const [duration, setDuration] = useState(
    advantage ? advantage.duration : false
  );

  // Gère la creation
  const cancelRef = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const advantageData = {
        description,
        type,
        occurence,
        duration,
      };

      if (advantage) {
        // Mise à jour de la catégorie existante
        const docRef = doc(
          firestore_db,
          'dev_mngt_plan_advantages',
          advantage.id
        );
        await updateDoc(docRef, advantageData);
        toast({
          title: 'Catégorie modifiée',
          description: 'La catégorie a été mise à jour avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Création d'une nouvelle catégorie
        advantageData.createdAt = serverTimestamp(); // Ajout du champ createdAt uniquement ici
        const docRef = await addDoc(
          collection(firestore_db, 'dev_mngt_plan_advantages'),
          advantageData
        );
        toast({
          title: 'Avantage ajouté',
          description: "L'avantage a été mis à jour avec succès.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        console.log('New category created with ID:', docRef.id);
      }
      onClose(); // Fermer le formulaire après l'opération
    } catch (error) {
      toast({
        title: 'Erreur de mise à jour',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertPopup
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      onNo={onClose}
      onYes={handleSubmit}
      windowTitle={
        advantage ? 'Modifier la catégorie' : 'Créer une nouvelle catégorie'
      }
    >
      <EditCategoryFormContent>
        <FormFields>
          <FormField
            id="description"
            label="Description"
            inputType="text"
            defaultValue={description}
            placeholder="Entrez le nouveau nom de la catégorie"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            isRequired
          />
          <FormField
            id="type"
            label="Type"
            inputType="select"
            value={type}
            onChange={handleTypeChange}
            isRequired
          >
            {!advantage && <option value="">Sélectionnez un type</option>}
            {Object.entries(EVENT_MNGT_PLAN_ADVANTAGES_TYPE).map(
              ([key, value]) => {
                return (
                  <option key={key} value={value}>
                    {value}
                  </option>
                );
              }
            )}
          </FormField>

          <FormFieldsGrid>
            <FormField
              id="occurence "
              label="Peut-il s'appliquer plusieurs fois? "
              isChecked={occurence}
              inputType="toggle"
              onChange={(e) => setOccurence(e.target.checked)}
              isRequired
            />
            <FormField
              id="duration "
              label="S'applique t-il sur une durée donnée ? "
              inputType="toggle"
              isChecked={duration}
              onChange={(e) => setDuration(e.target.checked)}
              isRequired
            />
          </FormFieldsGrid>
        </FormFields>
      </EditCategoryFormContent>
    </AlertPopup>
  );
}

export default EditBusinessAdvantagesForm;
