/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import Header from '../../organisms/Header/Header';
import Footer from '../../organisms/Footer/Footer';
import PageLayout from '../../templates/PageLayout/PageLayout';
import bg_banner from '../../../assets/sanbbizLogos/admin_pages_cover.png';
import styled from 'styled-components';
import Error404Icon from '../../atoms/Icon/Error404Icon';

const ErrorWrapper = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorTitle = styled.h1`
  font-weight: 300;
`;

const ErrorSubtitle = styled.h2`
  font-weight: 300;
`;

function ErrorPage() {
  const title = 'Erreur page'; // Le titre de la page

  return (
    <>
      <Header />
      <PageLayout title={title}>
        <ErrorWrapper>
          <ErrorTitle>Oups...</ErrorTitle>
          <Error404Icon />
          <ErrorSubtitle>
            Il semblerait que la page que vous cherchez n’existe pas
          </ErrorSubtitle>
        </ErrorWrapper>
      </PageLayout>
      <Footer />
    </>
  );
}

export default ErrorPage;
