/* ******************************************** */
/* * Author: Fabrice NIKIEMA
/* * Project : SANNBIZ
/* * Create at : 01/04/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import HorizontalScrollList from '../../molecules/HorizontalScrollList/HorizontalScrollList';
import { sortEventsByTicketsSold } from '../../../utils/function';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import leaf from '../../../assets/medias/topEventLeaf.png';
import { TABLE_NAME } from '../../../utils/variables';

const TopEventCardContainer = styled.div``;

const TopEventCardContent = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  &:hover {
    box-shadow: 0 6px 10px rgba(5, 17, 69, 0.2);
    transform: translateY(-2px);
  }
`;
const TopEventCardRank = styled.div`
  position: absolute;
  bottom: 0;
  left: 5px;
  color: black;
  padding: 5px 5px;
  font-size: 6rem;
  z-index: 2;
  -webkit-text-stroke: 1px white;
  text-stroke: 1px white;
`;

const TopEventCardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

function TopEventListCard({ event, index }) {
  const navigate = useNavigate();
  const [clickStart, setClickStart] = useState(0);

  const redirectClick = (event, eventId) => {
    const clickDuration = Date.now() - clickStart;
    if (clickDuration < 200) {
      // 200 millisecondes considérées comme un clic simple
      navigate(`/events/${eventId}`);
    }
    window.scrollTo(0, 0); // Scroller vers le haut de la page
  };

  return (
    <TopEventCardContainer>
      <TopEventCardContent
        onMouseDown={() => setClickStart(Date.now())}
        onMouseUp={(e) => redirectClick(e, event.id)}
      >
        <TopEventCardRank>{index}</TopEventCardRank>
        <TopEventCardImg
          src={event.posterPicture?.url}
          alt="Poster de l'événeemnt"
        />
      </TopEventCardContent>
    </TopEventCardContainer>
  );
}

const TopEventListBlock = styled.div`
  position: relative;
  background: linear-gradient(to bottom, #ecf0f2 60%, white 60%);
  padding: 30px 0px;
  &:before {
    content: '';
    position: absolute;
    left: 1.5%;
    top: 60%;
    transform: translateY(-70%);
    width: 55px;
    height: 122px;
    background: url(${leaf}) no-repeat;
    background-size: cover;
    z-index: 1;
  }
`;

const TopEventListTitle = styled.h1`
  font-weight: bold;
  font-size: 32px;
  color: #051145;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopEventListCardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 10px;
`;

function TopEvents() {
  const [events, setEvents] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeEvents = onSnapshot(
      collection(firestore_db, TABLE_NAME.events),
      (snapshot) => {
        const loadedEvents = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(loadedEvents);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    const unsubscribeTickets = onSnapshot(
      collection(firestore_db, TABLE_NAME.tickets),
      (snapshot) => {
        const loadedTickets = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTickets(loadedTickets);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    );

    return () => {
      unsubscribeEvents();
      unsubscribeTickets();
    };
  }, []);

  const sortedEvents = sortEventsByTicketsSold(events, tickets).slice(0, 10);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <TopEventListBlock>
      <TopEventListTitle>Top 10</TopEventListTitle>
      <HorizontalScrollList>
        <TopEventListCardsContainer>
          {sortedEvents.map((event, index) => (
            <TopEventListCard key={event.id} event={event} index={index + 1} />
          ))}
        </TopEventListCardsContainer>
      </HorizontalScrollList>
    </TopEventListBlock>
  );
}

export default TopEvents;
