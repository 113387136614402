/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 12/04/2024
/* ******************************************* */

import React, { useState } from 'react';
import FormField from '../../atoms/FormField/FormField';
import { Button, Box } from '@chakra-ui/react';
import CreateEventTicketForm from '../../molecules/CreateEventTicketForm/CreateEventTicketForm';
import styled from 'styled-components';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  position: relative;
  padding: 20px;
`;

function CreateEventTicket() {
  // Initialisation directe de l'état avec un formulaire de billet
  const [forms, setForms] = useState([{ id: Date.now(), type: 'ticket' }]);

  const addForm = (type) => {
    const newForm = {
      id: Date.now(), // timestamp comme ID unique
      type: type,
    };
    setForms((currentForms) => [...currentForms, newForm]);
  };

  const removeForm = (id) => {
    setForms((currentForms) => currentForms.filter((form) => form.id !== id));
  };

  return (
    <div>
      {forms.map((form, index) => (
        <Box key={form.id} position="relative">
          <FormContainer>
            <CreateEventTicketForm index={index + 1} ticketType={form.type} />
            <Button
              position="absolute"
              right="6"
              top="6"
              size="xs"
              colorScheme="red"
              onClick={() => removeForm(form.id)}
            >
              &#10005; {/* Bouton pour supprimer le formulaire */}
            </Button>
          </FormContainer>
        </Box>
      ))}
      <FormField
        id="addNewTicketOrStand"
        inputType="div"
        onChange={() => {}}
        justify="space-evenly"
        gap="20px"
      >
        <Button size="md" onClick={() => addForm('ticket')}>
          Ajouter un billet
        </Button>
        <Button size="md" onClick={() => addForm('stand')}>
          Ajouter un Stand
        </Button>
      </FormField>
    </div>
  );
}

export default CreateEventTicket;
