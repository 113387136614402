/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 30/03/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore_db } from '../../../api/firebase/firebase-config';
import FormField from '../../atoms/FormField/FormField';
import './CreateEventFormStep1.css';
import { extractImageFileDetails } from '../../../utils/function';
import { TABLE_NAME } from '../../../utils/variables';

function CreateEventFormTypeNCategory({
  setEventCoverPicture,
  removeEventCoverPicture,
  setEventTypeEventId,
  setEventCategoryId,
}) {
  const dispatch = useDispatch();

  const [coverPicture, setCoverPicture] = useState(null);
  const [eventTypes, setEventTypes] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    // Charger les types d'événements
    const fetchEventTypes = async () => {
      const querySnapshot = await getDocs(
        collection(firestore_db, TABLE_NAME.eventTypes)
      );
      const types = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEventTypes(types);
    };

    fetchEventTypes();
  }, []);

  useEffect(() => {
    // Charger les catégories basées sur le type sélectionné
    const fetchCategories = async () => {
      if (!selectedType) return;

      const q = query(
        collection(firestore_db, TABLE_NAME.categories),
        where('typeId', '==', selectedType)
      );
      const querySnapshot = await getDocs(q);
      const cats = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(cats);
    };

    fetchCategories();
  }, [selectedType]);

  const handleCoverPictureChange = (files) => {
    const fileData = files[0]; // Assumant que files est un tableau et nous prenons le premier élément
    if (fileData) {
      extractImageFileDetails(fileData)
        .then(({ width, height, url }) => {
          dispatch(setEventCoverPicture({ width, height, url }));
          setCoverPicture(url); // stocker l'URL pour l'aperçu dans l'UI
        })
        .catch((error) =>
          console.error('Failed to load image details:', error)
        );
    }
  };

  const handleCoverRemoved = () => {
    setCoverPicture(null);
    dispatch(removeEventCoverPicture());
  };

  const handleTypeChange = (event) => {
    const typeId = event.target.value;
    setSelectedType(typeId);
    dispatch(setEventTypeEventId(typeId));
  };

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    dispatch(setEventCategoryId(categoryId));
  };

  return (
    <div className="step1-form-container">
      <h1 className="step1-form-title">Informations de l'événement</h1>
      <FormField
        id="coverPicture"
        label="Photo de couverture de l'événement"
        inputType="imageUploader"
        defaultImageUrl={coverPicture}
        onFilesSelected={handleCoverPictureChange}
        onImageRemoved={handleCoverRemoved}
        canBeCrop={true}
        imgWidth="100%"
        imgHeight="200px"
      />
      <form>
        <div className="step1-form-content-container">
          {eventTypes && (
            <FormField
              id="eventType"
              label="Type d'événement"
              isRequired={true}
              inputType="select"
              placeholder="Choississez le type d'événement"
              value={selectedType}
              onChange={handleTypeChange}
            >
              {eventTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </FormField>
          )}
          <FormField
            id="eventCategory"
            label="Catégorie de l'événement"
            isRequired={true}
            inputType="select"
            placeholder="Choisissez la catégorie"
            disabled={!selectedType || !categories}
            onChange={handleCategoryChange}
          >
            {categories &&
              categories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
          </FormField>
        </div>
      </form>
    </div>
  );
}

export default CreateEventFormTypeNCategory;
