/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 10/04/2024
/* ******************************************* */

import React, { useState, useEffect } from 'react';
import FormField from '../../atoms/FormField/FormField';
import PropTypes from 'prop-types';
import { Text } from '@chakra-ui/react';
import './CreateEventTicketForm.css';
import EndEventTicketDateSelector from '../EndEventTicketDateSelector/EndEventTicketDateSelector';

function CreateEventTicketForm({ index, ticketType }) {
  // Sélection du type d'entrée en fonction de la prop `ticketType`
  let TicketTypeComponent;

  switch (ticketType) {
    case 'ticket':
      TicketTypeComponent = (
        <>
          <Text fontSize="xl" as="b" p={2} textAlign="center">
            {' '}
            Création du billet {index}
          </Text>

          <div className="ticket-form-content-container">
            <div className="step4-form-content-left">
              <FormField
                id="eventTicketName"
                label="Nom du ticket"
                inputType="text"
                placeholder="ex: Standard, Vip, ..."
                onChange={() => {}}
              />

              <FormField
                id="eventTicketPrice"
                label="Prix du ticket"
                inputType="currency"
                placeholder="Quel est le prix ?"
                onChange={() => {}}
              />

              <FormField
                id="eventTicketStock"
                label="Quantité disponible "
                inputType="number"
                placeholder="ex: 400"
                onChange={() => {}}
              />
            </div>
            <div className="ticket-form-content-right">
              <FormField
                id="eventTicketDesc"
                label="Description du ticket"
                inputType="textarea"
                placeholder="Décrivez le ticket"
                onChange={() => {}}
                height="216px"
              />
            </div>
          </div>
          <EndEventTicketDateSelector />
        </>
      );
      break;

    case 'stand':
      TicketTypeComponent = (
        <>
          <Text fontSize="xl" as="b" p={2} textAlign="center">
            {' '}
            Création du stand {index}
          </Text>

          <div className="ticket-form-content-container">
            <div className="ticket-form-content-left">
              <FormField
                id="eventTicketName"
                label="Nom du stand"
                inputType="text"
                placeholder="ex: Standard, Vip, ..."
                onChange={() => {}}
              />

              <FormField
                id="eventTicketPrice"
                label="Prix du stand"
                inputType="number"
                placeholder="Quel est le prix ?"
                onChange={() => {}}
              />

              <FormField
                id="eventTicketStock"
                label="Quantité disponible "
                inputType="number"
                placeholder="ex: 5"
                onChange={() => {}}
              />
            </div>
            <div className="ticket-form-content-right">
              <FormField
                id="eventTicketDesc"
                label="Description du stand"
                inputType="textarea"
                placeholder="Décrivez le stand"
                onChange={() => {}}
                height="216px"
              />
            </div>
          </div>
          <EndEventTicketDateSelector />
        </>
      );
      break;

    // Ajout d'autres cas
    default:
      TicketTypeComponent = <></>;
      break;
  }

  return <form className="ticket-form-container">{TicketTypeComponent}</form>;
}

CreateEventTicketForm.propTypes = {
  index: PropTypes.string,
  ticketType: PropTypes.oneOf(['ticket', 'stand']),
};

export default CreateEventTicketForm;
