/* ******************************************** */
/* * Author: Nikiema Wendmanegré Darrel Fabrice 
/* * Project : SANNBIZ
/* * Create at : 25/03/2024
/* ******************************************* */

import React from 'react';
import { Box, Flex, Text, Icon, Link } from '@chakra-ui/react';
import { FaRegClock, FaFacebookF, FaInstagram, FaPhone } from 'react-icons/fa6';

import styled from 'styled-components';
import { useSanbbizConfig } from '../../../contexts/ConfigContext';

const StyledIcon = styled(Link)`
  background: white;
  border-radius: 50%;
  width: 18px;
  margin: 0;
  padding 2px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResponsiveBox = styled(Box)`
  @media (max-width: 930px) {
    display: none;
  }
`;

function TopHeader({ props }) {
  const { config } = useSanbbizConfig();
  return (
    <ResponsiveBox bg="#F1360A" color="white" p={0} height={20} {...props}>
      <Flex alignItems="center" justifyContent="space-between" mx="50px">
        <Flex alignItems="center" gap={3}>
          <Flex alignItems="center">
            <StyledIcon>
              <Icon as={FaRegClock} color="#000000" w="12px" />
            </StyledIcon>
            <Text fontSize="md">Lundi - Dimanche, 24h / 24h </Text>
          </Flex>

          <Flex alignItems="center">
            <StyledIcon>
              <Icon as={FaPhone} color="#000000" w="10px" />
            </StyledIcon>
            <Text fontSize="sm">{config.contact.phone}</Text>
          </Flex>
        </Flex>
        <Flex alignItems="center">
          <StyledIcon
            href="https://www.facebook.com/profile.php?id=61557393976958"
            isExternal
          >
            <Icon as={FaFacebookF} color="#000000" w="8px" />
          </StyledIcon>
          <StyledIcon href="https://www.instagram.com/sanbbiz/" isExternal>
            <Icon as={FaInstagram} color="#000000" w="12px" />
          </StyledIcon>
        </Flex>
      </Flex>
    </ResponsiveBox>
  );
}

export default TopHeader;
